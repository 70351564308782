import React, { Component } from "react";
import { UserContext } from "core/UserContext";
import { Grid, Hidden } from "@material-ui/core";
import { windowMobileDetection } from "core/windowMobileDetection";
import { Text } from "components/Atoms/Text";
import { Button } from "components/Atoms/Button";
import { ListCard } from "components/Organisms/ListCard";
import { Image } from "components/Atoms/Image";
import { Navbar } from "components/Organisms/NavBar";
import { Footer } from "components/Organisms/Footer";
import { getLink } from "assets/languages/i18n/links";

class ServicesAuxiliars extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  static contextType = UserContext;
  render() {
    return (
      <Grid container>
        {/* NavBar */}
        <Navbar
          translation={this.context.translation.templates.navbar}
          link="auxiliars"
        />
        {/* SECTION HEADER */}
        <Grid item xs={12} style={{ marginTop: "120px" }} />
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={12}>
              <Text
                variant={windowMobileDetection() ? "customize" : "h2"}
                weight
                fontSize="28px"
                textAlign={windowMobileDetection() ? "center" : "left"}
                margin="8px 4px"
              >
                {this.context.translation.templates.auxiliars.header.title}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text
                variant={windowMobileDetection() ? "customize" : "p2"}
                fontSize="18px"
                textAlign={windowMobileDetection() ? "center" : "left"}
                margin="0 4px 16px 4px"
                padding={windowMobileDetection() ? "0 16px" : "0 0 16px 0"}
              >
                {this.context.translation.templates.auxiliars.header.subtitle}
              </Text>
            </Grid>
            <Grid item xs={1} md={false} />
            <Grid item xs={10} md={12}>
              <Grid
                container
                spacing={windowMobileDetection() ? 2 : 4}
                style={{
                  paddingBottom: windowMobileDetection() ? "16px" : "64px",
                }}
              >
                <ListCard
                  items={Object.keys(
                    this.context.translation.templates.auxiliars.auxiliars.cards
                  ).map((card) => {
                    return {
                      xs: 12,
                      md: 6,
                      variant: "ImageCardBottom",
                      sizeTitle: windowMobileDetection() ? "18px" : "28px",
                      sizeSubtitle: windowMobileDetection() ? "16px" : "22px",
                      title:
                        this.context.translation.templates.auxiliars.auxiliars
                          .cards[card].title,
                      url: this.context.translation.templates.auxiliars
                        .auxiliars.cards[card].image,
                      light: true,
                      sizeImage: windowMobileDetection() ? "120%" : "120%",
                      height: windowMobileDetection() ? "100px" : "190px",
                    };
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={false} md={2} />
        {/* SECTION CONTRACT */}
        <Grid item xs={12}>
          <Grid
            container
            style={{
              background: "#F6F8F9",
              padding: windowMobileDetection() ? "8px 0" : "32px 0",
            }}
          >
            <Grid item xs={12}>
              <Text
                variant={windowMobileDetection() ? "customize" : "h2"}
                weight
                fontSize="28px"
                textAlign="center"
                margin={windowMobileDetection() ? "8px 0" : "0 0 24px 0"}
              >
                {this.context.translation.templates.auxiliars.contract.title}
              </Text>
            </Grid>
            <Grid item xs={1} md={5} />
            <Grid item xs={10} md={2} style={{ paddingBottom: "40px" }}>
              <Button
                weight
                variant="b3"
                label={
                  this.context.translation.templates.auxiliars.hire.buttons
                    .request_budget
                }
                fontSize={windowMobileDetection() ? "16px" : "20px"}
                margin={windowMobileDetection() ? "4px 0" : "8px 0"}
                link={getLink("budget")}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* SECTION KEYS */}
        <Grid container>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign="center"
              margin={windowMobileDetection() ? "8px 0" : " 24px 0"}
            >
              {this.context.translation.templates.auxiliars.keys.title}
            </Text>
          </Grid>

          <Grid item xs={2} md={1} />
          <Grid
            item
            xs={8}
            md={10}
            style={{ margin: windowMobileDetection() ? "8px 0" : "32px 0" }}
          >
            <Grid
              container
              spacing={windowMobileDetection() ? 2 : 4}
              justify="center"
            >
              <ListCard
                items={Object.keys(
                  this.context.translation.templates.auxiliars.keys.cards
                ).map((card) => {
                  return {
                    xs: 12,
                    md: 4,
                    variant: "TextCard",
                    sizeTitle: windowMobileDetection() ? "22px" : "28px",
                    sizeSubtitle: windowMobileDetection() ? "18px" : "24px",
                    title:
                      this.context.translation.templates.auxiliars.keys.cards[
                        card
                      ].title,
                    textLabel:
                      this.context.translation.templates.auxiliars.keys.cards[
                        card
                      ].text,
                    light: true,
                  };
                })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={6}>
                <Text
                  variant={windowMobileDetection() ? "customize" : "p2"}
                  fontSize="18px"
                  textAlign="center"
                  margin={
                    windowMobileDetection() ? "16px 0 16px 0" : "8px 0 [32px 0"
                  }
                  padding="0 16px"
                >
                  {this.context.translation.templates.auxiliars.keys.footer}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* SECTION OTHER SERVICES */}
        <Hidden smDown>
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                style={{
                  background: "#F6F8F9",
                  padding: windowMobileDetection() ? "16px 0" : "64px 0",
                }}
              >
                <Grid item xs={2} />
                <Grid
                  item
                  xs={8}
                  style={{ paddingBottom: "40px", paddingTop: "16px" }}
                >
                  <Grid container spacing={4}>
                    <ListCard
                      items={Object.keys(
                        this.context.translation.templates.auxiliars
                          .other_services.cards
                      ).map((card) => {
                        return {
                          xs: 6,
                          md: 3,
                          variant: "ImageCard",
                          // sizeTitle: windowMobileDetection() ? "16px" : "28px",
                          // title: this.context.translation.templates.auxiliars.other_services.cards[card]
                          //   .title,
                          url: this.context.translation.templates.auxiliars
                            .other_services.cards[card].image,
                          height: windowMobileDetection() ? "100px" : "260px",
                          sizeImage: windowMobileDetection() ? "200%" : "100%",
                        };
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        {/* SECTION CONTACT US */}
        <Hidden smUp>
          <Grid item xs={12}>
            <Grid container style={{ paddingTop: "16px" }}>
              <Grid item xs={1} md={5} />
              <Grid item xs={10} md={2}>
                <Text
                  variant={windowMobileDetection() ? "customize" : "p2"}
                  fontSize="18px"
                  textAlign="center"
                  margin="16px 0 16px 0"
                  padding="0 16px"
                >
                  {
                    this.context.translation.templates.auxiliars.contact_us
                      .title
                  }
                </Text>
              </Grid>
              <Grid item xs={1} md={5} />
              <Grid item xs={1} md={5} />
              <Grid item xs={10} md={2} style={{ paddingBottom: "40px" }}>
                <Button
                  weight
                  variant="b3"
                  label={
                    this.context.translation.templates.auxiliars.contact_us
                      .buttons.contact_us
                  }
                  fontSize={windowMobileDetection() ? "16px" : "20px"}
                  margin={windowMobileDetection() ? "4px 0" : "8px 0"}
                  link={getLink("budget")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Footer
          translation={this.context.translation.templates.footer}
          noMarginTop
        />
      </Grid>
    );
  }
}

export { ServicesAuxiliars };
