import React from "react";
import { Text } from "components/Atoms/Text";
import { Item } from "components/Atoms/Item";
import PropTypes from "prop-types";

const ListItem = (props) => {
  const items = props.items.map((item) => {
    // console.log("item", item);
    return (
      <Item link={item.link} key={item.id} external={item.external}>
        {item.component || (
          <Text style={item.styles} margin={item.margin}>
            {item.text}
          </Text>
        )}
      </Item>
    );
  });
  return [
    props.title && (
      <Text variant="customize" isBold color="#fff">
        {props.title || ""}
      </Text>
    ),
    [...items],
  ];
};

ListItem.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};

export { ListItem };
