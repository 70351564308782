const AssemblersTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.assemblers.header.title[typeFormat],
      subtitle: translation.assemblers.header.subtitle[typeFormat],
    },
    hire: {
      title: translation.assemblers.hire.title[typeFormat],
      buttons: {
        request_budget:
          translation.assemblers.hire.buttons.request_budget[typeFormat],
      },
    },
    assemblers: {
      cards: {
        auxiliars: {
          image:
            "services/assemblers/tarjetas_servicios_montadores_auxiliares.jpg",
          title:
            translation.assemblers.assemblers.cards.auxiliars.title[typeFormat],
          text: translation.assemblers.assemblers.cards.auxiliars.text[
            typeFormat
          ],
        },
        hand_basic: {
          image:
            "services/assemblers/tarjetas_servicios_montadores_hand_basico.jpg",
          title:
            translation.assemblers.assemblers.cards.hand_basic.title[
              typeFormat
            ],
          text: translation.assemblers.assemblers.cards.hand_basic.text[
            typeFormat
          ],
        },
      },
    },
    contract: {
      title: translation.assemblers.contract.title[typeFormat],
      buttons: {
        contact_us:
          translation.assemblers.contract.buttons.contact_us[typeFormat],
      },
    },
    keys: {
      title: translation.assemblers.keys.title[typeFormat],
      cards: {
        teamwork: {
          title: translation.assemblers.keys.cards.teamwork.title[typeFormat],
          text: translation.assemblers.keys.cards.teamwork.text[typeFormat],
        },
        experience: {
          title: translation.assemblers.keys.cards.experience.title[typeFormat],
          text: translation.assemblers.keys.cards.experience.text[typeFormat],
        },
      },
      footer: translation.assemblers.keys.footer[typeFormat],
    },
    other_services: {
      cards: {
        stand: {
          image: "services/hostesses.jpeg",
        },
        promoters: {
          image: "services/coordinators.jpg",
        },
        brand: {
          image: "services/cocktails.jpg",
        },
        assemblers: {
          image: "services/assemblers_hostesses.jpg",
        },
      },
    },
    contact_us: {
      title: translation.assemblers.contact_us.title[typeFormat],
      buttons: {
        contact_us:
          translation.assemblers.contact_us.buttons.contact_us[typeFormat],
      },
    },
  };
};

export { AssemblersTemplate };
