import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Text } from "components/Atoms/Text";
import { Link } from "components/Atoms/Link";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  b1: (props) => ({
    background: props.disabled ? "#999999" : "#000000",
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    width: "100%",
    color: "#ffffff",
    outline: "none",
  }),
  b1_disabled: {
    background: "#999999",
    borderRadius: "4px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    width: "100%",
    color: "#ffffff",
    outline: "none",
  },
  b2: {
    background: "transparent",
    borderRadius: "3px",
    border: "2px solid #000000",
    display: "flex",
    cursor: "pointer",
    textAlign: "center",
    justifyContent: "center",
    height: "46px",
    width: "100%",
    outline: "none",
  },
  b3: {
    background: "transparent",
    border: "1px solid #000000",
    borderRadius: "3px",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      background: "#000000",
      color: "#fff",
      transition: ".1s all ease-in",
      outline: "none",
    },
  },
  b4: {
    background: "#FFFFFF",
    boxShadow: "4px 3px 10px 0 rgba(0,0,0,0.15)",
    borderRadius: "10px",
    cursor: "pointer",
    width: "100%",
    outline: "none",
  },

  b5: {
    background: "#FFFFFF",
    boxShadow: "2px 1px 10px 0 rgba(0,0,0,0.20)",
    borderRadius: "3px",
    width: "100%",
    border: "0",
    cursor: "pointer",
    outline: "none",
  },
  b6: {
    background: "#FFFFFF",
    boxShadow: "4px 3px 10px 0 rgba(0,0,0,0.15)",
    borderRadius: "8px",
    width: "100%",
    outline: "none",
    cursor: "pointer",
  },
  b7: {
    background: "#FFFFFF",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.20)",
    borderRadius: "10px",
    width: "100%",
    border: 0,
    outline: "none",
    cursor: "pointer",
  },
}));

const Button = (props) => {
  const classes = useStyles(props.disabled);
  let content;

  if (props.externalLink) {
    content = (
      <a href={props.externalLink} target="blank">
        <button className={props.variant ? classes[props.variant] : null}>
          {props.children ? (
            props.children
          ) : (
            <Text weight={props.weight}>{props.label}</Text>
          )}
        </button>
      </a>
    );
  } else if (props.link) {
    content = (
      <Link to={props.link}>
        <button className={props.variant ? classes[props.variant] : null}>
          <Text
            weight={props.weight}
            fontSize={props.fontSize}
            margin={props.margin}
          >
            {props.label}
          </Text>
        </button>
      </Link>
    );
  } else {
    content = (
      <button
        className={props.variant ? classes[props.variant] : null}
        disabled={props.disabled}
        onClick={props.disabled ? null : props.onClick}
      >
        <Text
          weight={props.weight}
          fontSize={props.fontSize}
          margin={props.margin}
        >
          {props.label}
        </Text>
      </button>
    );
  }
  return content;
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  weight: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  link: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.string,
};

export { Button };
