import axios from "axios";

class axiosClient {
  constructor() {
    this.client = axios.create({
      // baseURL: process.env.REACT_APP_API_URL
      baseURL: process.env.REACT_APP_API_URL,
    });
  }

  get = (url) => {
    return this.client.get(url);
  };

  post = (url, data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    // console.log("form data", formData);
    return this.client.post(url, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };
}

export default axiosClient;
