import { templates } from "./templates";

class Language {
  constructor(language) {
    this.translation = require(`assets/languages/i18n/translations/${language}`).translation;
  }

  getLanguage = () => {
    return {
      templates: templates(this.translation),
    };
  };
}

export { Language };
