const translation = {
  thanks: {
    texts: {
      title: {
        mobile: "Message received!<br>We are back with you shortly ;)",
        web: "Message received!<br>We are back with you shortly ;)",
      },
    },
    buttons: {
      back_home: {
        mobile: "Back Home",
        web: "Back Home",
      },
    },
  },
  assemblers: {
    header: {
      title: {
        mobile: "Assemblers for events",
        web: "Assemblers for events",
      },
      subtitle: {
        mobile: "Setup and disambling of stands & structures.",
        web: "Setup and disambling of stands & structures.",
      },
    },
    hire: {
      title: {
        mobile: "Hire assemblers",
        web: "Hire assemblers",
      },
      buttons: {
        request_budget: {
          mobile: "Request budget",
          web: "Request budget",
        },
      },
    },
    assemblers: {
      cards: {
        auxiliars: {
          title: {
            mobile: "Setup assemblers",
            web: "Setup assemblers",
          },
          text: {
            mobile:
              "Helping on the packing and discharge of all the materials used on the setup.",
            web: "Helping on the packing and discharge of all the materials used on the setup.",
          },
        },
        hand_basic: {
          title: {
            mobile: "Basic hands",
            web: "Basic hands",
          },
          text: {
            mobile:
              "In charge of the setup and removal of booths of basic structure.",
            web: "In charge of the setup and removal of booths of basic structure.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "If you need assemblers for your event",
        web: "If you need assemblers for your event",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    keys: {
      title: {
        mobile: "Our keys",
        web: "Our keys",
      },
      cards: {
        teamwork: {
          title: {
            mobile: "Team Work",
            web: "Team Work",
          },
          text: {
            mobile:
              "Cohesion with teammates to combine expertise and technical abilities. Our mission is to create a great teamwork.",
            web: "Cohesion with teammates to combine expertise and technical abilities. Our mission is to create a great teamwork.",
          },
        },
        experience: {
          title: {
            mobile: "Experience",
            web: "Experience",
          },
          text: {
            mobile:
              "Key factor for complex setups and to get a quick and succesfull work done effective.",
            web: "Key factor for complex setups and to get a quick and succesfull work done effective.",
          },
        },
      },
      footer: {
        mobile: "",
        web: "Assemblers for events in Spain. The highest implication.",
      },
    },
    contact_us: {
      title: {
        mobile: "Assemblers for events in Spain. The highest implication.",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "",
        },
      },
    },
  },
  auxiliars: {
    header: {
      title: {
        mobile: "Assistants for events",
        web: "Assistants for events",
      },
      subtitle: {
        mobile: "Different profiles to solve certain tasks at your event.",
        web: "Different profiles to solve certain tasks at your event.",
      },
    },
    hire: {
      title: {
        mobile: "Hire assistants for events",
        web: "Hire assistants for events",
      },
      buttons: {
        request_budget: {
          mobile: "Request budget",
          web: "equest budget",
        },
      },
    },
    auxiliars: {
      cards: {
        access_staff: {
          title: {
            mobile: "Access Staff",
            web: "Access Staff",
          },
        },
        cashless: {
          title: {
            mobile: "Cashless",
            web: "Cashless",
          },
        },
        wristband_staff: {
          title: {
            mobile: "Wristband Staff",
            web: "Wristband Staff",
          },
        },
        merchandising: {
          title: {
            mobile: "Merchandising",
            web: "Merchandising",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Do you need staff for your event?",
        web: "Do you need staff for your event?",
      },
      buttons: {
        contact_us: {
          mobile: "Let us know",
          web: "Let us know",
        },
      },
    },
    keys: {
      title: {
        mobile: "Our keys",
        web: "Our keys",
      },
      cards: {
        experience: {
          title: {
            mobile: "Experience",
            web: "Experience",
          },
          text: {
            mobile:
              "Drivers to facilitate the transfer of visitants attending the event. From the airport to the hotel, and from the hotel to the event.",
            web: "Drivers to facilitate the transfer of visitants attending the event. From the airport to the hotel, and from the hotel to the event.",
          },
        },
        attitude: {
          title: {
            mobile: "Attitude",
            web: "Attitude",
          },
          text: {
            mobile:
              "Be professional and take your job serious is never granted. This is key for having committed people in your team.",
            web: "Be professional and take your job serious is never granted. This is key for having committed people in your team.",
          },
        },
      },
      footer: {
        mobile: "",
        web: "Different type of staff for events. For all kind of events.",
      },
    },
    contact_us: {
      title: {
        mobile: "Different type of staff for events. For all kind of events.",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "",
        },
      },
    },
  },
  waiters: {
    header: {
      title: {
        mobile: "Servers for events",
        web: "Servers for events",
      },
      subtitle: {
        mobile: "Servers for your events with catering and drinks.",
        web: "Servers for your events with catering and drinks.",
      },
    },
    hire: {
      title: {
        mobile: "Hire waiters",
        web: "Hire waiters",
      },
      buttons: {
        request_budget: {
          mobile: "Request budget",
          web: "equest budget",
        },
      },
    },
    waiters: {
      cards: {
        waiters: {
          title: {
            mobile: "Servers",
            web: "Servers",
          },
          text: {
            mobile: "Experienced staff, perfectly uniformed.",
            web: "Experienced staff, perfectly uniformed.",
          },
        },
        cocktail_shakers: {
          title: {
            mobile: "Mixologists",
            web: "Mixologists",
          },
          text: {
            mobile: "Experts in cocktails, precision and a great image.",
            web: "Experts in cocktails, precision and a great image.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Do you need servers for your event?",
        web: "Do you need servers for your event?",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    keys: {
      title: {
        mobile: "Our keys",
        web: "Our keys",
      },
      cards: {
        experience: {
          title: {
            mobile: "Expertise",
            web: "Expertise",
          },
          text: {
            mobile:
              "Carrying trays or make a good cocktail is not that easy. Expertise is a important requirement here.",
            web: "Carrying trays or make a good cocktail is not that easy. Expertise is a important requirement here.",
          },
        },
        uniformity: {
          title: {
            mobile: "Uniforms",
            web: "Uniforms",
          },
          text: {
            mobile:
              "All the teamwork well uniformed at the event. This is essential for the image of your event.",
            web: "All the teamwork well uniformed at the event. This is essential for the image of your event.",
          },
        },
      },
      footer: {
        mobile: "",
        web: "Servers for events all around Spain.",
      },
    },
    contact_us: {
      title: {
        mobile: "Servers for events all around Spain.",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "",
        },
      },
    },
  },
  about_us: {
    header: {
      title: {
        mobile: "Eloquence",
        web: "Eloquence",
      },
      texts: {
        info: {
          mobile:
            "We are a trusted temporary staff company, putting all our effort to make the best of your event.",
          web: "We are a trusted temporary staff company, putting all our effort to make the best of your event.",
        },
      },
      buttons: {
        contact_us: {
          mobile: null,
          web: "Contact us",
        },
      },
    },
    our_values: {
      title: { mobile: "Our values", web: "Our values" },
      cards: {
        support: {
          title: { mobile: "Excellence", web: "Excellence" },
          text: {
            mobile:
              "A five stars service in each of the events we participate.",
            web: "A five stars service in each of the events we participate.",
          },
        },
        excellency: {
          title: { mobile: "Digital", web: "Digital" },
          text: {
            mobile:
              "We are young and quick.<br/>Be digital allow us to be more effective.",
            web: "We are young and quick.<br/>Be digital allow us to be more effective.",
          },
        },
        decisives: {
          title: { mobile: "Treatment", web: "Treatment" },
          text: {
            mobile: "We take care in the same way of our clients and staff.",
            web: "We take care in the same way of our clients and staff.",
          },
        },
      },
    },
    our_history: {
      title: { mobile: "Our history", web: "Our history" },
      footer: {
        web: "Our first event, September 2016, Madrid.”",
        mobile: "Our first event, September 2016, Madrid.”",
      },
      text: {
        mobile:
          "Eloquence become reality in September 2016, focused on providing hostesses to international companies in Madrid.<br/><br/>We wanted to help all those companies that visit trade-shows, conference and events in our country. <br/><br/>Our main goal was to use English for helping everyone, from our staff to our communication with everyone, taking care in the same way of our clients and our staff. This helped us to become one of the most valued agencies for international companies in Spain, experts on the international market.<br/><br/>In 2019 we openned our new office in Barcelona. In 2020 we advanced to become a staff company, offering several type of profiles, from hostesses to hands.<br/><br/>Our future is next to the ultimate technology.<br/><br/> Are you comming with us?",
        web: "Eloquence become reality in September 2016, focused on providing hostesses to international companies in Madrid.<br/><br/>We wanted to help all those companies that visit trade-shows, conference and events in our country. <br/><br/>Our main goal was to use English for helping everyone, from our staff to our communication with everyone, taking care in the same way of our clients and our staff. This helped us to become one of the most valued agencies for international companies in Spain, experts on the international market.<br/><br/>In 2019 we openned our new office in Barcelona. In 2020 we advanced to become a staff company, offering several type of profiles, from hostesses to hands.<br/><br/>Our future is next to the ultimate technology.<br/><br/> Are you comming with us?",
      },
    },
    success_stories: {
      title: { mobile: "Success story", web: "Success story" },
      footer: {
        web: "Date: June 2019<br/><br/>Place: Madrid (Wanda Metropolitano Stadium)",
        mobile:
          "Date: June 2019<br/><br/>Place: Madrid (Wanda Metropolitano Stadium)",
      },
      subtitle: {
        mobile: "UEFA Champions League",
        web: "UEFA Champions League",
      },
      text: {
        mobile:
          "Eloquence was selected as the official hostess agency by the UEFA to organize all the staff around the big European football final. We took care of the selection, training, and implementation of 94 brand host and hostesses.<br/><br/>The UEFA prepared super big lounge where all the sponsors came together, Mastercard, Adidas and Heineken among others. The area put together more than 3000 fans during the eight hours doors stayed opened.<br><br/>Our team worked for more than three months on the proyect, screening from more than 500 people, standing out 12 group leaders, in charge of each sponsor and defined area. Three Eloquence's coordinators in contact with the main organizators.<br/>The main goal was to offer an excellent service, as well as to achieve the durability of the memories of the fans. Giving away big smiles, we wished the best of the luck to both fans teams and congrated the winnings and comfort losers. <br/>The event was an real success, obtaining the congratulation of the mayority of the sponsors.",
        web: "Eloquence was selected as the official hostess agency by the UEFA to organize all the staff around the big European football final. We took care of the selection, training, and implementation of 94 brand host and hostesses.<br/><br/>The UEFA prepared super big lounge where all the sponsors came together, Mastercard, Adidas and Heineken among others. The area put together more than 3000 fans during the eight hours doors stayed opened.<br><br/>Our team worked for more than three months on the proyect, screening from more than 500 people, standing out 12 group leaders, in charge of each sponsor and defined area. Three Eloquence's coordinators in contact with the main organizators.<br/>The main goal was to offer an excellent service, as well as to achieve the durability of the memories of the fans. Giving away big smiles, we wished the best of the luck to both fans teams and congrated the winnings and comfort losers. <br/>The event was an real success, obtaining the congratulation of the mayority of the sponsors.",
      },
    },
    budget: {
      buttons: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
  },
  budget: {
    header: {
      title: {
        mobile: "Do you need a quote?",
        web: "Do you need a quote?",
      },
      card: {
        title: {
          mobile: null,
          web: "Let us know what you need!",
        },
        subtitle: {
          mobile: null,
          web: "Fill the form and we will get back to you within 24h",
        },
        form: {
          mobile: null,
          web: {
            inputs: {
              name: { label: "Name", placeholder: "John Cameron" },
              email: { label: "Email", placeholder: "john@company.com" },
              phone: { label: "Phone", placeholder: "+33654939705" },
              city: {
                label: "Where is it?",
                placeholder: "Madrid, Barcelona, Bilbao...",
              },
              number_hostess: {
                label: "Nº of people",
                placeholder: "?",
              },
              message: {
                label: "Let us know a bit more about the project",
                placeholder: "Dates, city, brand, etc.",
              },
            },
            selects: {
              profile_type: {
                label: "Type of profile",
                placeholder: "Type of profile",
                width: 122,
                items: [
                  { value: "hostesses", label: "Hostesses" },
                  { value: "assemblers", label: "Assemblers" },
                  { value: "assistants", label: "Assistants" },
                  { value: "servers", label: "servers" },
                ],
              },
            },
            checkboxs: {
              privacy: {
                label: "I accept",
                link: "the privacy policies",
              },
            },
            buttons: {
              send: "Send",
            },
          },
        },
      },
    },
    subtitle: { mobile: "Let us know more details", web: null },
    form: {
      mobile: {
        inputs: {
          name: { label: "Name", placeholder: "John Cameron" },
          email: { label: "Email", placeholder: "john@ocompany.com" },
          phone: { label: "Phone", placeholder: "+34 654939705" },
          city: {
            label: "City of the event",
            placeholder: "Where it happens?",
          },
          number_hostess: {
            label: "Nº of people",
            placeholder: "?",
          },
          message: {
            label: "Details",
            placeholder: "Tell us more about the event, When is it?",
          },
        },
        checkboxs: {
          privacy: { label: "I accept ", link: "the privacy policies" },
        },
        buttons: {
          send: "Send",
        },
      },
      web: null,
    },
    contact_us: {
      title: {
        mobile: "Do you prefer to contact us?",
        web: "Do you prefer to contact us?",
      },
      card: {
        call_us: {
          title: { mobile: "Call us", web: "Call us" },
          text: {
            mobile: "<a href='tel:+34650377313'>+34 650 377 313</a>",
            web: "<a href='tel:+34650377313'>+34 650 377 313</a>",
          },
        },
        write_us: {
          title: { mobile: "Email us", web: "Email us" },
          text: {
            mobile:
              "<a href='mailto:sales@eloquence.es'>sales@eloquence.es</a>",
            web: "<a href='mailto:sales@eloquence.es'>sales@eloquence.es</a>",
          },
        },
      },
      buttons: {
        back_home: {
          label: { mobile: "Go to home", web: null },
        },
      },
    },
  },
  contact_us: {
    header: {
      title: {
        mobile: "A team ready to help you",
        web: "A team ready to help you",
      },
    },
    form: {
      title: {
        mobile: "How can we help you?",
        web: null,
      },
      subtitle: {
        mobile: "We'll reply you in less than 24h",
        web: null,
      },
      card: {
        mobile: {
          inputs: {
            name: { label: "Name", placeholder: "John Cameron" },
            email: { label: "Email", placeholder: "johncameron@company.com" },
            phone: { label: "Phone", placeholder: "+34 654939705" },
            city: {
              label: "City of the event",
              placeholder: "Where does the event happens?",
            },
            number_hostess: {
              label: "Number of people do you need",
              placeholder: "?",
            },
            message: {
              label:
                "Let us know a bit more about it (This form is for quotations and clients only)",
              placeholder: "When is the event?",
            },
          },
          checkboxs: {
            privacy: {
              label: "I accept",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Send",
          },
        },
        web: {
          title: "Can we help you?",
          subtitle: "Let us know how! We'll reply you in less than 24h.",
          inputs: {
            name: { label: "Complete name", placeholder: "John Cameron" },
            email: { label: "Email", placeholder: "johncameronz@company.com" },
            phone: { label: "Phone", placeholder: "+34650377313" },
            city: { label: "City of the event", placeholder: "Where is it?" },
            number_hostess: {
              label: "Nº of people do you need",
              placeholder: "?",
            },
            message: {
              label:
                "Let us know a bit more (This form is for quotations and clients only)",
              placeholder: "Comment any doubt",
            },
          },
          checkboxs: {
            privacy: {
              label: "I accept ",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Send",
          },
        },
      },
    },
    contact_us: {
      card: {
        call_us: {
          title: { mobile: "Call us", web: "Call us" },
          text: {
            mobile: "<a href='tel:+34650377313'>+34 650 37 73 13</a>",
            web: "<a href='tel:+34650377313'>+34 650 37 73 13</a>",
          },
        },
        write_us: {
          title: {
            mobile: "Write us",
            web: "Write us",
          },
          text: {
            mobile:
              "<a href='mailto:sales@eloquence.es'>sales@eloquence.es</a><br/>(hire staff)<br/><a href='mailto:work@eloquence.es'>work@eloquence.es</a><br/>(work with us)",
            web: "<a href='mailto:sales@eloquence.es'>sales@eloquence.es</a> (hire staff)<br/><a href='mailto:work@eloquence.es'>work@eloquence.es</a> (work with us)",
          },
        },
        our_offices: {
          title: { mobile: null, web: "Our offices" },
          madrid: {
            mobile: null,
            web: "C. del Marqués de Mondéjar 29-31, Planta 1-1 (Madrid)",
          },
          barcelona: {
            mobile: null,
            web: "C/ de València, 352, Principal, Primera, Eixample (Barcelona)<br/><br/><span style='font-size:28px'><b>Send us your CV</b></span><br/><a href='mailto:work@eloquence.es'>work@eloquence.es</a>",
          },
        },
        button: {
          work_with_us: {
            mobile: "Work with us",
            web: "Work with us",
          },
        },
      },
    },
    where_we_are: {
      title: { mobile: "Where we are", web: "Where we are" },
      madrid: {
        title: { mobile: "Madrid", web: "Madrid" },
        text: {
          mobile: "C. del Marqués de Mondéjar 29-31, Planta 1-1 (Madrid)",
          web: "C. del Marqués de Mondéjar 29-31, Planta 1-1 (Madrid)",
        },
      },
      barcelona: {
        title: { mobile: "Barcelona", web: "Barcelona" },
        text: {
          mobile:
            "C/ de València, 352, Principal, Primera, Eixample, 08009 Barcelona",
          web: "C/ de València, 352, Principal, Primera, Eixample, 08009 Barcelona",
        },
      },
      cv: {
        title: "Send us your CV",
      },
    },
    instagram: {
      title: {
        mobile: "Follow us in instagram",
        web: "Follow us in instagram",
      },
    },
    budget: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: null,
        },
      },
    },
  },
  home: {
    header: {
      title: {
        mobile: "Hire staff for your event nationwide",
        web: "",
      },
      ad: {
        web: "<b>Do you want to work with us?</b><br/>Send your CV at <a href='mailto:work@eloquence.es' style='color:#2FBCF0'>work@eloquence.es</a>",
      },
      form: {
        mobile: null,
        web: {
          title: "Hire staff for your event nationwide",
          subtitle: "Do you need a quote?",
          inputs: {
            email: { label: "Email", placeholder: "email@company.com" },
            phone: {
              label: "Phone Number",
              placeholder: "+34 654939705",
            },
            number_hostess: { label: "Number of people", placeholder: "?" },
            details: {
              label: "Details (This form is for quotations and clients only)",
              placeholder: "When and where is happening?",
            },
          },
          selects: {
            profile_type: {
              label: "Type of profile",
              placeholder: "Type of profile",
              width: 122,
              items: [
                { value: "hostesses", label: "Hostesses" },
                { value: "hands", label: "Hands" },
                { value: "staff", label: "Staff" },
                { value: "servers", label: "servers" },
              ],
            },
          },
          checkboxs: {
            privacy: {
              label: "I accept",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Send",
          },
        },
      },
    },
    budget: {
      mobile: {
        title: "Do you need a  quote?",
        subtitle: "Hire staff for your event",
        budget: {
          buttons: {
            request_budget: "Request budget",
          },
        },
      },
      web: null,
    },
    type_profile: {
      title: {
        mobile: "",
        web: "Choose the profile you need",
      },
      subtitle: {
        mobile: "We offer different profiles for your event",
        web: "We offer different profiles for your event throughout Spain",
      },
      cards: {
        hostesses: { title: { mobile: "Hostesses", web: "Hostesses" } },
        assemblers: { title: { mobile: "Hands", web: "Hands" } },
        auxiliars: { title: { mobile: "Staff", web: "Staff" } },
        waiters: { title: { mobile: "Servers", web: "Servers" } },
      },
    },
    guarantee: {
      title: {
        mobile: "How do we make it?",
        web: "How do we make it?",
      },
      cards: {
        quick: {
          title: { mobile: "Digital", web: "Digital" },
          text: {
            mobile: "We use our own software to manage the workflow.",
            web: "We use our own software to manage the workflow.",
          },
        },
        support: {
          title: { mobile: "Support", web: "Support" },
          text: {
            mobile:
              "We are close to our clients and staff. We take care of you all.",
            web: "We are close to our clients and staff. We take care of you all.",
          },
        },
        trust: {
          title: { mobile: "Quality", web: "Quality" },
          text: {
            mobile:
              "We follow the FIVE stars philosophy. The perfection itself.",
            web: "We follow the FIVE stars philosophy. The perfection itself.",
          },
        },
      },
    },
    result: {
      title: {
        mobile: "Result matter",
        web: "Result matter",
      },
      subtitle: {
        mobile: "What people say about us",
        web: "What people say about us",
      },
      cards: {
        philipp: {
          text: {
            mobile:
              "We are quite happy. When our hostess got sick they went above and beyond in finding a replacement within hours and made sure had spanish at the booth at all time.",
            web: "We are quite happy. When our hostess got sick they went above and beyond in finding a replacement within hours and made sure had spanish at the booth at all time.",
          },
          name: {
            mobile: "Philipp Zeiske, Bisutex",
            web: "Philipp Zeiske, Bisutex",
          },
        },
        sara: {
          text: {
            mobile:
              "Top agency and professionals, they look after their hostesses. They make every project more pleasant and fun. I can say they have nothing to envy to whichever agency.",
            web: "Top agency and professionals, they look after their hostesses. They make every project more pleasant and fun. I can say they have nothing to envy to whichever agency.",
          },
          name: {
            mobile: "Sara P, Hostess",
            web: "Sara P, Hostess",
          },
        },
        wendy: {
          text: {
            mobile:
              "The hostess quickly understood her role and responded to our clients, partners and staff, as if she were an employee of our company.Thank you!",
            web: "The hostess quickly understood her role and responded to our clients, partners and staff, as if she were an employee of our company.Thank you!",
          },
          name: {
            mobile: "Wendi W, Mobile World Congress",
            web: "Wendi W, Mobile World Congress",
          },
        },
      },
      buttons: {
        reviews: {
          mobile: "Check our reviews",
          web: "Check our reviews",
        },
      },
    },
    contact_us: {
      title: {
        mobile: "Want to know more?",
        web: "Want to know more?",
      },
      subtitle: {
        mobile: "Contact us, we are happy to help",
        web: "Contact us, we are<br/>happy to help",
      },
      phone: {
        mobile:
          "Hire staff:<br/><b><a href='tel:+34650377313'>+34 650 377 313</a></b> / <b><a href='mailto:sales@eloquence.es'>sales@eloquence.es</a></b>",
        web: "Hire staff:<b><a href='tel:+34650377313'>+34 650 377 313</a></b> /  <b><a href='mailto:sales@eloquence.es'>sales@eloquence.es</a></b>",
      },
      email: {
        mobile:
          "Work with us:<br/><b><a href='tel:+34910557420'>+34 910 557 420</a></b> / <b><a href='mailto:work@eloquence.es'>work@eloquence.es</a></b>",
        web: "Work with us: <b><a href='tel:+34910557420'>+34 910 557 420</a></b> / <b><a href='mailto:work@eloquence.es'>work@eloquence.es</a></b>",
      },
      buttons: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
    work_with_us: {
      title: {
        mobile: "Work with us",
        web: "Work with us",
      },
      subtitle: {
        mobile: "Fill out our form",
        web: "Fill out our form",
      },
      buttons: {
        join: {
          mobile: "Join to Eloquence",
          web: "Join to Eloquence",
        },
      },
      text: {
        mobile: "or email us to hostess@eloquence.es",
        web: "or email us to hostess@eloquence.es",
      },
    },
  },
  payment: {
    header: null,
    payment_gateway: {
      title: {
        mobile: "Welcome to the payment gateway",
        web: "Welcome to the payment gateway",
      },
      card: {
        title: {
          mobile: "Fill the fields",
          web: "Fill the fields",
        },
        subtitle: {
          mobile: "You will find the data on your invoice",
          web: "You will find the data on your invoice",
        },
        form: {
          inputs: {
            company: {
              label: "Company",
              placeholder: "Name of the company",
            },
            number_invoice: {
              label: "Nº of invoice",
              placeholder: "ELOQ-XXX",
            },
            cuantity: {
              label: "Amount",
              placeholder: "Showed in your invoice",
            },
          },
          checkboxs: {
            privacy: {
              label: "I accept ",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Pay",
          },
        },
      },
    },
    some_dude: {
      title: { mobile: "Any doubt?", web: "Any doubt?" },
      subtitle: {
        mobile: "Get in touch with us",
        web: "Get in touch with us",
      },
      phone: {
        mobile: "Phone <a href='tel:+34650377313'>+34 650 377 313</a>",
        web: "Phone <a href='tel:+34650377313'>+34 650 377 313</a>",
      },
      email: {
        mobile:
          "Email: <a href='mailto:sales@eloquence.es'>sales@eloquence.es</a>",
        web: "Email: <a href='mailto:sales@eloquence.es'>sales@eloquence.es</a>",
      },
    },
  },
  services: {
    header: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
    hostesses: {
      title: {
        mobile: "Event Staffing company",
        web: "Event Staffing company",
      },
      subtitle: {
        mobile: "Choose the type of profile you need",
        web: "Choose the type of profile you need",
      },
      cards: {
        hostesses: {
          title: { mobile: "Hostesses", web: "Hostesses" },
          text: {
            mobile:
              "For all kinds of events: congresses, trade-shows, promotions, etc.They are the image and first contact of the brands they represent.",
            web: "For all kinds of events: congresses, trade-shows, promotions, etc. They are the image and first contact of the brands they represent.",
          },
        },
        assemblers: {
          title: { mobile: "Assemblers", web: "Assemblers" },
          text: {
            mobile:
              "Set up and removal of booths. Packing and discharge of materials. They work as a team.",
            web: "Set up and removal of booths. Packing and discharge of materials. They work as a team.<br/><br/>",
          },
        },
        auxiliars: {
          title: { mobile: "Assistants", web: "Assistants" },
          text: {
            mobile:
              "Staff to cover special needs of your event, big concerts and festivals.",
            web: "Staff to cover special needs of your event, big concerts and festivals.",
          },
        },
        waiters: {
          title: { mobile: "Servers", web: "Servers" },
          text: {
            mobile:
              "Servers and mixologists to serve and give a touch of flavour and image to your event.",
            web: "Servers and mixologists to serve and give a touch of flavour and image to your event.",
          },
        },
      },
      footer: {
        mobile:
          "Check <b><a href='https://www.google.com/search?q=Eloquence+Hostess+Madrid&stick=H4sIAAAAAAAA_-NgU1IxqEgxMTKyTDRNM0oxNUsxTrMyqDBOTDZMMk1KsUxLMrU0NzJZxCrhmpNfWJqal5yq4JFfXJJaXKzgm5hSlJkCAAE1ayBDAAAA&hl=es&mat=CS-yoVdVHj_eElYBNqvzOvy_SuZOdFClVKeNTP-oHmjewOEEE_QrS20rxq4vzK8zHtaW6RoMGsR4m2tctnG9OnSXgCCrSWQTcNvIkcdl1E8f-VZPsGO4jpUMjjNpM8Y4mA&authuser=0'>our reviews</a></b> as agency!",
        web: "Check <b><a href='https://www.google.com/search?q=Eloquence+Hostess+Madrid&stick=H4sIAAAAAAAA_-NgU1IxqEgxMTKyTDRNM0oxNUsxTrMyqDBOTDZMMk1KsUxLMrU0NzJZxCrhmpNfWJqal5yq4JFfXJJaXKzgm5hSlJkCAAE1ayBDAAAA&hl=es&mat=CS-yoVdVHj_eElYBNqvzOvy_SuZOdFClVKeNTP-oHmjewOEEE_QrS20rxq4vzK8zHtaW6RoMGsR4m2tctnG9OnSXgCCrSWQTcNvIkcdl1E8f-VZPsGO4jpUMjjNpM8Y4mA&authuser=0'>our reviews</a></b> as agency!",
      },
    },
    doubt: {
      title: {
        mobile: "Do you need staff for your event?",
        web: "Do you need staff for your event?",
      },
      button: {
        contact_us: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    how_we_work: {
      title: { mobile: "How we make it", web: "How we make it" },
      cards: {
        selection: {
          title: { mobile: "Screeening", web: "Screening" },
          text: {
            mobile: "Profiles 100% verified throughout interviews.",
            web: "Profiles 100% verified throughout interviews.",
          },
        },
        planning: {
          title: { mobile: "Report & Feedback", web: "Report & Feedback" },
          text: {
            mobile: "We rate and review the performance of our staff.",
            web: "We rate and review the performance of our staff.",
          },
        },
        support: {
          title: { mobile: "Support", web: "Support" },
          text: {
            mobile: "We are always present at the event, attending any need.",
            web: "We are always present at the event, attending any need.",
          },
        },
      },
      footer: {
        mobile:
          "Trusted temporary staff company. We work all around Spain, at any type of event.",
        web: "Trusted temporary staff company. We work all around Spain, at any type of event.",
      },
    },
    request_budget: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
  },
  hostesses: {
    header: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
    hostesses: {
      title: {
        mobile: "Hostess agency in Spain",
        web: "Hostess agency in Spain",
      },
      subtitle: {
        mobile: "Different type of hostesses for your event",
        web: "Different type of hostesses for your event",
      },
      cards: {
        congress_hostesses: {
          title: {
            mobile: "Congress hostess",
            web: "Congress hostess",
          },
          text: {
            mobile:
              "They are essential due to their resolutive capacity. They work as a team to solve all the tasks in the event. They work so that congress is a success.",
            web: "They are essential due to their resolutive capacity. They work as a team to solve all the tasks in the event. They work so that congress is a success.",
          },
        },
        brand_hostesses: {
          title: { mobile: "Brand hostess", web: "Brand hostess" },
          text: {
            mobile:
              "They are the image of the brand, so it is crucial their appearance look perfect. They meet specific requirements of the brand itself.",
            web: "They are the image of the brand, so it is crucial their appearance look perfect. They meet specific requirements of the brand itself.",
          },
        },
        stand_hostesses: {
          title: { mobile: "Trade-Show hostess", web: "Trade-show hostess" },
          text: {
            mobile:
              "They are the first image of the company. They are responsible for receiving and attending the stand attendees. They have real knowledge about the company they represent.",
            web: "They are the first image of the company. They are responsible for receiving and attending the stand attendees. They have real knowledge about the company they represent.",
          },
        },
        promoter_hostesses: {
          title: { mobile: "Hostess promoter", web: "Hostess promoter" },
          text: {
            mobile:
              "They are located at the sales point of the brand. Having an essential role in the sales process. They are identified as a sales person from the store itself.",
            web: "They are located at the sales point of the brand. Having an essential role in the sales process. They are identified as a sales person from the store itself.",
          },
        },
      },
    },
    doubt: {
      title: {
        mobile: "Do you need hostesses?",
        web: "Do you need hostesses at your event?",
      },
      button: {
        contact_us: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    how_we_work: {
      title: {
        mobile: "Our three requirements",
        web: "Our three requirements",
      },
      cards: {
        selection: {
          title: { mobile: "Attitude", web: "Attitude" },
          text: {
            mobile:
              "The most important requirement for us. If there is no working attitude there is nothing. ",
            web: "The most important requirement for us. If there is no working attitude there is nothing. ",
          },
        },
        planning: {
          title: { mobile: "Languages", web: "Languages" },
          text: {
            mobile:
              "All our hostesses speak fluent English. This is mandatory and a basic requirement for us.",
            web: "All our hostesses speak fluent English. This is mandatory and a basic requirement for us.",
          },
        },
        support: {
          title: { mobile: "Appereance", web: "Appereance" },
          text: {
            mobile:
              "It is all about the image of your brand and company, it must be very well represented.",
            web: "It is all about the image of your brand and company, it must be very well represented.",
          },
        },
      },
      footer: {
        mobile:
          "Hostess agency in Spain working with the best hostess profiles.",
        web: "Hostess agency in Spain working with the best hostess profiles.",
      },
    },
    talent: {
      title: {
        mobile: "Other services",
        web: "Other services",
      },

      cards: {
        assemblers: {
          title: { mobile: "Assemblers", web: "Assemblers" },
        },
        auxiliars: {
          title: { mobile: "Assistants", web: "Assistants" },
        },
        waiters: {
          title: { mobile: "Servers", web: "Servers" },
        },
        services: {
          title: { mobile: "", web: "" },
        },
      },
    },
    request_budget: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
  },
  congress_hostesses: {
    header: {
      title: { mobile: "Congress hostesses", web: "Congress hostesses" },
      texts: {
        text_1: {
          mobile:
            "They work as a team so that the congress is a success and anticipate to any incident that may occur.",
          web: "They work as a team so that the congress is a success and anticipate to any incident that may occur.",
        },
        text_2: {
          mobile:
            "A congress is a meeting, in which during one or several days, people from different places present lectures on topics related to their work. The congress hostesses are responsible for participating directly in the organization of these meetings and are responsible for a wide variety of functions. It is mainly responsible for making everything go as established.<br/><br/>The congress hostesses are essential due to their resolving capacity. Are those who are concerned with reducing any incident that may occur in the venue and work as a team to enable the proper functioning of the congress.",
          web: "A congress is a meeting, in which during one or several days, people from different places present lectures on topics related to their work. The congress hostesses are responsible for participating directly in the organization of these meetings and are responsible for a wide variety of functions. It is mainly responsible for making everything go as established.<br/><br/>The congress hostesses are essential due to their resolving capacity. Are those who are concerned with reducing any incident that may occur in the venue and work as a team to enable the proper functioning of the congress.",
        },
      },
    },
    hire: {
      title: {
        mobile: "If you need congress hostesses",
        web: "If you need congress hostesses",
      },
      buttons: {
        request_budget: { mobile: "Request a budget", web: "Request a budget" },
      },
    },
    section: {
      text: {
        mobile:
          "They offer attendees all kinds of information about the congress, they facilitate their attention and help, they indicate the way, they prepare the rooms of the speakers, and they accompany the protocol staff in their functions and help in the registration and distribution of the accreditations.<br/><br/>An essential requirement for congress hostesses is knowing English, since Spain is the fifth country in terms of number of international congresses. At Eloquence, all our congress hostesses speak fluent English and have previous training and experience in congress participation. In addition, qualities like sympathy, naturalness, education and discretion, necessary to develop your work in the best possible way, are very important for us.",
        web: "They offer attendees all kinds of information about the congress, they facilitate their attention and help, they indicate the way, they prepare the rooms of the speakers, and they accompany the protocol staff in their functions and help in the registration and distribution of the accreditations.<br/><br/>An essential requirement for congress hostesses is knowing English, since Spain is the fifth country in terms of number of international congresses. <br/><br/>At Eloquence, all our congress hostesses speak fluent English and have previous training and experience in congress participation. In addition, qualities like sympathy, naturalness, education and discretion, necessary to develop your work in the best possible way, are very important for us.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "Specialized according to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stands", web: "Stands" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: {
      mobile: "We have a large experience providing hostesses for congresses.",
      web: "We have a large experience providing hostesses for congresses.",
    },
  },
  stand_hostesses: {
    header: {
      title: { mobile: "Fair hostesses", web: "Fair hostesses" },
      texts: {
        text_1: {
          mobile:
            "Responsible for attending the stand. They have a real knowledge about the company and its product.",
          web: "Responsible for attending the stand. They have a real knowledge about the company and its product.",
        },
        text_2: {
          mobile:
            "There are many companies that attend trade fairs in our country. In them, the company has the possibility of hiring a stand in which to offer its products and services. The figure of the fair stewardess is necessary to carry out some important tasks within the stand and ensure the proper functioning of this.<br/>The fair hostess is responsible for receiving and attending the stand attendees. For this, he has a real knowledge about the company he represents and the product or service offered at the stand. It is the first image of the company for potential clients so it must have a very good presence and an appropriate education.",
          web: "There are many companies that attend trade fairs in our country. In them, the company has the possibility of hiring a stand in which to offer its products and services. The figure of the fair stewardess is necessary to carry out some important tasks within the stand and ensure the proper functioning of this.<br/>The fair hostess is responsible for receiving and attending the stand attendees. For this, he has a real knowledge about the company he represents and the product or service offered at the stand. It is the first image of the company for potential clients so it must have a very good presence and an appropriate education.",
        },
      },
    },
    hire: {
      title: {
        mobile: "If you need fair hostesses",
        web: "If you need fair hostesses",
      },
      buttons: {
        request_budget: { mobile: "Request a budget", web: "Request a budget" },
      },
    },
    section: {
      text: {
        mobile:
          "Sometimes they will be sales fairs, where the hostesses can take charge of the task of selling. Its role as a fair hostess is to attract customers and familiarize them with the company they work for.<br/>Another function of the fair hostesses is to organize the appointments and meetings of the company’s own employees within the stand. At a fair of several days the organization of the stand is essential to ensure the success of the company at the fair.<br/><br/>We do everything possible to train our fair hostesses in the most functional aspects of the job. But above all, we value the knowledge of other languages to facilitate communication with foreign assistants, especially English, so all our fair hostesses dominate this language.",
        web: "Sometimes they will be sales fairs, where the hostesses can take charge of the task of selling. Its role as a fair hostess is to attract customers and familiarize them with the company they work for.<br/>Another function of the fair hostesses is to organize the appointments and meetings of the company’s own employees within the stand. At a fair of several days the organization of the stand is essential to ensure the success of the company at the fair.<br/><br/>We do everything possible to train our fair hostesses in the most functional aspects of the job. But above all, we value the knowledge of other languages to facilitate communication with foreign assistants, especially English, so all our fair hostesses dominate this language.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Others services" },
      subtitle: {
        mobile: "Specialized according to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Fairs", web: "Fairs" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: {
      mobile:
        "We are specialed on providing hostesess at international trade-shows.",
      web: "We are specialed on providing hostesess at international trade-shows.",
    },
  },
  promoter_hostesses: {
    header: {
      title: { mobile: "Promoter hostesses", web: "Promoter hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are located at the point of sale of the brand. Taking an essential role in the sale service.",
          web: "They are located at the point of sale of the brand. Taking an essential role in the sale service.",
        },
        text_2: {
          mobile:
            "In the sales channel of a product, an essential part of the process is promotion. To guarantee the sale of this product there are different types of promotion that in some occasions will need the help of one or several promoter hostesses.",
          web: "In the sales channel of a product, an essential part of the process is promotion. To guarantee the sale of this product there are different types of promotion that in some occasions will need the help of one or several promoter hostesses.",
        },
      },
    },
    hire: {
      title: {
        mobile: "Hire promoter hostesses",
        web: "Hire promoter hostesses",
      },
      buttons: {
        request_budget: { mobile: "Request a budget", web: "Request a budget" },
      },
    },
    section: {
      text: {
        mobile:
          "They will try to attract as many potential customers as possible, bringing the product closer to them to get their interest. They act as a link between the brand and potential buyers.They are responsible for getting the product to the customer, resolve any questions that they may have about the product or brand.<br/><br/>The delivery of advertising, the delivery of samples or the promotion at the point of sale. The promoter hostesses who are part of the Eloquence team, in addition to experience, training and English level have communication skills that allow them to be sociable and eloquent to connect the target with the brand.",
        web: "They will try to attract as many potential customers as possible, bringing the product closer to them to get their interest. They act as a link between the brand and potential buyers. <br/><br/>They are responsible for getting the product to the customer, resolve any questions that they may have about the product or brand.<br/><br/>The delivery of advertising, the delivery of samples or the promotion at the point of sale. The promoter hostesses who are part of the Eloquence team, in addition to experience, training and English level have communication skills that allow them to be sociable and eloquent to connect the target with the brand.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Fairs", web: "Fairs" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: {
      mobile: "Sales matters and we know it. We have experience on this field.",
      web: "Sales matters and we know it. We have experience on this field.",
    },
  },
  brand_hostesses: {
    header: {
      title: { mobile: "Brand hostesses", web: "Brand hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are the representation of the brand, a great appearence and a good image will be mandatory.",
          web: "They are the representation of the brand, a great appearence and a good image will be mandatory.",
        },
        text_2: {
          mobile:
            "The brand hostesses are the visible face of a brand in the event. We could say that the image stewardess profile stands out for its specific physical characteristics. They are responsible for promoting the image of a brand, which is why it is very important that their presence and image are well taken care of.<br/>Represent brands in events and galas, carry out the promotion of products, accompany lecturers and even work in fairs and conferences are some of the functions that the image hostesses carry out.",
          web: "The brand hostesses are the visible face of a brand in the event. We could say that the image stewardess profile stands out for its specific physical characteristics. They are responsible for promoting the image of a brand, which is why it is very important that their presence and image are well taken care of.<br/>Represent brands in events and galas, carry out the promotion of products, accompany lecturers and even work in fairs and conferences are some of the functions that the image hostesses carry out.",
        },
      },
    },
    hire: {
      title: { mobile: "Hire brand hostesses", web: "Hire brand hostesses" },
      buttons: {
        request_budget: { mobile: "Request a budget", web: "Request a budget" },
      },
    },
    section: {
      text: {
        mobile:
          "At Eloquence, we want to contribute more than just an image. We believe that beyond specific physical characteristics a good image stewardess must possess other qualities such as a positive attitude when working, sympathy, naturalness, an appropriate education, a good command of English and even knowledge of protocol.<br/><br/>We value overall our brand hostesses to communicate in an effective and eloquent way. We believe this is very important and add value to our services.",
        web: "At Eloquence, we want to contribute more than just an image. <br/><br/>We believe that beyond specific physical characteristics a good image stewardess must possess other qualities such as a positive attitude when working, sympathy, naturalness, an appropriate education, a good command of English and even knowledge of protocol.<br/><br/>We value overall our brand hostesses to communicate in an effective and eloquent way. We believe this is very important add value to our services. ",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Fairs", web: "Fairs" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: {
      mobile: "Brand hostesses profiles verified on our platform.",
      web: "Brand hostesses profiles verified on our platform.",
    },
  },
  work_with_us: {
    skills: {
      buttons: {
        work: {
          mobile: "Enjoy while you work",
          web: "Enjoy while you work",
        },
      },
      title: {
        mobile: "What do you need?",
        web: "What do you need?",
      },
      cards: {
        presence: {
          text: { mobile: "Appereance", web: "Appereance" },
        },
        english: { text: { mobile: "English B2", web: "English B2" } },
        attitude: {
          text: { mobile: "Proactive attitude", web: "Proactive attitude" },
        },
      },
    },
    team: {
      title: {
        mobile: "Join to the Eloquence's team",
        web: "Join to the Eloquence's team",
      },
      subtitle: {
        mobile: "Fill out the next form and pictures*",
        web: "Fill out the next form.<br>Don't forget to attach your pictures!",
      },
      card: {
        form: {
          inputs: {
            name: { label: "Complete name", placeholder: "Name Surname" },
            email: { label: "Email", placeholder: "email@email.com" },
            phone: { label: "Phone number", placeholder: "+34653335689" },
            city: {
              label: "City of work",
              placeholder: "Where you would like to work?",
            },
            nacionality: {
              label: "Nationality",
              placeholder: "Spanish, French, German...",
            },
            date: {
              label: "Birthday",
              placeholder: "22/05/1996",
              ok_label: "Acept",
              cancel_label: "Cancel",
              wrong_date: "Wrong date",
            },
            photos: { label: "Attach at least 2 photos", button: "Add photos" },
          },
          texts: {
            contact_us: {
              mobile: null,
              web: "If you have any doubt, email us to:",
            },
          },
          selects: {
            gender: {
              label: "Gender",
              placeholder: "Choose",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
            dress_size: {
              label: "Dress size (woman) or shirt size (man)",
              placeholder: "Choose",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
            shoe_size: {
              label: "Shoe size",
              placeholder: "Choose",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
            english_level: {
              label: "English level",
              placeholder: "Choose",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
            extra_languages: {
              label: "Extra languages",
              placeholder: "Write it here",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
          },
          textareas: {
            extra_languages: "Do you speak other languages?",
          },
          checkboxs: {
            privacy: {
              label: "I accept",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Send",
          },
        },
      },
    },
    our_philosophy: {
      title: {
        mobile: "Our philosophy",
        web: "Our philosophy",
      },
      text: {
        mobile:
          "At Eloquence we take care of our staff, we value its work and effort and we look forward to enjoy every single event with you. <br/><br/>Check the reviews the people work with us gave us. <b><a href='https://www.google.com/search?q=Eloquence+Hostess+Madrid&stick=H4sIAAAAAAAA_-NgU1IxqEgxMTKyTDRNM0oxNUsxTrMyqDBOTDZMMk1KsUxLMrU0NzJZxCrhmpNfWJqal5yq4JFfXJJaXKzgm5hSlJkCAAE1ayBDAAAA&hl=es&mat=CS-yoVdVHj_eElYBNqvzOvy_SuZOdFClVKeNTP-oHmjewOEEE_QrS20rxq4vzK8zHtaW6RoMGsR4m2tctnG9OnSXgCCrSWQTcNvIkcdl1E8f-VZPsGO4jpUMjjNpM8Y4mA&authuser=0'>Check reviews</a></b><br/><br/>If you have any doubt, email us to: <a href='mailto:work@eloquence.es'>work@eloquence.es</a>",
        web: "At Eloquence we take care of our staff, we value its work and effort and we look forward to enjoy every single event with you.<br/><br/>Check the reviews the people who work with us gave us. <b><a href='https://www.google.com/search?q=Eloquence+Hostess+Madrid&stick=H4sIAAAAAAAA_-NgU1IxqEgxMTKyTDRNM0oxNUsxTrMyqDBOTDZMMk1KsUxLMrU0NzJZxCrhmpNfWJqal5yq4JFfXJJaXKzgm5hSlJkCAAE1ayBDAAAA&hl=es&mat=CS-yoVdVHj_eElYBNqvzOvy_SuZOdFClVKeNTP-oHmjewOEEE_QrS20rxq4vzK8zHtaW6RoMGsR4m2tctnG9OnSXgCCrSWQTcNvIkcdl1E8f-VZPsGO4jpUMjjNpM8Y4mA&authuser=0'>Ver opiniones</a></b><br/><br/>If you have any doubt, email us to: <a href='mailto:work@eloquence.es'>work@eloquence.es</a>",
      },
    },
  },
  hostesses_madrid: {
    header: {
      title: {
        mobile: "Hostess agency in Madrid",
        web: "Hostess agency in Madrid",
      },
      texts: {
        text_1: {
          mobile:
            "A hostess agency in Madrid is the one that has the principal office based in Madrid. It is so important to have in mind the office location of the hostess agency and the support they can offer to their clients will depend directly on its main office address.",
          web: "A hostess agency in Madrid is the one that has the principal office based in Madrid. It is so important to have in mind the office location of the hostess agency and the support they can offer to their clients will depend directly on its main office address.",
        },
        text_2: {
          mobile:
            "<p>The competition and the number of hostess agencies in Madrid it is quite large. There are a lot of hostess agencies in Madrid, this is the reason why you might pick the correct one, depending on several features that from Eloquence we consider essential:</p><ul><li>Its website: It is important if they have a new and nice website, this will show they are a renovate company and they are not working on the past and the old way.</li><li>Its reviews on Google: The review from its clients and hostesses means a lot of its manner of working. Trust on prior client’s opinion is well recommended. You can check our reviews on the following link.</li><li>The type of event they do: Not all the hostess agencies in Madrid are focus on working on the same type of event. It is very important to check what’s the best type of event the agency can do.</li></ul>",
          web: "<p>The competition and the number of hostess agencies in Madrid it is quite large. There are a lot of hostess agencies in Madrid, this is the reason why you might pick the correct one, depending on several features that from Eloquence we consider essential:</p><ul><li>Its website: It is important if they have a new and nice website, this will show they are a renovate company and they are not working on the past and the old way.</li><li>Its reviews on Google: The review from its clients and hostesses means a lot of its manner of working. Trust on prior client’s opinion is well recommended. You can check our reviews on the following link.</li><li>The type of event they do: Not all the hostess agencies in Madrid are focus on working on the same type of event. It is very important to check what’s the best type of event the agency can do.</li></ul>",
        },
      },
    },
    hire: {
      title: {
        mobile: "If you need hostesses in Madrid",
        web: "If you need hostesses in Madrid",
      },
      buttons: {
        request_budget: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    section: {
      text: {
        mobile:
          "It is also very important the support the hostess agency in Madrid can offer to their clients. To be present at the event and know well your team of hostesses will definitely be an add value. At Eloquence, as a hostess agency in Madrid, we are always on site at our events.<br/><br/>On another hand, Madrid is the seventh city on the world ranking of international events organised the last year 2018. This means it will be important that the hostess agency will have an international character. The people working at the company and the hostesses must have a fluent conversation level in English, in order to communicate in the best way and ensuring a good outcome at the event. <br/><br/>Finally, if you are looking for a hostess agency in Madrid, make sure you pick the right one for the type of event or marketing action you are going to organize.",
        web: "It is also very important the support the hostess agency in Madrid can offer to their clients. To be present at the event and know well your team of hostesses will definitely be an add value. At Eloquence, as a hostess agency in Madrid, we are always on site at our events.<br/><br/>On another hand, Madrid is the seventh city on the world ranking of international events organised the last year 2018. This means it will be important that the hostess agency will have an international character. The people working at the company and the hostesses must have a fluent conversation level in English, in order to communicate in the best way and ensuring a good outcome at the event. <br/><br/>Finally, if you are looking for a hostess agency in Madrid, make sure you pick the right one for the type of event or marketing action you are going to organize.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "Specialized according to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        hostesses: {
          title: { mobile: "Hire hostesses", web: "Hire hostesses" },
        },
        barcelona: { title: { mobile: "Barcelona", web: "Barcelona" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contáctanos", web: "Contáctanos" } },
    },
    footer: {
      mobile:
        "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
      web: "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
    },
  },
  hostesses_barcelona: {
    header: {
      title: {
        mobile: "Hostess agency in Barcelona",
        web: "Hostess agency in Barcelona",
      },
      texts: {
        text_1: {
          mobile:
            "A hostess agency in Barcelona is the one that has the principal office based in Barcelona. It is so important to have in mind the office location of the hostess agency and the support they can offer to their clients will depend directly on its main office address.",
          web: "A hostess agency in Barcelona is the one that has the principal office based in Barcelona. It is so important to have in mind the office location of the hostess agency and the support they can offer to their clients will depend directly on its main office address.",
        },
        text_2: {
          mobile:
            "<p>The competition and the number of hostess agencies in Madrid it is quite large. There are a lot of hostess agencies in Madrid, this is the reason why you might pick the correct one, depending on several features that from Eloquence we consider essential:</p><ul><li>Its website: It is important if they have a new and nice website, this will show they are a renovate company and they are not working on the past and the old way.</li><li>Its reviews on Google: The review from its clients and hostesses means a lot of its manner of working. Trust on prior client’s opinion is well recommended. You can check our reviews on the following link.</li><li>The type of event they do: Not all the hostess agencies in Barcelona are focus on working on the same type of event. It is very important to check what’s the best type of event the agency can do.</li></ul>",
          web: "<p>The competition and the number of hostess agencies in Madrid it is quite large. There are a lot of hostess agencies in Madrid, this is the reason why you might pick the correct one, depending on several features that from Eloquence we consider essential:</p><ul><li>Its website: It is important if they have a new and nice website, this will show they are a renovate company and they are not working on the past and the old way.</li><li>Its reviews on Google: The review from its clients and hostesses means a lot of its manner of working. Trust on prior client’s opinion is well recommended. You can check our reviews on the following link.</li><li>The type of event they do: Not all the hostess agencies in Barcelona are focus on working on the same type of event. It is very important to check what’s the best type of event the agency can do.</li></ul>",
        },
      },
    },
    hire: {
      title: {
        mobile: "If you need hostesses in Barcelona",
        web: "If you need hostesses in Barcelona",
      },
      buttons: {
        request_budget: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    section: {
      text: {
        mobile:
          "It is also very important the support the hostess agency in Barcelona can offer to their clients. To be present at the event and know well your team of hostesses will definitely be an add value. At Eloquence, as a hostess agency in Barcelona, we are always on site at our events.<br/><br/>On another hand, Barcelona is the seventh city on the world ranking of international events organised the last year 2018. This means it will be important that the hostess agency will have an international character. The people working at the company and the hostesses must have a fluent conversation level in English, in order to communicate in the best way and ensuring a good outcome at the event.<br/><br/>Finally, if you are looking for a hostess agency in Barcelona, make sure you pick the right one for the type of event or marketing action you are going to organize.",
        web: "It is also very important the support the hostess agency in Barcelona can offer to their clients. To be present at the event and know well your team of hostesses will definitely be an add value. At Eloquence, as a hostess agency in Barcelona, we are always on site at our events.<br/><br/>On another hand, Barcelona is the seventh city on the world ranking of international events organised the last year 2018. This means it will be important that the hostess agency will have an international character. The people working at the company and the hostesses must have a fluent conversation level in English, in order to communicate in the best way and ensuring a good outcome at the event.<br/><br/>Finally, if you are looking for a hostess agency in Barcelona, make sure you pick the right one for the type of event or marketing action you are going to organize.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "Specialized according to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        hostesses: {
          title: { mobile: "Hire hostesses", web: "Hire hostesses" },
        },
        madrid: { title: { mobile: "Madrid", web: "Madrid" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contáctanos", web: "Contáctanos" } },
    },
    footer: {
      mobile:
        "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
      web: "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
    },
  },
  navbar: {
    items: {
      services: "Services",
      hostesses: "Hostesses",
      congress_hostesses: "Congress hostesses",
      stands_hostesses: "Stand hostesses",
      promotion_hostesses: "Promote hostesses",
      brand_hostesses: "Brand hostesses",
      about_us: "About Eloquence",
      work_with_us: "Work with us",
      request_budget: "Request budget",
      contact_us: "Contact us",
      assemblers: "Assemblers",
      auxiliars: "Assistants",
      waiters: "Servers",
      portal_clients: "Login",
      portal_workers: "Login workers",
    },
  },
  footer: {
    items: {
      sales: "Ask for a budget: ",
      hostess: "Work with us: ",
      phone: "",
      work_with_us: "Work with us",
      contact_us: "Contact us",
      services: "Services",
      about_us: "About Eloquence",
    },
  },
};

export { translation };
