const translation = {
  assemblers: {
    header: {
      title: {
        mobile: "Assemblers for events",
        web: "Assemblers for events",
      },
      subtitle: {
        mobile: "Setup and disambling of stands & structures.",
        web: "Setup and disambling of stands & structures.",
      },
    },
    assemblers: {
      cards: {
        auxiliars: {
          title: {
            mobile: "Setup assemblers",
            web: "Setup assemblers",
          },
          text: {
            mobile:
              "Helping on the packing and discharge of all the materials used on the setup.",
            web:
              "Helping on the packing and discharge of all the materials used on the setup.",
          },
        },
        hand_basic: {
          title: {
            mobile: "Basic hands",
            web: "Basic hands",
          },
          text: {
            mobile:
              "In charge of the setup and removal of booths of basic structure.",
            web:
              "In charge of the setup and removal of booths of basic structure.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "If you need assemblers for your event",
        web: "If you need assemblers for your event",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    keys: {
      title: {
        mobile: "Our keys",
        web: "Our keys",
      },
      cards: {
        teamwork: {
          title: {
            mobile: "Team Work",
            web: "Team Work",
          },
          text: {
            mobile:
              "Cohesion with teammates to combine expertise and technical abilities.Our mission is to create a great teamwork.",
            web:
              "Cohesion with teammates to combine expertise and technical abilities.Our mission is to create a great teamwork.",
          },
        },
        experience: {
          title: {
            mobile: "Experience",
            web: "Experience",
          },
          text: {
            mobile:
              "Key factor for complex setups and to get a quick and succesfull work done effective.",
            web:
              "Key factor for complex setups and to get a quick and succesfull work done effective.",
          },
        },
      },
      footer: {
        mobile: "",
        web:
          "Assemblers for events in Madrid and Barcelona, with national coverage. Highest implication.",
      },
    },
    contact_us: {
      title: {
        mobile: "Assemblers services for events in Madrid and Barcelona",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "",
        },
      },
    },
  },
  auxiliars: {
    header: {
      title: {
        mobile: "Assistants for events",
        web: "Assistants for events",
      },
      subtitle: {
        mobile: "Different profiles for your need to solve certain tasks",
        web: "Different profiles for your need to solve certain tasks",
      },
    },
    auxiliars: {
      cards: {
        drivers: {
          title: {
            mobile: "Drivers",
            web: "Drivers",
          },
          text: {
            mobile:
              "Drivers to make easier the transfer of the visitants from and to the event.",
            web:
              "Drivers to make easier the transfer of the visitants from and to the event.",
          },
        },
        access_personal: {
          title: {
            mobile: "Access staff",
            web: "Access staff",
          },
          text: {
            mobile: "Staff for ticketing on big concerts and festivals.",
            web: "Staff for ticketing on big concerts and festivals.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Do you need staff for your event?",
        web: "Do you need staff for your event?",
      },
      buttons: {
        contact_us: {
          mobile: "Let us know",
          web: "Let us know",
        },
      },
    },
    keys: {
      title: {
        mobile: "Our keys",
        web: "Our keys",
      },
      cards: {
        experience: {
          title: {
            mobile: "Experience",
            web: "Experience",
          },
          text: {
            mobile:
              "Drivers to facilitate the transfer of those attending the event. From the airport to the hotel, and from the hotel to the event.",
            web:
              "Drivers to facilitate the transfer of those attending the event. From the airport to the hotel, and from the hotel to the event.",
          },
        },
        attitude: {
          title: {
            mobile: "Attitude",
            web: "Attitude",
          },
          text: {
            mobile:
              "Be professional and take your job serious is never granted.This is key for having committed people in your team.",
            web:
              "Be professional and take your job serious is never granted.This is key for having committed people in your team.",
          },
        },
      },
      footer: {
        mobile: "",
        web:
          "Assistant personel for events. For all kind of events. Madrid and Barcelon and national coverage. ",
      },
    },
    contact_us: {
      title: {
        mobile: "Assitants services in Madrid and Barcelona",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "",
        },
      },
    },
  },
  waiters: {
    header: {
      title: {
        mobile: "Waiters for events",
        web: "Waiters for events",
      },
      subtitle: {
        mobile: "Solutions for your event with catering or drinks",
        web: "Solutions for your event with catering or drinks",
      },
    },
    waiters: {
      cards: {
        waiters: {
          title: {
            mobile: "Waiters",
            web: "Waiters",
          },
          text: {
            mobile: "Experienced staff, perfectly uniformed.",
            web: "Experienced staff, perfectly uniformed.",
          },
        },
        cocktail_shakers: {
          title: {
            mobile: "Mixologists",
            web: "Mixologists",
          },
          text: {
            mobile:
              "Experts in cocktails and drinks, precision and a great image.",
            web:
              "Experts in cocktails and drinks, precision and a great image.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Do you need servers for your event?",
        web: "Do you need servers for your event?",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    keys: {
      title: {
        mobile: "Our keys",
        web: "Our keys",
      },
      cards: {
        experience: {
          title: {
            mobile: "Expertise",
            web: "Expertise",
          },
          text: {
            mobile:
              "Carrying trays or make a good cocktail is not that easy. Expertise is a important requirement here.",
            web:
              "Carrying trays or make a good cocktail is not that easy. Expertise is a important requirement here.",
          },
        },
        uniformity: {
          title: {
            mobile: "Uniforms",
            web: "Uniforms",
          },
          text: {
            mobile:
              "All the work team well uniformed. Same uniform for everyone working at the event. This is essential for the image of your event.",
            web:
              "All the work team well uniformed. Same uniform for everyone working at the event. This is essential for the image of your event.",
          },
        },
      },
      footer: {
        mobile: "",
        web:
          "Servers for events in Madrid and Barcelona, also national coverage.",
      },
    },
    contact_us: {
      title: {
        mobile: "Servers for events in Madrid and Barcelona",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contact us",
          web: "",
        },
      },
    },
  },
  about_us: {
    header: {
      title: {
        mobile: "Eloquence",
        web: "Eloquence",
      },
      texts: {
        info: {
          mobile:
            "We are a trusted temporary staff company, putting all our effort to make the best of your event.",
          web:
            "We are a trusted temporary staff company, putting all our effort to make the best of your event.",
        },
      },
      buttons: {
        contact_us: {
          mobile: null,
          web: "Contact us",
        },
      },
    },
    our_values: {
      title: { mobile: "Our values", web: "Our values" },
      cards: {
        support: {
          title: { mobile: "Excellence", web: "Excellence" },
          text: {
            mobile:
              "A <b>five</b> stars service in each of the events we participate.",
            web:
              "A <b>five</b> stars service in each of the events we participate.",
          },
        },
        excellency: {
          title: { mobile: "Resolutives", web: "Resolutives" },
          text: {
            mobile:
              "We are young and quick.<br/>To be digital help us to be more effective.",
            web:
              "We are young and quick.<br/>To be digital help us to be more effective.",
          },
        },
        decisives: {
          title: { mobile: "Treatment", web: "Treatment" },
          text: {
            mobile: "We take care in the same way of our clients and staff.",
            web: "We take care in the same way of our clients and staff.",
          },
        },
      },
    },
    our_history: {
      title: { mobile: "Our history", web: "Our history" },
      text: {
        mobile:
          "Eloquence become reality in September 2016, focused on providing hostesses to international companies in Madrid.<br/><br/>We wanted to help all those companies that visit trade-shows, conference and events in our country <br/><br/>Our objetive was to use English for helping everyone, from our staff to our communication, taking care in the same way of our clients and staff. This helped us to become one of the most valued agencies in Spain, experts on the international market.<br/><br/>In 2019 we openned our new offices in Barcelona. In 2020 we advanced to become a staff company, offering several type of profiles.<br/><br/>Our future is next to technology, are you comming with us?",
        web:
          "Eloquence become reality in September 2016, focused on providing hostesses to international companies in Madrid.<br/><br/>We wanted to help all those companies that visit trade-shows, conference and events in our country <br/><br/>Our objetive was to use English for helping everyone, from our staff to our communication, taking care in the same way of our clients and staff. This helped us to become one of the most valued agencies in Spain, experts on the international market.<br/><br/>In 2019 we openned our new offices in Barcelona. In 2020 we advanced to become a staff company, offering several type of profiles.<br/><br/>Our future is next to technology, are you comming with us?",
      },
    },
    success_stories: {
      title: { mobile: "Case of success", web: "Case of success" },
      subtitle: {
        mobile: "UEFA Champions League",
        web: "UEFA Champions League",
      },
      text: {
        mobile:
          "Eloquence was selected as the official hostess agency by the UEFA to organize all the staff around the big European football final. We took care of the selection, training, and implementation of a total number of 94 brand host and hostesses.<br/><br/>UEFA prepared a beautiful and super big lounge where all the sponsors came together, Mastercard, Adidas and Heineken among others. The area put together more than 3000 fans during the 8 hours doors stayed opened.<br><br/>Our team worked for more than 3 months on the proyect, screening from more than 500 people, standing out 12 group leaders, in charge of each sponsor and defined area. Three Eloquence's coordinators in contact with the main organizators.<br/>The main goal was to offer an excellent service, as well as to achieve the durability of the memories of the fans. Giving away big smiles, we wished the best of the luck to both fans teams and congrated the winnings and comfort the losers. <br/>The event was an real success, obtaining the congratulation of the mayority of the sponsors at the lounge.",
        web:
          "Eloquence was selected as the official hostess agency by the UEFA to organize all the staff around the big European football final. We took care of the selection, training, and implementation of a total number of 94 brand host and hostesses.<br/><br/>UEFA prepared a beautiful and super big lounge where all the sponsors came together, Mastercard, Adidas and Heineken among others. The area put together more than 3000 fans during the 8 hours doors stayed opened.<br><br/>Our team worked for more than 3 months on the proyect, screening from more than 500 people, standing out 12 group leaders, in charge of each sponsor and defined area. Three Eloquence's coordinators in contact with the main organizators.<br/>The main goal was to offer an excellent service, as well as to achieve the durability of the memories of the fans. Giving away big smiles, we wished the best of the luck to both fans teams and congrated the winnings and comfort the losers. <br/>The event was an real success, obtaining the congratulation of the mayority of the sponsors at the lounge.",
      },
    },
    budget: {
      buttons: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
  },
  budget: {
    header: {
      title: {
        mobile: "Do you need a budget?",
        web: "Do you need a budget?",
      },
      card: {
        title: {
          mobile: null,
          web: "Let us know how we can help",
        },
        subtitle: {
          mobile: null,
          web: "Fill the form and we will get in contact in 24h",
        },
        form: {
          mobile: null,
          web: {
            inputs: {
              name: { label: "Name", placeholder: "John Cameron" },
              email: { label: "Email", placeholder: "john@company.com" },
              phone: { label: "Phone", placeholder: "+33654939705" },
              city: {
                label: "Where is it?",
                placeholder: "Madrid, Barcelona, Bilbao...",
              },
              number_hostess: {
                label: "Nº of people",
                placeholder: "?",
              },
              message: {
                label: "Let us know a bit more about the project",
                placeholder: "Dates, city, brand, etc.",
              },
            },
            selects: {
              profile_type: {
                label: "Type of profile",
                placeholder: "Type of profile",
                width: 122,
                items: [
                  { value: "hostesses", label: "Hostesses" },
                  { value: "assemblers", label: "Assemblers" },
                  { value: "assistants", label: "Assistants" },
                  { value: "waiters", label: "Waiters" },
                ],
              },
            },
            checkboxs: {
              privacy: {
                label: "I accept",
                link: "the privacy policies",
              },
            },
            buttons: {
              send: "Send",
            },
          },
        },
      },
    },
    subtitle: { mobile: "Let us know more details", web: null },
    form: {
      mobile: {
        inputs: {
          name: { label: "Name", placeholder: "John Cameron" },
          email: { label: "Email", placeholder: "john@ocompany.com" },
          phone: { label: "Phone", placeholder: "+34654939705" },
          city: {
            label: "City of the event",
            placeholder: "Where it happens?",
          },
          number_hostess: {
            label: "Nº of people",
            placeholder: "?",
          },
          message: {
            label: "Details",
            placeholder: "Tell us more about the event, When is it?",
          },
        },
        checkboxs: {
          privacy: { label: "I accept ", link: "the privacy policies" },
        },
        buttons: {
          send: "Send",
        },
      },
      web: null,
    },
    contact_us: {
      title: {
        mobile: "Do you need to contact us?",
        web: "Do you need to contact us?",
      },
      card: {
        call_us: {
          title: { mobile: "Call us", web: "Call us" },
          text: {
            mobile: "Phone: +34 654 939 705",
            web: "Phone: +34 654 939 705",
          },
        },
        write_us: {
          title: { mobile: "Email us", web: "Email us" },
          text: {
            mobile: "Email: sales@eloquence.es",
            web: "Email: sales@eloquence.es",
          },
        },
      },
      buttons: {
        back_home: {
          label: { mobile: "Go to home", web: null },
        },
      },
    },
  },
  contact_us: {
    header: {
      title: {
        mobile: "A team ready to help you",
        web: "A team ready to help you",
      },
    },
    form: {
      title: {
        mobile: "Can we help you?",
        web: null,
      },
      subtitle: {
        mobile: "We will reply you in 24h",
        web: null,
      },
      card: {
        mobile: {
          inputs: {
            name: { label: "Name", placeholder: "John Cameron" },
            email: { label: "Email", placeholder: "johncameron@company.com" },
            phone: { label: "Phone", placeholder: "+34 654939705" },
            city: {
              label: "City of the event",
              placeholder: "Where the event happens?",
            },
            number_hostess: {
              label: "Number of people do you need",
              placeholder: "?",
            },
            message: {
              label: "Let us know a bit more about it",
              placeholder: "When is the event?",
            },
          },
          checkboxs: {
            privacy: {
              label: "I accept",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Send",
          },
        },
        web: {
          title: "Can we help you?",
          subtitle: "Let us know how! We'll reply you in 24h",
          inputs: {
            name: { label: "Complete name", placeholder: "John Cameron" },
            email: { label: "Email", placeholder: "johncameronz@company.com" },
            phone: { label: "Phone", placeholder: "+34654939705" },
            city: { label: "City of the event", placeholder: "Where is it?" },
            number_hostess: {
              label: "Nº of people do you need",
              placeholder: "?",
            },
            message: {
              label: "Let us know a bit more",
              placeholder: "Comment any doubt",
            },
          },
          checkboxs: {
            privacy: {
              label: "I accept ",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Send",
          },
        },
      },
    },
    contact_us: {
      card: {
        call_us: {
          title: { mobile: "Call us", web: "Call us" },
          text: {
            mobile: "+34 654 939 705",
            web: "+34 654 939 705",
          },
        },
        write_us: {
          title: { mobile: "Email us", web: "Email us" },
          text: {
            mobile: "sales@eloquence.es",
            web: "sales@eloquence.es",
          },
        },
        our_offices: {
          title: { mobile: null, web: "Our offices" },
          madrid: {
            mobile: null,
            web: "Calle de Núñez de Balboa, 120<br/>28806, Madrid, España.",
          },
          barcelona: {
            mobile: null,
            web: "Carrer Calàbria 149<br/>08015, Barcelona, España.",
          },
        },
        button: {
          work_with_us: {
            mobile: "Work with us",
            web: "Work with us",
          },
        },
      },
    },
    where_we_are: {
      title: { mobile: "Where we are", web: "Where we are" },
      madrid: {
        title: { mobile: "Madrid", web: "Madrid" },
        text: {
          mobile: "Calle de Núñez de Balboa, 120<br/>Cink Emprende",
          web: "Calle de Núñez de Balboa, 120<br/>Cink Emprende",
        },
      },
      barcelona: {
        title: { mobile: "Barcelona", web: "Barcelona" },
        text: {
          mobile: "Carrer Calàbria 149<br/>The Office Coworking",
          web: "Carrer Calàbria 149<br/>The Office Coworking",
        },
      },
    },
    instagram: {
      title: {
        mobile: "Follow us in instagram",
        web: "Follow us in instagram",
      },
    },
    budget: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: null,
        },
      },
    },
  },
  home: {
    header: {
      title: {
        mobile: "Hire staff for your event",
        web: "Hire staff<br/>for your event",
      },
      form: {
        mobile: null,
        web: {
          title: "Do you need a quote?",
          subtitle: "Let us know and we'll help you",
          inputs: {
            email: { label: "Email", placeholder: "email@company.com" },
            phone: {
              label: "Phone Number",
              placeholder: "+34654939705",
            },
            number_hostess: { label: "Number of people", placeholder: "?" },
            details: {
              label: "Details",
              placeholder: "When and where is happening?",
            },
          },
          selects: {
            profile_type: {
              label: "Type of profile",
              placeholder: "Type of profile",
              width: 122,
              items: [
                { value: "hostesses", label: "Hostesses" },
                { value: "hands", label: "Hands" },
                { value: "staff", label: "Staff" },
                { value: "waiters", label: "Waiters" },
              ],
            },
          },
          checkboxs: {
            privacy: {
              label: "I accept",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Send",
          },
        },
      },
    },
    budget: {
      mobile: {
        title: "Do you need a  quote?",
        subtitle: "Let us know what you need",
        budget: {
          buttons: {
            request_budget: "Request budget",
          },
        },
      },
      web: null,
    },
    type_profile: {
      title: {
        mobile: "",
        web: "Choose your type of profile",
      },
      subtitle: {
        mobile: "We offer different profiles for your event",
        web: "We offer different profiles for your event",
      },
      cards: {
        hostesses: { title: { mobile: "Hostesses", web: "Hostesses" } },
        assemblers: { title: { mobile: "Hands", web: "Hands" } },
        auxiliars: { title: { mobile: "Staff", web: "Staff" } },
        waiters: { title: { mobile: "Waiters", web: "Waiters" } },
      },
    },
    guarantee: {
      title: {
        mobile: "How we do it?",
        web: "How we do it?",
      },
      cards: {
        quick: {
          title: { mobile: "Digital", web: "Digital" },
          text: {
            mobile: "We are one of the most digital companies in the market.",
            web: "We are one of the most digital companies in the market.",
          },
        },
        support: {
          title: { mobile: "Support", web: "Support" },
          text: {
            mobile:
              "We are close to our clients and staff. We take care of you all.",
            web:
              "We are close to our clients and staff. We take care of you all.",
          },
        },
        trust: {
          title: { mobile: "Quality", web: "Quality" },
          text: {
            mobile:
              "We follow the FIVE stars philosophy. The perfection itself.",
            web: "We follow the FIVE stars philosophy. The perfection itself.",
          },
        },
      },
    },
    result: {
      title: {
        mobile: "Result matters",
        web: "Result matters",
      },
      subtitle: {
        mobile: "What people say about us",
        web: "What people say about us",
      },
      cards: {
        philipp: {
          text: {
            mobile:
              "We are very happy. When our hostess got sick they went above and beyond in finding a replacement within hours and made sure had spanish at the booth at all times.",
            web:
              "We are very happy. When our hostess got sick they went above and beyond in finding a replacement within hours and made sure had spanish at the booth at all times.",
          },
          name: {
            mobile: "Philipp Zeiske, Bisutex",
            web: "Philipp Zeiske, Bisutex",
          },
        },
        sara: {
          text: {
            mobile:
              "Top agency and professionals, they look after their hostesses. They make every project more pleasant and fun. I can say they have nothing to envy to whichever agency.",
            web:
              "Top agency and professionals, they look after their hostesses. They make every project more pleasant and fun. I can say they have nothing to envy to whichever agency.",
          },
          name: {
            mobile: "Sara P, Hostess",
            web: "Sara P, Hostess",
          },
        },
        wendy: {
          text: {
            mobile:
              "The hostess quickly understood her role and responded to our clients, partners and staff, as if she were an employee of our company.Thank you!",
            web:
              "The hostess quickly understood her role and responded to our clients, partners and staff, as if she were an employee of our company.Thank you!",
          },
          name: {
            mobile: "Wendi W, Mobile World Congress",
            web: "Wendi W, Mobile World Congress",
          },
        },
      },
      buttons: {
        reviews: {
          mobile: "Check our reviews",
          web: "Check our reviews",
        },
      },
    },
    contact_us: {
      title: {
        mobile: "Want to know more?",
        web: "Want to know more?",
      },
      subtitle: {
        mobile: "Contact us, we are happy to help",
        web: "Contact us, we are<br/>happy to help",
      },
      phone: {
        mobile: "Phone number: +34 654 939 705",
        web: "Phone number: +34 654 939 705",
      },
      email: {
        mobile: "Email: sales@eloquence.es",
        web: "Email: sales@eloquence.es",
      },
      buttons: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
    work_with_us: {
      title: {
        mobile: "Work with us",
        web: "Work with us",
      },
      subtitle: {
        mobile: "Fill out our form",
        web: "Fill out our form",
      },
      buttons: {
        join: {
          mobile: "Join to Eloquence",
          web: "Join to Eloquence",
        },
      },
      text: {
        mobile: "or email us to hostess@eloquence.es",
        web: "or email us to hostess@eloquence.es",
      },
    },
  },
  payment: {
    header: null,
    payment_gateway: {
      title: {
        mobile: "Welcome to the payment gateway",
        web: "Welcome to the payment gateway",
      },
      card: {
        title: {
          mobile: "Fill the fields",
          web: "Fill the fields",
        },
        subtitle: {
          mobile: "You will find the data on your invoice",
          web: "You will find the data on your invoice",
        },
        form: {
          inputs: {
            company: {
              label: "Company",
              placeholder: "Name of the company",
            },
            number_invoice: {
              label: "Nº of invoice",
              placeholder: "ELOQ-XXX",
            },
            cuantity: {
              label: "Amount",
              placeholder: "Showed in your invoice",
            },
          },
          checkboxs: {
            privacy: {
              label: "I accept ",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Pay",
          },
        },
      },
    },
    some_dude: {
      title: { mobile: "Any doubt?", web: "Any doubt?" },
      subtitle: {
        mobile: "Get in touch with us",
        web: "Get in touch with us",
      },
      phone: {
        mobile: "Phone +34 654 939 705",
        web: "Phone +34 654 939 705",
      },
      email: {
        mobile: "Email: sales@eloquencespain.com",
        web: "Email: sales@eloquencespain.com",
      },
    },
  },
  services: {
    header: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
    hostesses: {
      title: {
        mobile: "Staff for your events",
        web: "Staff for your events",
      },
      subtitle: {
        mobile: "Based on your requirements",
        web: "Based on your requirements",
      },
      cards: {
        hostesses: {
          title: { mobile: "Hostesses", web: "Hostesses" },
          text: {
            mobile:
              "For all kinds of events: congresses, fairs, promotions, company events, etc.They are the image and contact of the brands they represent. All our hostesses<b>speak English</b>.",
            web:
              "For all kinds of events: congresses, fairs, promotions, company events, etc. They are the image and contact of the brands they represent. All our hostesses<b>speak English</b>.",
          },
        },
        assemblers: {
          title: { mobile: "Assemblers", web: "Assemblers" },
          text: {
            mobile:
              "Assembly and disassembly of stands.Loading and unloading of materials. <b> They work as a team </b> so that everything turns out well and faces any unforeseen event.",
            web:
              "Assembly and disassembly of stands. Loading and unloading of materials. <b> They work as a team </b> so that everything turns out well and faces any unforeseen event.",
          },
        },
        auxiliars: {
          title: { mobile: "Assistants", web: "Assistants" },
          text: {
            mobile: "Neccesary staff that complement your event.",
            web: "Neccesary staff that complement your event",
          },
        },
        waiters: {
          title: { mobile: "Waiters", web: "Waiters" },
          text: {
            mobile:
              "Mixologist and waiters to give a touch of flavour and image to your event.",
            web:
              "Mixologist and waiters to give a touch of flavour and image to your event.",
          },
        },
      },
      footer: {
        mobile:
          "Check <b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>our reviews</a></b> as agency!",
        web:
          "Check <b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>our reviews</a></b> as agency!",
      },
    },
    doubt: {
      title: {
        mobile: "If you have any doubt",
        web: "If you want to hire talent or have any doubt",
      },
      button: {
        contact_us: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    how_we_work: {
      title: { mobile: "How we do it", web: "How we do it" },
      cards: {
        selection: {
          title: { mobile: "Selection", web: "Selection" },
          text: {
            mobile: "Profiles 100% verified through interviews",
            web: "Profiles 100% verified through interviews",
          },
        },
        planning: {
          title: { mobile: "Report & Feedback", web: "Report & Feedback" },
          text: {
            mobile: "We review to all our staff",
            web: "We review to all our staff",
          },
        },
        support: {
          title: { mobile: "Support", web: "Support" },
          text: {
            mobile: "Present in the event, attending any need",
            web: "Present in the event, attending any need",
          },
        },
      },
      footer: {
        mobile:
          "Trusted temporary staff company. We work all around Spain, in every single type of event. Available 24/7 the whole year.",
        web:
          "Trusted temporary staff company. We work all around Spain, in every single type of event. Available 24/7 the whole year.",
      },
    },
    request_budget: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
  },
  hostesses: {
    header: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
    hostesses: {
      title: {
        mobile: "Hostesses for your event",
        web: "Hostesses for your event",
      },
      subtitle: {
        mobile: "Different type of hostesses",
        web: "Different type of hostesses",
      },
      cards: {
        congress_hostesses: {
          title: {
            mobile: "Congress hostess",
            web: "Congress hostess",
          },
          text: {
            mobile:
              "They are essential due to their resolutive capacity. They work as a team to solve all the tasks in the event. They work so that congress is a <b>success.</b>",
            web:
              "They are essential due to their resolutive capacity. They work as a team to solve all the tasks in the event. They work so that congress is a <b>success.</b>",
          },
        },
        brand_hostesses: {
          title: { mobile: "Brand hostess", web: "Brand hostess" },
          text: {
            mobile:
              "They are the image of the brand, that is why it is very important their appearance looks perfect. They meet specific requirements of the brand itself.",
            web:
              "They are the image of the brand, that is why it is very important their appearance looks perfect. They meet specific requirements of the brand itself.",
          },
        },
        stand_hostesses: {
          title: { mobile: "Stand hostess", web: "Stand hostess" },
          text: {
            mobile:
              "They are the first image of the company. They are responsible for receiving and attending the stand attendees. They have real knowledge about the company they represent.",
            web:
              "They are the first image of the company. They are responsible for receiving and attending the stand attendees. They have real knowledge about the company they represent.",
          },
        },
        promoter_hostesses: {
          title: { mobile: "Hosstes promoter", web: "Hosstes promoter" },
          text: {
            mobile:
              "They are located at the sales point of the brand, having a sales profile. They have an essential role in the sales process. They are identified as a sales person from the store.",
            web:
              "They are located at the sales point of the brand, having a sales profile. They have an essential role in the sales process. They are identified as a sales person from the store.",
          },
        },
      },
    },
    doubt: {
      title: {
        mobile: "If you have any doubt",
        web: "Would you need hostesses at your event?",
      },
      button: {
        contact_us: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    how_we_work: {
      title: {
        mobile: "Our three commandments",
        web: "Our three commandments",
      },
      cards: {
        selection: {
          title: { mobile: "Attitude", web: "Attitude" },
          text: {
            mobile: "The most important requirement for us",
            web: "The most important requirement for us",
          },
        },
        planning: {
          title: { mobile: "Languages", web: "Languages" },
          text: {
            mobile: "All our hostesses speak fluent English",
            web: "All our hostesses speak fluent English",
          },
        },
        support: {
          title: { mobile: "Appereance", web: "Appereance" },
          text: {
            mobile: "It is about the image of your brand or company",
            web: "It is about the image of your brand or company",
          },
        },
      },
      footer: {
        mobile:
          "Hostess agency in Spain working with the best hostess profiles.",
        web: "Hostess agency in Spain working with the best hostess profiles.",
      },
    },
    talent: {
      title: {
        mobile: "Others services",
        web: "Others services",
      },

      cards: {
        assemblers: {
          title: { mobile: "Assemblers", web: "Assemblers" },
        },
        auxiliars: {
          title: { mobile: "Assistants", web: "Assistants" },
        },
        waiters: {
          title: { mobile: "Waiters", web: "Waiters" },
        },
        services: {
          title: { mobile: "Services", web: "Services" },
        },
      },
    },
    request_budget: {
      button: {
        request_budget: {
          mobile: "Request a budget",
          web: "Request a budget",
        },
      },
    },
  },
  congress_hostesses: {
    header: {
      title: { mobile: "Congress hostesses", web: "Congress hostesses" },
      texts: {
        text_1: {
          mobile:
            "They work as a team so that the congress is a success and anticipate to any incident that may occur in the event.",
          web:
            "They work as a team so that the congress is a success and anticipate to any incident that may occur in the event.",
        },
        text_2: {
          mobile:
            "A congress is a meeting, in which during one or several days, people from different places present lectures on topics related to their work. The congress hostesses are responsible for participating directly in the organization of these meetings and are responsible for a wide variety of functions. It is mainly responsible for making everything go as established.<br/>The congress hostesses are essential due to their resolving capacity. Are those who are concerned with reducing any incident that may occur in the venue and work as a team to enable the proper functioning of the congress.",
          web:
            "A congress is a meeting, in which during one or several days, people from different places present lectures on topics related to their work. The congress hostesses are responsible for participating directly in the organization of these meetings and are responsible for a wide variety of functions. It is mainly responsible for making everything go as established.<br/>The congress hostesses are essential due to their resolving capacity. Are those who are concerned with reducing any incident that may occur in the venue and work as a team to enable the proper functioning of the congress.",
        },
      },
    },
    hire: {
      title: {
        mobile: "If you need congress hostesses",
        web: "If you need congress hostesses",
      },
      buttons: {
        request_budget: { mobile: "Request a budget", web: "Request a budget" },
      },
    },
    section: {
      text: {
        mobile:
          "They offer attendees all kinds of information about the congress, they facilitate their attention and help, they indicate the way, they prepare the rooms of the speakers, and they accompany the protocol staff in their functions and help in the registration and distribution of the accreditations.<br/><br/>An essential requirement for congress hostesses is knowing English, since Spain is the fifth country in terms of number of international congresses. At Eloquence, all our congress hostesses speak fluent English and have previous training and experience in congress participation. In addition, qualities like sympathy, naturalness, education and discretion, necessary to develop your work in the best possible way, are very important for us.",
        web:
          "They offer attendees all kinds of information about the congress, they facilitate their attention and help, they indicate the way, they prepare the rooms of the speakers, and they accompany the protocol staff in their functions and help in the registration and distribution of the accreditations.<br/><br/>An essential requirement for congress hostesses is knowing English, since Spain is the fifth country in terms of number of international congresses. At Eloquence, all our congress hostesses speak fluent English and have previous training and experience in congress participation. In addition, qualities like sympathy, naturalness, education and discretion, necessary to develop your work in the best possible way, are very important for us.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "Specialized according to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stands", web: "Stands" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: { mobile: "texto aqui", web: "texto aqui" },
  },
  stand_hostesses: {
    header: {
      title: { mobile: "Fair hostesses", web: "Fair hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are responsible for attending the stand assistants. They have real knowledge about the company and its product.",
          web:
            "They are responsible for attending the stand assistants. They have real knowledge about the company and its product.",
        },
        text_2: {
          mobile:
            "There are many companies that attend trade fairs in our country. In them, the company has the possibility of hiring a stand in which to offer its products and services. The figure of the fair stewardess is necessary to carry out some important tasks within the stand and ensure the proper functioning of this.<br/>The fair hostess is responsible for receiving and attending the stand attendees. For this, he has a real knowledge about the company he represents and the product or service offered at the stand. It is the first image of the company for potential clients so it must have a very good presence and an appropriate education.",
          web:
            "There are many companies that attend trade fairs in our country. In them, the company has the possibility of hiring a stand in which to offer its products and services. The figure of the fair stewardess is necessary to carry out some important tasks within the stand and ensure the proper functioning of this.<br/>The fair hostess is responsible for receiving and attending the stand attendees. For this, he has a real knowledge about the company he represents and the product or service offered at the stand. It is the first image of the company for potential clients so it must have a very good presence and an appropriate education.",
        },
      },
    },
    hire: {
      title: {
        mobile: "If you need fair hostesses",
        web: "If you need fair hostesses",
      },
      buttons: {
        request_budget: { mobile: "Request a budget", web: "Request a budget" },
      },
    },
    section: {
      text: {
        mobile:
          "Sometimes they will be sales fairs, where the hostesses can take charge of the task of selling. Its role as a fair hostess is to attract customers and familiarize them with the company they work for.<br/>Another function of the fair hostesses is to organize the appointments and meetings of the company’s own employees within the stand. At a fair of several days the organization of the stand is essential to ensure the success of the company at the fair.<br/>We do everything possible to train our fair hostesses in the most functional aspects of the job. But above all, we value the knowledge of other languages to facilitate communication with foreign assistants, especially English, so all our fair hostesses dominate this language.",
        web:
          "Sometimes they will be sales fairs, where the hostesses can take charge of the task of selling. Its role as a fair hostess is to attract customers and familiarize them with the company they work for.<br/>Another function of the fair hostesses is to organize the appointments and meetings of the company’s own employees within the stand. At a fair of several days the organization of the stand is essential to ensure the success of the company at the fair.<br/>We do everything possible to train our fair hostesses in the most functional aspects of the job. But above all, we value the knowledge of other languages to facilitate communication with foreign assistants, especially English, so all our fair hostesses dominate this language.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Others services" },
      subtitle: {
        mobile: "Specialized according to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Fairs", web: "Fairs" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: { mobile: "texto aqui", web: "texto aqui" },
  },
  promoter_hostesses: {
    header: {
      title: { mobile: "Promoter hostesses", web: "Promoter hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are located at the point of sale of the brand, with a more commercial profile. Take an essential role in the sale or service.",
          web:
            "They are located at the point of sale of the brand, with a more commercial profile. Take an essential role in the sale or service.",
        },
        text_2: {
          mobile:
            "In the sales channel of a product, an essential part of the process is promotion. To guarantee the sale of this product there are different types of promotion that in some occasions will need the help of one or several promoter hostesses.",
          web:
            "In the sales channel of a product, an essential part of the process is promotion. To guarantee the sale of this product there are different types of promotion that in some occasions will need the help of one or several promoter hostesses.",
        },
      },
    },
    hire: {
      title: {
        mobile: "Hire promoter hostesses",
        web: "Hire promoter hostesses",
      },
      buttons: {
        request_budget: { mobile: "Request a budget", web: "Request a budget" },
      },
    },
    section: {
      text: {
        mobile:
          "They will try to attract as many potential customers as possible, bringing the product closer to them to get their interest. They act as a link between the brand and potential buyers; They are responsible for getting the product to the customer, resolve any questions that they may have about the product or brand.<br/>The delivery of advertising, the delivery of samples or the promotion at the point of sale. The promoter hostesses who are part of the Eloquence team, in addition to experience, training and English level have communication skills that allow them to be sociable and eloquent to connect the target with the brand.",
        web:
          "They will try to attract as many potential customers as possible, bringing the product closer to them to get their interest. They act as a link between the brand and potential buyers; They are responsible for getting the product to the customer, resolve any questions that they may have about the product or brand.<br/>The delivery of advertising, the delivery of samples or the promotion at the point of sale. The promoter hostesses who are part of the Eloquence team, in addition to experience, training and English level have communication skills that allow them to be sociable and eloquent to connect the target with the brand.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Fairs", web: "Fairs" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: { mobile: "texto aqui", web: "texto aqui" },
  },
  brand_hostesses: {
    header: {
      title: { mobile: "Brand hostesses", web: "Brand hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are the representation of the brand, it is important that their presence and image are well taken care of.",
          web:
            "They are the representation of the brand, it is important that their presence and image are well taken care of.",
        },
        text_2: {
          mobile:
            "The model hostesses are the visible face of a brand in the event. We could say that the image stewardess profile stands out for its specific physical characteristics. They are responsible for promoting the image of a brand, which is why it is very important that their presence and image are well taken care of.<br/>Represent brands in events and galas, carry out the promotion of products, accompany lecturers and even work in fairs and conferences are some of the functions that the image hostesses carry out.",
          web:
            "The model hostesses are the visible face of a brand in the event. We could say that the image stewardess profile stands out for its specific physical characteristics. They are responsible for promoting the image of a brand, which is why it is very important that their presence and image are well taken care of.<br/>Represent brands in events and galas, carry out the promotion of products, accompany lecturers and even work in fairs and conferences are some of the functions that the image hostesses carry out.",
        },
      },
    },
    hire: {
      title: { mobile: "Hire brand hostesses", web: "Hire brand hostesses" },
      buttons: {
        request_budget: { mobile: "Request a budget", web: "Request a budget" },
      },
    },
    section: {
      text: {
        mobile:
          "At Eloquence, we want to contribute more than just an image. We believe that beyond specific physical characteristics a good image stewardess must possess other qualities such as a positive attitude when working, sympathy, naturalness, an appropriate education, a good command of English and even knowledge of protocol.<br/>We value overall our model hostesses to communicate in an effective and eloquent way. We believe this add value to our services.",
        web:
          "At Eloquence, we want to contribute more than just an image. We believe that beyond specific physical characteristics a good image stewardess must possess other qualities such as a positive attitude when working, sympathy, naturalness, an appropriate education, a good command of English and even knowledge of protocol.<br/>We value overall our model hostesses to communicate in an effective and eloquent way. We believe this add value to our services.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Fairs", web: "Fairs" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: { mobile: "texto aqui", web: "texto aqui" },
  },
  work_with_us: {
    skills: {
      buttons: {
        work: {
          mobile: "Enjoy while you work",
          web: "Enjoy while you work",
        },
      },
      title: {
        mobile: "What do you need?",
        web: "What do you need?",
      },
      cards: {
        presence: {
          text: { mobile: "Presence", web: "Presence" },
        },
        english: { text: { mobile: "English B2", web: "English B2" } },
        attitude: {
          text: { mobile: "Proactive attitude", web: "Proactive attitude" },
        },
      },
    },
    team: {
      title: {
        mobile: "Join to the Eloquence's team",
        web: "Join to the Eloquence's team",
      },
      subtitle: {
        mobile: "Fill out the next form and pictures *",
        web: "Fill out the next form.<br>Don't forget to attach the pictures!",
      },
      card: {
        form: {
          inputs: {
            name: { label: "Complete name", placeholder: "Name Surname" },
            email: { label: "Email", placeholder: "email@email.com" },
            phone: { label: "Phone number", placeholder: "+34653335689" },
            city: {
              label: "City of work",
              placeholder: "Where you would like to work?",
            },
            nacionality: {
              label: "Nationality",
              placeholder: "Spanish, French, German...",
            },
            date: {
              label: "Birthday",
              placeholder: "22/05/1996",
              ok_label: "Acept",
              cancel_label: "Cancel",
              wrong_date: "Wrong date",
            },
            photos: { label: "Attach at least 2 photos", button: "Add photos" },
          },
          texts: {
            contact_us: {
              mobile: null,
              web: "If you have any doubt, email us to:",
            },
          },
          selects: {
            gender: {
              label: "Gender",
              placeholder: "Choose",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
            dress_size: {
              label: "Dress size (woman) or shirt size (man)",
              placeholder: "Choose",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
            shoe_size: {
              label: "Shoe size",
              placeholder: "Choose",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
            english_level: {
              label: "English level",
              placeholder: "Choose",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
            extra_languages: {
              label: "Extra languages",
              placeholder: "Write it here",
              items: [
                { value: "man", label: "Man" },
                { value: "woman", label: "Woman" },
              ],
            },
          },
          textareas: {
            extra_languages: "Do you speak other languages?",
          },
          checkboxs: {
            privacy: {
              label: "I accept",
              link: "the privacy policies",
            },
          },
          buttons: {
            send: "Send",
          },
        },
      },
    },
    our_philosophy: {
      title: {
        mobile: "Our philosophy",
        web: "Our philosophy",
      },
      text: {
        mobile:
          "At Eloquence we take care of our staff, we value its work and effort and we look forward to enjoy every single event with them. <br/><br/>Check the reviews the people work with us gave us.<b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>Check reviews</a></b><br/><br/>If you have any doubt, email us to: work@eloquence.es",
        web:
          "At Eloquence we take care of our staff, we value its work and effort and we look forward to enjoy every single event with theam.<br/><br/>Check the reviews the peopl work with us gave us.<b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>Ver opiniones</a></b><br/><br/>If you have any doubt, email us to: work@eloquence.es",
      },
    },
  },
  navbar: {
    items: {
      services: "Services",
      hostesses: "Hostesses",
      congress_hostesses: "Congress hostesses",
      stands_hostesses: "Stand hostesses",
      promotion_hostesses: "Promote hostesses",
      brand_hostesses: "Brand hostesses",
      about_us: "About Eloquence",
      work_with_us: "Work with us",
      request_budget: "Reques budget",
      contact_us: "Contact us",
      assemblers: "Assemblers",
      auxiliars: "Assistants",
      waiters: "Waiters",
    },
  },
  footer: {
    items: {
      sales: "Events: ",
      hostess: "Work with us: ",
      phone: "",
      work_with_us: "Work with us",
      contact_us: "Contact us",
      services: "Services",
      about_us: "About Eloquence",
    },
  },
};

export { translation };
