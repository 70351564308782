import React from "react";
import { ListItem } from "components/Molecules/ListItem";
import { Image } from "components/Atoms/Image";
import { Grid, Hidden } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Text } from "components/Atoms/Text";
const Footer = (props) => {
  // console.log("render footer", props);
  return (
    <Grid
      container
      style={{
        background: " #000",
        paddingTop: "16px",
        paddingBottom: "24px",
        marginTop: props.noMarginTop ? null : "24px",
      }}
    >
      <Hidden smDown>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={10} md={3}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image url="logo-white.png" to="/" width="50%" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItem
                    items={[
                      {
                        component: (
                          <InstagramIcon
                            fontSize="large"
                            style={{ color: "#fff" }}
                          />
                        ),
                        link: "https://www.instagram.com/eloquence_es/",
                        external: true,
                      },
                      {
                        component: (
                          <LinkedInIcon
                            fontSize="large"
                            style={{ color: "#fff" }}
                          />
                        ),
                        link: "https://www.linkedin.com/company/eloquence-hostess/",
                        external: true,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <ListItem
                items={[
                  props.translation.items.sales,
                  props.translation.items.hostess,
                  // props.translation.items.phone,
                ].map((item) => {
                  return {
                    component: (
                      <Text
                        variant="customize-with-link"
                        color="#fff"
                        weight
                        margin="8px 0"
                      >
                        {item.label}
                        <>
                          {item.links.map((_link) => {
                            return (
                              <a
                                href={_link.link}
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                  fontWeight: "normal",
                                  marginLeft: "4px",
                                }}
                              >
                                {_link.label}
                              </a>
                            );
                          })}
                        </>
                      </Text>
                    ),
                  };
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                marginTop: "8px",
              }}
            >
              <ListItem
                items={[
                  // props.translation.items.work_with_us,
                  props.translation.items.contact_us,
                  props.translation.items.services,
                  props.translation.items.about_us,
                ].map((item) => {
                  return {
                    text: item.label,
                    link: item.link,
                    id: item.id,
                    margin: "8px 0",
                  };
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Image url="logo-white.png" to="/" width="100%" />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ListItem
                items={[
                  // props.translation.items.phone,
                  props.translation.items.sales,
                  props.translation.items.hostess,
                ].map((item) => {
                  return {
                    component: (
                      <Text
                        variant="customize-with-link"
                        margin="8px"
                        color="#fff"
                        style={{ textAlign: "center" }}
                      >
                        <b
                          style={{
                            borderBottom: "1px solid #fff",
                          }}
                        >
                          {item.label}
                        </b>
                        <br />
                        <>
                          {item.links.map((_link) => {
                            return (
                              <a
                                href={_link.link}
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                  marginLeft: "4px",
                                }}
                              >
                                {_link.label}
                              </a>
                            );
                          })}
                        </>
                      </Text>
                    ),
                  };
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ListItem
                items={[
                  // props.translation.items.work_with_us,
                  props.translation.items.contact_us,
                  props.translation.items.services,
                  props.translation.items.about_us,
                ].map((item) => {
                  return { text: item.label, link: item.link, id: item.id };
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ListItem
                items={[
                  {
                    component: (
                      <InstagramIcon
                        fontSize="large"
                        style={{ color: "#fff" }}
                      />
                    ),
                    link: "https://www.instagram.com/eloquence_es/",
                    external: true,
                  },
                  {
                    component: (
                      <LinkedInIcon
                        fontSize="large"
                        style={{ color: "#fff" }}
                      />
                    ),
                    link: "https://www.linkedin.com/company/eloquence-hostess/",
                    external: true,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export { Footer };
