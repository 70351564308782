const translation = {
  assemblers: {
    header: {
      title: {
        mobile: "Montadores para evento",
        web: "Montadores para evento",
      },
      subtitle: {
        mobile: "Montaje y desmontaje de stands.",
        web: "Montaje y desmontaje de stands.",
      },
    },
    assemblers: {
      cards: {
        auxiliars: {
          title: {
            mobile: "Auxiliares de montaje",
            web: "Auxiliares de montaje",
          },
          text: {
            mobile:
              "Realizar la carga, descarga y distribución de todos los materiales del evento.",
            web:
              "Realizar la carga, descarga y distribución de todos los materiales del evento.",
          },
        },
        hand_basic: {
          title: {
            mobile: "Hand Básico",
            web: "Hand Básico",
          },
          text: {
            mobile:
              "Ayudantes en el montaje de cualquier tipo de infraestructura.",
            web:
              "Ayudantes en el montaje de cualquier tipo de infraestructura.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Si necesitas montadores para tu evento",
        web: "Si necesitas montadores para tu evento",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    keys: {
      title: {
        mobile: "Nuestras claves",
        web: "Nuestras claves",
      },
      cards: {
        teamwork: {
          title: {
            mobile: "Trabajo en equipo",
            web: "Trabajo en equipo",
          },
          text: {
            mobile:
              "Solidaridad con el compañero y combinar especialidades técnicas, hacen el mejor de los equipos para un montaje.",
            web:
              "Solidaridad con el compañero y combinar especialidades técnicas, hacen el mejor de los equipos para un montaje.",
          },
        },
        experience: {
          title: {
            mobile: "Experiencia",
            web: "Experiencia",
          },
          text: {
            mobile:
              "Importante para realizar un trabajo rápido y satisfactorio en los montajes más complejos.",
            web:
              "Importante para realizar un trabajo rápido y satisfactorio en los montajes más complejos.",
          },
        },
      },
      footer: {
        mobile: "",
        web: "Servicio de montadores en todo España",
      },
    },
    contact_us: {
      title: {
        mobile: "Servicio de montadores en todo España",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "",
        },
      },
    },
  },
  auxiliars: {
    header: {
      title: {
        mobile: "Personal auxiliar para eventos",
        web: "Personal auxiliar para eventos",
      },
      subtitle: {
        mobile: "",
        web: "Perfiles más específicos para resolver ciertas tareas del evento",
      },
    },
    auxiliars: {
      cards: {
        drivers: {
          title: {
            mobile: "Conductores",
            web: "Conductores",
          },
          text: {
            mobile:
              "Chofers para facilitar el traslado de los asistentes al evento.",
            web:
              "Chofers para facilitar el traslado de los asistentes al evento.",
          },
        },
        access_personal: {
          title: {
            mobile: "Personal de accesos",
            web: "Personal de accesos",
          },
          text: {
            mobile:
              "Personal de ticketing para festivales, conciertos o grandes eventos.",
            web:
              "Personal de ticketing para festivales, conciertos o grandes eventos.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Si necesitas auxiliares para tu evento",
        web: "Si necesitas auxiliares para tu evento",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    keys: {
      title: {
        mobile: "Nuestras claves",
        web: "Nuestras claves",
      },
      cards: {
        experience: {
          title: {
            mobile: "Experiencia",
            web: "Experiencia",
          },
          text: {
            mobile:
              "La experiencia es un grado cuando se trata de trasladar a los asistentes de tu evento. Valoramos el historial de trabajo con nosotros.",
            web:
              "La experiencia es un grado cuando se trata de trasladar a los asistentes de tu evento. Valoramos el historial de trabajo con nosotros",
          },
        },
        attitude: {
          title: {
            mobile: "Actitud",
            web: "Actitud",
          },
          text: {
            mobile:
              "Ser profesional y serio es algo que no se puede dar por hecho. Valoramos la actitud de nuestros perfiles en base a su experiencia.",
            web:
              "Ser profesional y serio es algo que no se puede dar por hecho. Valoramos la actitud de nuestros perfiles en base a su experiencia.",
          },
        },
      },
      footer: {
        mobile: "",
        web: "Servicio de auxiliares con cobertura nacional",
      },
    },
    contact_us: {
      title: {
        mobile: "Servicio de auxiliares con cobertura nacional",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "",
        },
      },
    },
  },
  waiters: {
    header: {
      title: {
        mobile: "Camareros para eventos",
        web: "Camareros para eventos",
      },
      subtitle: {
        mobile: "",
        web: "Soluciones para atender a los asistentes de tu evento",
      },
    },
    waiters: {
      cards: {
        waiters: {
          title: {
            mobile: "Camareros",
            web: "Camareros",
          },
          text: {
            mobile:
              "Personal experimentado y correctamente uniformado para servir en tu evento.",
            web:
              "Personal experimentado y correctamente uniformado para servir en tu evento.",
          },
        },
        cocktail_shakers: {
          title: {
            mobile: "Cocteleros",
            web: "Cocteleros",
          },
          text: {
            mobile:
              "Expertos en coctelería, precisión e imagen son sus señas de identidad.",
            web:
              "Expertos en coctelería, precisión e imagen son sus señas de identidad.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Si necesitas camareros para tu evento",
        web: "Si necesitas camareros para tu evento",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    keys: {
      title: {
        mobile: "Nuestras claves",
        web: "Nuestras claves",
      },
      cards: {
        experience: {
          title: {
            mobile: "Experiencia",
            web: "Experiencia",
          },
          text: {
            mobile:
              "Saber llevar la bandeja o hacer un Daiquirí no se aprende el primer día. La experiencia es un grado aquí.",
            web:
              "Saber llevar la bandeja o hacer un Daiquirí no se aprende el primer día. La experiencia es un grado aquí.",
          },
        },
        uniformity: {
          title: {
            mobile: "Uniformidad",
            web: "Uniformidad",
          },
          text: {
            mobile:
              "Todos a una. Mismo uniforme para todos y cada uno de las personas que trabajan en el evento.",
            web:
              "Todos a una. Mismo uniforme para todos y cada uno de las personas que trabajan en el evento.",
          },
        },
      },
      footer: {
        mobile: "",
        web: "Servicio de camareros con cobertura nacional",
      },
    },
    contact_us: {
      title: {
        mobile: "Servicio de camareros en todo España",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "",
        },
      },
    },
  },
  about_us: {
    header: {
      title: {
        mobile: "Eloquence",
        web: "Eloquence",
      },
      texts: {
        info: {
          mobile:
            "Somos una agencia de personal de eventos de confianza, ponemos todo nuestro esfuerzo	para que tu evento sea un éxito.",
          web:
            "Somos una agencia de personal de eventos de confianza, ponemos todo nuestro esfuerzo	para que tu evento sea un éxito.",
        },
      },
      buttons: {
        contact_us: {
          mobile: null,
          web: "Contáctanos",
        },
      },
    },
    our_values: {
      title: { mobile: "Nuestros valores", web: "Nuestros valores" },
      cards: {
        support: {
          title: { mobile: "Excelencia", web: "Excelencia" },
          text: {
            mobile:
              "Trabajamos por un servicio 5 estrellas, el feedback de nuestros clientes nos importa.",
            web:
              "Trabajamos por un servicio 5 estrellas, el feedback de nuestros clientes nos importa.",
          },
        },
        excellency: {
          title: { mobile: "Digitales", web: "Digitales" },
          text: {
            mobile:
              "Somos jóvenes, rápidos y nos gusta aplicar la tecnología en nuestro sector.",
            web:
              "Somos jóvenes, rápidos y nos gusta aplicar la tecnología en nuestro sector.",
          },
        },
        decisives: {
          title: { mobile: "Trato", web: "Trato" },
          text: {
            mobile:
              "Cuidamos por igual de nuestros clientes como de las personas que trabajan con nosotros.",
            web:
              "Cuidamos por igual de nuestros clientes como de las personas que trabajan con nosotros.",
          },
        },
      },
    },
    our_history: {
      title: { mobile: "Nuestra historia", web: "Nuestra historia" },
      footer: {
        web: "“Nuestro primer evento, septiembre 2016, Madrid.”",
        mobile: "“Nuestro primer evento, septiembre 2016, Madrid.”",
      },
      text: {
        mobile:
          "Eloquence nace en Septiembre del 2016 enfocada a proveer azafatas a empresas internacionales en sus eventos en Madrid.<br/><br/>Queríamos atender y ayudar a todas esas empresas que visitan eventos, ferias y congresos en nuestro país cada año. <br/><br/>Nuestra idea ha sido siempre la de potenciar el uso del inglés en el sector, cuidando por igual a nuestros clientes como a nuestros azafatos. En poco tiempo logramos posicionarnos como una de las agencias de referencia para empresas extranjeras en España, así como una de las mejor valoradas tanto por clientes como por sus azafatos.<br/><br/>En el 2019 abrimos oficinas en Barcelona y en el 2020 evolucionamos para convertirnos en agencia de personal de eventos.<br/><br/>Nuestro futuro está ligado a la digitalización, ¿te vienes con nosotros?",
        web:
          "Eloquence nace en Septiembre del 2016 enfocada a proveer azafatas a empresas internacionales en sus eventos en Madrid.<br/><br/>Queríamos atender y ayudar a todas esas empresas que visitan eventos, ferias y congresos en nuestro país cada año. <br/><br/>Nuestra idea ha sido siempre la de potenciar el uso del inglés en el sector, cuidando por igual a nuestros clientes como a nuestros azafatos. En poco tiempo logramos posicionarnos como una de las agencias de referencia para empresas extranjeras en España, así como una de las mejor valoradas tanto por clientes como por sus azafatos.<br/><br/>En el 2019 abrimos oficinas en Barcelona y en el 2020 evolucionamos para convertirnos en agencia de personal de eventos.<br/><br/>Nuestro futuro está ligado a la digitalización, ¿te vienes con nosotros?",
      },
    },
    success_stories: {
      title: { mobile: "Caso de éxito", web: "Caso de éxito" },
      subtitle: {
        mobile: "UEFA Champions League",
        web: "UEFA Champions League",
      },
      footer: {
        web:
          "Fecha: Junio 2019<br/><br/>Lugar: Madrid (Estadio Wanda Metropolitano)",
        mobile:
          "Fecha: Junio 2019<br/><br/>Lugar: Madrid (Estadio Wanda Metropolitano)",
      },
      text: {
        mobile:
          "Eloquence fue elegida como la agencia de azafatas proveedora oficial de la UEFA para la captación, selección, formación y puesta en escena de un total de 94 azafatos de imagen.<br/><br/>La UEFA preparó un lounge gigante donde se dieron cita todos los patrocinadores de la competición, algunos como Mastercard, Heineken o Adidas. El recinto acogió a más de 3.000 invitados durante las 8 horas que permaneció abierto a los asistentes.<br><br/>Nuestro objetivo era el de ofrecer un servicio excelente al visitante, así como también el de lograr la perdurabilidad de los recuerdos en su memoria. Siempre con una sonrisa de oreja a oreja, deseamos suerte a ambas aficiones antes del partido, y felicitamos y consolamos a ganadores y vencidos después del mismo.<br><br/>El evento fue un auténtico éxito a nivel organización del personal, obteniendo la felicitación expresa de la mayoría de los sponsors.",
        web:
          "Eloquence fue elegida como la agencia de personal proveedora oficial de la UEFA para la captación, selección, formación y puesta en escena de un total de 94 azafatos de imagen.<br/><br/>La UEFA preparó un lounge gigante donde se dieron cita todos los patrocinadores de la competición, algunos como Mastercard, Heineken o Adidas. El recinto acogió a más de 3.000 invitados durante las 8 horas que permaneció abierto a los asistentes.<br><br/>Nuestro objetivo era el de ofrecer un servicio excelente al visitante, así como también el de lograr la perdurabilidad de los recuerdos en su memoria. Siempre con una sonrisa de oreja a oreja, deseamos suerte a ambas aficiones antes del partido, y felicitamos y consolamos a ganadores y vencidos después del mismo.<br><br/>El evento fue un auténtico éxito a nivel organización del personal, obteniendo la felicitación expresa de la mayoría de los sponsors.",
      },
    },
    budget: {
      buttons: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
  },
  budget: {
    header: {
      title: {
        mobile: "¿Necesitas presupuesto?",
        web: "¿Necesitas presupuesto?",
      },
      card: {
        title: {
          mobile: null,
          web: "Dinos cómo podemos ayudarte",
        },
        subtitle: {
          mobile: null,
          web: "Rellena el formulario y te contactaremos en 24h",
        },
        form: {
          mobile: null,
          web: {
            inputs: {
              name: { label: "Nombre", placeholder: "Patricia Díaz" },
              email: { label: "Email", placeholder: "patridiaz@company.com" },
              phone: { label: "Teléfono", placeholder: "+33654939705" },
              city: {
                label: "¿Dónde sucede el evento?",
                placeholder: "Madrid, Barcelona, Bilbao...",
              },
              number_hostess: {
                label: "Número de personas",
                placeholder: "?",
              },
              message: {
                label: "Cuéntanos un poco mas sobre el proyecto",
                placeholder: "Fechas, lugar, marca involucrada, etc.",
              },
            },
            selects: {
              profile_type: {
                label: "Tipo de perfil",
                placeholder: "Tipo de perfil",
                width: 122,
                items: [
                  { value: "azafatas", label: "Azafatas" },
                  { value: "montadores", label: "Montadores" },
                  { value: "auxiliares", label: "Auxiliares" },
                  { value: "camareros", label: "Camareros" },
                ],
              },
            },
            checkboxs: {
              privacy: {
                label: "Acepto ",
                link: "políticas de privacidad",
              },
            },
            buttons: {
              send: "Enviar",
            },
          },
        },
      },
    },
    subtitle: { mobile: "Cuéntanos los detalles del evento", web: null },
    form: {
      mobile: {
        inputs: {
          name: { label: "Nombre", placeholder: "Patricia Díaz" },
          email: { label: "Email", placeholder: "patridiaz@tuempresa.com" },
          phone: { label: "Teléfono", placeholder: "+34654939705" },
          city: { label: "Ciudad del evento", placeholder: "¿Dónde ocurre?" },
          number_hostess: {
            label: "Nº de personas",
            placeholder: "?",
          },
          message: {
            label: "Detalles",
            placeholder: "Cuéntanos más detalles!",
          },
        },
        checkboxs: {
          privacy: { label: "Acepto ", link: "políticas de privacidad" },
        },
        buttons: {
          send: "Enviar",
        },
      },
      web: null,
    },
    contact_us: {
      title: {
        mobile: "¿Necesitas contactarnos?",
        web: "¿Necesitas contactarnos?",
      },
      card: {
        call_us: {
          title: { mobile: "Llámanos", web: "Llámanos" },
          text: {
            mobile: "+34 654 939 705",
            web: "A nuestro número: +34 654 939 705",
          },
        },
        write_us: {
          title: { mobile: "Escríbenos", web: "Escríbenos" },
          text: {
            mobile: "sales@eloquence.es",
            web: "A nuestro correo: sales@eloquence.es",
          },
        },
      },
      buttons: {
        back_home: {
          label: { mobile: "Ir a portada", web: null },
        },
      },
    },
  },
  contact_us: {
    header: {
      title: {
        mobile: "Un equipo preparado para ayudarte",
        web: "Un equipo preparado para ayudarte",
      },
    },
    form: {
      title: {
        mobile: "¿Te podemos ayudar?",
        web: null,
      },
      subtitle: {
        mobile: "Te daremos respuesta en menos de 24h",
        web: null,
      },
      card: {
        mobile: {
          inputs: {
            name: { label: "Nombre", placeholder: "Patricia Díaz" },
            email: { label: "Email", placeholder: "patridiaz@company.com" },
            phone: { label: "Teléfono", placeholder: "+34 654939705" },
            city: {
              label: "Ciudad del evento",
              placeholder: "¿Dónde ocurre tu evento?",
            },
            number_hostess: {
              label: "Número de personas que necesitas",
              placeholder: "?",
            },
            message: {
              label: "Cuéntanos más acerca de tu evento!",
              placeholder: "¿Cuándo es?",
            },
          },
          checkboxs: {
            privacy: {
              label: "Acepto ",
              link: "políticas de privacidad",
            },
          },
          buttons: {
            send: "Enviar",
          },
        },
        web: {
          title: "¿Te podemos ayudar?",
          subtitle: "¡Dinos como! Te daremos respuesta en 24h",
          inputs: {
            name: { label: "Nombre completo", placeholder: "Patricia Díaz" },
            email: { label: "Email", placeholder: "patridiaz@company.com" },
            phone: { label: "Teléfono", placeholder: "+34654939705" },
            city: { label: "Ciudad del evento", placeholder: "¿Dónde ocurre?" },
            number_hostess: {
              label: "Nº de personas que necesitas",
              placeholder: "?",
            },
            message: {
              label: "Cuéntanos un poco más!",
              placeholder: "Coméntanos cualquier duda :)",
            },
          },
          checkboxs: {
            privacy: {
              label: "Acepto ",
              link: "políticas de privacidad",
            },
          },
          buttons: {
            send: "Enviar",
          },
        },
      },
    },
    contact_us: {
      card: {
        call_us: {
          title: { mobile: "Llámanos", web: "Llámanos" },
          text: {
            mobile: "+34 654 939 705",
            web: "+34 654 939 705",
          },
        },
        write_us: {
          title: { mobile: "Escríbenos", web: "Escríbenos" },
          text: {
            mobile: "sales@eloquence.es",
            web: "sales@eloquence.es",
          },
        },
        our_offices: {
          title: { mobile: null, web: "Nuestras oficinas" },
          madrid: {
            mobile: null,
            web: "Calle de Núñez de Balboa, 120<br/>Madrid, España.",
          },
          barcelona: {
            mobile: null,
            web: "Carrer Calàbria 149<br/>Barcelona, España.",
          },
        },
        button: {
          work_with_us: {
            mobile: "Trabaja con nosotros",
            web: "Trabaja con nosotros",
          },
        },
      },
    },
    where_we_are: {
      title: { mobile: "Dónde estamos", web: "Dónde estamos" },
      madrid: {
        title: { mobile: "Madrid", web: "Madrid" },
        text: {
          mobile: "Calle de Núñez de Balboa, 120<br/>Cink Emprende",
          web: "Calle de Núñez de Balboa, 120<br/>Cink Emprende",
        },
      },
      barcelona: {
        title: { mobile: "Barcelona", web: "Barcelona" },
        text: {
          mobile: "Carrer Calàbria 149<br/>The Office Coworking",
          web: "Carrer Calàbria 149<br/>The Office Coworking",
        },
      },
    },
    instagram: {
      title: { mobile: "Síguenos en instagram", web: "Síguenos en instagram" },
    },
    budget: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: null,
        },
      },
    },
  },
  home: {
    header: {
      title: {
        mobile: null,
        web: null,
      },
      form: {
        mobile: null,
        web: {
          title: "Contrata personal para tu evento",
          subtitle: "¿Necesitas presupuesto?",
          inputs: {
            email: { label: "Email", placeholder: "email@tuempresa.com" },
            phone: {
              label: "Teléfono de contacto",
              placeholder: "+34654939705",
            },
            number_hostess: { label: "Número de personas", placeholder: "?" },
            details: {
              label: "Detalles",
              placeholder: "¿Cuándo y dónde sucede el evento?",
            },
          },
          selects: {
            profile_type: {
              label: "Tipo de perfil",
              placeholder: "Tipo de perfil",
              width: 122,
              items: [
                { value: "azafatas", label: "Azafatas" },
                { value: "montadores", label: "Montadores" },
                { value: "auxiliares", label: "Auxiliares" },
                { value: "camareros", label: "Camareros" },
              ],
            },
          },
          checkboxs: {
            privacy: {
              label: "Acepto ",
              link: "políticas de privacidad ",
            },
          },
          buttons: {
            send: "Enviar",
          },
        },
      },
    },
    budget: {
      mobile: {
        title: "Contrata personal para tu evento",
        subtitle: null,
        budget: {
          buttons: {
            request_budget: "Pedir presupuesto",
          },
        },
      },
      web: null,
    },
    type_profile: {
      title: {
        mobile: "",
        web: "Elige el tipo de perfil",
      },
      subtitle: {
        mobile: "Diferentes tipos de perfiles para tu evento",
        web:
          "Ponemos a tu disposición diferentes tipos de perfiles para tu evento",
      },
      cards: {
        hostesses: { title: { mobile: "Azafatas", web: "Azafatas" } },
        assemblers: { title: { mobile: "Montadores", web: "Montadores" } },
        auxiliars: { title: { mobile: "Auxiliares", web: "Auxiliares" } },
        waiters: { title: { mobile: "Camareros", web: "Camareros" } },
      },
    },
    guarantee: {
      title: {
        mobile: "¿Qué es Eloquence?",
        web: "¿Qué es Eloquence?",
      },
      cards: {
        quick: {
          title: { mobile: "Digital", web: "Digital" },
          text: {
            mobile:
              "Gestión de todas las acciones a través de nuestra plataforma.",
            web:
              "Gestión de todas las acciones a través de nuestra plataforma.",
          },
        },
        support: {
          title: { mobile: "Support", web: "Support" },
          text: {
            mobile:
              "Estamos cerca de nuestros clientes, cuidamos de nuestro personal.",
            web:
              "Estamos cerca de nuestros clientes, cuidamos de nuestro personal.",
          },
        },
        trust: {
          title: { mobile: "Quality", web: "Quality" },
          text: {
            mobile:
              "Trabajamos con la filosofía 5 estrellas, es nuestro objetivo en cada evento.",
            web:
              "Trabajamos con la filosofía 5 estrellas, es nuestro objetivo en cada evento",
          },
        },
      },
    },
    result: {
      title: {
        mobile: "El resultado nos importa",
        web: "El resultado nos importa",
      },
      subtitle: {
        mobile: "Lo que dicen quienes trabajan con nosotros",
        web: "Lo que dicen quienes trabajan con nosotros",
      },
      cards: {
        philipp: {
          text: {
            mobile:
              "Muy satisfechos con el servicio prestado. Incluso cuando nuestra azafata se puso mala, Eloquence se volcó en la búsqueda de un reemplazo y se aseguró que siempre hubiera personal en nuestro stand.",
            web:
              "Muy satisfechos con el servicio prestado. Incluso cuando nuestra azafata se puso mala, Eloquence se volcó en la búsqueda de un reemplazo y se aseguró que siempre hubiera personal en nuestro stand.",
          },
          name: {
            mobile: "Philipp Zeiske, Bisutex",
            web: "Philipp Zeiske, Bisutex",
          },
        },
        sara: {
          text: {
            mobile:
              "Agencia de azafatas de 10. Profesionales y muy atentos con sus azafatas, cada trabajo es ameno. En 7 años de azafata puedo decir que no tienen nada que envidiar a las agencias más antiguas.",
            web:
              "Agencia de azafatas de 10. Profesionales y muy atentos con sus azafatas, cada trabajo es ameno. En 7 años de azafata puedo decir que no tienen nada que envidiar a las agencias más antiguas.",
          },
          name: {
            mobile: "Sara P, Azafata",
            web: "Sara P, Azafata",
          },
        },
        wendy: {
          text: {
            mobile:
              "La azafata comprendió rápidamente su papel y respondió a nuestros clientes, socios y personal, como si fuera una empleada de nuestra empresa. ¡Gracias!",
            web:
              "La azafata comprendió rápidamente su papel y respondió a nuestros clientes, socios y personal, como si fuera una empleada de nuestra empresa. ¡Gracias por todo el apoyo!",
          },
          name: {
            mobile: "Wendi W, Mobile World Congress",
            web: "Wendi W, Mobile World Congress",
          },
        },
      },
      buttons: {
        reviews: {
          mobile: "Mira nuestras reviews",
          web: "Mira nuestras reviews",
        },
      },
    },
    contact_us: {
      title: {
        mobile: "¿Quieres saber más?",
        web: "¿Quieres saber más?",
      },
      subtitle: {
        mobile: "Ponte en contacto con nosotros",
        web: "Ponte en contacto<br/>con nosotros",
      },
      phone: {
        mobile: "Teléfono: +34 654 939 705",
        web: "Teléfono: +34 654 939 705",
      },
      email: {
        mobile: "Email: sales@eloquence.es",
        web: "Email: sales@eloquence.es",
      },
      buttons: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    work_with_us: {
      title: {
        mobile: "Trabaja con nosotros",
        web: "Trabaja con nosotros",
      },
      subtitle: {
        mobile: "Rellena nuestro formulario",
        web: "Relllena nuestro formulario",
      },
      buttons: {
        join: {
          mobile: "Únete a Eloquence",
          web: "Únete a Eloquence",
        },
      },
      text: {
        mobile: null,
        web: "o escribe work@eloquence.es",
      },
    },
  },
  payment: {
    header: null,
    payment_gateway: {
      title: {
        mobile: "Bienvenido a la pasarela de pago",
        web: "Bienvenido a la pasarela de pago",
      },
      card: {
        title: {
          mobile: "Rellena los siguientes campos",
          web: "Rellena los siguientes campos",
        },
        subtitle: {
          mobile: "Encontrarás los datos en tu factura",
          web: "Encontrarás los datos en tu factura",
        },
        form: {
          inputs: {
            company: {
              label: "Empresa",
              placeholder: "Nombre de empresa",
            },
            number_invoice: {
              label: "Nº de factura",
              placeholder: "ELOQ-XXX",
            },
            cuantity: {
              label: "Cantidad",
              placeholder: "Mostrada en la factura",
            },
          },
          checkboxs: {
            privacy: {
              label: "Acepto ",
              link: "políticas de privacidad",
            },
          },
          buttons: {
            send: "Pagar",
          },
        },
      },
    },
    some_dude: {
      title: { mobile: "¿Alguna duda?", web: "¿Alguna duda?" },
      subtitle: {
        mobile: "Ponte en contacto con nosotros",
        web: "Ponte en contacto con nosotros",
      },
      phone: {
        mobile: "Teléfono +34 654 939 705",
        web: "Teléfono +34 654 939 705",
      },
      email: {
        mobile: "Email: sales@eloquencespain.com",
        web: "Email: sales@eloquencespain.com",
      },
    },
  },
  services: {
    header: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    hostesses: {
      title: {
        mobile: "Personal para tu evento",
        web: "Personal para tu evento",
      },
      subtitle: {
        mobile: "Según la necesidad de tu proyecto",
        web: "Según la necesidad de tu proyecto",
      },
      cards: {
        hostesses: {
          title: { mobile: "Azafatas", web: "Azafatas" },
          text: {
            mobile:
              "Para todo tipo de eventos: congresos, ferias, promociones, actos de empresa, etc. Son la primera imagen y la representación de la empresa en el evento.",
            web:
              "Para todo tipo de eventos: congresos, ferias, promociones, actos de empresa, etc. Son la primera imagen y la representación de la empresa en el evento.",
          },
        },
        assemblers: {
          title: { mobile: "Montadores", web: "Montadores" },
          text: {
            mobile:
              "Montaje y desmontaje de estructuras. Carga y descarga de materiales. <b>Trabajan en equipo</b> para tener el trabajo en tiempo.",
            web:
              "Montaje y desmontaje de estructuras. Carga y descarga de materiales. <b>Trabajan en equipo</b> para tener el trabajo en tiempo.<br/><br/>",
          },
        },
        auxiliars: {
          title: { mobile: "Auxiliares", web: "Auxiliares" },
          text: {
            mobile:
              "El personal para cubrir las necesidades más específicas de tu evento o de grandes acciones como festivales y conciertos.",
            web:
              "El personal para cubrir las necesidades más específicas de tu evento o de grandes acciones como festivales y conciertos.",
          },
        },
        waiters: {
          title: { mobile: "Camareros", web: "Camareros" },
          text: {
            mobile:
              "Camareros y cocteleros para servir en los eventos con más sabor.",
            web:
              "Camareros y cocteleros para servir en los eventos con más sabor.<br/><br/>",
          },
        },
      },
      footer: {
        mobile:
          "¡Comprueba <b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>nuestras reviews</a></br> como agencia!",
        web:
          "¡Comprueba <b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>nuestras reviews</a></b> como agencia!",
      },
    },
    doubt: {
      title: {
        mobile: "Si necesitas contratar personal",
        web: "Si quieres contratar personal o tienes alguna duda",
      },
      button: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    how_we_work: {
      title: { mobile: "¿Cómo lo hacemos?", web: "¿Cómo lo hacemos?" },
      cards: {
        selection: {
          title: { mobile: "Selección", web: "Selección" },
          text: {
            mobile: "Trabajamos únicamente con perfiles 100% verificados",
            web:
              "Trabajamos únicamente con perfiles 100% verificados<br/><br/>",
          },
        },
        planning: {
          title: { mobile: "Report & Feedback", web: "Report & Feedback" },
          text: {
            mobile: "Evaluamos personalmente con ratings a nuestro staff",
            web:
              "Evaluamos personalmente con ratings a nuestro staff<br/><br/>",
          },
        },
        support: {
          title: { mobile: "Gestión", web: "Gestión" },
          text: {
            mobile:
              "La gestión de las acciones se realiza a través de nuestra plataforma",
            web:
              "La gestión de las acciones se realiza a través de nuestra plataforma",
          },
        },
      },
      footer: {
        mobile:
          "Agencia de personal de eventos de confianza. Trabajamos en todo el territorio nacional, en todo tipo de eventos. Disponibilidad 24/7/365",
        web:
          "Agencia de personal de eventos de confianza. Trabajamos en todo el territorio nacional, en todo tipo de eventos. Disponibilidad 24/7/365",
      },
    },
    request_budget: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
  },
  hostesses: {
    header: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    hostesses: {
      title: {
        mobile: "Azafatas para tu evento",
        web: "Azafatas para tu evento",
      },
      subtitle: {
        mobile: "Elige el tipo de azafata que más se ajusta a tu proyecto",
        web: "Elige el tipo de azafata que más se ajusta a tu proyecto",
      },
      cards: {
        congress_hostesses: {
          title: {
            mobile: "Azafatas de congreso",
            web: "Azafatas de congreso",
          },
          text: {
            mobile:
              "Son esenciales debido a su capacidad resolutiva. Protocolo por encima de todo. Trabajan en equipo ante cualquier incidencia que pueda ocurrir en el evento.</b>",
            web:
              "Son esenciales debido a su capacidad resolutiva. Protocolo por encima de todo. Trabajan en equipo ante cualquier incidencia que pueda ocurrir en el evento.</br>",
          },
        },
        brand_hostesses: {
          title: { mobile: "Azafatas de imagen", web: "Azafatas de imagen" },
          text: {
            mobile:
              "Son la imagen de la marca, por eso es muy importante que su presencia esté muy cuidada. Cumplen con requisitos específicos de la propia marca.",
            web:
              "Son la imagen de la marca, por eso es muy importante que su presencia esté muy cuidada. Cumplen con requisitos específicos de la propia marca.",
          },
        },
        stand_hostesses: {
          title: { mobile: "Azafatas de ferias", web: "Azafatas de ferias" },
          text: {
            mobile:
              "Son la primera imagen de la empresa. Se encargan de recibir y atender a los asistentes al stand. Cuentan con un conocimiento real acerca de la empresa a la que representan.",
            web:
              "Son la primera imagen de la empresa. Se encargan de recibir y atender a los asistentes al stand. Cuentan con un conocimiento real acerca de la empresa a la que representan.",
          },
        },
        promoter_hostesses: {
          title: { mobile: "Azafatas promotoras", web: "Azafatas promotoras" },
          text: {
            mobile:
              "Se ubican en el punto de venta de la marca, con un perfil más proactivo y comercial. Toma un papel esencial en la decisión del cliente final.",
            web:
              "Se ubican en el punto de venta de la marca, con un perfil más proactivo y comercial. Toma un papel esencial en la decisión del cliente final.<br/><br/>",
          },
        },
      },
    },
    doubt: {
      title: {
        mobile: "Si necesitas contratar azafatas",
        web: "Si necesitas contratar azafatas para tu evento",
      },
      button: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    how_we_work: {
      title: {
        mobile: "Nuestros 3 indispensables",
        web: "Nuestros 3 indispensables",
      },
      cards: {
        selection: {
          title: { mobile: "Actitud", web: "Actitud" },
          text: {
            mobile:
              "Es lo más importante para nosotros. Sin actitud no queda nada.",
            web:
              "Es lo más importante para nosotros. Sin actitud no queda nada.",
          },
        },
        planning: {
          title: { mobile: "Inglés", web: "Inglés" },
          text: {
            mobile:
              "Todas nuestras azafatas hablan inglés. Es requisito básico para nosotros.",
            web:
              "Todas nuestras azafatas hablan inglés. Es requisito básico para nosotros.",
          },
        },
        support: {
          title: { mobile: "Presencia", web: "Presencia" },
          text: {
            mobile:
              "No es solo la imagen, también cuenta y mucho su presencia en general.",
            web:
              "No es solo la imagen, también cuenta y mucho su presencia en general.",
          },
        },
      },
      footer: {
        mobile:
          "Azafatas de eventos para tu proyecto. Trabajamos con los mejores perfiles de azafatas, verificados 100%",
        web:
          "Azafatas de eventos para tu proyecto. Trabajamos con los mejores perfiles de azafatas, verificados 100%",
      },
    },
    talent: {
      title: {
        mobile: "Otros servicios",
        web: "Otros servicios",
      },

      cards: {
        assemblers: {
          title: { mobile: "Montadores", web: "Montadores" },
        },
        auxiliars: {
          title: { mobile: "Auxiliares", web: "Auxiliares" },
        },
        waiters: {
          title: { mobile: "Camareros", web: "Camareros" },
        },
        services: {
          title: { mobile: "Servicios", web: "Servicios" },
        },
      },
    },
    request_budget: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
  },
  congress_hostesses: {
    header: {
      title: { mobile: "Azafatas de congreso", web: "Azafatas de congreso" },
      texts: {
        text_1: {
          mobile:
            "Trabajan en equipo para que el congreso sea un éxito y ante cualquier incidencia que pueda ocurrir en el evento.",
          web:
            "Trabajan en equipo para que el congreso sea un éxito y ante cualquier incidencia que pueda ocurrir en el evento.",
        },
        text_2: {
          mobile:
            "Un congreso es una reunión, en la que durante uno o varios días, personas de distintos lugares presentan conferencias sobre temas relacionados con su trabajo. Las azafatas de congreso son la encargadas de participar directamente en la organización de estas reuniones y son responsables de una gran variedad de funciones. Principalmente son la encargadas de hacer que todo salga según lo establecido.<br/>Las azafatas de congreso son esenciales debido a su capacidad resolutiva. Son quienes se ocupan de reducir cualquier incidencia que pueda ocurrir en el recinto y trabajan en equipo para posibilitar el funcionamiento adecuado del congreso.",
          web:
            "Un congreso es una reunión, en la que durante uno o varios días, personas de distintos lugares presentan conferencias sobre temas relacionados con su trabajo. Las azafatas de congreso son la encargadas de participar directamente en la organización de estas reuniones y son responsables de una gran variedad de funciones. Principalmente son la encargadas de hacer que todo salga según lo establecido.<br/>Las azafatas de congreso son esenciales debido a su capacidad resolutiva. Son quienes se ocupan de reducir cualquier incidencia que pueda ocurrir en el recinto y trabajan en equipo para posibilitar el funcionamiento adecuado del congreso.",
        },
      },
    },
    hire: {
      title: {
        mobile: "Si necesitas azafatas de congreso",
        web: "Si necesitas azafatas de congreso",
      },
      buttons: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    section: {
      text: {
        mobile:
          "Ofrecen a los asistentes todo tipo de información sobre el congreso, les facilitan su atención y ayuda, indican el camino, preparan las salas de los ponentes, y acompañan al personal de protocolo en sus funciones y ayudan en el registro y reparto de las acreditaciones.<br/><br/>Un requisito indispensable para las azafatas de congreso es saber inglés, ya que España es el quinto país en cuanto a número de congresos internacionales se refiere. En Eloquence, todas nuestras azafatas hablan inglés fluído y cuentan con formación y experiencia previa en la participación de congresos. Además, para nosotros son muy importantes cualidades como la simpatía, la naturalidad, la educación y la discreción, necesarias para desarrollar su trabajo de la mejor manera posible.",
        web:
          "Ofrecen a los asistentes todo tipo de información sobre el congreso, les facilitan su atención y ayuda, indican el camino, preparan las salas de los ponentes, y acompañan al personal de protocolo en sus funciones y ayudan en el registro y reparto de las acreditaciones.<br/><br/>Un requisito indispensable para las azafatas de congreso es saber inglés, ya que España es el quinto país en cuanto a número de congresos internacionales se refiere. En Eloquence, todas nuestras azafatas hablan inglés fluído y cuentan con formación y experiencia previa en la participación de congresos. Además, para nosotros son muy importantes cualidades como la simpatía, la naturalidad, la educación y la discreción, necesarias para desarrollar su trabajo de la mejor manera posible.",
      },
    },
    other_services: {
      title: { mobile: "Otros servicios", web: "Otros servicios" },
      subtitle: {
        mobile: "Según la necesidad de tu proyecto",
        web: "Especializados según la necesidad de tu proyecto",
      },
      cards: {
        stand: {
          title: { mobile: "Ferias", web: "Ferias" },
        },
        promoters: { title: { mobile: "Promotoras", web: "Promotoras" } },
        brand: { title: { mobile: "Imagen", web: "Imagen" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: {
      mobile:
        "Tenemos experiencia en la implementación en congresos de grandes equipos de azafatos",
      web:
        "Tenemos experiencia en la implementación en congresos de grandes equipos de azafatos",
    },
  },
  stand_hostesses: {
    header: {
      title: { mobile: "Azafatas de ferias", web: "Azafatas de ferias" },
      texts: {
        text_1: {
          mobile:
            "Se encargan de recibir y atender a los asistentes al stand. Cuentan con un conocimiento real acerca de la empresa a la que representan.",
          web:
            "Se encargan de recibir y atender a los asistentes al stand. Cuentan con un conocimiento real acerca de la empresa a la que representan.",
        },
        text_2: {
          mobile:
            "Son muchas las empresas que acuden a ferias de negocio en nuestro país. En ellas, la empresa tiene la posibilidad de contratar un stand en el que ofrecer sus productos y servicios. La figura de la azafata de feria es necesaria para llevar a cabo algunas tareas importantes dentro del stand y garantizar el correcto funcionamiento de este.<br/>La azafata de feria se encarga de recibir y atender a los asistentes al stand. Para ello, cuenta con un conocimiento real acerca de la empresa a la que representa y el producto o servicio que ofrece en el stand. Es la primera imagen de la empresa para los clientes potenciales por lo que debe tener una muy buena presencia y una educación apropiada.",
          web:
            "Son muchas las empresas que acuden a ferias de negocio en nuestro país. En ellas, la empresa tiene la posibilidad de contratar un stand en el que ofrecer sus productos y servicios. La figura de la azafata de feria es necesaria para llevar a cabo algunas tareas importantes dentro del stand y garantizar el correcto funcionamiento de este.<br/>La azafata de feria se encarga de recibir y atender a los asistentes al stand. Para ello, cuenta con un conocimiento real acerca de la empresa a la que representa y el producto o servicio que ofrece en el stand. Es la primera imagen de la empresa para los clientes potenciales por lo que debe tener una muy buena presencia y una educación apropiada.",
        },
      },
    },
    hire: {
      title: {
        mobile: "Contratar azafatas de feria",
        web: "Contratar azafatas de feria",
      },
      buttons: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    section: {
      text: {
        mobile:
          "En ocasiones serán ferias de venta, donde la azafata puede encargarse de la tarea de vender. Su función de la azafata de feria es la de atraer clientes y familiarizarlos con la empresa para la que trabaja.<br/>Otra función de las azafatas de feria es ocuparse de agendar las citas y reuniones de los propios trabajadores de la empresa dentro del stand. En una feria de varios días la organización del stand es esencial para garantizar el éxito de la empresa en la feria.<br/>Hacemos todo lo posible para formar a nuestras azafatas de feria en los aspectos más funcionales del puesto de trabajo. Pero por encima de todo, valoramos el conocimiento de otros idiomas para facilitar la comunicación con asistentes extranjeros, especialmente el inglés, por lo que todas nuestras azafatas de feria dominan este idioma.",
        web:
          "En ocasiones serán ferias de venta, donde la azafata puede encargarse de la tarea de vender. Su función de la azafata de feria es la de atraer clientes y familiarizarlos con la empresa para la que trabaja.<br/>Otra función de las azafatas de feria es ocuparse de agendar las citas y reuniones de los propios trabajadores de la empresa dentro del stand. En una feria de varios días la organización del stand es esencial para garantizar el éxito de la empresa en la feria.<br/>Hacemos todo lo posible para formar a nuestras azafatas de feria en los aspectos más funcionales del puesto de trabajo. Pero por encima de todo, valoramos el conocimiento de otros idiomas para facilitar la comunicación con asistentes extranjeros, especialmente el inglés, por lo que todas nuestras azafatas de feria dominan este idioma.",
      },
    },
    other_services: {
      title: { mobile: "Otros servicios", web: "Otros servicios" },
      subtitle: {
        mobile: "Según la necesidad de tu proyecto",
        web: "Según la necesidad de tu proyecto",
      },
      cards: {
        stand: {
          title: { mobile: "Ferias", web: "Ferias" },
        },
        promoters: { title: { mobile: "Promotoras", web: "Promotoras" } },
        brand: { title: { mobile: "Imagen", web: "Imagen" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contáctanos", web: "Contáctanos" } },
    },
    footer: {
      mobile: "Somos expertos en proveer azafatas en ferias internacionales",
      web: "Somos expertos en proveer azafatas en ferias internacionales",
    },
  },
  promoter_hostesses: {
    header: {
      title: { mobile: "Azafatas promotoras", web: "Azafatas promotoras" },
      texts: {
        text_1: {
          mobile:
            "Se ubican en el punto de venta de la marca, con un perfil más comercial. Toma un papel esencial en la venta.",
          web:
            "Se ubican en el punto de venta de la marca, con un perfil más comercial. Toma un papel esencial en la venta.",
        },
        text_2: {
          mobile:
            "En el canal de venta de un producto, una parte esencial del proceso es la promoción. Para garantizar la venta de este producto existen diferentes tipos de promoción que en algunas ocasiones necesitarán de la ayuda de una o varias azafatas promotoras.",
          web:
            "En el canal de venta de un producto, una parte esencial del proceso es la promoción. Para garantizar la venta de este producto existen diferentes tipos de promoción que en algunas ocasiones necesitarán de la ayuda de una o varias azafatas promotoras.",
        },
      },
    },
    hire: {
      title: {
        mobile: "Contratar azafatas promotoras",
        web: "Contratar azafatas promotoras",
      },
      buttons: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    section: {
      text: {
        mobile:
          "Intentarán atraer al mayor número de clientes potenciales posible, acercando el producto a estos para conseguir su interés. Actúan como un nexo de unión entre la marca y los posibles compradores; se encargan de hacer llegar al producto al cliente, resuelven cualquier duda que éste pueda tener acerca del producto o la marca.<br/><br/>La entrega de publicidad, la entrega de muestras o la promoción en punto de venta. Las azafatas que forman parte del equipo de Eloquence, además de experiencia, formación y nivel de inglés poseen habilidades de comunicación que les permite ser sociables y elocuentes para conectar el target con la marca.",
        web:
          "Intentarán atraer al mayor número de clientes potenciales posible, acercando el producto a estos para conseguir su interés. Actúan como un nexo de unión entre la marca y los posibles compradores; se encargan de hacer llegar al producto al cliente, resuelven cualquier duda que éste pueda tener acerca del producto o la marca.<br/><br/>La entrega de publicidad, la entrega de muestras o la promoción en punto de venta. Las azafatas que forman parte del equipo de Eloquence, además de experiencia, formación y nivel de inglés poseen habilidades de comunicación que les permite ser sociables y elocuentes para conectar el target con la marca.",
      },
    },
    other_services: {
      title: { mobile: "Otros servicios", web: "Otros servicios" },
      subtitle: {
        mobile: "Según la necesidad de tu proyecto",
        web: "Acorde a la necesidad de tu proyecto",
      },
      cards: {
        stand: {
          title: { mobile: "Ferias", web: "Ferias" },
        },
        promoters: { title: { mobile: "Promotoras", web: "Promotoras" } },
        brand: { title: { mobile: "Imagen", web: "Imagen" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contáctanos", web: "Contáctanos" } },
    },
    footer: {
      mobile:
        "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
      web:
        "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
    },
  },
  brand_hostesses: {
    header: {
      title: { mobile: "Azafatas de imagen", web: "Azafatas de imagen" },
      texts: {
        text_1: {
          mobile:
            "Son la imagen de la marca y de tu evento. Su presencia debe estar muy cuidada.",
          web:
            "Son la imagen de la marca y de tu evento. Su presencia debe estar muy cuidada.",
        },
        text_2: {
          mobile:
            "Las azafatas de imagen son la cara visible de una marca en el evento. Podríamos decir que el perfil de azafata de imagen destaca por unas características físicas concretas. Son las encargadas de promover la imagen de una marca, por eso es muy importante que su presencia e imagen estén muy cuidadas.<br/>Representar marcas en eventos y galas, llevar a cabo la promoción de productos, acompañar conferenciantes e incluso trabajar en ferias y congresos son algunas de las funciones que este tipo de perfil de azafatas lleva a cabo.",
          web:
            "Las azafatas de imagen son la cara visible de una marca en el evento. Podríamos decir que el perfil de azafata de imagen destaca por unas características físicas concretas. Son las encargadas de promover la imagen de una marca, por eso es muy importante que su presencia e imagen estén muy cuidadas.<br/>Representar marcas en eventos y galas, llevar a cabo la promoción de productos, acompañar conferenciantes e incluso trabajar en ferias y congresos son algunas de las funciones que este tipo de perfil de azafatas lleva a cabo.",
        },
      },
    },
    hire: {
      title: {
        mobile: "Contratar azafatas de imagen",
        web: "Contratar azafatas de imagen",
      },
      buttons: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    section: {
      text: {
        mobile:
          "En Eloquence queremos aportar algo más que una imagen. Creemos que más allá de unas características físicas concretas una buena azafata de imagen debe poseer otras cualidades tales como: una actitud positiva a la hora de trabajar, simpatía, naturalidad, una educación apropiada, un buen manejo del inglés y conocimientos de protocolo, entre otras cosas.<br/>Valoramos por encima de todo que nuestras azafatas comuniquen de una forma eficaz y elocuente.",
        web:
          "En Eloquence queremos aportar algo más que una imagen. Creemos que más allá de unas características físicas concretas una buena azafata de imagen debe poseer otras cualidades tales como: una actitud positiva a la hora de trabajar, simpatía, naturalidad, una educación apropiada, un buen manejo del inglés y conocimientos de protocolo, entre otras cosas.<br/>Valoramos por encima de todo que nuestras azafatas comuniquen de una forma eficaz y elocuente.",
      },
    },
    other_services: {
      title: { mobile: "Otros servicios", web: "Otros servicios" },
      subtitle: {
        mobile: "Según la necesidad de tu proyecto",
        web: "Según la necesidad de tu proyecto",
      },
      cards: {
        stand: {
          title: { mobile: "Ferias", web: "Ferias" },
        },
        promoters: { title: { mobile: "Promotoras", web: "Promotoras" } },
        brand: { title: { mobile: "Imagen", web: "Imagen" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contáctanos", web: "Contáctanos" } },
    },
    footer: {
      mobile:
        "Perfiles de azafatas de imagen verificados 100% por nuestro equipo",
      web: "Perfiles de azafatas de imagen verificados 100% por nuestro equipo",
    },
  },
  work_with_us: {
    skills: {
      buttons: {
        work: {
          mobile: "Disfruta mientras trabajas",
          web: "Disfruta mientras trabajas",
        },
      },
      title: {
        mobile: "¿Qué necesitas?",
        web: "¿Qué necesitas?",
      },
      cards: {
        presence: {
          text: { mobile: "Buena presencia", web: "Buena presencia" },
        },
        english: { text: { mobile: "Inglés B2", web: "Inglés B2" } },
        attitude: {
          text: { mobile: "Actitud proactiva", web: "Actidud proactiva" },
        },
      },
    },
    team: {
      title: {
        mobile: "Únete al equipo Eloquence",
        web: "Únete al equipo Eloquence",
      },
      subtitle: {
        mobile: "Rellena el formulario y fotos *",
        web: "Rellena el formulario.<br>¡No te olvides de adjuntar tus fotos!",
      },
      card: {
        form: {
          inputs: {
            name: { label: "Nombre completo", placeholder: "Nombre Apellidos" },
            email: { label: "Email", placeholder: "email@email.com" },
            phone: { label: "Teléfono", placeholder: "+34653335689" },
            city: {
              label: "Ciudad de trabajo",
              placeholder: "¿Dónde te gustaria trabajar?",
            },
            nacionality: {
              label: "Nacionalidad",
              placeholder: "Española, francesa, inglesa...",
            },
            date: {
              label: "Año de nacimiento",
              placeholder: "22/05/1996",
              ok_label: "Aceptar",
              cancel_label: "Cancelar",
              wrong_date: "Fecha incorrecta",
            },
            photos: { label: "Añade al menos 2 fotos", button: "Añadir fotos" },
          },
          texts: {
            contact_us: {
              mobile: null,
              web: "Si tienes cualquier duda, escríbenos a:",
            },
          },
          selects: {
            gender: {
              label: "Género",
              placeholder: "Elige",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
            dress_size: {
              label: "Talla vestido (mujer) o camisa (hombre)",
              placeholder: "Elige",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
            shoe_size: {
              label: "Talla zapato",
              placeholder: "Elige",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
            english_level: {
              label: "Nivel inglés",
              placeholder: "Elige",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
            extra_languages: {
              label: "Extra idiomas",
              placeholder: "Escribe",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
          },
          textareas: {
            extra_languages: "¿Hablas otros idiomas?",
          },
          checkboxs: {
            privacy: {
              label: "Acepto ",
              link: "políticas de privacidad",
            },
          },
          buttons: {
            send: "Enviar",
          },
        },
      },
    },
    our_philosophy: {
      title: {
        mobile: "Nuestra filosofía",
        web: "Nuestra filosofía",
      },
      text: {
        mobile:
          "En Eloquence cuidamos de nuestro personal, valoramos su trabajo y hacemos que disfruten de la experiencia única de cada evento.<br/><br/>Comprueba la opinión de las personas que han trabajado con nosotros. <b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>Ver opiniones</a></b><br/><br/>Si tienes cualquier duda, escríbenos a: work@eloquence.es",
        web:
          "En Eloquence cuidamos de nuestro personal, valoramos su trabajo y hacemos que disfruten de la experiencia única de cada evento.<br/><br/>Comprueba la opinión de las personas que han trabajado con nosotros. <b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>Ver opiniones</a></b><br/><br/>Si tienes cualquier duda, escríbenos a: work@eloquence.es",
      },
    },
  },
  hostesses_madrid: {
    header: {
      title: {
        mobile: "Agencia de azafatas en Madrid",
        web: "Agencia de azafatas en Madrid",
      },
      texts: {
        text_1: {
          mobile:
            "Una agencia de azafatas en Madrid es aquella agencia que basa su oficina principal en Madrid. Es importante tener muy en cuenta el punto de ubicación de las oficinas de la agencia de azafatas, ya que el soporte que puedan dar a sus clientes variará mucho en función de su domicilio principal.",
          web:
            "Una agencia de azafatas en Madrid es aquella agencia que basa su oficina principal en Madrid. Es importante tener muy en cuenta el punto de ubicación de las oficinas de la agencia de azafatas, ya que el soporte que puedan dar a sus clientes variará mucho en función de su domicilio principal.",
        },
        text_2: {
          mobile:
            "<p>La competencia y el número de agencias de azafatas en Madrid es bastante alta. Agencias de azafatas en Madrid hay muchas, por esto es importante elegir la correcta, teniendo en cuenta varias variables que desde Eloquence consideramos importantes:</p><ul><li>Su web: Es importante que tenga una web cuidada y renovada, esto te dirá si es una empresa que se esfuerza por renovarse o quedaron anclados en el pasar de los años.</li><li>Sus reseñas en Google: La valoración de los clientes y de las azafatas a las agencias dice mucho de la manera de trabajar de cada agencia. Puedes revisar las nuestras en este link.</li><li>El tipo de evento que realizan: No todas las agencias de azafatas de Madrid se dedican a organizar el mismo tipo de acciones o eventos. Es importante ver cuál es el punto fuerte de cada agencia.</li></ul>",
          web:
            "<p>La competencia y el número de agencias de azafatas en Madrid es bastante alta. Agencias de azafatas en Madrid hay muchas, por esto es importante elegir la correcta, teniendo en cuenta varias variables que desde Eloquence consideramos importantes:</p><ul><li>Su web: Es importante que tenga una web cuidada y renovada, esto te dirá si es una empresa que se esfuerza por renovarse o quedaron anclados en el pasar de los años.</li><li>Sus reseñas en Google: La valoración de los clientes y de las azafatas a las agencias dice mucho de la manera de trabajar de cada agencia. Puedes revisar las nuestras en este link.</li><li>El tipo de evento que realizan: No todas las agencias de azafatas de Madrid se dedican a organizar el mismo tipo de acciones o eventos. Es importante ver cuál es el punto fuerte de cada agencia.</li></ul>",
        },
      },
    },
    hire: {
      title: {
        mobile: "Si necesitas azafatas en Madrid",
        web: "Si necesitas azafatas en Madrid",
      },
      buttons: {
        request_budget: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    section: {
      text: {
        mobile:
          "Es super importante el soporte que la agencia de azafatas en Madrid pueda ofrecer a sus clientes. Estar presente en los eventos y conocer al equipo de azafatas ayudará a que el evento sea un éxito. En Eloquence, como agencia de azafatas en Madrid, estamos presentes en todos nuestros eventos.<br/><br/>Madrid es la séptima ciudad en el ranking de organización de eventos internacionales del mundo, lo que significa, que también será muy importante el carácter internacional que tenga la agencia de azafatas en Madrid. Que las mismas personas que integran la agencia y su equipo de azafatas hablen inglés de manera fluida, ayudará a la hora de comunicar y establecer un buen desarrollo dentro del evento.<br/><br/>En definitiva, si tienes un evento y buscas una agencia de azafatas en Madrid asegúrate que la agencia que escoges es la idónea para el tipo de evento o acción que tengas.",
        web:
          "Es super importante el soporte que la agencia de azafatas en Madrid pueda ofrecer a sus clientes. Estar presente en los eventos y conocer al equipo de azafatas ayudará a que el evento sea un éxito. En Eloquence, como agencia de azafatas en Madrid, estamos presentes en todos nuestros eventos.<br/><br/>Madrid es la séptima ciudad en el ranking de organización de eventos internacionales del mundo, lo que significa, que también será muy importante el carácter internacional que tenga la agencia de azafatas en Madrid. Que las mismas personas que integran la agencia y su equipo de azafatas hablen inglés de manera fluida, ayudará a la hora de comunicar y establecer un buen desarrollo dentro del evento.<br/><br/>En definitiva, si tienes un evento y buscas una agencia de azafatas en Madrid asegúrate que la agencia que escoges es la idónea para el tipo de evento o acción que tengas.",
      },
    },
    other_services: {
      title: { mobile: "Otros servicios", web: "Otros servicios" },
      subtitle: {
        mobile: "Especializados según la necesidad de tu proyecto",
        web: "Especializados según la necesidad de tu proyecto",
      },
      cards: {
        hostesses: {
          title: { mobile: "Contrata azafatas", web: "Contrata azafatas" },
        },
        barcelona: { title: { mobile: "Barcelona", web: "Barcelona" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contáctanos", web: "Contáctanos" } },
    },
    footer: {
      mobile:
        "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
      web:
        "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
    },
  },
  hostesses_barcelona: {
    header: {
      title: {
        mobile: "Agencia de azafatas en Barcelona",
        web: "Agencia de azafatas en Barcelona",
      },
      texts: {
        text_1: {
          mobile:
            "Una agencia de azafatas en Barcelona es aquella agencia que basa su oficina principal en Barcelona. Es importante tener muy en cuenta el punto de ubicación de las oficinas de la agencia de azafatas, ya que el soporte que puedan dar a sus clientes variará mucho en función de su domicilio principal.",
          web:
            "Una agencia de azafatas en Barcelona es aquella agencia que basa su oficina principal en Barcelona. Es importante tener muy en cuenta el punto de ubicación de las oficinas de la agencia de azafatas, ya que el soporte que puedan dar a sus clientes variará mucho en función de su domicilio principal.",
        },
        text_2: {
          mobile:
            "<p>La competencia y el número de agencias de azafatas en Barcelona es alta, al igual que en Madrid. Agencias de azafatas en Barcelona hay muchas, por esto es importante elegir la correcta, teniendo en cuenta varias variables que desde Eloquence consideramos importantes:</p><ul><li>Su web: Es importante que tenga una web cuidada y renovada, esto te dirá si es una empresa que se esfuerza por renovarse o quedaron anclados en el pasar de los años.</li><li>Sus reseñas en Google: La valoración de los clientes y de las azafatas a las agencias dice mucho de la manera de trabajar de cada agencia. Puedes revisar las nuestras en este link.</li><li>El tipo de evento que realizan: No todas las agencias de azafatas de Barcelona se dedican a organizar el mismo tipo de acciones o eventos. Es importante ver cuál es el punto fuerte de cada agencia.</li></ul>",
          web:
            "<p>La competencia y el número de agencias de azafatas en Barcelona es alta, al igual que en Madrid. Agencias de azafatas en Barcelona hay muchas, por esto es importante elegir la correcta, teniendo en cuenta varias variables que desde Eloquence consideramos importantes:</p><ul><li>Su web: Es importante que tenga una web cuidada y renovada, esto te dirá si es una empresa que se esfuerza por renovarse o quedaron anclados en el pasar de los años.</li><li>Sus reseñas en Google: La valoración de los clientes y de las azafatas a las agencias dice mucho de la manera de trabajar de cada agencia. Puedes revisar las nuestras en este link.</li><li>El tipo de evento que realizan: No todas las agencias de azafatas de Barcelona se dedican a organizar el mismo tipo de acciones o eventos. Es importante ver cuál es el punto fuerte de cada agencia.</li></ul>",
        },
      },
    },
    hire: {
      title: {
        mobile: "Si necesitas azafatas en Barcelona",
        web: "Si necesitas azafatas en Barcelona",
      },
      buttons: {
        request_budget: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    section: {
      text: {
        mobile:
          "Es super importante el soporte que la agencia de azafatas en Barcelona pueda ofrecer a sus clientes. Estar presente en los eventos y conocer al equipo de azafatas ayudará a que el evento sea un éxito. En Eloquence, como agencia de azafatas en Barcelona, estamos presentes en todos nuestros eventos.<br/><br/>Barcelona es la cuarta ciudad en el ranking de organización de eventos internacionales del mundo, lo que significa, que también será muy importante el carácter internacional que tenga la agencia de azafatas en Barcelona. Que las mismas personas que integran la agencia y su equipo de azafatas hablen inglés de manera fluida, ayudará a la hora de comunicar y establecer un buen desarrollo dentro del evento.<br/><br/>En definitiva, si tienes un evento y buscas una agencia de azafatas en Barcelona asegúrate que la agencia que escoges es la idónea para el tipo de evento o acción que tengas.",
        web:
          "Es super importante el soporte que la agencia de azafatas en Barcelona pueda ofrecer a sus clientes. Estar presente en los eventos y conocer al equipo de azafatas ayudará a que el evento sea un éxito. En Eloquence, como agencia de azafatas en Barcelona, estamos presentes en todos nuestros eventos.<br/><br/>Barcelona es la cuarta ciudad en el ranking de organización de eventos internacionales del mundo, lo que significa, que también será muy importante el carácter internacional que tenga la agencia de azafatas en Barcelona. Que las mismas personas que integran la agencia y su equipo de azafatas hablen inglés de manera fluida, ayudará a la hora de comunicar y establecer un buen desarrollo dentro del evento.<br/><br/>En definitiva, si tienes un evento y buscas una agencia de azafatas en Barcelona asegúrate que la agencia que escoges es la idónea para el tipo de evento o acción que tengas.",
      },
    },
    other_services: {
      title: { mobile: "Otros servicios", web: "Otros servicios" },
      subtitle: {
        mobile: "Especializados según la necesidad de tu proyecto",
        web: "Especializados según la necesidad de tu proyecto",
      },
      cards: {
        hostesses: {
          title: { mobile: "Contrata azafatas", web: "Contrata azafatas" },
        },
        madrid: { title: { mobile: "Madrid", web: "Madrid" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contáctanos", web: "Contáctanos" } },
    },
    footer: {
      mobile:
        "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
      web:
        "Trabajamos con nuestro cliente la acción de marketing y venta al completo",
    },
  },
  navbar: {
    items: {
      services: "Servicios",
      hostesses: "Azafatas",
      congress_hostesses: "Azafatas de congreso",
      stands_hostesses: "Azafatas de feria",
      promotion_hostesses: "Azafatas promotoras",
      brand_hostesses: "Azafatas de imagen",
      about_us: "Sobre Eloquence",
      work_with_us: "Trabaja con nosotros",
      request_budget: "Pedir presupuesto",
      contact_us: "Contáctanos",
      assemblers: "Montadores",
      auxiliars: "Auxiliares",
      waiters: "Camareros",
      portal_clients: "Acceso clientes",
      portal_workers: "Acceso empleados",
    },
  },
  footer: {
    items: {
      sales: "Eventos: ",
      hostess: "Trabaja con nosotros: ",
      phone: "",
      work_with_us: "Trabaja con nosotros",
      contact_us: "Contáctanos",
      services: "Servicios",
      about_us: "Sobre Eloquence",
    },
  },
};

export { translation };
