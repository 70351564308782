const ServicesPromoterHostessesTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.promoter_hostesses.header.title[typeFormat],
      texts: {
        text_1: translation.promoter_hostesses.header.texts.text_1[typeFormat],
        text_2: translation.promoter_hostesses.header.texts.text_2[typeFormat],
      },
    },
    hire: {
      title: translation.promoter_hostesses.hire.title[typeFormat],
      buttons: {
        request_budget:
          translation.promoter_hostesses.hire.buttons.request_budget[
            typeFormat
          ],
      },
    },
    section: {
      text: translation.promoter_hostesses.section.text[typeFormat],
    },
    other_services: {
      title: translation.promoter_hostesses.other_services.title[typeFormat],
      subtitle:
        translation.promoter_hostesses.other_services.subtitle[typeFormat],
      cards: {
        stand: {
          image:
            "services/promoter_hostesses/Tarjetas_Servicios_Azafatas_promotoras_galeria1.jpg",
          title:
            translation.promoter_hostesses.other_services.cards.stand.title[
              typeFormat
            ],
        },
        promoters: {
          image:
            "services/promoter_hostesses/Tarjetas_Servicios_Azafatas_promotoras_galeria2.jpg",
          title:
            translation.promoter_hostesses.other_services.cards.promoters.title[
              typeFormat
            ],
        },
        brand: {
          image:
            "services/promoter_hostesses/Tarjetas_Servicios_Azafatas_promotoras_galeria3.jpg",
          title:
            translation.promoter_hostesses.other_services.cards.brand.title[
              typeFormat
            ],
        },
        assemblers: {
          image:
            "services/promoter_hostesses/Tarjetas_Servicios_Azafatas_promotoras_galeria4.jpg",
          title:
            translation.promoter_hostesses.other_services.cards.assemblers
              .title[typeFormat],
        },
      },
    },
    contact_us: {
      button: {
        contact_us:
          translation.promoter_hostesses.contact_us.button.contact_us[
            typeFormat
          ],
      },
    },
    footer: translation.promoter_hostesses.footer[typeFormat],
  };
};

export { ServicesPromoterHostessesTemplate };
