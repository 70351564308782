import React from "react";
import { getLink } from "assets/languages/i18n/links";

const HomeTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";

  return {
    header: {
      title: translation.home.header.title[typeFormat],
      ad: translation.home.header.ad[typeFormat],
      slides: [
        {
          src: "home/header_landing_-1 copia.jpeg",
          position: { mobile: "center center", web: "center center" },
          backgroundSize: { mobile: "160%", web: "105%" },
        },
        // {
        //   src: "home/home_header_slide_1.jpeg",
        //   position: { mobile: "bottom center", web: "bottom center" },
        //   backgroundSize: { mobile: "130%", web: "120%" },
        // },
        {
          src: "home/home_header_slide_2.jpg",
          position: { mobile: "bottom center", web: "bottom center" },
          backgroundSize: { mobile: "110%", web: "125%" },
        },
        {
          src: "home/home_header_slide_3.jpeg",
          position: { mobile: "bottom center", web: "center center" },
          backgroundSize: { mobile: "130%", web: "105%" },
        },
        {
          src: "home/home_header_slide_4.jpeg",
          position: { mobile: "bottom center", web: "center center" },
          backgroundSize: { mobile: "110%", web: "105%" },
        },
        {
          src: "home/home_header_slide_5.jpeg",
          position: { mobile: "bottom center", web: "center center" },
          backgroundSize: { mobile: "120%", web: "100%" },
        },
        {
          src: "home/home_header_slide_6.jpeg",
          position: { mobile: "bottom center", web: "center center" },
          backgroundSize: { mobile: "120%", web: "105%" },
        },
        {
          src: "home/header_landing_7.jpeg",
          position: { mobile: "bottom center", web: "center center" },
          backgroundSize: { mobile: "120%", web: "105%" },
        },
      ],
      form: {
        mobile: null,
        web: {
          title: translation.home.header.form.web.title,
          subtitle: translation.home.header.form.web.subtitle,
          inputs: {
            email: {
              label: translation.home.header.form.web.inputs.email.label,
              placeholder:
                translation.home.header.form.web.inputs.email.placeholder,
            },
            phone: {
              label: translation.home.header.form.web.inputs.phone.label,
              placeholder:
                translation.home.header.form.web.inputs.phone.placeholder,
            },
            number_hostess: {
              label:
                translation.home.header.form.web.inputs.number_hostess.label,
              placeholder:
                translation.home.header.form.web.inputs.number_hostess
                  .placeholder,
            },
            details: {
              label: translation.home.header.form.web.inputs.details.label,
              placeholder:
                translation.home.header.form.web.inputs.details.placeholder,
            },
          },
          selects: {
            profile_type: {
              label:
                translation.home.header.form.web.selects.profile_type.label,
              width:
                translation.home.header.form.web.selects.profile_type.width,
              placeholder:
                translation.home.header.form.web.selects.profile_type
                  .placeholder,
              items:
                translation.home.header.form.web.selects.profile_type.items,
            },
          },
          checkboxs: {
            privacy: {
              label: translation.home.header.form.web.checkboxs.privacy.label,
              link: (
                <a
                  href="https://eloquence.es/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translation.home.header.form.web.checkboxs.privacy.link}
                </a>
              ),
            },
          },
          buttons: {
            send: translation.home.header.form.web.buttons.send,
          },
        },
      },
    },
    budget: {
      mobile: {
        title: translation.home.budget.mobile.title,
        subtitle: translation.home.budget.mobile.subtitle,
        budget: {
          buttons: {
            request_budget:
              translation.home.budget.mobile.budget.buttons.request_budget,
          },
        },
      },
      web: null,
    },
    type_profile: {
      title: translation.home.type_profile.title[typeFormat],
      subtitle: translation.home.type_profile.subtitle[typeFormat],
      cards: {
        hostesses: {
          to: getLink("hostesses"),
          title:
            translation.home.type_profile.cards.hostesses.title[typeFormat],
          image: "home/tarjetas_azafatas.jpeg",
        },
        assemblers: {
          to: getLink("assemblers"),
          title:
            translation.home.type_profile.cards.assemblers.title[typeFormat],
          image: "home/tarjetas_montadores.jpeg",
        },
        auxiliars: {
          to: getLink("auxiliars"),
          title:
            translation.home.type_profile.cards.auxiliars.title[typeFormat],
          image: "home/auxiliares_card.jpg",
        },
        waiters: {
          to: getLink("waiters"),
          title: translation.home.type_profile.cards.waiters.title[typeFormat],
          image: "home/tarjetas_home_camareros.jpg",
        },
      },
    },
    clients: [
      "home/clients_amazon.png",
      "home/clients_bpn.png",
      "home/clients_daimler.png",
      "home/clients_google.png",
      "home/clients_uefa.png",
      "home/clients_uk.png",
    ],
    guarantee: {
      title: translation.home.guarantee.title[typeFormat],
      cards: {
        quick: {
          icon: "home/icono_digital_home.png",
          title: translation.home.guarantee.cards.quick.title[typeFormat],
          text: translation.home.guarantee.cards.quick.text[typeFormat],
        },
        support: {
          icon: "home/icon_support.png",
          title: translation.home.guarantee.cards.support.title[typeFormat],
          text: translation.home.guarantee.cards.support.text[typeFormat],
        },
        trust: {
          icon: "home/icon_trust.png",
          title: translation.home.guarantee.cards.trust.title[typeFormat],
          text: translation.home.guarantee.cards.trust.text[typeFormat],
        },
      },
    },
    result: {
      title: translation.home.result.title[typeFormat],
      subtitle: translation.home.result.subtitle[typeFormat],
      cards: {
        philipp: {
          text: translation.home.result.cards.philipp.text[typeFormat],
          name: translation.home.result.cards.philipp.name[typeFormat],
          image: "home/avatar_philip.png",
        },
        sara: {
          text: translation.home.result.cards.sara.text[typeFormat],
          name: translation.home.result.cards.sara.name[typeFormat],
          image: "home/avatar_sara.png",
        },
        wendy: {
          text: translation.home.result.cards.wendy.text[typeFormat],
          name: translation.home.result.cards.wendy.name[typeFormat],
          image: "home/avatar_wendy.png",
        },
      },
      buttons: {
        reviews: translation.home.result.buttons.reviews[typeFormat],
      },
    },
    contact_us: {
      title: translation.home.contact_us.title[typeFormat],
      subtitle: translation.home.contact_us.subtitle[typeFormat],
      phone: translation.home.contact_us.phone[typeFormat],
      email: translation.home.contact_us.email[typeFormat],
      buttons: {
        request_budget:
          translation.home.contact_us.buttons.request_budget[typeFormat],
      },
    },
    work_with_us: {
      title: translation.home.work_with_us.title[typeFormat],
      subtitle: translation.home.work_with_us.subtitle[typeFormat],
      buttons: {
        join: translation.home.work_with_us.buttons.join[typeFormat],
      },
      text: translation.home.work_with_us.text[typeFormat],
    },
  };
};

export { HomeTemplate };
