import React from "react";
import { Text } from "components/Atoms/Text";
import { Image } from "components/Atoms/Image";
import PropTypes from "prop-types";
import "./style.css";
import { Link } from "react-router-dom";

const ImageCard = (props) => {
  // console.log(props);
  const content = (
    <div className="imageCard">
      <Image
        isBackground
        height={props.height}
        url={props.url}
        width="100%"
        borderRadius={props.title ? "10px 10px 0 0" : " 10px"}
        sizeImage={props.sizeImage}
        backgroundPosition={props.backgroundPosition}
      />
      {props.title ? (
        <Text
          weight={props.weightTitle}
          fontSize={props.sizeTitle}
          margin={props.marginTitle}
        >
          {props.title}
        </Text>
      ) : null}
      {props.textLabel ? (
        <Text
          fontSize={props.sizeSubtitle}
          margin="0"
          padding="0 16px 16px 16px"
          light={props.light}
        >
          {props.textLabel}
        </Text>
      ) : null}
    </div>
  );
  return props.link ? <Link to={props.link}>{content}</Link> : content;
};

ImageCard.propTypes = {
  url: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  sizeTitle: PropTypes.number,
  marginTitle: PropTypes.string,
  weightTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  sizeSubtitle: PropTypes.number,
  textLabel: PropTypes.string,
  sizeImage: PropTypes.string,
  link: PropTypes.string,
};

export { ImageCard };
