import { getLink } from "../../links";

const NavBarTemplate = (translation) => {
  return {
    items: {
      services: {
        label: translation.navbar.items.services,
        link: getLink("services"),
        id: "services",
      },
      hostesses: {
        label: translation.navbar.items.hostesses,
        link: getLink("hostesses"),
        id: "hostesses",
      },
      congress_hostesses: {
        label: translation.navbar.items.congress_hostesses,
        link: getLink("congress_hostesses"),
        id: "congress_hostesses",
      },
      stand_hostesses: {
        label: translation.navbar.items.stands_hostesses,
        link: getLink("stand_hostesses"),
        id: "stand_hostesses",
      },
      promotion_hostesses: {
        label: translation.navbar.items.promotion_hostesses,
        link: getLink("promoter_hostesses"),
        id: "promotion_hostesses",
      },
      brand_hostesses: {
        label: translation.navbar.items.brand_hostesses,
        link: getLink("brand_hostesses"),
        id: "brand_hostesses",
      },
      about_us: {
        label: translation.navbar.items.about_us,
        link: getLink("about_us"),
        id: "about_us",
      },
      // work_with_us: {
      //   label: translation.navbar.items.work_with_us,
      //   link: getLink("work_with_us"),
      //   id: "work_with_us",
      // },
      request_budget: {
        label: translation.navbar.items.request_budget,
        link: getLink("budget"),
        id: "request_budget",
      },
      // contact_us: {
      //   label: translation.navbar.items.contact_us,
      //   link: getLink("contact_us"),
      //   id: "contact_us",
      // },
      assemblers: {
        label: translation.navbar.items.assemblers,
        link: getLink("assemblers"),
        id: "assemblers",
      },
      auxiliars: {
        label: translation.navbar.items.auxiliars,
        link: getLink("auxiliars"),
        id: "auxiliars",
      },
      waiters: {
        label: translation.navbar.items.waiters,
        link: getLink("waiters"),
        id: "waiters",
      },
      portal_clients: {
        label: translation.navbar.items.portal_clients,
      },
      portal_workers: {
        label: translation.navbar.items.portal_workers,
      },
    },
  };
};

export { NavBarTemplate };
