import React, { Component } from "react";
import { UserContext } from "core/UserContext";
import { Grid } from "@material-ui/core";
import { windowMobileDetection } from "core/windowMobileDetection";
import { Text } from "components/Atoms/Text";
import { Button } from "components/Atoms/Button";
import { Navbar } from "components/Organisms/NavBar";
import { Footer } from "components/Organisms/Footer";
import { getLink } from "assets/languages/i18n/links";

class Thanks extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  static contextType = UserContext;
  render() {
    return (
      <Grid container>
        {/* NavBar */}
        <Navbar
          translation={this.context.translation.templates.navbar}
          link="hostesses"
        />
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            style={{
              minHeight: windowMobileDetection()
                ? "calc(100vh - 480px)"
                : "calc(100vh - 216px)",
            }}
          >
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign="center"
              padding={
                windowMobileDetection() ? "120px 32px 0 32px" : "120px 0 0 0"
              }
            >
              {this.context.translation.templates.thanks.texts.title}
            </Text>
            <div
              style={{
                width: windowMobileDetection() ? "80%" : "40%",
                margin: "0 auto",
              }}
            >
              <Button
                weight
                variant="b3"
                label={
                  this.context.translation.templates.thanks.buttons.back_home
                }
                fontSize={windowMobileDetection() ? "16px" : "20px"}
                margin={windowMobileDetection() ? "4px 0" : "8px 0"}
                link={getLink("home")}
              />
            </div>
          </Grid>
          <Grid item xs={2} md={5} />
          <Grid item xs={10} md={2} style={{ paddingBottom: "40px" }}></Grid>
        </Grid>
        <Footer translation={this.context.translation.templates.footer} />
      </Grid>
    );
  }
}

export { Thanks };
