import React from "react";
import { Text } from "components/Atoms/Text";
import { Image } from "components/Atoms/Image";
import PropTypes from "prop-types";
import "./style.css";

const ImageCardBottom = (props) => {
  return (
    <div className="imageCardBottom">
      {props.title ? (
        <Text
          weight={props.weightTitle}
          fontSize={props.sizeTitle}
          margin={props.marginTitle}
        >
          {props.title}
        </Text>
      ) : null}
      {props.textLabel ? (
        <Text
          fontSize={props.sizeSubtitle}
          margin="0"
          padding="0 16px 16px 16px"
          light={props.light}
        >
          {props.textLabel}
        </Text>
      ) : null}
      <Image
        isBackground
        height={props.height}
        url={props.url}
        width="100%"
        borderRadius={props.title ? "0 0 10px 10px" : " 10px"}
        sizeImage={props.sizeImage}
      />
    </div>
  );
};

ImageCardBottom.propTypes = {
  url: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  sizeTitle: PropTypes.number,
  marginTitle: PropTypes.string,
  weightTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  sizeSubtitle: PropTypes.number,
  textLabel: PropTypes.string,
  sizeImage: PropTypes.string,
};

export { ImageCardBottom };
