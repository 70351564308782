import React from "react";
import parse from "html-react-parser";

const Text = (props) => {
  let content;

  // function breakLine(text) {
  //   var br = React.createElement("br");
  //   var regex = /(<br\/>)/g;
  //   return text.split(regex).map(function(line, index) {
  //     return line.match(regex) ? <br key={"key_" + index} /> : line;
  //   });
  // }

  // // TODO
  // function checkBold(text) {
  //   var b = React.createElement("b");
  //   var regex = /[<b>][\w\W]*[<\/b>]/g;
  //   return text.split(regex).map(function(line, index) {
  //     return line.match(regex) ? <b key={"key_" + index}>{line}</b> : line;
  //   });
  // }

  let style = {
    fontWeight: props.weight ? "900" : props.light ? "200" : "400",
    fontSize: props.fontSize,
    letterSpacing: props.letterSpacing,
    fontFamily: "Lato",
    ...props.style,
  };

  if (props.padding) style.padding = props.padding;
  if (props.margin) style.margin = props.margin;
  if (props.lineHeight) style.lineHeight = props.lineHeight;
  if (props.textAlign) style.textAlign = props.textAlign;
  switch (props.variant) {
    case "h1":
      style.fontWeight = "700";
      style.letterSpacing = "0";
      style.fontSize = "56px";
      style.color = props.color || "#212121";
      content = (
        <h1 style={style}>
          {props.children ? parse(props.children) : props.children}
        </h1>
      );
      break;
    case "h2":
      style.fontWeight = "700";
      style.letterSpacing = "0";
      style.fontSize = "50px";
      style.color = props.color || "#212121";
      content = (
        <h2 style={style}>
          {props.children ? parse(props.children) : props.children}
        </h2>
      );
      break;
    case "p1":
      style.fontWeight = "700";
      style.letterSpacing = "4px";
      style.fontSize = "22px";
      style.color = props.color || "#000000";
      content = (
        <p style={style}>
          {props.children ? parse(props.children) : props.children}
        </p>
      );
      break;
    case "p2":
      style.fontWeight = "400";
      style.letterSpacing = "0";
      style.fontSize = "22px";
      style.color = props.color || "#000000";
      content = (
        <p style={style}>
          {props.children ? parse(props.children) : props.children}
        </p>
      );
      break;
    case "p3":
      style.fontWeight = "400";
      style.letterSpacing = "0";
      style.fontSize = "16px";
      style.color = props.color || "#000000";
      content = (
        <p style={style}>
          {props.children ? parse(props.children) : props.children}
        </p>
      );
      break;
    case "customize":
      style.color = props.color;
      content = (
        <p style={style}>
          {props.children ? parse(props.children) : props.children}
        </p>
      );
      break;
    case "customize-with-link":
      style.color = props.color;
      content = <p style={style}>{props.children}</p>;
      break;
    default:
      content = (
        <p style={style}>
          {props.children ? parse(props.children) : props.children}
        </p>
      );
      break;
  }
  return content;
};

export { Text };
