import React, { useContext } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuHover from "material-ui-popup-state/HoverMenu";
import { Image } from "components/Atoms/Image";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Grid, MenuList } from "@material-ui/core";
import { Text } from "components/Atoms/Text";
import { Button } from "components/Atoms/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { MobileNavBar } from "./Mobile";
import { Hidden } from "@material-ui/core";
import { windowMobileDetection } from "core/windowMobileDetection";
import NestedMenuItem from "material-ui-nested-menu-item";

import Spanish from "assets/img/languages/spanish.png";
import English from "assets/img/languages/english.png";

import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { UserContext } from "core/UserContext";
import { getLink } from "assets/languages/i18n/links";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
    },
  },
  sectionMobile: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  activeLink: {
    textDecoration: "none",
    color: "#B1A079 !important",
  },
  link: {
    textDecoration: "none",
    color: "#000",
    "&:hover, &:focus": {
      transition: ".2s all ease-in-out",
      color: "#B1A079", // textShadow: "0px 0px 20px rgba(255,200,10,0.74)",
    },
  },
  buttonLink: {
    textDecoration: "none",
    color: "#000",
    border: "2px solid black",
    // padding: "0 6px",
    borderRadius: "3px",
    "&:hover, &:focus": {
      transition: ".2s all ease-in-out",
      color: "#B1A079",
      border: "2px solid #B1A079",
    },
  },

  containerPortalButtons: { display: "flex" },
  buttonLinkPortalClients: {
    textDecoration: "none",
    color: "#0A28A4",
    border: "2px solid #0A28A4",
  },
  buttonLinkPortalWorkers: {
    textDecoration: "none",
    color: "#0A28A4",
    border: "2px solid #0A28A4",
    "&:hover, &:focus": {
      transition: ".2s all ease-in-out",
      color: "#fff",
      border: "2px solid #0A28A4",
      background: "#0A28A4",
    },
  },
  activeButtonLink: {
    textDecoration: "none",
    color: "#B1A079 !important",
    border: "2px solid #B1A079",
  },
}));

const Navbar = (props) => {
  const context = useContext(UserContext);
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState({
    services: false,
    hostesses: false,
  });
  const history = useHistory();

  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCollapseOpen = (type) => {
    // console.log("handled", type);
    const newCollapseOpen = collapseOpen;
    newCollapseOpen[type] = !newCollapseOpen[type];
    setCollapseOpen(newCollapseOpen);
    // console.log(collapseOpen);
  };

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{
          background: "#fff",
          boxShadow: "0 2px 8px 0 rgba(0,0,0,0.15)",
          height: "80px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={false} md={1} />
                <Grid
                  item
                  xs={4}
                  md={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Image
                      url="logo-black.png"
                      width={windowMobileDetection() ? "100%" : null}
                      height={windowMobileDetection() ? null : "60px"}
                    />
                  </Link>
                </Grid>
                <Hidden smDown>
                  <Grid
                    item
                    xs={8}
                    md={7}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {/* <NavLink
                      className={classes.link}
                      onClick={handleProfileMenuOpen}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text variant="customize" fontSize="16px">
                        {props.translation.items.services.label}
                      </Text>
                      {!isMenuOpen && <KeyboardArrowDownIcon />}
                    </NavLink> */}
                    <div {...bindHover(popupState)}>
                      <NavLink
                        exact
                        activeClassName={classes.activeLink}
                        to={props.translation.items.services.link}
                        className={classes.link}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <KeyboardArrowDownIcon
                            style={{ paddingRight: "4px" }}
                          />
                          <Text variant="customize" fontSize="16px">
                            {props.translation.items.services.label}
                          </Text>
                        </div>
                      </NavLink>

                      <MenuHover
                        {...bindMenu(popupState)}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        popupId="demoMenu"
                      >
                        <NestedMenuItem
                          component={NavLink}
                          label={props.translation.items.hostesses.label}
                          to={props.translation.items.hostesses.link}
                          activeClassName={classes.activeLink}
                          onClick={() =>
                            history.push(props.translation.items.hostesses.link)
                          }
                          parentMenuOpen={true}
                          exact
                        >
                          <MenuItem
                            component={NavLink}
                            activeClassName={classes.activeLink}
                            exact
                            to={props.translation.items.congress_hostesses.link}
                          >
                            {props.translation.items.congress_hostesses.label}
                          </MenuItem>
                          <MenuItem
                            component={NavLink}
                            activeClassName={classes.activeLink}
                            exact
                            to={props.translation.items.brand_hostesses.link}
                          >
                            {props.translation.items.brand_hostesses.label}
                          </MenuItem>
                          <MenuItem
                            component={NavLink}
                            activeClassName={classes.activeLink}
                            exact
                            to={props.translation.items.stand_hostesses.link}
                          >
                            {props.translation.items.stand_hostesses.label}
                          </MenuItem>
                          <MenuItem
                            component={NavLink}
                            activeClassName={classes.activeLink}
                            exact
                            to={
                              props.translation.items.promotion_hostesses.link
                            }
                          >
                            {props.translation.items.promotion_hostesses.label}
                          </MenuItem>
                        </NestedMenuItem>

                        <MenuItem
                          component={NavLink}
                          activeClassName={classes.activeLink}
                          exact
                          to={props.translation.items.assemblers.link}
                        >
                          {props.translation.items.assemblers.label}
                        </MenuItem>
                        <MenuItem
                          component={NavLink}
                          activeClassName={classes.activeLink}
                          exact
                          to={props.translation.items.auxiliars.link}
                        >
                          {props.translation.items.auxiliars.label}
                        </MenuItem>

                        <MenuItem
                          component={NavLink}
                          activeClassName={classes.activeLink}
                          exact
                          to={props.translation.items.waiters.link}
                        >
                          {props.translation.items.waiters.label}
                        </MenuItem>
                        {/*
                        <MenuItem
                          component={NavLink}
                          activeClassName={classes.activeLink}
                          exact
                          to={props.translation.items.brand_hostesses.link}
                        >
                          {props.translation.items.brand_hostesses.label}
                        </MenuItem> */}
                      </MenuHover>
                    </div>
                    <NavLink
                      activeClassName={classes.activeLink}
                      to={props.translation.items.about_us.link}
                      className={classes.link}
                      exact
                    >
                      <Text variant="customize" fontSize="16px">
                        {props.translation.items.about_us.label}
                      </Text>
                    </NavLink>
                    {/* <NavLink
                      activeClassName={classes.activeLink}
                      to={props.translation.items.work_with_us.link}
                      className={classes.link}
                      exact
                    >
                      <Text variant="customize" fontSize="16px">
                        {props.translation.items.work_with_us.label}
                      </Text>
                    </NavLink> */}
                    <NavLink
                      activeClassName={classes.activeButtonLink}
                      to={props.translation.items.request_budget.link}
                      className={classes.buttonLink}
                      exact
                    >
                      <Text
                        variant="customize"
                        fontSize="16px"
                        margin="12px 4px"
                        weight
                      >
                        {props.translation.items.request_budget.label}
                      </Text>
                    </NavLink>
                    {/* <NavLink
                      activeClassName={classes.activeButtonLink}
                      to={props.translation.items.contact_us.link}
                      className={classes.buttonLink}
                      exact
                    >
                      <Text
                        variant="customize"
                        fontSize="16px"
                        margin="12px 4px"
                        weight
                      >
                        {props.translation.items.contact_us.label}
                      </Text>
                    </NavLink> */}
                    <a
                      href="https://eloquence.onsinch.com/users/login"
                      className={classes.link}
                      style={{ color: "#0A28A4" }}
                    >
                      <Text
                        variant="customize"
                        // fontSize="12px"
                        margin="12px 4px"
                        // weight
                      >
                        {props.translation.items.portal_clients.label}
                      </Text>
                    </a>
                    {/* <a
                      href="https://eloquence.onsinch.com/users/login"
                      className={classes.buttonLinkPortalWorkers}
                      // style={{ marginLeft: "8px" }}
                    >
                      <Text
                        variant="customize"
                        // fontSize="12px"
                        margin="12px 4px"
                        // weight
                      >
                        {props.translation.items.portal_workers.label}
                      </Text>
                    </a> */}
                    <img
                      onClick={() => {
                        context.language === "es"
                          ? context.handleLanguageChange("en")
                          : context.handleLanguageChange("es");
                        history.push(getLink(props.link));
                      }}
                      src={context.language === "es" ? Spanish : English}
                      style={{ width: "30px" }}
                      alt="language-flag"
                    />
                  </Grid>
                </Hidden>
                <Hidden smUp>
                  <Grid item xs={8}>
                    <MobileNavBar
                      translation={props.translation}
                      handleDrawerOpen={handleDrawerOpen}
                      drawerOpen={drawerOpen}
                      handleCollapseOpen={handleCollapseOpen}
                      collapseOpen={collapseOpen}
                      link={props.link}
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export { Navbar };
