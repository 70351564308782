import Axios from "core/axios";

class FormServices {
  constructor() {
    this.service = new Axios();
  }

  contactUs = data => this.service.post("/contact_us.php", data);

  workWithUs = data => this.service.post("/work_with_us.php", data);
}

export default FormServices;
