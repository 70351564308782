const WaitersTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.waiters.header.title[typeFormat],
      subtitle: translation.waiters.header.subtitle[typeFormat],
    },
    hire: {
      title: translation.waiters.hire.title[typeFormat],
      buttons: {
        request_budget:
          translation.waiters.hire.buttons.request_budget[typeFormat],
      },
    },
    waiters: {
      cards: {
        waiters: {
          image: "services/waiters/tarjetas_servicios_camareros_camareros.jpg",
          title: translation.waiters.waiters.cards.waiters.title[typeFormat],
          text: translation.waiters.waiters.cards.waiters.text[typeFormat],
        },
        cocktail_shakers: {
          image: "services/waiters/tarjetas_servicios_camareros_coctail.jpg",
          title:
            translation.waiters.waiters.cards.cocktail_shakers.title[
              typeFormat
            ],
          text: translation.waiters.waiters.cards.cocktail_shakers.text[
            typeFormat
          ],
        },
      },
    },
    contract: {
      title: translation.waiters.contract.title[typeFormat],
      buttons: {
        contact_us: translation.waiters.contract.buttons.contact_us[typeFormat],
      },
    },
    keys: {
      title: translation.waiters.keys.title[typeFormat],
      cards: {
        experience: {
          title: translation.waiters.keys.cards.experience.title[typeFormat],
          text: translation.waiters.keys.cards.experience.text[typeFormat],
        },
        uniformity: {
          title: translation.waiters.keys.cards.uniformity.title[typeFormat],
          text: translation.waiters.keys.cards.uniformity.text[typeFormat],
        },
      },
      footer: translation.waiters.keys.footer[typeFormat],
    },
    other_services: {
      cards: {
        stand: {
          image: "services/waiters/tarjetas_servicios_camareros_galeria_1.jpg",
        },
        promoters: {
          image: "services/waiters/tarjetas_servicios_camareros_galeria_2.jpg",
        },
        brand: {
          image: "services/waiters/tarjetas_servicios_camareros_galeria_3.jpg",
        },
        assemblers: {
          image: "services/waiters/tarjetas_servicios_camareros_galeria_4.jpg",
        },
      },
    },
    contact_us: {
      title: translation.waiters.contact_us.title[typeFormat],
      buttons: {
        contact_us:
          translation.waiters.contact_us.buttons.contact_us[typeFormat],
      },
    },
  };
};

export { WaitersTemplate };
