import React, { Component } from "react";
import { UserContext } from "core/UserContext";
import { Grid, Checkbox, Hidden } from "@material-ui/core";
import { windowMobileDetection } from "core/windowMobileDetection";
import { Header } from "components/Organisms/Header";
import { Text } from "components/Atoms/Text";
import { Card } from "components/Organisms/Card";
import Input from "components/Atoms/Input";
import { Button } from "components/Atoms/Button";
import { Navbar } from "components/Organisms/NavBar";
import { Footer } from "components/Organisms/Footer";
import { Image } from "components/Atoms/Image";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: {
        company: "",
        number_invoice: "",
        cuantity: "",
        policies: false,
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChangeInput = (event) => {
    const invoice = { ...this.state.invoice };
    invoice[event.target.name] = event.target.value;
    this.setState({ invoice });
  };

  handleChangeCheckbox = (name) => (event) => {
    const invoice = { ...this.state.invoice };
    invoice[name] = event.target.checked;
    this.setState({ invoice });
  };

  checkObject = () => {
    if (this.state.invoice.company === "") {
      return false;
    }
    if (this.state.invoice.number_invoice === "") {
      return false;
    }
    if (this.state.invoice.cuantity === "") {
      return false;
    }
    if (!this.state.invoice.policies) {
      return false;
    }
    return true;
  };

  static contextType = UserContext;
  render() {
    return (
      <Grid container>
        {/* NavBar */}
        <Navbar translation={this.context.translation.templates.navbar} />

        {/* Header */}
        <Header
          image="payment/header.jpg"
          height={windowMobileDetection() ? "40vh" : "40vh"}
          backgroundPosition={
            windowMobileDetection() ? "50% center" : "center center"
          }
          sizeImage={windowMobileDetection() ? "150%" : "100%"}
        />
        {/* Section Payment Gateway */}
        <Grid container style={{ background: "#F6F8F9", padding: "16px" }}>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              fontSize="22px"
              textAlign="center"
              margin="31px 0 43px 0"
            >
              {this.context.translation.templates.payment.payment_gateway.title}
            </Text>
          </Grid>
          <Grid item xs={1} md={4} />
          <Grid item xs={10} md={4}>
            <Card>
              <Grid
                container
                spacing={2}
                style={{ padding: windowMobileDetection() ? "8px" : "32px" }}
              >
                <Grid item xs={12}>
                  <Text
                    variant="customize"
                    fontSize="28px"
                    textAlign="left"
                    weight
                    margin="8px 0 8px 0"
                  >
                    {
                      this.context.translation.templates.payment.payment_gateway
                        .card.title
                    }
                  </Text>
                  <Text
                    variant="customize"
                    fontSize="20px"
                    textAlign="left"
                    margin="8px 0 8px 0"
                  >
                    {
                      this.context.translation.templates.payment.payment_gateway
                        .card.subtitle
                    }
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    fullWidth
                    name="company"
                    id="company"
                    label={
                      this.context.translation.templates.payment.payment_gateway
                        .card.form.inputs.company.label
                    }
                    placeholder={
                      this.context.translation.templates.payment.payment_gateway
                        .card.form.inputs.company.placeholder
                    }
                    variant="outlined"
                    type="text"
                    margin="dense"
                    required
                    onChange={this.handleChangeInput}
                    value={this.state.invoice.company}
                  />
                  <Input
                    fullWidth
                    name="number_invoice"
                    id="number_invoice"
                    label={
                      this.context.translation.templates.payment.payment_gateway
                        .card.form.inputs.number_invoice.label
                    }
                    placeholder={
                      this.context.translation.templates.payment.payment_gateway
                        .card.form.inputs.number_invoice.placeholder
                    }
                    variant="outlined"
                    type="text"
                    margin="dense"
                    required
                    onChange={this.handleChangeInput}
                    value={this.state.invoice.number_invoice}
                  />
                  <Input
                    fullWidth
                    name="cuantity"
                    id="cuantity"
                    label={
                      this.context.translation.templates.payment.payment_gateway
                        .card.form.inputs.cuantity.label
                    }
                    placeholder={
                      this.context.translation.templates.payment.payment_gateway
                        .card.form.inputs.cuantity.placeholder
                    }
                    variant="outlined"
                    type="text"
                    margin="dense"
                    required
                    onChange={this.handleChangeInput}
                    value={this.state.invoice.cuantity}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={this.state.invoice.policies}
                      onChange={this.handleChangeCheckbox("policies")}
                      color="default"
                      value="policies"
                    />
                    <Text
                      variant="customize"
                      margin="0 4px 0 0"
                      fontSize="12px"
                    >
                      {
                        this.context.translation.templates.payment
                          .payment_gateway.card.form.checkboxs.privacy.label
                      }
                    </Text>
                    <div
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {
                        this.context.translation.templates.payment
                          .payment_gateway.card.form.checkboxs.privacy.link
                      }
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant={this.checkObject() ? "b1" : "b1_disabled"}
                    label={
                      this.context.translation.templates.payment.payment_gateway
                        .card.form.buttons.send
                    }
                    color="primary"
                    disabled={this.checkObject() ? false : true}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {/* Section some dude */}
        <Grid container>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign="center"
              margin={windowMobileDetection() ? "16px 0" : "24px 0 32px 0"}
            >
              {this.context.translation.templates.payment.some_dude.title}
            </Text>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={12} md={5} style={{ paddingRight: "24px" }}>
                <Text variant="h2" weight fontSize="38px" margin="0 0 38px 0">
                  {
                    this.context.translation.templates.payment.some_dude
                      .subtitle
                  }
                </Text>
                <Text
                  variant="customize"
                  fontSize="22px"
                  textAlign="left"
                  margin="8px 0 8px 0"
                >
                  {this.context.translation.templates.payment.some_dude.email}
                </Text>
                <Text
                  variant="customize"
                  fontSize="22px"
                  textAlign="left"
                  margin="8px 0 8px 0"
                >
                  {this.context.translation.templates.payment.some_dude.phone}
                </Text>
              </Grid>
              <Grid item md={1} />
              <Grid item xs={12} md={6}>
                <Image
                  url="payment/some_dude.jpg"
                  width="100%"
                  borderRadius="10px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer translation={this.context.translation.templates.footer} />
      </Grid>
    );
  }
}

export { Payment };
