import React, { Component } from "react";
import { UserContext } from "core/UserContext";
import { Grid, Checkbox, Hidden } from "@material-ui/core";
import { Header } from "components/Organisms/Header";
import { windowMobileDetection } from "core/windowMobileDetection";
import { Text } from "components/Atoms/Text";
import { Card } from "components/Organisms/Card";
import Input from "components/Atoms/Input";
import { Button } from "components/Atoms/Button";
import InstaGrid from "components/Atoms/InstaGrid";
import { Navbar } from "components/Organisms/NavBar";
import { Footer } from "components/Organisms/Footer";
import FormServices from "core/services/index.services";
import { getLink } from "assets/languages/i18n/links";
import { Redirect } from "react-router";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business: {
        name: "",
        email: "",
        phone: "",
        city: "",
        number_hostess: "",
        message: "",
        policies: false,
      },
      redirect_to_thanks: false,
    };
    this.service = new FormServices();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChangeInput = (event) => {
    const business = { ...this.state.business };
    business[event.target.name] = event.target.value;
    this.setState({ business });
  };

  handleChangeCheckbox = (name) => (event) => {
    const business = { ...this.state.business };
    business[name] = event.target.checked;
    this.setState({ business });
  };

  callApi = () => {
    this.service
      .contactUs(this.state.business)
      .then((response) => {
        this.setState({ redirect_to_thanks: true });
        // this.setState({
        //   business: {
        //     name: "",
        //     email: "",
        //     phone: "",
        //     city: "",
        //     number_hostess: "",
        //     message: "",
        //     policies: false,
        //   },
        // });
      })
      .catch((err) => {
        alert("Error en la petición, intentelo más tarde");
        this.setState({
          business: {
            name: "",
            email: "",
            phone: "",
            city: "",
            number_hostess: "",
            message: "",
            policies: false,
          },
        });
      });
  };

  checkObject = () => {
    if (this.state.business.name === "") {
      return false;
    }
    if (this.state.business.email === "") {
      return false;
    }
    if (this.state.business.phone === "") {
      return false;
    }
    if (this.state.business.message === "") {
      return false;
    }
    if (!this.state.business.policies) {
      return false;
    }
    return true;
  };

  static contextType = UserContext;
  render() {
    return this.state.redirect_to_thanks ? (
      <Redirect to={getLink("thanks")} />
    ) : (
      <Grid container>
        {/* NavBar */}
        <Navbar
          translation={this.context.translation.templates.navbar}
          link="contact_us"
        />
        <Header
          image="contactUs/header.jpg"
          height={windowMobileDetection() ? "30vh" : "30vh"}
          backgroundPosition={
            windowMobileDetection() ? "bottom center" : "center 70%"
          }
          sizeImage={windowMobileDetection() ? "150%" : "100%"}
        >
          <Grid
            container
            style={{
              height: windowMobileDetection() ? "100%" : "100%",
              paddingTop: windowMobileDetection() ? "14%" : null,
              paddingLeft: windowMobileDetection() ? "5%" : "5%",
            }}
            alignItems={windowMobileDetection() ? "center" : null}
          >
            <Grid item xs={4}>
              <Text
                variant={windowMobileDetection() ? "customize" : "h1"}
                fontSize="28px"
                padding="0 0 0 8px"
                weight
              >
                {this.context.translation.templates.contact_us.header.title}
              </Text>
            </Grid>
          </Grid>
        </Header>
        <Grid
          container
          style={{ background: "#F6F8F9", paddingBottom: "48px" }}
        >
          <Grid item xs={12} md={false}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign="center"
              margin="24px 0 0 0"
            >
              {this.context.translation.templates.contact_us.form.title}
            </Text>
          </Grid>
          <Grid item xs={12} md={false}>
            <Text variant="p3" textAlign="center" margin="2px 0 16px 0">
              {this.context.translation.templates.contact_us.form.subtitle}
            </Text>
          </Grid>
          <Grid item xs={1} md={1} />
          <Grid item xs={10} md={6}>
            <Card
              styles={{
                padding: windowMobileDetection() ? "8px" : "8px 32px 32px 32px",
              }}
            >
              <Grid container spacing="2" style={{ padding: "8px" }}>
                {!windowMobileDetection() && (
                  <Grid item xs={12}>
                    <Text
                      variant="customize"
                      weight
                      fontSize="28px"
                      margin="8px 0 0 0"
                    >
                      {
                        this.context.translation.templates.contact_us.form.card
                          .title
                      }
                    </Text>
                    <Text
                      variant="customize"
                      fontSize="24px"
                      margin="0 0 8px 0"
                    >
                      {
                        this.context.translation.templates.contact_us.form.card
                          .subtitle
                      }
                    </Text>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Input
                    fullWidth
                    name="name"
                    id="name"
                    label={
                      this.context.translation.templates.contact_us.form.card
                        .inputs.name.label
                    }
                    placeholder={
                      this.context.translation.templates.contact_us.form.card
                        .inputs.name.placeholder
                    }
                    variant="outlined"
                    type="text"
                    margin="dense"
                    required
                    onChange={this.handleChangeInput}
                    value={this.state.business.name}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Input
                    fullWidth
                    name="email"
                    id="email"
                    label={
                      this.context.translation.templates.contact_us.form.card
                        .inputs.email.label
                    }
                    placeholder={
                      this.context.translation.templates.contact_us.form.card
                        .inputs.email.placeholder
                    }
                    variant="outlined"
                    type="email"
                    margin="dense"
                    required
                    onChange={this.handleChangeInput}
                    value={this.state.business.email}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    fullWidth
                    name="phone"
                    id="phone"
                    label={
                      this.context.translation.templates.contact_us.form.card
                        .inputs.phone.label
                    }
                    placeholder={
                      this.context.translation.templates.contact_us.form.card
                        .inputs.phone.placeholder
                    }
                    variant="outlined"
                    type="telephone"
                    margin="dense"
                    required
                    onChange={this.handleChangeInput}
                    value={this.state.business.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    fullWidth
                    name="message"
                    id="message"
                    label={
                      this.context.translation.templates.contact_us.form.card
                        .inputs.message.label
                    }
                    placeholder={
                      this.context.translation.templates.contact_us.form.card
                        .inputs.message.placeholder
                    }
                    variant="outlined"
                    type="number"
                    margin="dense"
                    required
                    onChange={this.handleChangeInput}
                    value={this.state.business.message}
                    multiline
                    rows={4}
                  />
                </Grid>
                <Hidden smDown>
                  <Grid item xs={2}>
                    <Button
                      variant={this.checkObject() ? "b1" : "b1_disabled"}
                      fontSize="16px"
                      margin="0"
                      label={
                        this.context.translation.templates.contact_us.form.card
                          .buttons.send
                      }
                      color="primary"
                      disabled={this.checkObject() ? false : true}
                      onClick={this.callApi}
                    />
                  </Grid>
                </Hidden>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={this.state.business.policies}
                    onChange={this.handleChangeCheckbox("policies")}
                    color="default"
                    value="policies"
                  />
                  <Text variant="customize" margin="0 4px 0 0">
                    {
                      this.context.translation.templates.contact_us.form.card
                        .checkboxs.privacy.label
                    }
                  </Text>
                  {
                    this.context.translation.templates.contact_us.form.card
                      .checkboxs.privacy.link
                  }
                </div>
                <Hidden smUp>
                  <Button
                    variant={this.checkObject() ? "b1" : "b1_disabled"}
                    label={
                      this.context.translation.templates.contact_us.form.card
                        .buttons.send
                    }
                    color="primary"
                    disabled={this.checkObject() ? false : true}
                    onClick={this.callApi}
                  />
                </Hidden>
              </Grid>
            </Card>
          </Grid>
          <Hidden smUp>
            <Grid item xs={12} md={false} />
            <Grid item xs={2} md={false} />
          </Hidden>
          <Hidden mdDown>
            <Grid item xs={false} md={1} />
          </Hidden>
          <Grid item xs={8} md={3}>
            <Card
              variant="ContactCard"
              sizeTitle={windowMobileDetection() ? "24px" : "28px"}
              sizeSubtitle={windowMobileDetection() ? "14px" : "18px"}
              callUsTitle={
                this.context.translation.templates.contact_us.contact_us.card
                  .call_us.title
              }
              callUsSubtitle={
                this.context.translation.templates.contact_us.contact_us.card
                  .call_us.text
              }
              writeUsTitle={
                this.context.translation.templates.contact_us.contact_us.card
                  .write_us.title
              }
              writeUsSubtitle={
                this.context.translation.templates.contact_us.contact_us.card
                  .write_us.text
              }
              ourOfficeTitle={
                this.context.translation.templates.contact_us.contact_us.card
                  .our_offices.title
              }
              ourOfficeSubtitle1={
                this.context.translation.templates.contact_us.contact_us.card
                  .our_offices.madrid
              }
              ourOfficeSubtitle2={
                this.context.translation.templates.contact_us.contact_us.card
                  .our_offices.barcelona
              }
              link={getLink("work_with_us")}
              btnText={
                this.context.translation.templates.contact_us.contact_us.card
                  .button.work_with_us
              }
            />
          </Grid>
        </Grid>
        {/* SECTION WHERE WE ARE*/}
        <Grid container>
          <Grid item xs={1} md={1} />
          <Grid item xs={10} md={6}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign={windowMobileDetection() ? "center" : "left"}
            >
              {this.context.translation.templates.contact_us.where_we_are.title}
            </Text>
          </Grid>
          <Grid item xs={1} md={12} />
          <Grid item xs={1} md={1} />
          <Grid item xs={10} md={5} style={{ padding: "8px" }}>
            <Text variant="customize" weight fontSize="24px" margin="0">
              {
                this.context.translation.templates.contact_us.where_we_are
                  .madrid.title
              }
            </Text>
            <Text variant="customize" fontSize="18px" margin="0 0 8px 0">
              {
                this.context.translation.templates.contact_us.where_we_are
                  .madrid.text
              }
            </Text>
            <iframe
              title="madrid"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.1284787727486!2d-3.6646808999999996!3d40.42815389999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4229a5f2d56d3f%3A0x3ac1b5bd9fb59724!2sEloquence%20Madrid%20%7C%20Personal%20para%20Eventos!5e0!3m2!1ses!2ses!4v1706868417416!5m2!1ses!2ses"
              width="100%"
              height="450"
              frameBorder={0}
              style={{ border: "0" }}
              allowFullScreen
            ></iframe>
          </Grid>
          <Hidden smUp>
            <Grid item xs={12} md={false} />
            <Grid item xs={1} md={false} />
          </Hidden>
          <Grid item xs={10} md={5} style={{ padding: "8px" }}>
            <Text
              variant="customize"
              weight
              fontSize="24px"
              margin={windowMobileDetection() ? "8px 0 0 0" : "0"}
            >
              {
                this.context.translation.templates.contact_us.where_we_are
                  .barcelona.title
              }
            </Text>
            <Text variant="customize" fontSize="18px" margin="0 0 8px 0">
              {
                this.context.translation.templates.contact_us.where_we_are
                  .barcelona.text
              }
            </Text>

            <iframe
              title="barcelona"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Eloquence%20Barcelona+(Mi%20nombre%20de%20egocios)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="450"
              frameBorder={0}
              style={{ border: "0" }}
              allowFullScreen
            ></iframe>
          </Grid>
          <Hidden smUp>
            <Grid item xs={12} md={false} />
          </Hidden>
          {/* <Grid
            item
            xs={12}
            md={4}
            style={{ background: windowMobileDetection() ? "#F6F8F9" : null }}
          >
            <Grid container>
              <Grid item xs={2} />
              <Grid item xs={8}>
                <InstaGrid
                  account="eloquence_es"
                  title={
                    this.context.translation.templates.contact_us.instagram
                      .title
                  }
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Hidden mdUp>
              <br />
              <Button
                variant="b3"
                label={
                  this.context.translation.templates.contact_us.budget.button
                    .request_budget
                }
                link={getLink("budget")}
              />
            </Hidden>
          </Grid>
        </Grid>
        <Footer translation={this.context.translation.templates.footer} />
      </Grid>
    );
  }
}

export { ContactUs };
