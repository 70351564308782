import React from "react";
import { Text } from "components/Atoms/Text";
import PropTypes from "prop-types";
import { Button } from "components/Atoms/Button";
import "./style.css";
import { windowMobileDetection } from "core/windowMobileDetection";

const ContactCard = (props) => {
  // console.log("props", props);
  return (
    <div className="contactCard">
      <Text weight fontSize={props.sizeTitle} margin="8px 0 0 0">
        {props.callUsTitle}
      </Text>
      <Text fontSize={props.sizeSubtitle} margin="0">
        {props.callUsSubtitle}
      </Text>
      <Text weight fontSize={props.sizeTitle} margin="16px 0 0 0">
        {props.writeUsTitle}
      </Text>
      <Text fontSize={props.sizeSubtitle} margin="0 0 16px 0">
        {props.writeUsSubtitle}
      </Text>
      {props.ourOfficeTitle && [
        <Text fontSize={props.sizeTitle} weight margin="0">
          {props.ourOfficeTitle}
        </Text>,
        <Text
          fontSize={props.sizeSubtitle}
          margin="0"
          padding="0px 0px 0px 0px"
        >
          {props.ourOfficeSubtitle1}
        </Text>,

        <Text
          fontSize={props.sizeSubtitle}
          margin="16px 0 16px 0"
          padding="0px 0px 0px 0px"
        >
          {props.ourOfficeSubtitle2}
        </Text>,
      ]}
      {props.btnText && (
        <Button
          label={props.btnText}
          variant="b3"
          fontSize={windowMobileDetection() ? "16px" : "22px"}
          onClick={props.handleClickButton}
          externalLink={props.link || null}
          weight
          margin="8px"
        ></Button>
      )}
    </div>
  );
};

ContactCard.prototype = {
  btnText: PropTypes.string,
  sizeTitle: PropTypes.number,
  sizeSubtitle: PropTypes.number,
  callUsTitle: PropTypes.string.isRequired,
  callUsSubtitle: PropTypes.string.isRequired,
  writeUsTitle: PropTypes.string.isRequired,
  writeUsSubtitle: PropTypes.string.isRequired,
  ourOfficeTitle: PropTypes.string,
  ourOfficeSubtitle1: PropTypes.string,
  ourOfficeSubtitle2: PropTypes.string,
  handleClickButton: PropTypes.func,
  link: PropTypes.string,
};

export { ContactCard };
