import React from "react";

const WorkWithUsTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";

  return {
    skills: {
      buttons: {
        work: translation.skills.buttons.work[typeFormat],
      },
      title: translation.skills.title[typeFormat],
      cards: {
        presence: {
          icon: "workWithUs/icon_presencia_work.png",
          text: translation.skills.cards.presence.text[typeFormat],
        },
        english: {
          icon: "workWithUs/icon_english_work.png",
          text: translation.skills.cards.english.text[typeFormat],
        },
        attitude: {
          icon: "workWithUs/icon_proactive.png",
          text: translation.skills.cards.attitude.text[typeFormat],
        },
      },
    },
    team: {
      title: translation.team.title[typeFormat],
      subtitle: translation.team.subtitle[typeFormat],
      card: {
        form: {
          inputs: {
            name: {
              label: translation.team.card.form.inputs.name.label,
              placeholder: translation.team.card.form.inputs.name.placeholder,
            },
            email: {
              label: translation.team.card.form.inputs.email.label,
              placeholder: translation.team.card.form.inputs.email.placeholder,
            },
            phone: {
              label: translation.team.card.form.inputs.phone.label,
              placeholder: translation.team.card.form.inputs.phone.placeholder,
            },
            city: {
              label: translation.team.card.form.inputs.city.label,
              placeholder: translation.team.card.form.inputs.city.placeholder,
            },
            nacionality: {
              label: translation.team.card.form.inputs.nacionality.label,
              placeholder:
                translation.team.card.form.inputs.nacionality.placeholder,
            },
            date: {
              label: translation.team.card.form.inputs.date.label,
              placeholder: translation.team.card.form.inputs.date.placeholder,
              ok_label: translation.team.card.form.inputs.date.ok_label,
              cancel_label: translation.team.card.form.inputs.date.cancel_label,
              wrong_date: translation.team.card.form.inputs.date.wrong_date,
            },
            photos: {
              label: translation.team.card.form.inputs.photos.label,
              button: translation.team.card.form.inputs.photos.button,
            },
          },
          selects: {
            gender: {
              label: translation.team.card.form.selects.gender.label,
              placeholder:
                translation.team.card.form.selects.gender.placeholder,
              items: translation.team.card.form.selects.gender.items,
            },
            dress_size: {
              label: translation.team.card.form.selects.dress_size.label,
              placeholder:
                translation.team.card.form.selects.dress_size.placeholder,
              items: translation.team.card.form.selects.dress_size.items,
            },
            shoe_size: {
              label: translation.team.card.form.selects.shoe_size.label,
              placeholder:
                translation.team.card.form.selects.shoe_size.placeholder,
              items: translation.team.card.form.selects.shoe_size.items,
            },
            english_level: {
              label: translation.team.card.form.selects.english_level.label,
              placeholder:
                translation.team.card.form.selects.english_level.placeholder,
              items: translation.team.card.form.selects.english_level.items,
            },
            extra_languages: {
              label: translation.team.card.form.selects.extra_languages.label,
              placeholder:
                translation.team.card.form.selects.extra_languages.placeholder,
              items: translation.team.card.form.selects.extra_languages.items,
            },
          },
          texts: {
            contact_us: translation.team.card.form.texts.contact_us[typeFormat],
          },
          textareas: {
            extra_languages:
              translation.team.card.form.textareas.extra_languages,
          },
          checkboxs: {
            privacy: {
              label: translation.team.card.form.checkboxs.privacy.label,
              link: (
                <a
                  href="https://eloquence.es/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translation.team.card.form.checkboxs.privacy.link}
                </a>
              ),
            },
          },
          buttons: {
            send: translation.team.card.form.buttons.send,
          },
        },
      },
    },
    our_philosophy: {
      title: translation.our_philosophy.title[typeFormat],
      text: translation.our_philosophy.text[typeFormat],
    },
  };
};

export { WorkWithUsTemplate };
