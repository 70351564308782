import { getLink } from "assets/languages/i18n/links";

const ServicesTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      button: {
        request_budget:
          translation.services.header.button.request_budget[typeFormat],
      },
    },
    hostesses: {
      title: translation.services.hostesses.title[typeFormat],
      subtitle: translation.services.hostesses.subtitle[typeFormat],
      cards: {
        hostesses: {
          to: getLink("hostesses"),
          image: "services/tarjetas_servicios_azafatas.jpg",
          title:
            translation.services.hostesses.cards.hostesses.title[typeFormat],
          text: translation.services.hostesses.cards.hostesses.text[typeFormat],
        },
        assemblers: {
          to: getLink("assemblers"),
          image: "services/tarjetas_servicios_montadores.jpg",
          title:
            translation.services.hostesses.cards.assemblers.title[typeFormat],
          text:
            translation.services.hostesses.cards.assemblers.text[typeFormat],
        },
        auxiliars: {
          to: getLink("auxiliars"),
          image: "services/tarjetas_servicios_auxiliares.jpg",
          title:
            translation.services.hostesses.cards.auxiliars.title[typeFormat],
          text: translation.services.hostesses.cards.auxiliars.text[typeFormat],
        },
        waiters: {
          to: getLink("waiters"),
          image: "services/tarjetas_servicios_camareros.jpg",
          title: translation.services.hostesses.cards.waiters.title[typeFormat],
          text: translation.services.hostesses.cards.waiters.text[typeFormat],
        },
      },
      footer: translation.services.hostesses.footer[typeFormat],
    },
    doubt: {
      title: translation.services.doubt.title[typeFormat],
      button: {
        contact_us: translation.services.doubt.button.contact_us[typeFormat],
      },
    },
    how_we_work: {
      title: translation.services.how_we_work.title[typeFormat],
      cards: {
        selection: {
          icon: "services/icon_selection.png",
          title:
            translation.services.how_we_work.cards.selection.title[typeFormat],
          text:
            translation.services.how_we_work.cards.selection.text[typeFormat],
        },
        planning: {
          icon: "services/icon_report.png",
          title:
            translation.services.how_we_work.cards.planning.title[typeFormat],
          text:
            translation.services.how_we_work.cards.planning.text[typeFormat],
        },
        support: {
          icon: "services/icon_support.png",
          title:
            translation.services.how_we_work.cards.support.title[typeFormat],
          text: translation.services.how_we_work.cards.support.text[typeFormat],
        },
      },
      footer: translation.services.how_we_work.footer[typeFormat],
    },
    request_budget: {
      button: {
        request_budget:
          translation.services.request_budget.button.request_budget[typeFormat],
      },
    },
  };
};

export { ServicesTemplate };
