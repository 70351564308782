import { getLink } from "../../links";

const HostessesBarcelonaTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.hostesses_barcelona.header.title[typeFormat],
      texts: {
        text_1: translation.hostesses_barcelona.header.texts.text_1[typeFormat],
        text_2: translation.hostesses_barcelona.header.texts.text_2[typeFormat],
      },
    },
    hire: {
      title: translation.hostesses_barcelona.hire.title[typeFormat],
      buttons: {
        request_budget:
          translation.hostesses_barcelona.hire.buttons.request_budget[
            typeFormat
          ],
      },
    },
    section: {
      text: translation.hostesses_barcelona.section.text[typeFormat],
    },
    other_services: {
      title: translation.hostesses_barcelona.other_services.title[typeFormat],
      subtitle:
        translation.hostesses_barcelona.other_services.subtitle[typeFormat],
      cards: {
        hostesses: {
          image: "hostessesBarcelona/hostesses.jpg",
          title:
            translation.hostesses_barcelona.other_services.cards.hostesses
              .title[typeFormat],
          link: getLink("hostesses"),
        },
        madrid: {
          image: "hostessesBarcelona/madrid.png",
          title:
            translation.hostesses_barcelona.other_services.cards.madrid.title[
              typeFormat
            ],
          link: getLink("hostesses_madrid"),
        },
      },
    },
    contact_us: {
      button: {
        contact_us:
          translation.hostesses_barcelona.contact_us.button.contact_us[
            typeFormat
          ],
      },
    },
  };
};

export { HostessesBarcelonaTemplate };
