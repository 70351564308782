import React, { Component } from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { Header } from "components/Organisms/Header";
import { windowMobileDetection } from "core/windowMobileDetection";
import { Text } from "components/Atoms/Text";
import { UserContext } from "core/UserContext";
import { Button } from "components/Atoms/Button";
import { ListCard } from "components/Organisms/ListCard";
import { Image } from "components/Atoms/Image";
import { Navbar } from "components/Organisms/NavBar";
import { Footer } from "components/Organisms/Footer";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { getLink } from "assets/languages/i18n/links";

class AboutUs extends Component {
  static contextType = UserContext;

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  //TODO: crear carpeta con imagenes
  createGridList = (numberItems) => {
    let content = [];
    for (let index = 1; index <= numberItems; index++) {
      content.push(
        <GridListTile key={index} cols={1}>
          <img
            src={require("assets/img/aboutUs/galery/" + index + ".jpg")}
            alt={index}
          />
        </GridListTile>
      );
    }

    return content;
  };

  render() {
    //TODO IMPROVE WHEN CHANGE LANGUAGE

    // console.log(this.context.translation);
    return (
      <Grid container>
        {/* NavBar */}
        <Navbar
          translation={this.context.translation.templates.navbar}
          link="about_us"
        />
        {/* SECTION HEADER */}
        <Header
          image="aboutUs/header_big.jpg"
          height={windowMobileDetection() ? "40vh" : "50vh"}
          backgroundPosition="80% 20%"
          sizeImage={windowMobileDetection() ? "300%" : "160%"}
        >
          <Grid
            container
            style={{
              height: windowMobileDetection() ? "100%" : "60%",
              paddingTop: windowMobileDetection() ? "12%" : "5%",
              paddingLeft: windowMobileDetection() ? null : "10%",
              paddingBottom: "56px",
            }}
          >
            <Grid item xs={12} md={12}>
              <Text
                variant={windowMobileDetection() ? "customize" : "h1"}
                weight
                padding={windowMobileDetection() ? "0 0 0 12px" : null}
                fontSize={windowMobileDetection() ? "22px" : null}
                margin="0"
              >
                {this.context.translation.templates.about_us.header.title}
              </Text>
            </Grid>
            <Grid item xs={5} md={3}>
              <Text
                variant={windowMobileDetection() ? "customize" : "customize"}
                fontSize={windowMobileDetection() ? "18px" : "24px"}
                padding={windowMobileDetection() ? "0 0 0 12px" : null}
                light={windowMobileDetection() ? true : true}
              >
                {this.context.translation.templates.about_us.header.texts.info}
              </Text>
            </Grid>
            <Grid xs={false} md={12} />
            <Hidden smDown>
              <Grid item xs={false} md={3}>
                <Button
                  variant="b5"
                  label={
                    this.context.translation.templates.about_us.header.buttons
                      .contact_us
                  }
                  fontSize={windowMobileDetection() ? null : "22px"}
                  weight
                  link={getLink("contact_us")}
                />
              </Grid>
            </Hidden>
          </Grid>
        </Header>
        {/* SECTION OUR VALUES */}
        <Grid
          container
          style={{ marginBottom: windowMobileDetection() ? "0" : "24px" }}
        >
          <Grid
            item
            xs={12}
            style={{ marginTop: windowMobileDetection() ? "0" : "8px" }}
          >
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              textAlign="center"
              fontSize="28px"
              weight
            >
              {this.context.translation.templates.about_us.our_values.title}
            </Text>
          </Grid>
          <Grid item xs={2} md={1} />
          <Grid item xs={8} md={10}>
            <Grid container spacing={2}>
              <ListCard
                items={Object.keys(
                  this.context.translation.templates.about_us.our_values.cards
                ).map((card) => {
                  return {
                    xs: 12,
                    md: 4,
                    variant: "TextCard",
                    sizeTitle: windowMobileDetection() ? "22px" : "28px",
                    sizeSubtitle: windowMobileDetection() ? "18px" : "24px",
                    title:
                      this.context.translation.templates.about_us.our_values
                        .cards[card].title,
                    textLabel:
                      this.context.translation.templates.about_us.our_values
                        .cards[card].text,
                    light: true,
                  };
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* SECTION OUR HISTORY */}
        <Grid
          container
          style={{
            background: "#F6F8F9",
            marginTop: "16px",
            paddingBottom: "48px",
          }}
        >
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              textAlign="center"
              fontSize="28px"
              weight
            >
              {this.context.translation.templates.about_us.our_history.title}
            </Text>
          </Grid>
          {windowMobileDetection() ? (
            <Grid container>
              <Grid item xs={1} md={false} />
              <Grid item xs={10}>
                <Image
                  url="aboutUs/our_history.jpg"
                  width="100%"
                  borderRadius="10px"
                />
                <Text
                  variant="customize"
                  fontSize={windowMobileDetection() ? "12px" : "16px"}
                >
                  {
                    this.context.translation.templates.about_us.our_history
                      .footer
                  }
                </Text>
              </Grid>
              <Grid item xs={1} md={false} />
              <Grid item xs={1} md={false} />
              <Grid item xs={10}>
                <Text variant="p2" fontSize="10px">
                  {this.context.translation.templates.about_us.our_history.text}
                </Text>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item xs={false} md={2} />
              <Grid item xs={false} md={8}>
                <div
                  style={{
                    width: "30%",
                    float: "right",
                    marginLeft: "92px",
                    marginBottom: "8px",
                  }}
                >
                  <Image
                    url="aboutUs/our_history.jpg"
                    width="100%"
                    borderRadius="10px"
                  />
                  <Text
                    variant="customize"
                    fontSize={windowMobileDetection() ? "12px" : "16px"}
                  >
                    {
                      this.context.translation.templates.about_us.our_history
                        .footer
                    }
                  </Text>
                </div>
                <Text
                  variant="customize"
                  fontSize="22px"
                  padding="0 24px 0 0"
                  margin="0"
                >
                  {this.context.translation.templates.about_us.our_history.text}
                </Text>
              </Grid>
            </>
          )}
        </Grid>
        {/* SECTION BUDGET */}
        <Grid
          container
          style={{ margin: windowMobileDetection() ? "24px 0" : "48px 0" }}
        >
          <Grid item xs={1} md={4} />
          <Grid item xs={10} md={4}>
            <Button
              fullWidth
              variant="b3"
              label={
                this.context.translation.templates.about_us.budget.buttons
                  .request_budget
              }
              fontSize={windowMobileDetection() ? null : "22px"}
              weight
              link={getLink("budget")}
            />
          </Grid>
        </Grid>
        {/* SECTION SUCCESS STORIES */}
        <Grid
          container
          style={{
            background: "#F6F8F9",
          }}
        >
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              textAlign="center"
              fontSize="28px"
              weight
            >
              {
                this.context.translation.templates.about_us.success_stories
                  .title
              }
            </Text>
          </Grid>
          {windowMobileDetection() && (
            <>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Image
                  url="aboutUs/success_stories.png"
                  width="100%"
                  borderRadius="10px"
                />
                <Text
                  variant="customize"
                  fontSize={windowMobileDetection() ? "12px" : "16px"}
                >
                  {
                    this.context.translation.templates.about_us.success_stories
                      .footer
                  }
                </Text>
              </Grid>

              <Grid item xs={1} />
            </>
          )}
          <Grid item xs={1} />

          <Grid item xs={10} md={5}>
            <Text
              variant="customize"
              fontSize={windowMobileDetection() ? "24px" : "32px"}
              margin={windowMobileDetection() ? "16px 0 4px 0" : "0 0 16px 0"}
              weight={windowMobileDetection() ? true : true}
            >
              {
                this.context.translation.templates.about_us.success_stories
                  .subtitle
              }
            </Text>

            {!windowMobileDetection() && (
              <>
                <Text
                  variant="customize"
                  fontSize={windowMobileDetection() ? "16px" : "18px"}
                  weight={windowMobileDetection() ? true : true}
                >
                  {
                    this.context.translation.templates.about_us.success_stories
                      .footer
                  }
                </Text>
                <Image
                  url="aboutUs/success_stories.png"
                  width="90%"
                  borderRadius="10px"
                />
              </>
            )}
          </Grid>
          <Hidden smUp>
            <Grid item xs={1} md={false} />
            <Grid item xs={1} md={false} />
          </Hidden>
          <Grid item xs={10} md={5}>
            <Text
              variant={windowMobileDetection() ? "p2" : "customize"}
              fontSize="22px"
              padding="0 24px 0 0"
            >
              {this.context.translation.templates.about_us.success_stories.text}
            </Text>
          </Grid>
          {/* <Grid item xs={10} style={{ marginBottom: "16px" }}>
            <GridList cellHeight={160} cols={4}>
              {this.createGridList(16)}
            </GridList>
          </Grid> */}
        </Grid>

        <Footer translation={this.context.translation.templates.footer} />
      </Grid>
    );
  }
}

export { AboutUs };
