import { getLink } from "assets/languages/i18n/links";

const ServicesHostessesTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      button: {
        request_budget:
          translation.hostesses.header.button.request_budget[typeFormat],
      },
    },
    hostesses: {
      title: translation.hostesses.hostesses.title[typeFormat],
      subtitle: translation.hostesses.hostesses.subtitle[typeFormat],
      cards: {
        congress_hostesses: {
          to: getLink("congress_hostesses"),
          image: "services/congress_hostesses.jpg",
          title:
            translation.hostesses.hostesses.cards.congress_hostesses.title[
              typeFormat
            ],
          text:
            translation.hostesses.hostesses.cards.congress_hostesses.text[
              typeFormat
            ],
        },
        brand_hostesses: {
          to: getLink("brand_hostesses"),
          image: "services/brand_hostesses.jpg",
          title:
            translation.hostesses.hostesses.cards.brand_hostesses.title[
              typeFormat
            ],
          text:
            translation.hostesses.hostesses.cards.brand_hostesses.text[
              typeFormat
            ],
        },
        stand_hostesses: {
          to: getLink("stand_hostesses"),
          image: "services/hostesses/Tarjetas_servicios_azafatas_feria.jpg",
          title:
            translation.hostesses.hostesses.cards.stand_hostesses.title[
              typeFormat
            ],
          text:
            translation.hostesses.hostesses.cards.stand_hostesses.text[
              typeFormat
            ],
        },
        promoter_hostesses: {
          to: getLink("promoter_hostesses"),
          image:
            "services/hostesses/Tarjetas_servicios_azafatas_promotoras.jpg",
          title:
            translation.hostesses.hostesses.cards.promoter_hostesses.title[
              typeFormat
            ],
          text:
            translation.hostesses.hostesses.cards.promoter_hostesses.text[
              typeFormat
            ],
        },
      },
    },
    doubt: {
      title: translation.hostesses.doubt.title[typeFormat],
      button: {
        contact_us: translation.hostesses.doubt.button.contact_us[typeFormat],
      },
    },
    how_we_work: {
      title: translation.hostesses.how_we_work.title[typeFormat],
      cards: {
        selection: {
          icon: "services/hostesses/icon_proactive.png",
          title:
            translation.hostesses.how_we_work.cards.selection.title[typeFormat],
          text:
            translation.hostesses.how_we_work.cards.selection.text[typeFormat],
        },
        planning: {
          icon: "services/hostesses/icon_english.png",
          title:
            translation.hostesses.how_we_work.cards.planning.title[typeFormat],
          text:
            translation.hostesses.how_we_work.cards.planning.text[typeFormat],
        },
        support: {
          icon: "services/hostesses/icon_presencia.png",
          title:
            translation.hostesses.how_we_work.cards.support.title[typeFormat],
          text:
            translation.hostesses.how_we_work.cards.support.text[typeFormat],
        },
      },
      footer: translation.hostesses.how_we_work.footer[typeFormat],
    },
    talent: {
      title: translation.hostesses.talent.title[typeFormat],
      cards: {
        assemblers: {
          to: getLink("assemblers"),
          image:
            "services/hostesses/Tarjetas_Servicios_Azafatas_montadores.jpg",
          title:
            translation.hostesses.talent.cards.assemblers.title[typeFormat],
        },
        auxiliars: {
          to: getLink("auxiliars"),
          image:
            "services/hostesses/Tarjetas_Servicios_Azafatas_auxiliares.jpg",
          title: translation.hostesses.talent.cards.auxiliars.title[typeFormat],
        },
        waiters: {
          to: getLink("waiters"),
          image: "services/hostesses/Tarjetas_Servicios_Azafatas_camareros.jpg",
          title: translation.hostesses.talent.cards.waiters.title[typeFormat],
        },
      },
    },
    request_budget: {
      button: {
        request_budget:
          translation.hostesses.request_budget.button.request_budget[
            typeFormat
          ],
      },
    },
  };
};

export { ServicesHostessesTemplate };
