const AuxiliarsTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.auxiliars.header.title[typeFormat],
      subtitle: translation.auxiliars.header.subtitle[typeFormat],
    },
    hire: {
      title: translation.auxiliars.hire.title[typeFormat],
      buttons: {
        request_budget:
          translation.auxiliars.hire.buttons.request_budget[typeFormat],
      },
    },
    auxiliars: {
      cards: {
        access_staff: {
          image: "auxiliars/access_staff.jpeg",
          title:
            translation.auxiliars.auxiliars.cards.access_staff.title[
              typeFormat
            ],
        },
        cashless: {
          image: "auxiliars/cashless.jpeg",
          title:
            translation.auxiliars.auxiliars.cards.cashless.title[typeFormat],
        },
        wristband_staff: {
          image: "auxiliars/wristband_staff.jpeg",
          title:
            translation.auxiliars.auxiliars.cards.wristband_staff.title[
              typeFormat
            ],
        },
        merchandising: {
          image: "auxiliars/merchandising.jpeg",
          title:
            translation.auxiliars.auxiliars.cards.merchandising.title[
              typeFormat
            ],
        },
      },
    },
    contract: {
      title: translation.auxiliars.contract.title[typeFormat],
      buttons: {
        contact_us:
          translation.auxiliars.contract.buttons.contact_us[typeFormat],
      },
    },
    keys: {
      title: translation.auxiliars.keys.title[typeFormat],
      cards: {
        experience: {
          title: translation.auxiliars.keys.cards.experience.title[typeFormat],
          text: translation.auxiliars.keys.cards.experience.text[typeFormat],
        },
        attitude: {
          title: translation.auxiliars.keys.cards.attitude.title[typeFormat],
          text: translation.auxiliars.keys.cards.attitude.text[typeFormat],
        },
      },
      footer: translation.auxiliars.keys.footer[typeFormat],
    },
    other_services: {
      cards: {
        stand: {
          image:
            "services/auxiliars/tarjetas_servicios_auxiliares_galeria_1.jpg",
        },
        promoters: {
          image:
            "services/auxiliars/tarjetas_servicios_auxiliares_galeria_2.jpg",
        },
        brand: {
          image:
            "services/auxiliars/tarjetas_servicios_auxiliares_galeria_3.jpg",
        },
        assemblers: {
          image:
            "services/auxiliars/tarjetas_servicios_auxiliares_galeria_4.jpg",
        },
      },
    },
    contact_us: {
      title: translation.auxiliars.contact_us.title[typeFormat],
      buttons: {
        contact_us:
          translation.auxiliars.contact_us.buttons.contact_us[typeFormat],
      },
    },
  };
};

export { AuxiliarsTemplate };
