import React from "react";
import { Image } from "components/Atoms/Image";
import { Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  insideHeader: {
    height: "100%",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      style={{
        minHeight: props.height,
        marginTop: !props.ismMarginTop ? "80px" : null,
        position: "relative",
      }}
    >
      <Image
        isBackground
        url={props.image}
        backgroundPosition={props.backgroundPosition}
        sizeImage={props.sizeImage}
        height={props.height}
        isFixed={props.isFixed}
      >
        <div className={classes.insideHeader}>{props.children}</div>
      </Image>
    </Grid>
  );
};

Header.propTypes = {
  image: PropTypes.string,
  position: PropTypes.string,
  height: PropTypes.string,
  ismMarginTop: PropTypes.bool,
  children: PropTypes.object,
};
export { Header };
