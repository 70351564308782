import { getLink } from "../../links";

const HostessesMadridTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.hostesses_madrid.header.title[typeFormat],
      texts: {
        text_1: translation.hostesses_madrid.header.texts.text_1[typeFormat],
        text_2: translation.hostesses_madrid.header.texts.text_2[typeFormat],
      },
    },
    hire: {
      title: translation.hostesses_madrid.hire.title[typeFormat],
      buttons: {
        request_budget:
          translation.hostesses_madrid.hire.buttons.request_budget[typeFormat],
      },
    },
    section: {
      text: translation.hostesses_madrid.section.text[typeFormat],
    },
    other_services: {
      title: translation.hostesses_madrid.other_services.title[typeFormat],
      subtitle:
        translation.hostesses_madrid.other_services.subtitle[typeFormat],
      cards: {
        hostesses: {
          image: "hostessesMadrid/hostesses.jpg",
          title:
            translation.hostesses_madrid.other_services.cards.hostesses.title[
              typeFormat
            ],
          link: getLink("hostesses"),
        },
        barcelona: {
          image: "hostessesMadrid/barcelona.jpg",
          title:
            translation.hostesses_madrid.other_services.cards.barcelona.title[
              typeFormat
            ],
          link: getLink("hostesses_barcelona"),
        },
      },
    },
    contact_us: {
      button: {
        contact_us:
          translation.hostesses_madrid.contact_us.button.contact_us[typeFormat],
      },
    },
  };
};

export { HostessesMadridTemplate };
