import React from "react";
import PropTypes from "prop-types";

import { Link } from "components/Atoms/Link";

const Item = props => {
  let content;

  // Modify Styles
  switch (props.variant) {
    case "item_1":
      break;
    default:
      break;
  }

  //Check link exists
  if (props.link !== undefined) {
    if (props.external) {
      content = (
        <a
          href={props.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff", textDecoration: "none" }}
        >
          {props.children}
        </a>
      );
    } else {
      content = (
        <Link
          to={props.link}
          // style={{ textDecoration: "none", color: "#fff" }}
          color="#fff"
          key={props.id}
        >
          {props.children}
        </Link>
      );
    }
  } else {
    content = props.children;
  }

  return content;
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  link: PropTypes.string,
  variant: PropTypes.string
};

export { Item };
