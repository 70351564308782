const ThanksTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";

  return {
    texts: {
      title: translation.texts.title[typeFormat],
    },
    buttons: {
      back_home: translation.buttons.back_home[typeFormat],
    },
  };
};

export { ThanksTemplate };
