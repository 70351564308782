const ServicesStandHostessesTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.stand_hostesses.header.title[typeFormat],
      texts: {
        text_1: translation.stand_hostesses.header.texts.text_1[typeFormat],
        text_2: translation.stand_hostesses.header.texts.text_2[typeFormat],
      },
    },
    hire: {
      title: translation.stand_hostesses.hire.title[typeFormat],
      buttons: {
        request_budget:
          translation.stand_hostesses.hire.buttons.request_budget[typeFormat],
      },
    },
    section: {
      text: translation.stand_hostesses.section.text[typeFormat],
    },
    other_services: {
      title: translation.stand_hostesses.other_services.title[typeFormat],
      subtitle: translation.stand_hostesses.other_services.subtitle[typeFormat],
      cards: {
        stand: {
          image:
            "services/stand_hostesses/Tarjetas_Servicios_Azafatas_ferias_galeria1.jpg",
          title:
            translation.stand_hostesses.other_services.cards.stand.title[
              typeFormat
            ],
        },
        promoters: {
          image:
            "services/stand_hostesses/Tarjetas_Servicios_Azafatas_ferias_galeria2.jpg",
          title:
            translation.stand_hostesses.other_services.cards.promoters.title[
              typeFormat
            ],
        },
        brand: {
          image:
            "services/stand_hostesses/Tarjetas_Servicios_Azafatas_ferias_galeria3.jpg",
          title:
            translation.stand_hostesses.other_services.cards.brand.title[
              typeFormat
            ],
        },
        assemblers: {
          image:
            "services/stand_hostesses/Tarjetas_Servicios_Azafatas_ferias_galeria4.jpg",
          title:
            translation.stand_hostesses.other_services.cards.assemblers.title[
              typeFormat
            ],
        },
      },
    },
    contact_us: {
      button: {
        contact_us:
          translation.stand_hostesses.contact_us.button.contact_us[typeFormat],
      },
    },
    footer: translation.stand_hostesses.footer[typeFormat],
  };
};

export { ServicesStandHostessesTemplate };
