import React from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

const Input = (props) => {
  return (
    <TextField
      variant={props.variant}
      margin={props.margin}
      fullWidth={props.fullWidth}
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      type={props.type}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      autoComplete={props.autoComplete}
      className={props.classes}
      maxLength={props.maxLength}
      required={props.required}
      autoFocus={props.autoFocus}
      multiline={props.multiline}
      rows={props.rows}
      InputProps={props.InputProps}
    />
  );
};

Input.propTypes = {
  variant: PropTypes.string.isRequired,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

export default Input;
