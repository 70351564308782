import React, { Component } from "react";
import CookieBanner from "react-cookie-banner";
import { Cookies } from "react-cookie-banner";
import { windowMobileDetection } from "core/windowMobileDetection";

const styles = {
  banner: {
    fontFamily: "Source Sans Pro",
    minHeight: 57,
    background: "rgba(52, 64, 81, 0.88)",
    backgroundSize: "30px 30px",
    backgroundColor: "",
    fontSize: "15px",
    fontWeight: 600,
    position: "fixed",
    bottom: 0,
    zIndex: 9999,
  },
  button: {
    border: "1px solid white",
    borderRadius: 4,
    width: 66,
    height: 32,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -18,
  },
  message: {
    display: "block",
    padding: "9px 4px",
    lineHeight: 1.3,
    textAlign: "left",
    marginRight: 120,
    color: "white",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
  },
};

const mobileStyles = {
  banner: {
    fontFamily: "Source Sans Pro",
    minHeight: 140,
    background: "rgba(52, 64, 81, 0.88)",
    backgroundSize: "30px 30px",
    backgroundColor: "",
    fontSize: "15px",
    fontWeight: 600,
    position: "fixed",
    bottom: 0,
    zIndex: 9999,
  },
  button: {
    border: "1px solid white",
    borderRadius: 4,
    width: 66,
    height: 32,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -18,
  },
  message: {
    display: "block",
    padding: "9px 4px",
    lineHeight: 1.3,
    textAlign: "left",
    marginRight: 120,
    color: "white",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
  },
};

const message =
  "Buildo uses cookies to guarantee users the employment of its site features, offering a better purchasing experience. By continuing to browse the site you're agreeing to our use of cookies.";

class BannerCookie extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.translation = null;
    this.state = {
      //   dismissOnScroll: false,
      dismissOnClick: true,
    };
  }

  // reset cookies on first render
  // !this.state.accepted &&
  //   cookies.get("accepts-cookies") &&
  //   cookies.remove("accepts-cookies");

  //   toggleDismissOnScroll() {
  //     this.setState({ dismissOnScroll: !this.state.dismissOnScroll });
  //   }

  render() {
    return (
      // this.cookies.get("accept-cookies") && (
      <CookieBanner
        styles={windowMobileDetection() ? mobileStyles : styles}
        message={message}
        link={
          <a
            href="https://eloquence.es/cookies-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            More information on our use of cookies
          </a>
        }
        buttonMessage="Close"
        dismissOnScroll={false}
        dismissOnClick={this.state.dismissOnClick}
        onAccept={() => {
          this.cookies.set("accept-cookies", true);
          this.setState({ accepted: true });
        }}
      />
      // )
    );
  }
}

export { BannerCookie };
