import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { AboutUs } from "pages/AboutUs";
import { Budget } from "pages/Budget";
import { UserContext } from "core/UserContext";
import { Language } from "assets/languages/i18n";
import { ContactUs } from "pages/ContactUs";
import { ServicesHostesses } from "pages/Services/Hostesses";
import { ServicesCongressHostesses } from "pages/Services/CongressHostesses";
import { ServicesStandHostesses } from "pages/Services/StandHostesses";
import { ServicesPromoterHostesses } from "pages/Services/PromoterHostesses";
import { ServicesBrandHostesses } from "pages/Services/BrandHostesses";
import { Services } from "pages/Services/Services";
import { Home } from "pages/Home";
import { Payment } from "pages/Payment";
import { PrivacyPolicy } from "pages/PrivacyPolicy";
import { BannerCookie } from "components/Organisms/BannerCookie";
import { CookiePolicy } from "pages/CookiePolicy";
import { ServicesAssemblers } from "pages/Services/Assemblers";
import { ServicesAuxiliars } from "pages/Services/Auxiliars";
import { ServicesWaiters } from "pages/Services/Waiters";
import { links } from "assets/languages/i18n/links";
import { HostessesMadrid } from "pages/HostessesMadrid";
import { HostessesBacelona } from "pages/HostessesBarcelona";
import { Thanks } from "pages/Thanks";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language:
        ["es", "en"].find(
          (language) => language === navigator.language.substring(0, 2)
        ) || "es",
      handleChangeLanguage: this.handleChangeLanguage,
    };
    localStorage.setItem(
      "eloquence-lang",
      ["es", "en"].find(
        (language) => language === navigator.language.substring(0, 2)
      ) || "es"
    );
  }

  handleChangeLanguage = (language) => {
    localStorage.setItem(
      "eloquence-lang",
      ["es", "en"].find((_language) => _language === language) || "es"
    );
    this.setState({
      language:
        ["es", "en"].find((_language) => _language === language) || "es",
    });
  };
  render() {
    // console.log("links", links);
    return (
      <UserContext.Provider
        value={{
          language: this.state.language,
          translation: new Language(this.state.language).getLanguage(),
          handleLanguageChange: this.state.handleChangeLanguage,
        }}
      >
        <Switch>
          <Route path="/" exact>
            {this.state.language === "es" ? (
              <Redirect to="/personal-para-eventos" />
            ) : this.state.language === "en" ? (
              <Redirect to="/event-staffing-agency" />
            ) : (
              <Redirect to="/personal-para-eventos" />
            )}
          </Route>
          {/* Home */}
          {Object.values(links.home).map((link) => (
            <Route path={link} exact component={Home} />
          ))}
          {/* About us */}
          {Object.values(links.about_us).map((link) => (
            <Route path={link} exact component={AboutUs} />
          ))}
          {/* Contact us */}
          {Object.values(links.contact_us).map((link) => (
            <Route path={link} exact component={ContactUs} />
          ))}
          {/* Services */}
          {Object.values(links.services).map((link) => (
            <Route path={link} exact component={Services} />
          ))}
          {/* Assemblers */}
          {Object.values(links.assemblers).map((link) => (
            <Route path={link} exact component={ServicesAssemblers} />
          ))}
          {/* Auxiliars */}
          {Object.values(links.auxiliars).map((link) => (
            <Route path={link} exact component={ServicesAuxiliars} />
          ))}
          {/* Waiters */}
          {Object.values(links.waiters).map((link) => (
            <Route path={link} exact component={ServicesWaiters} />
          ))}
          {/* Hostesses */}
          {Object.values(links.hostesses).map((link) => (
            <Route path={link} exact component={ServicesHostesses} />
          ))}
          {/* Congress hostesses */}
          {Object.values(links.congress_hostesses).map((link) => (
            <Route path={link} exact component={ServicesCongressHostesses} />
          ))}
          {/* Stand hostesses */}
          {Object.values(links.stand_hostesses).map((link) => (
            <Route path={link} exact component={ServicesStandHostesses} />
          ))}
          {/* Promoter hostesses */}
          {Object.values(links.promoter_hostesses).map((link) => (
            <Route path={link} exact component={ServicesPromoterHostesses} />
          ))}
          {/* Brand hostesses */}
          {Object.values(links.brand_hostesses).map((link) => (
            <Route path={link} exact component={ServicesBrandHostesses} />
          ))}
          {/* Budget */}
          {Object.values(links.budget).map((link) => (
            <Route path={link} exact component={Budget} />
          ))}
          {/* Hostesses Madrid */}
          {Object.values(links.hostesses_madrid).map((link) => (
            <Route path={link} exact component={HostessesMadrid} />
          ))}
          {/* Hostess Barcelona */}
          {Object.values(links.hostesses_barcelona).map((link) => (
            <Route path={link} exact component={HostessesBacelona} />
          ))}
          {/* thanks */}
          {Object.values(links.thanks).map((link) => (
            <Route path={link} exact component={Thanks} />
          ))}
          <Route path="/payment" exact component={Payment} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/cookies-policy" exact component={CookiePolicy} />
        </Switch>
        <BannerCookie />
      </UserContext.Provider>
    );
  }
}

export default withRouter(App);
