import React from "react";
import { Link as LinkRouter } from "react-router-dom";
import PropTypes from "prop-types";

const Link = (props) => {
  return (
    <LinkRouter
      to={props.to}
      style={{
        textDecoration: !props.underline ? "none" : "underline",
        color: props.color || "inherit",
      }}
    >
      {props.children}
    </LinkRouter>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  color: PropTypes.string,
  underline: PropTypes.bool,
  children: PropTypes.object,
};

export { Link };
