import React from "react";
import { TextCard } from "components/Molecules/Cards/TextCard";
import { IconCard } from "components/Molecules/Cards/IconCard";
import { ImageCard } from "components/Molecules/Cards/ImageCard";
import { ImageCardBottom } from "components/Molecules/Cards/ImageCardBottom";
import { ContactCard } from "components/Molecules/Cards/ContactCard";
import { CommentCard } from "components/Molecules/Cards/CommentCard";
import PropTypes from "prop-types";
import { Link } from "components/Atoms/Link";
import "./style.css";

const Card = (props) => {
  let content;
  switch (props.variant) {
    case "IconCard":
      content = (
        <IconCard
          url={props.url}
          sizeTitle={props.sizeTitle}
          title={props.title}
          widthImage={props.widthImage}
          sizeSubtitle={props.sizeSubtitle}
          textLabel={props.textLabel}
          light={props.light}
        ></IconCard>
      );
      break;
    case "TextCard":
      content = (
        <TextCard
          sizeTitle={props.sizeTitle}
          title={props.title}
          sizeSubtitle={props.sizeSubtitle}
          textLabel={props.textLabel}
          light={props.light}
        />
      );
      break;
    case "ContactCard":
      content = (
        <ContactCard
          btnText={props.btnText}
          sizeTitle={props.sizeTitle}
          sizeSubtitle={props.sizeSubtitle}
          callUsTitle={props.callUsTitle}
          callUsSubtitle={props.callUsSubtitle}
          writeUsTitle={props.writeUsTitle}
          writeUsSubtitle={props.writeUsSubtitle}
          ourOfficeTitle={props.ourOfficeTitle}
          ourOfficeSubtitle1={props.ourOfficeSubtitle1}
          ourOfficeSubtitle2={props.ourOfficeSubtitle2}
          handleClickButton={props.handleClickButton}
          link={props.link}
        />
      );
      break;
    case "ImageCard":
      content = (
        <ImageCard
          url={props.url}
          height={props.height}
          sizeTitle={props.sizeTitle}
          weightTitle={props.weightTitle}
          marginTitle={props.marginTitle}
          title={props.title}
          sizeSubtitle={props.sizeSubtitle}
          textLabel={props.textLabel}
          sizeImage={props.sizeImage}
          light={props.light}
          link={props.link}
          backgroundPosition={props.backgroundPosition}
        />
      );
      break;
    case "ImageCardBottom":
      content = (
        <ImageCardBottom
          url={props.url}
          height={props.height}
          sizeTitle={props.sizeTitle}
          weightTitle={props.weightTitle}
          marginTitle={props.marginTitle}
          title={props.title}
          sizeSubtitle={props.sizeSubtitle}
          textLabel={props.textLabel}
          sizeImage={props.sizeImage}
          light={props.light}
        />
      );
      break;
    case "CommentCard":
      content = (
        <CommentCard text={props.text} image={props.image} name={props.name} />
      );
      break;
    default:
      content = (
        <div className="Card" style={props.styles}>
          {props.children}
        </div>
      );
      break;
  }
  return props.to ? (
    <Link to={props.to} style={{ color: "inherit", textDecoration: "none" }}>
      {content}
    </Link>
  ) : (
    content
  );
};

Card.prototype = {
  url: PropTypes.string,
  sizeTitle: PropTypes.number,
  title: PropTypes.string.isRequired,
  sizeSubtitle: PropTypes.number,
  textLabel: PropTypes.string,
};
export { Card };
