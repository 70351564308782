import React from "react";

const ContactUsTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.contact_us.header.title[typeFormat],
    },
    form: {
      title: translation.contact_us.form.title[typeFormat],
      subtitle: translation.contact_us.form.subtitle[typeFormat],
      card: {
        title: translation.contact_us.form.card.web.title,
        subtitle: translation.contact_us.form.card.web.subtitle,
        inputs: {
          name: {
            label:
              translation.contact_us.form.card[typeFormat].inputs.name.label,
            placeholder:
              translation.contact_us.form.card[typeFormat].inputs.name
                .placeholder,
          },
          email: {
            label:
              translation.contact_us.form.card[typeFormat].inputs.email.label,
            placeholder:
              translation.contact_us.form.card[typeFormat].inputs.email
                .placeholder,
          },
          phone: {
            label:
              translation.contact_us.form.card[typeFormat].inputs.phone.label,
            placeholder:
              translation.contact_us.form.card[typeFormat].inputs.phone
                .placeholder,
          },
          city: {
            label:
              translation.contact_us.form.card[typeFormat].inputs.city.label,
            placeholder:
              translation.contact_us.form.card[typeFormat].inputs.city
                .placeholder,
          },
          number_hostess: {
            label:
              translation.contact_us.form.card[typeFormat].inputs.number_hostess
                .label,
            placeholder:
              translation.contact_us.form.card[typeFormat].inputs.number_hostess
                .placeholder,
          },
          message: {
            label:
              translation.contact_us.form.card[typeFormat].inputs.message.label,
            placeholder:
              translation.contact_us.form.card[typeFormat].inputs.message
                .placeholder,
          },
        },
        checkboxs: {
          privacy: {
            label:
              translation.contact_us.form.card[typeFormat].checkboxs.privacy
                .label,

            link: (
              <a
                href="https://eloquence.es/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {
                  translation.contact_us.form.card[typeFormat].checkboxs.privacy
                    .link
                }
              </a>
            ),
          },
        },
        buttons: {
          send: translation.contact_us.form.card[typeFormat].buttons.send,
        },
      },
    },
    contact_us: {
      card: {
        call_us: {
          title:
            translation.contact_us.contact_us.card.call_us.title[typeFormat],
          text: translation.contact_us.contact_us.card.call_us.text[typeFormat],
        },
        write_us: {
          title:
            translation.contact_us.contact_us.card.write_us.title[typeFormat],
          text: translation.contact_us.contact_us.card.write_us.text[
            typeFormat
          ],
        },
        our_offices: {
          title:
            translation.contact_us.contact_us.card.our_offices.title[
              typeFormat
            ],
          madrid:
            translation.contact_us.contact_us.card.our_offices.madrid[
              typeFormat
            ],
          barcelona:
            translation.contact_us.contact_us.card.our_offices.barcelona[
              typeFormat
            ],
        },
        button: {
          work_with_us:
            translation.contact_us.contact_us.card.button.work_with_us[
              typeFormat
            ],
        },
      },
    },
    where_we_are: {
      title: translation.contact_us.where_we_are.title[typeFormat],
      madrid: {
        title: translation.contact_us.where_we_are.madrid.title[typeFormat],
        text: translation.contact_us.where_we_are.madrid.text[typeFormat],
      },
      barcelona: {
        title: translation.contact_us.where_we_are.barcelona.title[typeFormat],
        text: translation.contact_us.where_we_are.barcelona.text[typeFormat],
      },
      cv: {
        cv: translation.contact_us.where_we_are.cv.title,
      },
    },
    instagram: {
      title: translation.contact_us.instagram.title[typeFormat],
    },
    budget: {
      button: {
        request_budget:
          translation.contact_us.budget.button.request_budget[typeFormat],
      },
    },
  };
};

export { ContactUsTemplate };
