const translation = {
  about_us: {
    header: {
      title: {
        mobile: null,
        web: "Eloquence",
      },
      texts: {
        info: {
          mobile:
            "We are a trusted hostess agency, where we put all our efforts to make your event a success.",
          web:
            "We are a trusted hostess agency, where we put all our efforts to make your event a success.",
        },
      },
      buttons: {
        contact_us: {
          mobile: null,
          web: "Contact us",
        },
      },
    },
    our_values: {
      title: { mobile: "Our values", web: "Our values" },
      cards: {
        support: {
          title: { mobile: "Support", web: "Support" },
          text: {
            mobile:
              "Attending every need as if it were ours, hostesses and customers.",
            web:
              "Attending every need as if it were ours, hostesses and customers.",
          },
        },
        excellency: {
          title: { mobile: "Excellency", web: "Excellency" },
          text: {
            mobile:
              "Un servicio 5 estrellas.Cada detalle cuenta, todo tiene que estar bien atado.",
            web:
              "Un servicio 5 estrellas.Cada detalle cuenta, todo tiene que estar bien atado.",
          },
        },
        decisives: {
          title: { mobile: "Decisives", web: "Decisives" },
          text: {
            mobile: "Young and fast. We adapt to what the situation demands.",
            web: "Young and fast. We adapt to what the situation demands.",
          },
        },
      },
    },
    our_history: {
      title: { mobile: "Our history", web: "Our history" },
      text: {
        mobile:
          "Eloquence was created to provide hostesses to international companies at their events in Madrid. <br/><br/>We wanted to attend and help all those companies that visit events, fairs and congresses in our country every year. <br/><br/>Treating each client in a very personalized way, we have managed to position ourselves as one of the reference agencies for foreign companies in Spain, especially in Madrid and Barcelona.",
        web:
          "Eloquence was created to provide hostesses to international companies at their events in Madrid. We wanted to attend and help all those companies that visit events, fairs, and congresses in our country every year.<br/><br/>Our idea has been always to implement English into the sector, looking after clients and hostesses at the same level.<br/><br/>We managed to position ourselves as one of the reference agencies for foreign companies in Spain, as well as one of the most recommended agencies by clients and hostesses.",
      },
    },
    success_stories: {
      title: { mobile: "Recent projects", web: "Recent projects" },
    },
    budget: {
      buttons: {
        request_budget: {
          mobile: "Request budget",
          web: null,
        },
      },
    },
  },
  budget: {
    header: {
      title: {
        mobile: "Do you need a budget?",
        web: "Do you need a budget?",
      },
      card: {
        title: {
          mobile: null,
          web: "Tell us what we can help",
        },
        subtitle: {
          mobile: null,
          web: "Fill in your details and we will contact you in 24h",
        },
        form: {
          mobile: null,
          web: {
            inputs: {
              name: { label: "Name", placeholder: "Mary Lyras" },
              email: { label: "Email", placeholder: "mary@company.com" },
              phone: { label: "Phone", placeholder: "+33 654939705" },
              city: { label: "City", placeholder: "Where your event happens?" },
              number_hostess: {
                label: "Hostess Nº",
                placeholder: "?",
              },
              message: {
                label: "Message",
                placeholder: "Tell us a bit more about the project",
              },
            },
            checkboxs: {
              privacy: { label: "I accept the ", link: "privacy polices" },
            },
            buttons: {
              send: "Send",
            },
          },
        },
      },
    },
    subtitle: { mobile: "Let us know about you and your event", web: null },
    form: {
      mobile: {
        inputs: {
          name: { label: "Name", placeholder: "Mary Lyras" },
          email: { label: "Email", placeholder: "mary@company.com" },
          phone: { label: "Phone", placeholder: "+33 654939705" },
          city: { label: "City", placeholder: "Where your event happens?" },
          number_hostess: {
            label: "Hostess Nº",
            placeholder: "?",
          },
          message: {
            label: "Message",
            placeholder: "Tell us a bit more about the project",
          },
        },
        checkboxs: {
          privacy: { label: "I accept the ", link: "privacy polices" },
        },
        buttons: {
          send: "Send",
        },
      },
      web: null,
    },
    contact_us: {
      title: {
        mobile: "Do you need to contact us?",
        web: "Need to contact us?",
      },
      card: {
        call_us: {
          title: { mobile: "Call us", web: "Call us" },
          text: {
            mobile: "Phone: +34 654 939 705",
            web: "Phone: +34 654 939 705",
          },
        },
        write_us: {
          title: { mobile: "Write us", web: "Write us" },
          text: {
            mobile: "Email: sales@eloquence.es",
            web: "Email: sales@eloquence.es",
          },
        },
      },
      buttons: {
        back_home: {
          label: { mobile: "Back to home", web: null },
        },
      },
    },
  },
  contact_us: {
    header: {
      title: {
        mobile: "A team ready to help you",
        web: "A team ready to help you",
      },
    },
    form: {
      title: {
        mobile: "Tell us how we can help",
        web: "Tell us how we can help",
      },
      subtitle: { mobile: "Fill in your details", web: "Fill in your details" },
      card: {
        mobile: {
          inputs: {
            name: { label: "Name", placeholder: "Mary Lyras" },
            email: { label: "Email", placeholder: "mary@company.com" },
            phone: { label: "Phone", placeholder: "+33 654939705" },
            city: { label: "City", placeholder: "Where your event happens?" },
            number_hostess: {
              label: "Hostess Nº",
              placeholder: "?",
            },
            message: {
              label: "Message",
              placeholder: "Tell us a bit more about the project",
            },
          },
          checkboxs: {
            privacy: { label: "I accept the ", link: "privacy polices" },
          },
          buttons: {
            send: "Send",
          },
        },
        web: {
          inputs: {
            name: { label: "Name", placeholder: "Mary Lyras" },
            email: { label: "Email", placeholder: "mary@company.com" },
            phone: { label: "Phone", placeholder: "+33 654939705" },
            city: { label: "City", placeholder: "Where your event happens?" },
            number_hostess: {
              label: "Hostess Nº",
              placeholder: "?",
            },
            message: {
              label: "Message",
              placeholder: "Tell us a bit more about the project",
            },
          },
          checkboxs: {
            privacy: { label: "I accept the ", link: "privacy polices" },
          },
          buttons: {
            send: "Send",
          },
        },
      },
    },
    contact_us: {
      card: {
        call_us: {
          title: { mobile: "Call us", web: "Call us" },
          text: {
            mobile: "Phone: +34 654 939 705",
            web: "Phone: +34 654 939 705",
          },
        },
        write_us: {
          title: { mobile: "Write us", web: "Write us" },
          text: {
            mobile: "Email: sales@eloquence.es",
            web: "Email: sales@eloquence.es",
          },
        },
        our_offices: {
          title: { mobile: null, web: "Our offices" },
          madrid: {
            mobile: null,
            web: "Calle de Núñez de Balboa, 120<br/>28806, Madrid, España.",
          },
          barcelona: {
            mobile: null,
            web: "Carrer Calàbria 149<br/>08015, Barcelona, España.",
          },
        },
        button: {
          work_with_us: { mobile: "Work with us", web: "Work with us" },
        },
      },
    },
    where_we_are: {
      title: { mobile: "Where we are", web: "Where we are" },
      madrid: {
        title: { mobile: "Madrid", web: "Madrid" },
        text: {
          mobile: "Calle de Núñez de Balboa, 120<br/>Cink Emprende",
          web: "Calle de Núñez de Balboa, 120<br/>Cink Emprende",
        },
      },
      barcelona: {
        title: { mobile: "Barcelona", web: "Barcelona" },
        text: {
          mobile: "Carrer Calàbria 149<br/>The Office Coworking",
          web: "Carrer Calàbria 149<br/>The Office Coworking",
        },
      },
    },
    instagram: {
      title: {
        mobile: "Follow us in instagram",
        web: "Follow us in instagram",
      },
    },
    budget: {
      button: {
        request_budget: {
          mobile: "Request budget",
          web: null,
        },
      },
    },
  },
  home: {
    header: {
      title: { mobile: "Hire hostesses in 24h", web: "Hire hostesses in 24h" },
      form: {
        mobile: null,
        web: {
          title: "Do you need a budget?",
          subtitle: "Drop in your detauls and we will help you",
          inputs: {
            email: { label: "Email", placeholder: "email@company.com" },
            phone: { label: "Phone", placeholder: "+33 654939705" },
            number_hostess: { label: "Hostess Nº", placeholder: "?" },
            details: {
              label: "Details",
              placeholder: "When and where is the event happening?",
            },
          },
          checkboxs: {
            privacy: { label: "I accept the ", link: "privacy polices" },
          },
          buttons: {
            send: "Send",
          },
        },
      },
    },
    budget: {
      mobile: {
        title: "Do you need a budget?",
        subtitle: "Let us know what do you need",
        budget: {
          buttons: {
            request_budget: "Request budget",
          },
        },
      },
      web: null,
    },
    type_hostess: {
      title: {
        mobile: "",
        web: "Choose the type of hostess",
      },
      subtitle: {
        mobile: "We offer a fast, economic and quality service",
        web: "We offer a fast, economic and quality service",
      },
      cards: {
        congress: { title: { mobile: "Congress", web: "Congress" } },
        stands: { title: { mobile: "Stands", web: "Stands" } },
        promotion: { title: { mobile: "Promotion", web: "Promotion" } },
        model: { title: { mobile: "Model", web: "Model" } },
      },
    },
    guarantee: {
      title: {
        mobile: "Eloquence gives<br/>you guarantee",
        web: "Eloquence gives you guarantee",
      },
      cards: {
        quick: {
          title: { mobile: "Quick", web: "Quick" },
          text: {
            mobile: "In about 24h you can have your hostesses",
            web: "In about 24h you can have your hostesses",
          },
        },
        support: {
          title: { mobile: "Support", web: "Support" },
          text: {
            mobile: "A team behind your project to help you",
            web: "A team behind your project to help you",
          },
        },
        trust: {
          title: { mobile: "Trust", web: "Trust" },
          text: {
            mobile: "We love success check our reviews",
            web: "We love success check our reviews",
          },
        },
      },
    },
    result: {
      title: {
        mobile: "The result matters to us",
        web: "The result matters to us",
      },
      subtitle: {
        mobile: "What they say about his experience",
        web: "What they say about his experience",
      },
      cards: {
        philipp: {
          text: {
            mobile:
              "We are very happy. When our hostess got sick they went above and beyond in finding a replacement within hours and made sure had spanish at the booth at all times.",
            web:
              "We are very happy. When our hostess got sick they went above and beyond in finding a replacement within hours and made sure had spanish at the booth at all times.",
          },
          name: {
            mobile: "Philipp Zeiske, Bisutex",
            web: "Philipp Zeiske, Bisutex",
          },
        },
        sara: {
          text: {
            mobile:
              "Top agency and professionals, they look after their hostesses. They make every project more pleasant and fun. I can say they have nothing to envy to whichever agency.",
            web:
              "Top agency and professionals, they look after their hostesses. They make every project more pleasant and fun. I can say they have nothing to envy to whichever agency.",
          },
          name: {
            mobile: "Sara P, Hostess",
            web: "Sara P, Hostess",
          },
        },
        wendy: {
          text: {
            mobile:
              "The hostess quickly understood her role and responded to our clients, partners and staff, as if she were an employee of our company. Thank you!",
            web:
              "The hostess quickly understood her role and responded to our clients, partners and staff, as if she were an employee of our company. Thank you!",
          },
          name: {
            mobile: "Wendi W, Mobile World Congress",
            web: "Wendi W, Mobile World Congress",
          },
        },
      },
    },
    contact_us: {
      title: {
        mobile: "Want to know more?",
        web: "Want to know more?",
      },
      subtitle: {
        mobile: "Contact us, we are happy to help.",
        web: "Contact us, we are happy to help.",
      },
      phone: {
        mobile: "Phone: +34 654 939 705",
        web: "Phone: +34 654 939 705",
      },
      email: {
        mobile: "Email: sales@eloquence.es",
        web: "Email: sales@eloquence.es",
      },
      buttons: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
    work_with_us: {
      title: {
        mobile: "Work with us",
        web: "Work with us",
      },
      subtitle: {
        mobile: "Fill our quick form",
        web: "Fill our quick form",
      },
      buttons: {
        join: {
          mobile: "Join the Eloquence Team",
          web: "Join the Eloquence Team",
        },
      },
      text: {
        mobile: "or email hostess@eloquence.es",
        web: "or email hostess@eloquence.es",
      },
    },
  },
  payment: {
    header: null,
    payment_gateway: {
      title: {
        mobile: "Bienvenido a la pasarela de pago",
        web: "Bienvenido a la pasarela de pago",
      },
      card: {
        title: {
          mobile: "Rellena los siguientes campos",
          web: "Rellena los siguientes campos",
        },
        subtitle: {
          mobile: "Encontrarás los datos en tu factura",
          web: "Encontrarás los datos en tu factura",
        },
        form: {
          inputs: {
            company: {
              label: "Empresa",
              placeholder: "Nombre de empresa",
            },
            number_invoice: {
              label: "Nº de factura",
              placeholder: "ELOQ-XXX",
            },
            cuantity: {
              label: "Cantidad",
              placeholder: "Mostrada en la factura",
            },
          },
          checkboxs: {
            privacy: { label: "I accept the ", link: "privacy polices" },
          },
          buttons: {
            send: "Pay",
          },
        },
      },
    },
    some_dude: {
      title: { mobile: "¿Alguna duda?", web: "¿Alguna duda?" },
      subtitle: {
        mobile: "Ponte en contacto con nosotros",
        web: "Ponte en contacto con nosotros",
      },
      phone: {
        mobile: "Telefono +34 654 939 705",
        web: "Telefono +34 654 939 705",
      },
      email: {
        mobile: "Email: sales@eloquencespain.com",
        web: "Email: sales@eloquencespain.com",
      },
    },
  },
  services_hostesses: {
    header: {
      button: {
        request_budget: {
          mobile: "Request budget",
          web: "Request budget",
        },
      },
    },
    hostesses: {
      title: {
        mobile: "Hostesses for your event",
        web: "Hostesses for your event",
      },
      subtitle: {
        mobile: "According to the need of your project",
        web: "According to the need of your project",
      },
      cards: {
        congress_hostesses: {
          title: { mobile: "Congress hostesses", web: "Congress hostesses" },
          text: {
            mobile:
              "They are essential due to their resolutive capacity. They work as a team to solve all the tasks in the event. They work so that the congress is a success.",
            web:
              "They are essential due to their resolutive capacity. They work as a team to solve all the tasks in the event. They work so that the congress is a success.",
          },
        },
        stand_hostesses: {
          title: { mobile: "Stand hostesses", web: "Stand hostesses" },
          text: {
            mobile:
              "They are the first image of the company. They are responsible for receiving and attending the stand attendees. They have real knowledge about the company.",
            web:
              "They are the first image of the company. They are responsible for receiving and attending the stand attendees. They have real knowledge about the company.",
          },
        },
        promoter_hostesses: {
          title: { mobile: "Promoter hostesses", web: "Promoter hostesses" },
          text: {
            mobile:
              "They are located at the point of sale of the brand, with a more commercial profile. They take an essential role in the sale. They are identified as a seller.",
            web:
              "They are located at the point of sale of the brand, with a more commercial profile. They take an essential role in the sale. They are identified as a seller.",
          },
        },
        brand_hostesses: {
          title: { mobile: "Brand hostesses", web: "Brand hostesses" },
          text: {
            mobile:
              "They are the image of the brand, that is why it is very important that their presence looks perfect. They meet specific requirements.",
            web:
              "They are the image of the brand, that is why it is very important that their presence looks perfect. They meet specific requirements.",
          },
        },
      },
      footer: {
        mobile:
          "A trusted hostess agency, in which we care a lot about the success of your event, <b>check our reviews.</b>",
        web:
          "A trusted hostess agency, in which we care a lot about the success of your event, <b>check our reviews.</b>",
      },
    },
    doubt: {
      title: {
        mobile: "If you have any doubt",
        web: "If you want to hire hostesses or have any doubt",
      },
      button: {
        contact_us: {
          mobile: "Contact us",
          web: "Contact us",
        },
      },
    },
    how_we_work: {
      title: { mobile: "How we work", web: "How we work" },
      cards: {
        selection: {
          title: { mobile: "Selection", web: "Selection" },
          text: {
            mobile: "We filter and select the best profiles",
            web: "We filter and select the best profiles",
          },
        },
        planning: {
          title: { mobile: "Planning", web: "Planning" },
          text: {
            mobile: "Detail schedules, tasks and event requirements",
            web: "Detail schedules, tasks and event requirements",
          },
        },
        support: {
          title: { mobile: "Support", web: "Support" },
          text: {
            mobile: "Looking for the best, in continuous contact with you",
            web: "Looking for the best, in continuous contact with you",
          },
        },
      },
      footer: {
        mobile:
          "Hostesses service in <b>Spain</b> for your event, marketing action or project. We guarantee speed, a selection of quality and close support.",
        web:
          "Hostesses service in <b>Spain</b> for your event, marketing action or project. We guarantee speed, a selection of quality and close support.",
      },
    },
    talent: {
      title: {
        mobile: "Fill your event with talent",
        web: "Fill your event with talent",
      },
      subtitle: {
        mobile: "Choose the service you need",
        web: "Choose the service you need with the best professionals",
      },
      cards: {
        hostesses: {
          title: { mobile: "Hostesses", web: "Hostesses" },
        },
        coordinators: {
          title: { mobile: "Coordinators", web: "Coordinators" },
        },
        promoters: {
          title: { mobile: "Promoters", web: "Promoters" },
        },
        cocktails: {
          title: { mobile: "Cocktails", web: "Cocktails" },
        },
      },
    },
    request_budget: {
      button: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
  },
  services_congress_hostesses: {
    header: {
      title: { mobile: "Congress hostesses", web: "Congress hostesses" },
      texts: {
        text_1: {
          mobile:
            "They work as a team so the congress is a success and anticipate to any incident that may occur in the event.",
          web:
            "They work as a team so the congress is a success and anticipate to any incident that may occur in the event.",
        },
        text_2: {
          mobile:
            "A congress is a meeting, in which during one or several days, people from different places present lectures on topics related to their work. The congress hostesses are responsible for participating directly in the organization of these meetings and are responsible for a wide variety of functions. It is mainly responsible for making everything go as established.<br/>The congress hostesses are essential due to their resolving capacity. Are those who are concerned with reducing any incident that may occur in the venue and work as a team to enable the proper functioning of the congress.",
          web:
            "A congress is a meeting, in which during one or several days, people from different places present lectures on topics related to their work. The congress hostesses are responsible for participating directly in the organization of these meetings and are responsible for a wide variety of functions. It is mainly responsible for making everything go as established.<br/>The congress hostesses are essential due to their resolving capacity. Are those who are concerned with reducing any incident that may occur in the venue and work as a team to enable the proper functioning of the congress.",
        },
      },
    },
    hire: {
      title: {
        mobile: "Hire congress hostess",
        web: "If you need congress hostesses",
      },
      buttons: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
    section: {
      text: {
        mobile:
          "They offer attendees all kinds of information about the congress, they facilitate their attention and help, they indicate the way, they prepare the rooms of the speakers, and they accompany the protocol staff in their functions and help in the registration and distribution of the accreditations.<br/><br/>An essential requirement for congress hostesses is knowing English, since Spain is the fifth country in terms of number of international congresses. At Eloquence, all our congress hostesses speak fluent English and have previous training and experience in congress participation. In addition, qualities like sympathy, naturalness, education and discretion, necessary to develop your work in the best possible way, are very important for us.",
        web:
          "They offer attendees all kinds of information about the congress, they facilitate their attention and help, they indicate the way, they prepare the rooms of the speakers, and they accompany the protocol staff in their functions and help in the registration and distribution of the accreditations.<br/><br/>An essential requirement for congress hostesses is knowing English, since Spain is the fifth country in terms of number of international congresses. At Eloquence, all our congress hostesses speak fluent English and have previous training and experience in congress participation. In addition, qualities like sympathy, naturalness, education and discretion, necessary to develop your work in the best possible way, are very important for us.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stand", web: "Stand" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
  },
  services_stand_hostesses: {
    header: {
      title: { mobile: "Stand hostesses", web: "Stand hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are responsible for attending the stand assistants. They have real knowledge about the company and its product.",
          web:
            "They are responsible for attending the stand assistants. They have real knowledge about the company and its product.",
        },
        text_2: {
          mobile:
            "There are many companies that attend trade fairs in our country. In them, the company has the possibility of hiring a stand in which to offer its products and services. The figure of the fair stewardess is necessary to carry out some important tasks within the stand and ensure the proper functioning of this.",
          web:
            "There are many companies that attend trade fairs in our country. In them, the company has the possibility of hiring a stand in which to offer its products and services. The figure of the fair stewardess is necessary to carry out some important tasks within the stand and ensure the proper functioning of this.",
        },
      },
    },
    hire: {
      title: { mobile: "Hire stand hostess", web: "Hire stand hostess" },
      buttons: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
    section: {
      text: {
        mobile:
          "Sometimes they will be sales fairs, where the hostesses can take charge of the task of selling. Its role as a fair hostess is to attract customers and familiarize them with the company they work for.<br/>We do everything possible to train our fair hostesses in the most functional aspects of the job. But above all, we value the knowledge of other languages to facilitate communication with foreign assistants, especially English, so all our fair hostesses dominate this language.",
        web:
          "Sometimes they will be sales fairs, where the hostesses can take charge of the task of selling. Its role as a fair hostess is to attract customers and familiarize them with the company they work for.<br/>We do everything possible to train our fair hostesses in the most functional aspects of the job. But above all, we value the knowledge of other languages to facilitate communication with foreign assistants, especially English, so all our fair hostesses dominate this language.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "According to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stand", web: "Stand" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
  },
  services_promoter_hostesses: {
    header: {
      title: { mobile: "Promoter hostesses", web: "Promoter hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are located at the point of sale of the brand, with a more commercial profile. Take an essential role in the sale or service.",
          web:
            "They are located at the point of sale of the brand, with a more commercial profile. Take an essential role in the sale or service.",
        },
        text_2: {
          mobile:
            "In the sales channel of a product, an essential part of the process is promotion. To guarantee the sale of this product there are different types of promotion that in some occasions will need the help of one or several promoter hostesses.",
          web:
            "In the sales channel of a product, an essential part of the process is promotion. To guarantee the sale of this product there are different types of promotion that in some occasions will need the help of one or several promoter hostesses.",
        },
      },
    },
    hire: {
      title: { mobile: "Hire promoter hostess", web: "Hire promoter hostess" },
      buttons: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
    section: {
      text: {
        mobile:
          "They will try to attract as many potential customers as possible, bringing the product closer to them to get their interest. They act as a link between the brand and potential buyers; They are responsible for getting the product to the customer, resolve any questions that they may have about the product or brand.<br/>The delivery of advertising, the delivery of samples or the promotion at the point of sale.<br/>The promoter hostesses who are part of the Eloquence team, in addition to experience, training and English level have communication skills that allow them to be sociable and eloquent to connect the target with the brand.",
        web:
          "They will try to attract as many potential customers as possible, bringing the product closer to them to get their interest. They act as a link between the brand and potential buyers; They are responsible for getting the product to the customer, resolve any questions that they may have about the product or brand.<br/>The delivery of advertising, the delivery of samples or the promotion at the point of sale.<br/>The promoter hostesses who are part of the Eloquence team, in addition to experience, training and English level have communication skills that allow them to be sociable and eloquent to connect the target with the brand.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "According to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stand", web: "Stand" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
  },
  services_brand_hostesses: {
    header: {
      title: { mobile: "Brand hostesses", web: "Brand hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are the representation of the brand, it is important that their presence and image are well taken care of.",
          web:
            "They are the representation of the brand, it is important that their presence and image are well taken care of.",
        },
        text_2: {
          mobile:
            "The image hostesses are the visible face of a brand in the event. We could say that the image stewardess profile stands out for its specific physical characteristics. They are responsible for promoting the image of a brand, which is why it is very important that their presence and image are well taken care of.",
          web:
            "The image hostesses are the visible face of a brand in the event. We could say that the image stewardess profile stands out for its specific physical characteristics. They are responsible for promoting the image of a brand, which is why it is very important that their presence and image are well taken care of.",
        },
      },
    },
    hire: {
      title: { mobile: "Hire brand hostess", web: "Hire brand hostess" },
      buttons: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
    section: {
      text: {
        mobile:
          "At Eloquence we want to contribute more than just an image. We believe that beyond a specific physical characteristics a good image stewardess must possess other qualities such as: a positive attitude when working, sympathy, naturalness, an appropriate education, a good command of English and even knowledge of protocol",
        web:
          "At Eloquence we want to contribute more than just an image. We believe that beyond a specific physical characteristics a good image stewardess must possess other qualities such as: a positive attitude when working, sympathy, naturalness, an appropriate education, a good command of English and even knowledge of protocol",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "According to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stand", web: "Stand" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
  },
  work_with_us: {
    skills: {
      buttons: {
        work: {
          mobile: "Enjoy while you work",
          web: "Enjoy while you work",
        },
      },
      title: {
        mobile: "Eloquence skills",
        web: "Eloquence skills",
      },
      cards: {
        presence: { text: { mobile: "Good presence", web: "Good presence" } },
        english: { text: { mobile: "English B2", web: "English B2" } },
        attitude: {
          text: { mobile: "Proactive attitude", web: "Proactive attitude" },
        },
      },
    },
    team: {
      title: {
        mobile: "Join the Eloquence team",
        web: "Join the Eloquence team",
      },
      subtitle: {
        mobile: "Fill in all the fields *",
        web: "Fill in all the fields *",
      },
      card: {
        form: {
          inputs: {
            name: { label: "Complete name", placeholder: "Name Surname" },
            email: { label: "Email", placeholder: "email@email.com" },
            phone: { label: "Phone number", placeholder: "+33653335689" },
            city: {
              label: "City of work",
              placeholder: "Where do you like to work?",
            },
            nacionality: {
              label: "Nacionality",
              placeholder: "Española, francesa",
            },
            date: {
              label: "Year or birth",
              placeholder: "22/05/1996",
              ok_label: "Aceptar",
              cancel_label: "Cancelar",
              wrong_date: "Fecha incorrecta",
            },
            photos: { label: "Add at least 2 photos", button: "Add images" },
          },
          selects: {
            gender: {
              label: "Gender",
              placeholder: "Choose",
              items: [
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ],
            },
            dress_size: {
              label: "Dress size (woman) or shirt size (man)",
              placeholder: "Choose",
              items: [
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ],
            },
            shoe_size: {
              label: "Shoe size",
              placeholder: "Choose",
              items: [
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ],
            },
            english_level: {
              label: "English level",
              placeholder: "Choose",
              items: [
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ],
            },
            extra_languages: {
              label: "Extra languages",
              placeholder: "Choose",
              items: [
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ],
            },
          },
          checkboxs: {
            privacy: { label: "I accept the ", link: "privacy polices" },
          },
          buttons: {
            send: "Send",
          },
        },
      },
    },
    our_philosophy: {
      title: {
        mobile: "Our philosophy",
        web: "Our philosophy",
      },
      text: {
        mobile:
          "Work as a hostess in a professional and entertaining environment. We value so much the work of our hostesses, they are our image and we want them to feel comfortable and enjoying the unique experience of each event. <br/><br/>Check out the opinion of the people who have worked with us: <b>see opinions</b>.<br/><br/>If you have any questions, write to:hostess@eloquence.es",
        web:
          "Work as a hostess in a professional and entertaining environment. We value so much the work of our hostesses, they are our image and we want them to feel comfortable and enjoying the unique experience of each event. <br/><br/>Check out the opinion of the people who have worked with us: <b>see opinions</b>.<br/><br/>If you have any questions, write to:hostess@eloquence.es",
      },
    },
  },
  navbar: {
    items: {
      services: "Services",
      hostesses: "Hostesses",
      congress_hostesses: "Congress hostesses",
      stands_hostesses: "Stands hostesses",
      promotion_hostesses: "Promotion hostesses",
      brand_hostesses: "Brand hostesses",
      about_us: "About us",
      work_with_us: "Work with us",
      request_budget: "Request budget",
      contact_us: "Contact us",
    },
  },
  footer: {
    items: {
      sales: "Events: ",
      hostess: "Work with us: ",
      phone: "Phone: ",
      work_with_us: "Work with us",
      contact_us: "Contact us",
      services: "Services",
      about_us: "About Eloquence",
    },
  },
};

export { translation };
