const translation = {
  assemblers: {
    header: {
      title: {
        mobile: "Montadores para evento",
        web: "Montadores para evento",
      },
      subtitle: {
        mobile: "Montaje y desmontaje de stands.",
        web: "Montaje y desmontaje de stands.",
      },
    },
    assemblers: {
      cards: {
        auxiliars: {
          title: {
            mobile: "Auxiliares de montaje",
            web: "Auxiliares de montaje",
          },
          text: {
            mobile:
              "Realizar la carga, descarga y distribución de todos los materiales del evento.",
            web:
              "Realizar la carga, descarga y distribución de todos los materiales del evento.",
          },
        },
        hand_basic: {
          title: {
            mobile: "Hand Básico",
            web: "Hand Básico",
          },
          text: {
            mobile:
              "Ayudantes en el montaje de cualquier tipo de infraestructura.",
            web:
              "Ayudantes en el montaje de cualquier tipo de infraestructura.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Si necesita montadores para tu evento",
        web: "Si necesita montadores para tu evento",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    keys: {
      title: {
        mobile: "Nuestras claves",
        web: "Nuestras claves",
      },
      cards: {
        teamwork: {
          title: {
            mobile: "Trabajo en equipo",
            web: "Trabajo en equipo",
          },
          text: {
            mobile:
              "Solidaridad con el compañero y combinar especialidades técnicas, hace el mejor de los equipos para un montaje.",
            web:
              "Solidaridad con el compañero y combinar especialidades técnicas, hace el mejor de los equipos para un montaje.",
          },
        },
        experience: {
          title: {
            mobile: "Experiencia",
            web: "Experiencia",
          },
          text: {
            mobile:
              "Importante para montajes complejos y para realizar el trabajo rápido y satisfactoriamente. Adelantandose a imprevistos.",
            web:
              "Importante para montajes complejos y para realizar el trabajo rápido y satisfactoriamente. Adelantandose a imprevistos.",
          },
        },
      },
      footer: {
        mobile: "",
        web:
          "Servicio de montadores en Madrid y Barcelona con cobertura nacional",
      },
    },
    contact_us: {
      title: {
        mobile: "Servicio de montadores en Madrid y Barcelona",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "",
        },
      },
    },
  },
  auxiliars: {
    header: {
      title: {
        mobile: "Personal auxiliar para eventos",
        web: "Personal auxiliar para eventos",
      },
      subtitle: {
        mobile: "Distintos perfiles que necesitan para resolver ciertas tareas",
        web: "Distintos perfiles que necesitan para resolver ciertas tareas",
      },
    },
    auxiliars: {
      cards: {
        drivers: {
          title: {
            mobile: "Conductores",
            web: "Conductores",
          },
          text: {
            mobile:
              "Chofers para facilitar el traslado de los asistentes al evento.",
            web:
              "Chofers para facilitar el traslado de los asistentes al evento.",
          },
        },
        access_personal: {
          title: {
            mobile: "Personal de accesos",
            web: "Personal de accesos",
          },
          text: {
            mobile:
              "Personal de ticketing para festivales, conciertos o grandes eventos",
            web:
              "Personal de ticketing para festivales, conciertos o grandes eventos",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Si necesita auxiliares para tu evento",
        web: "Si necesita auxiliares para tu evento",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    keys: {
      title: {
        mobile: "Nuestras claves",
        web: "Nuestras claves",
      },
      cards: {
        experience: {
          title: {
            mobile: "Experiencia",
            web: "Experiencia",
          },
          text: {
            mobile:
              "Chofers para facilitar el traslado de los asistentes al evento. Del aeropuerto al hotel, y del hotel al evento.",
            web:
              "Chofers para facilitar el traslado de los asistentes al evento. Del aeropuerto al hotel, y del hotel al evento.",
          },
        },
        attitude: {
          title: {
            mobile: "Actitud",
            web: "Actitud",
          },
          text: {
            mobile:
              "Ser profesional y serio es algo que no se puede dar por hecho. Valoramos la actitud de nuestros perfiles en base a la experiencia.",
            web:
              "Ser profesional y serio es algo que no se puede dar por hecho. Valoramos la actitud de nuestros perfiles en base a la experiencia.",
          },
        },
      },
      footer: {
        mobile: "",
        web:
          "Servicio de auxiliares en Madrid y Barcelona con cobertura nacional",
      },
    },
    contact_us: {
      title: {
        mobile: "Servicio de auxiliares en Madrid y Barcelona",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "",
        },
      },
    },
  },
  waiters: {
    header: {
      title: {
        mobile: "Camareros para eventos",
        web: "Camareros para eventos",
      },
      subtitle: {
        mobile: "Soluciones para tu evento con catering o bebidas",
        web: "Soluciones para tu evento con catering o bebidas",
      },
    },
    waiters: {
      cards: {
        waiters: {
          title: {
            mobile: "Camareros",
            web: "Camareros",
          },
          text: {
            mobile:
              "Personal experimentado y correctamente uniformado para atender.",
            web:
              "Personal experimentado y correctamente uniformado para atender.",
          },
        },
        cocktail_shakers: {
          title: {
            mobile: "Cocteleros",
            web: "Cocteleros",
          },
          text: {
            mobile: "Expertos en coctelería, precisión e imagen.",
            web: "Expertos en coctelería, precisión e imagen.",
          },
        },
      },
    },
    contract: {
      title: {
        mobile: "Si necesita camareros para tu evento",
        web: "Si necesita camareros para tu evento",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    keys: {
      title: {
        mobile: "Nuestras claves",
        web: "Nuestras claves",
      },
      cards: {
        experience: {
          title: {
            mobile: "Experiencia",
            web: "Experiencia",
          },
          text: {
            mobile:
              "Saber llevar bandeja o hacer un Daiquirí no se aprende el primer día. La experiencia es un grado aquí.",
            web:
              "Saber llevar bandeja o hacer un Daiquirí no se aprende el primer día. La experiencia es un grado aquí.",
          },
        },
        uniformity: {
          title: {
            mobile: "Uniformidad",
            web: "Uniformidad",
          },
          text: {
            mobile:
              "Todo el equipo a una. Mismo uniforme para todos y cada uno de las personas que trabajen en el evento.",
            web:
              "Todo el equipo a una. Mismo uniforme para todos y cada uno de las personas que trabajen en el evento.",
          },
        },
      },
      footer: {
        mobile: "",
        web:
          "Servicio de camareros en Madrid y Barcelona con cobertura nacional",
      },
    },
    contact_us: {
      title: {
        mobile: "Servicio de camareros en Madrid y Barcelona",
        web: "",
      },
      buttons: {
        contact_us: {
          mobile: "Contáctanos",
          web: "",
        },
      },
    },
  },
  about_us: {
    header: {
      title: {
        mobile: "Eloquence",
        web: "Eloquence",
      },
      texts: {
        info: {
          mobile:
            "Somos una agencia de personal de eventos de confianza, ponemos todo nuestro esfuerzo	para que tu evento sea un éxito.",
          web:
            "Somos una agencia de personal de eventos de confianza, ponemos todo nuestro esfuerzo	para que tu evento sea un éxito.",
        },
      },
      buttons: {
        contact_us: {
          mobile: null,
          web: "Contáctanos",
        },
      },
    },
    our_values: {
      title: { mobile: "Nuestros valores", web: "Nuestros valores" },
      cards: {
        support: {
          title: { mobile: "Excelencia", web: "Excelencia" },
          text: {
            mobile:
              "Un servicio 5 estrellas, el feedback de nuestros clientes nos importa.",
            web:
              "Un servicio 5 estrellas, el feedback de nuestros clientes nos importa.",
          },
        },
        excellency: {
          title: { mobile: "Resolutivos", web: "Resolutivos" },
          text: {
            mobile:
              "Somos jóvenes y rápidos.</br>Dando soluciones que surgen en el día a día.",
            web:
              "Somos jóvenes y rápidos.</br>Dando soluciones que surgen en el día a día.",
          },
        },
        decisives: {
          title: { mobile: "Trato", web: "Trato" },
          text: {
            mobile:
              "Cuidamos por igual de nuestros clientes y de nuestro personal.",
            web:
              "Cuidamos por igual de nuestros clientes y de nuestro personal.",
          },
        },
      },
    },
    our_history: {
      title: { mobile: "Nuestra historia", web: "Nuestra historia" },
      text: {
        mobile:
          "Eloquence nace en Septiembre del 2016 enfocada a proveer azafatas a empresas internacionales en sus eventos en Madrid.<br/><br/>Queríamos atender y ayudar a todas esas empresas que visitan eventos, ferias y congresos en nuestro país cada año. <br/><br/>Nuestra idea ha sido siempre la de potenciar el uso del inglés en el sector, cuidando por igual a nuestros clientes como a nuestras azafatos. En poco tiempo logramos posicionarnos como una de las agencias de referencia para empresas extranjeras en España, así como una de las mejor valoradas tanto por clientes como por sus azafatos.<br/><br/>En el 2019 abrimos oficinas en Barcelona y en el 2020 evolucionamos para convertirnos en agencia de personal de eventos.<br/><br/>Nuestro futuro está ligado a la digitalización, ¿te vienes con nosotros?",
        web:
          "Eloquence nace en Septiembre del 2016 enfocada a proveer azafatas a empresas internacionales en sus eventos en Madrid.<br/><br/>Queríamos atender y ayudar a todas esas empresas que visitan eventos, ferias y congresos en nuestro país cada año. <br/><br/>Nuestra idea ha sido siempre la de potenciar el uso del inglés en el sector, cuidando por igual a nuestros clientes como a nuestras azafatos. En poco tiempo logramos posicionarnos como una de las agencias de referencia para empresas extranjeras en España, así como una de las mejor valoradas tanto por clientes como por sus azafatos.<br/><br/>En el 2019 abrimos oficinas en Barcelona y en el 2020 evolucionamos para convertirnos en agencia de personal de eventos.<br/><br/>Nuestro futuro está ligado a la digitalización, ¿te vienes con nosotros?",
      },
    },
    success_stories: {
      title: { mobile: "Casos de éxito", web: "Casos de éxito" },
      subtitle: {
        mobile: "UEFA Champions League",
        web: "UEFA Champions League",
      },
      text: {
        mobile:
          "Eloquence fue elegida como la agencia de personal proveedora oficial de la UEFA para la captación, selección, formación y puesta en escena de un total de 94 azafatos de imagen.<br/><br/>La UEFA preparó un lounge gigante donde se dieron cita todos los patrocinadores de la competición, algunos como Mastercard, Heineken o Adidas. El recinto acogió a más de 3.000 invitados durante las 8 horas que permaneció abierto a los asistentes.<br><br/>Nuestro objetivo era el de ofrecer un servicio excelente al visitante, así como también el de lograr la perdurabilidad de los recuerdos en su memoria. Siempre con una sonrisa de oreja a oreja, deseamos suerte a ambas aficiones antes del partido, y felicitamos y consolamos a ganadores y vencidos después del mismo.<br><br/>El evento fue un auténtico éxito a nivel organización del personal, obteniendo la felicitación expresa de la mayoría de los sponsors.",
        web:
          "Eloquence fue elegida como la agencia de personal proveedora oficial de la UEFA para la captación, selección, formación y puesta en escena de un total de 94 azafatos de imagen.<br/><br/>La UEFA preparó un lounge gigante donde se dieron cita todos los patrocinadores de la competición, algunos como Mastercard, Heineken o Adidas. El recinto acogió a más de 3.000 invitados durante las 8 horas que permaneció abierto a los asistentes.<br><br/>Nuestro objetivo era el de ofrecer un servicio excelente al visitante, así como también el de lograr la perdurabilidad de los recuerdos en su memoria. Siempre con una sonrisa de oreja a oreja, deseamos suerte a ambas aficiones antes del partido, y felicitamos y consolamos a ganadores y vencidos después del mismo.<br><br/>El evento fue un auténtico éxito a nivel organización del personal, obteniendo la felicitación expresa de la mayoría de los sponsors.",
      },
    },
    budget: {
      buttons: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
  },
  budget: {
    header: {
      title: {
        mobile: "¿Necesitas presupuesto?",
        web: "¿Necesitas presupuesto?",
      },
      card: {
        title: {
          mobile: null,
          web: "Cómo podemos ayudarte",
        },
        subtitle: {
          mobile: null,
          web: "Rellena el formulario y te contactaremos en 24h",
        },
        form: {
          mobile: null,
          web: {
            inputs: {
              name: { label: "Nombre", placeholder: "Patricia Díaz" },
              email: { label: "Email", placeholder: "patridiaz@company.com" },
              phone: { label: "Teléfono", placeholder: "+33654939705" },
              city: {
                label: "¿Dónde sucede el evento?",
                placeholder: "Madrid, Barcelona, Bilbao...",
              },
              number_hostess: {
                label: "Número de personas",
                placeholder: "?",
              },
              message: {
                label: "Cuéntanos un poco mas sobre el proyecto",
                placeholder: "Fechas, lugar, marca involucrada, etc.",
              },
            },
            selects: {
              profile_type: {
                label: "Tipo de perfil",
                placeholder: "Tipo de perfil",
                width: 122,
                items: [
                  { value: "azafatas", label: "Azafatas" },
                  { value: "montadores", label: "Montadores" },
                  { value: "auxiliares", label: "Auxiliares" },
                  { value: "camareros", label: "Camareros" },
                ],
              },
            },
            checkboxs: {
              privacy: {
                label: "Yo acepto ",
                link: "las políticas de privacidad",
              },
            },
            buttons: {
              send: "Enviar",
            },
          },
        },
      },
    },
    subtitle: { mobile: "Cuéntanos los detalles del evento", web: null },
    form: {
      mobile: {
        inputs: {
          name: { label: "Nombre", placeholder: "Patricia Díaz" },
          email: { label: "Email", placeholder: "patridiaz@tuempresa.com" },
          phone: { label: "Teléfono", placeholder: "+34654939705" },
          city: { label: "Ciudad del evento", placeholder: "¿Dónde ocurre?" },
          number_hostess: {
            label: "Nº de personas",
            placeholder: "?",
          },
          message: {
            label: "Detalles",
            placeholder: "Cuéntanos mas acerca del evento, ¿cuándo es?",
          },
        },
        checkboxs: {
          privacy: { label: "Yo acepto ", link: "las políticas de privacidad" },
        },
        buttons: {
          send: "Enviar",
        },
      },
      web: null,
    },
    contact_us: {
      title: {
        mobile: "¿Necesitas contactarnos?",
        web: "¿Necesitas contactarnos?",
      },
      card: {
        call_us: {
          title: { mobile: "Llámanos", web: "Llámanos" },
          text: {
            mobile: "Teléfono: +34 654 939 705",
            web: "Teléfono: +34 654 939 705",
          },
        },
        write_us: {
          title: { mobile: "Escríbenos", web: "Escríbenos" },
          text: {
            mobile: "Email: sales@eloquence.es",
            web: "Email: sales@eloquence.es",
          },
        },
      },
      buttons: {
        back_home: {
          label: { mobile: "Ir a portada", web: null },
        },
      },
    },
  },
  contact_us: {
    header: {
      title: {
        mobile: "Un equipo preparado para ayudarte",
        web: "Un equipo preparado para ayudarte",
      },
    },
    form: {
      title: {
        mobile: "¿Te podemos ayudar?",
        web: null,
      },
      subtitle: {
        mobile: "Te daremos respuesta en menos de 24h",
        web: null,
      },
      card: {
        mobile: {
          inputs: {
            name: { label: "Nombre", placeholder: "Patricia Díaz" },
            email: { label: "Email", placeholder: "patridiaz@company.com" },
            phone: { label: "Teléfono", placeholder: "+34 654939705" },
            city: {
              label: "Ciudad del evento",
              placeholder: "¿Dónde ocurre tu evento?",
            },
            number_hostess: {
              label: "Número de personas que necesitas",
              placeholder: "?",
            },
            message: {
              label: "Cuéntanos más acerca del evento",
              placeholder: "¿Cuándo es?",
            },
          },
          checkboxs: {
            privacy: {
              label: "Yo acepto ",
              link: "las políticas de privacidad",
            },
          },
          buttons: {
            send: "Enviar",
          },
        },
        web: {
          title: "¿Te podemos ayudar?",
          subtitle: "¡Dinos como! Te daremos respuesta en 24h",
          inputs: {
            name: { label: "Nombre completo", placeholder: "Patricia Díaz" },
            email: { label: "Email", placeholder: "patridiaz@company.com" },
            phone: { label: "Teléfono", placeholder: "+34654939705" },
            city: { label: "Ciudad del evento", placeholder: "¿Dónde ocurre?" },
            number_hostess: {
              label: "Nº de personas que necesitas",
              placeholder: "?",
            },
            message: {
              label: "Cuéntanos un poco más sobre tu necesidad",
              placeholder: "Coméntanos cualquier duda",
            },
          },
          checkboxs: {
            privacy: {
              label: "Yo acepto ",
              link: "las políticas de privacidad",
            },
          },
          buttons: {
            send: "Enviar",
          },
        },
      },
    },
    contact_us: {
      card: {
        call_us: {
          title: { mobile: "Llámanos", web: "Llámanos" },
          text: {
            mobile: "Teléfono: +34 654 939 705",
            web: "Teléfono: +34 654 939 705",
          },
        },
        write_us: {
          title: { mobile: "Escríbenos", web: "Escríbenos" },
          text: {
            mobile: "Email: sales@eloquence.es",
            web: "Email: sales@eloquence.es",
          },
        },
        our_offices: {
          title: { mobile: null, web: "Nuestras oficinas" },
          madrid: {
            mobile: null,
            web: "Calle de Núñez de Balboa, 120<br/>28806, Madrid, España.",
          },
          barcelona: {
            mobile: null,
            web: "Carrer Calàbria 149<br/>08015, Barcelona, España.",
          },
        },
        button: {
          work_with_us: {
            mobile: "Trabaja con nosotros",
            web: "Trabaja con nosotros",
          },
        },
      },
    },
    where_we_are: {
      title: { mobile: "Donde estamos", web: "Donde estamos" },
      madrid: {
        title: { mobile: "Madrid", web: "Madrid" },
        text: {
          mobile: "Calle de Núñez de Balboa, 120<br/>Cink Emprende",
          web: "Calle de Núñez de Balboa, 120<br/>Cink Emprende",
        },
      },
      barcelona: {
        title: { mobile: "Barcelona", web: "Barcelona" },
        text: {
          mobile: "Carrer Calàbria 149<br/>The Office Coworking",
          web: "Carrer Calàbria 149<br/>The Office Coworking",
        },
      },
    },
    instagram: {
      title: { mobile: "Síguenos en instagram", web: "Síguenos en instagram" },
    },
    budget: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: null,
        },
      },
    },
  },
  home: {
    header: {
      title: {
        mobile: "Contrata personal de evento",
        web: "Contrata personal<br/>para tu evento",
      },
      form: {
        mobile: null,
        web: {
          title: "¿Necesitas personal de eventos?",
          subtitle: "Cuéntanos y te ayudamos",
          inputs: {
            email: { label: "Email", placeholder: "email@tuempresa.com" },
            phone: {
              label: "Teléfono de contacto",
              placeholder: "+34654939705",
            },
            number_hostess: { label: "Número de personas", placeholder: "?" },
            details: {
              label: "Detalles",
              placeholder: "¿Cuándo y dónde sucede el evento?",
            },
          },
          selects: {
            profile_type: {
              label: "Tipo de perfil",
              placeholder: "Tipo de perfil",
              width: 122,
              items: [
                { value: "azafatas", label: "Azafatas" },
                { value: "montadores", label: "Montadores" },
                { value: "auxiliares", label: "Auxiliares" },
                { value: "camareros", label: "Camareros" },
              ],
            },
          },
          checkboxs: {
            privacy: {
              label: "Yo acepto ",
              link: "las políticas de privacidad ",
            },
          },
          buttons: {
            send: "Enviar",
          },
        },
      },
    },
    budget: {
      mobile: {
        title: "¿Necesitas presupuesto?",
        subtitle: "Cuéntanos lo que necesitas",
        budget: {
          buttons: {
            request_budget: "Pedir presupuesto",
          },
        },
      },
      web: null,
    },
    type_profile: {
      title: {
        mobile: "",
        web: "Elige el tipo de perfil",
      },
      subtitle: {
        mobile: "Diferentes tipos de perfiles para tu evento",
        web:
          "Ponemos a tu disposición diferentes tipos de perfiles para tu evento",
      },
      cards: {
        hostesses: { title: { mobile: "Azafatas", web: "Azafatas" } },
        assemblers: { title: { mobile: "Montadores", web: "Montadores" } },
        auxiliars: { title: { mobile: "Auxiliares", web: "Auxiliares" } },
        waiters: { title: { mobile: "Camareros", web: "Camareros" } },
      },
    },
    guarantee: {
      title: {
        mobile: "¿Cómo lo hacemos?",
        web: "¿Cómo lo hacemos?",
      },
      cards: {
        quick: {
          title: { mobile: "Digital", web: "Digital" },
          text: {
            mobile: "Gestionando clientes y personal digitalmente.",
            web: "Gestionando clientes y personal digitalmente.",
          },
        },
        support: {
          title: { mobile: "Soporte", web: "Soporte" },
          text: {
            mobile: "Cerca de nuestros clientes y cuidando a nuestro personal.",
            web: "Cerca de nuestros clientes y cuidando de nuestro personal.",
          },
        },
        trust: {
          title: { mobile: "Calidad", web: "Calidad" },
          text: {
            mobile: "Filosofía de 5 estrellas, el evento debe salir perfecto.",
            web: "Filosofía de 5 estrellas, el evento debe salir perfecto.",
          },
        },
      },
    },
    result: {
      title: {
        mobile: "El resultado nos importa",
        web: "El resultado nos importa",
      },
      subtitle: {
        mobile: "Lo que dicen quienes trabajan con nosotros",
        web: "Lo que dicen quienes trabajan con nosotros",
      },
      cards: {
        philipp: {
          text: {
            mobile:
              "Muy satisfechos con el servicio prestado. Incluso cuando nuestra azafata se puso mala, Eloquence se volcó en la búsqueda de un reemplazo y se aseguró que siempre hubiera personal en nuestro stand.",
            web:
              "Muy satisfechos con el servicio prestado. Incluso cuando nuestra azafata se puso mala, Eloquence se volcó en la búsqueda de un reemplazo y se aseguró que siempre hubiera personal en nuestro stand.",
          },
          name: {
            mobile: "Philipp Zeiske, Bisutex",
            web: "Philipp Zeiske, Bisutex",
          },
        },
        sara: {
          text: {
            mobile:
              "Agencia de azafatas de 10. Profesionales y muy atentos con sus azafatas, cada trabajo es ameno. En 7 años de azafata puedo decir que no tienen nada que envidiar a las agencias más antiguas.",
            web:
              "Agencia de azafatas de 10. Profesionales y muy atentos con sus azafatas, cada trabajo es ameno. En 7 años de azafata puedo decir que no tienen nada que envidiar a las agencias más antiguas.",
          },
          name: {
            mobile: "Sara P, Azafata",
            web: "Sara P, Azafata",
          },
        },
        wendy: {
          text: {
            mobile:
              "La azafata comprendió rápidamente su papel y respondió a nuestros clientes, socios y personal, como si fuera una empleada de nuestra empresa. ¡Gracias!",
            web:
              "La azafata comprendió rápidamente su papel y respondió a nuestros clientes, socios y personal, como si fuera una empleada de nuestra empresa. ¡Gracias!",
          },
          name: {
            mobile: "Wendi W, Mobile World Congress",
            web: "Wendi W, Mobile World Congress",
          },
        },
      },
      buttons: {
        reviews: {
          mobile: "Mira nuestras reviews",
          web: "Mira nuestras reviews",
        },
      },
    },
    contact_us: {
      title: {
        mobile: "¿Quieres saber más?",
        web: "¿Quieres saber más?",
      },
      subtitle: {
        mobile: "Ponte en contacto con nosotros",
        web: "Ponte en contacto con nosotros",
      },
      phone: {
        mobile: "Teléfono: +34 654 939 705",
        web: "Teléfono: +34 654 939 705",
      },
      email: {
        mobile: "Email: sales@eloquence.es",
        web: "Email: sales@eloquence.es",
      },
      buttons: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    work_with_us: {
      title: {
        mobile: "Trabaja con nosotros",
        web: "Trabaja con nosotros",
      },
      subtitle: {
        mobile: "Rellena nuestro formulario",
        web: "Relllena nuestro formulario",
      },
      buttons: {
        join: {
          mobile: "Únete a Eloquence",
          web: "Únete a Eloquence",
        },
      },
      text: {
        mobile: "o escribe a hostess@eloquence.es",
        web: "o escribe hostess@eloquence.es",
      },
    },
  },
  payment: {
    header: null,
    payment_gateway: {
      title: {
        mobile: "Bienvenido a la pasarela de pago",
        web: "Bienvenido a la pasarela de pago",
      },
      card: {
        title: {
          mobile: "Rellena los siguientes campos",
          web: "Rellena los siguientes campos",
        },
        subtitle: {
          mobile: "Encontrarás los datos en tu factura",
          web: "Encontrarás los datos en tu factura",
        },
        form: {
          inputs: {
            company: {
              label: "Empresa",
              placeholder: "Nombre de empresa",
            },
            number_invoice: {
              label: "Nº de factura",
              placeholder: "ELOQ-XXX",
            },
            cuantity: {
              label: "Cantidad",
              placeholder: "Mostrada en la factura",
            },
          },
          checkboxs: {
            privacy: {
              label: "Yo acepto ",
              link: "las políticas de privacidad",
            },
          },
          buttons: {
            send: "Pagar",
          },
        },
      },
    },
    some_dude: {
      title: { mobile: "¿Alguna duda?", web: "¿Alguna duda?" },
      subtitle: {
        mobile: "Ponte en contacto con nosotros",
        web: "Ponte en contacto con nosotros",
      },
      phone: {
        mobile: "Teléfono +34 654 939 705",
        web: "Teléfono +34 654 939 705",
      },
      email: {
        mobile: "Email: sales@eloquencespain.com",
        web: "Email: sales@eloquencespain.com",
      },
    },
  },
  services: {
    header: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    hostesses: {
      title: {
        mobile: "Personal para tu evento",
        web: "Personal para tu evento",
      },
      subtitle: {
        mobile: "Según la necesidad de tu proyecto",
        web: "Según la necesidad de tu proyecto",
      },
      cards: {
        hostesses: {
          title: { mobile: "Azafatas", web: "Azafatas" },
          text: {
            mobile:
              "Para todo tipo de eventos: congresos, ferias, promociones, eventos de empresa etc. Son la imagen y toma de contacto de las marcas a quienes representan. Todas nuestras azafatas <b>hablan Inglés</b>.",
            web:
              "Para todo tipo de eventos: congresos, ferias, promociones, eventos de empresa etc. Son la imagen y toma de contacto de las marcas a quienes representan. Todas nuestras azafatas <b>hablan Inglés</b>.",
          },
        },
        assemblers: {
          title: { mobile: "Montadores", web: "Montadores" },
          text: {
            mobile:
              "Montaje y desmontaje de stands. Carga y descarga de materiales. <b>Trabajan en equipo</b> para que todo salga bien y enfrentarse a cualquier imprevisto.",
            web:
              "Montaje y desmontaje de stands. Carga y descarga de materiales. <b>Trabajan en equipo</b> para que todo salga bien y enfrentarse a cualquier imprevisto.",
          },
        },
        auxiliars: {
          title: { mobile: "Auxiliares", web: "Auxiliares" },
          text: {
            mobile: "El personal necesario que complementa tu evento.",
            web: "El personal necesario que complementa tu evento.",
          },
        },
        waiters: {
          title: { mobile: "Camareros", web: "Camareros" },
          text: {
            mobile:
              "Cocteleros y camareros para darle un toque mixólogo a tu evento.",
            web:
              "Cocteleros y camareros para darle un toque mixólogo a tu evento.",
          },
        },
      },
      footer: {
        mobile:
          "¡Comprueba <b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>nuestras reviews</a></b> como agencia!",
        web:
          "¡Comprueba <b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>nuestras reviews</a></b> como agencia!",
      },
    },
    doubt: {
      title: {
        mobile: "Si tienes alguna duda",
        web: "Si quiere contratar talento o tienes alguna duda",
      },
      button: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    how_we_work: {
      title: { mobile: "Cómo lo hacemos", web: "Cómo lo hacemos" },
      cards: {
        selection: {
          title: { mobile: "Selección", web: "Selección" },
          text: {
            mobile: "Perfiles 100% verificados mediante entrevistas",
            web: "Perfiles 100% verificados mediante entrevistas",
          },
        },
        planning: {
          title: { mobile: "Reporte & Feedback", web: "Reporte & Feedback" },
          text: {
            mobile: "Evaluamos a nuestro personal por evento",
            web: "Evaluamos a nuestro personal por evento",
          },
        },
        support: {
          title: { mobile: "Soporte", web: "Soporte" },
          text: {
            mobile: "Presentes en el evento, atendiendo cada necesidad",
            web: "Presentes en el evento, atendiendo cada necesidad",
          },
        },
      },
      footer: {
        mobile:
          "Agencia de personal de eventos de confianza. Trabajamos en todo el territorio nacional, en todo tipo de eventos. Disponibilidad 24/7",
        web:
          "Agencia de personal de eventos de confianza. Trabajamos en todo el territorio nacional, en todo tipo de eventos. Disponibilidad 24/7",
      },
    },
    request_budget: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
  },
  hostesses: {
    header: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
    hostesses: {
      title: {
        mobile: "Personal para tu evento",
        web: "Personal para tu evento",
      },
      subtitle: {
        mobile: "Según la necesidad de tu proyecto",
        web: "Según la necesidad de tu proyecto",
      },
      cards: {
        congress_hostesses: {
          title: {
            mobile: "Azafatas de congreso",
            web: "Azafatas de congreso",
          },
          text: {
            mobile:
              "Para todo tipo de eventos: congresos, ferias, promociones, eventos de empresa etc. Son la imagen y toma de contacto de las marcas a quienes representan. Todas nuestras azafatas <b>hablan Inglés.</b>",
            web:
              "Para todo tipo de eventos: congresos, ferias, promociones, eventos de empresa etc. Son la imagen y toma de contacto de las marcas a quienes representan. Todas nuestras azafatas <b>hablan Inglés.</b>",
          },
        },
        brand_hostesses: {
          title: { mobile: "Azafatas de imagen", web: "Azafatas de imagen" },
          text: {
            mobile:
              "Montaje y desmontaje de stands. Carga y descarga de materiales. <b>Trabajan en equipo</b> para que todo salga bien y enfrentarse a cualquier imprevisto.",
            web:
              "Montaje y desmontaje de stands. Carga y descarga de materiales. <b>Trabajan en equipo</b> para que todo salga bien y enfrentarse a cualquier imprevisto.",
          },
        },
        stand_hostesses: {
          title: { mobile: "Azafatas de ferias", web: "Azafatas de ferias" },
          text: {
            mobile: "El personal necesario que complementa tu evento",
            web: "El personal necesario que complementa tu evento",
          },
        },
        promoter_hostesses: {
          title: { mobile: "Azafatas promotoras", web: "Azafatas promotoras" },
          text: {
            mobile:
              "Cocteleros y camareros para darle un toque mixólogo a tu evento",
            web:
              "Cocteleros y camareros para darle un toque mixólogo a tu evento",
          },
        },
      },
    },
    doubt: {
      title: {
        mobile: "Si tienes alguna duda",
        web: "Si quiere contratar talento o tienes alguna duda",
      },
      button: {
        contact_us: {
          mobile: "Contáctanos",
          web: "Contáctanos",
        },
      },
    },
    how_we_work: {
      title: {
        mobile: "Nuestros 3 indispensables",
        web: "Nuestros 3 indispensables",
      },
      cards: {
        selection: {
          title: { mobile: "Actitud", web: "Actitud" },
          text: {
            mobile: "Perfiles 100% verificados mediante entrevistas",
            web: "Perfiles 100% verificados mediante entrevistas",
          },
        },
        planning: {
          title: { mobile: "Inglés", web: "Inglés" },
          text: {
            mobile: "Evaluamos a nuestro personal por evento",
            web: "Evaluamos a nuestro personal por evento",
          },
        },
        support: {
          title: { mobile: "Presencia", web: "Presencia" },
          text: {
            mobile: "Presentes en el evento, atendiendo cada necesidad",
            web: "Presentes en el evento, atendiendo cada necesidad",
          },
        },
      },
      footer: {
        mobile:
          "Agencia de personal de eventos de confianza. Trabajamos en todo el territorio nacional, en todo tipo de eventos. Disponibilidad 24/7",
        web:
          "Agencia de personal de eventos de confianza. Trabajamos en todo el territorio nacional, en todo tipo de eventos. Disponibilidad 24/7",
      },
    },
    talent: {
      title: {
        mobile: "Otros servicios",
        web: "Otros servicios",
      },

      cards: {
        assemblers: {
          title: { mobile: "Montadores", web: "Montadores" },
        },
        auxiliars: {
          title: { mobile: "Auxiliares", web: "Auxiliares" },
        },
        waiters: {
          title: { mobile: "Camareros", web: "Camareros" },
        },
        services: {
          title: { mobile: "Servicios", web: "Servicios" },
        },
      },
    },
    request_budget: {
      button: {
        request_budget: {
          mobile: "Pedir presupuesto",
          web: "Pedir presupuesto",
        },
      },
    },
  },
  congress_hostesses: {
    header: {
      title: { mobile: "Congress hostesses", web: "Congress hostesses" },
      texts: {
        text_1: {
          mobile:
            "They work as a team so the congress is a success and anticipate to any incident that may occur in the event.",
          web:
            "They work as a team so the congress is a success and anticipate to any incident that may occur in the event.",
        },
        text_2: {
          mobile:
            "A congress is a meeting, in which during one or several days, people from different places present lectures on topics related to their work. The congress hostesses are responsible for participating directly in the organization of these meetings and are responsible for a wide variety of functions. It is mainly responsible for making everything go as established.<br/>The congress hostesses are essential due to their resolving capacity. Are those who are concerned with reducing any incident that may occur in the venue and work as a team to enable the proper functioning of the congress.",
          web:
            "A congress is a meeting, in which during one or several days, people from different places present lectures on topics related to their work. The congress hostesses are responsible for participating directly in the organization of these meetings and are responsible for a wide variety of functions. It is mainly responsible for making everything go as established.<br/>The congress hostesses are essential due to their resolving capacity. Are those who are concerned with reducing any incident that may occur in the venue and work as a team to enable the proper functioning of the congress.",
        },
      },
    },
    hire: {
      title: {
        mobile: "Hire congress hostess",
        web: "If you need congress hostesses",
      },
      buttons: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
    section: {
      text: {
        mobile:
          "They offer attendees all kinds of information about the congress, they facilitate their attention and help, they indicate the way, they prepare the rooms of the speakers, and they accompany the protocol staff in their functions and help in the registration and distribution of the accreditations.<br/><br/>An essential requirement for congress hostesses is knowing English, since Spain is the fifth country in terms of number of international congresses. At Eloquence, all our congress hostesses speak fluent English and have previous training and experience in congress participation. In addition, qualities like sympathy, naturalness, education and discretion, necessary to develop your work in the best possible way, are very important for us.",
        web:
          "They offer attendees all kinds of information about the congress, they facilitate their attention and help, they indicate the way, they prepare the rooms of the speakers, and they accompany the protocol staff in their functions and help in the registration and distribution of the accreditations.<br/><br/>An essential requirement for congress hostesses is knowing English, since Spain is the fifth country in terms of number of international congresses. At Eloquence, all our congress hostesses speak fluent English and have previous training and experience in congress participation. In addition, qualities like sympathy, naturalness, education and discretion, necessary to develop your work in the best possible way, are very important for us.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "Specialized according to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stand", web: "Stand" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: { mobile: "texto aqui", web: "texto aqui" },
  },
  stand_hostesses: {
    header: {
      title: { mobile: "Stand hostesses", web: "Stand hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are responsible for attending the stand assistants. They have real knowledge about the company and its product.",
          web:
            "They are responsible for attending the stand assistants. They have real knowledge about the company and its product.",
        },
        text_2: {
          mobile:
            "There are many companies that attend trade fairs in our country. In them, the company has the possibility of hiring a stand in which to offer its products and services. The figure of the fair stewardess is necessary to carry out some important tasks within the stand and ensure the proper functioning of this.",
          web:
            "There are many companies that attend trade fairs in our country. In them, the company has the possibility of hiring a stand in which to offer its products and services. The figure of the fair stewardess is necessary to carry out some important tasks within the stand and ensure the proper functioning of this.",
        },
      },
    },
    hire: {
      title: { mobile: "Hire stand hostess", web: "Hire stand hostess" },
      buttons: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
    section: {
      text: {
        mobile:
          "Sometimes they will be sales fairs, where the hostesses can take charge of the task of selling. Its role as a fair hostess is to attract customers and familiarize them with the company they work for.<br/>We do everything possible to train our fair hostesses in the most functional aspects of the job. But above all, we value the knowledge of other languages to facilitate communication with foreign assistants, especially English, so all our fair hostesses dominate this language.",
        web:
          "Sometimes they will be sales fairs, where the hostesses can take charge of the task of selling. Its role as a fair hostess is to attract customers and familiarize them with the company they work for.<br/>We do everything possible to train our fair hostesses in the most functional aspects of the job. But above all, we value the knowledge of other languages to facilitate communication with foreign assistants, especially English, so all our fair hostesses dominate this language.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "According to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stand", web: "Stand" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: { mobile: "texto aqui", web: "texto aqui" },
  },
  promoter_hostesses: {
    header: {
      title: { mobile: "Promoter hostesses", web: "Promoter hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are located at the point of sale of the brand, with a more commercial profile. Take an essential role in the sale or service.",
          web:
            "They are located at the point of sale of the brand, with a more commercial profile. Take an essential role in the sale or service.",
        },
        text_2: {
          mobile:
            "In the sales channel of a product, an essential part of the process is promotion. To guarantee the sale of this product there are different types of promotion that in some occasions will need the help of one or several promoter hostesses.",
          web:
            "In the sales channel of a product, an essential part of the process is promotion. To guarantee the sale of this product there are different types of promotion that in some occasions will need the help of one or several promoter hostesses.",
        },
      },
    },
    hire: {
      title: { mobile: "Hire promoter hostess", web: "Hire promoter hostess" },
      buttons: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
    section: {
      text: {
        mobile:
          "They will try to attract as many potential customers as possible, bringing the product closer to them to get their interest. They act as a link between the brand and potential buyers; They are responsible for getting the product to the customer, resolve any questions that they may have about the product or brand.<br/>The delivery of advertising, the delivery of samples or the promotion at the point of sale.<br/>The promoter hostesses who are part of the Eloquence team, in addition to experience, training and English level have communication skills that allow them to be sociable and eloquent to connect the target with the brand.",
        web:
          "They will try to attract as many potential customers as possible, bringing the product closer to them to get their interest. They act as a link between the brand and potential buyers; They are responsible for getting the product to the customer, resolve any questions that they may have about the product or brand.<br/>The delivery of advertising, the delivery of samples or the promotion at the point of sale.<br/>The promoter hostesses who are part of the Eloquence team, in addition to experience, training and English level have communication skills that allow them to be sociable and eloquent to connect the target with the brand.",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "According to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stand", web: "Stand" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: { mobile: "texto aqui", web: "texto aqui" },
  },
  brand_hostesses: {
    header: {
      title: { mobile: "Brand hostesses", web: "Brand hostesses" },
      texts: {
        text_1: {
          mobile:
            "They are the representation of the brand, it is important that their presence and image are well taken care of.",
          web:
            "They are the representation of the brand, it is important that their presence and image are well taken care of.",
        },
        text_2: {
          mobile:
            "The image hostesses are the visible face of a brand in the event. We could say that the image stewardess profile stands out for its specific physical characteristics. They are responsible for promoting the image of a brand, which is why it is very important that their presence and image are well taken care of.",
          web:
            "The image hostesses are the visible face of a brand in the event. We could say that the image stewardess profile stands out for its specific physical characteristics. They are responsible for promoting the image of a brand, which is why it is very important that their presence and image are well taken care of.",
        },
      },
    },
    hire: {
      title: { mobile: "Hire brand hostess", web: "Hire brand hostess" },
      buttons: {
        request_budget: { mobile: "Request budget", web: "Request budget" },
      },
    },
    section: {
      text: {
        mobile:
          "At Eloquence we want to contribute more than just an image. We believe that beyond a specific physical characteristics a good image stewardess must possess other qualities such as: a positive attitude when working, sympathy, naturalness, an appropriate education, a good command of English and even knowledge of protocol",
        web:
          "At Eloquence we want to contribute more than just an image. We believe that beyond a specific physical characteristics a good image stewardess must possess other qualities such as: a positive attitude when working, sympathy, naturalness, an appropriate education, a good command of English and even knowledge of protocol",
      },
    },
    other_services: {
      title: { mobile: "Other services", web: "Other services" },
      subtitle: {
        mobile: "According to the need of your project",
        web: "According to the need of your project",
      },
      cards: {
        stand: {
          title: { mobile: "Stand", web: "Stand" },
        },
        promoters: { title: { mobile: "Promoters", web: "Promoters" } },
        brand: { title: { mobile: "Brand", web: "Brand" } },
        assemblers: { title: { mobile: "Assemblers", web: "Assemblers" } },
      },
    },
    contact_us: {
      button: { contact_us: { mobile: "Contact us", web: "Contact us" } },
    },
    footer: { mobile: "texto aqui", web: "texto aqui" },
  },
  work_with_us: {
    skills: {
      buttons: {
        work: {
          mobile: "Disfruta mientras trabajas",
          web: "Disfruta mientras trabajas",
        },
      },
      title: {
        mobile: "¿Qué necesitas?",
        web: "¿Qué necesitas?",
      },
      cards: {
        presence: {
          text: { mobile: "Buena presencia", web: "Buena presencia" },
        },
        english: { text: { mobile: "Inglés B2", web: "Inglés B2" } },
        attitude: {
          text: { mobile: "Actitud proactiva", web: "Actidud proactiva" },
        },
      },
    },
    team: {
      title: {
        mobile: "Únete al equipo Eloquence",
        web: "Únete al equipo Eloquence",
      },
      subtitle: {
        mobile: "Rellena el formulario y fotos *",
        web: "Rellena el formulario.<br>¡No te olvides de adjuntar tus fotos!",
      },
      card: {
        form: {
          inputs: {
            name: { label: "Nombre completo", placeholder: "Nombre Apellidos" },
            email: { label: "Email", placeholder: "email@email.com" },
            phone: { label: "Teléfono", placeholder: "+34653335689" },
            city: {
              label: "Ciudad de trabajo",
              placeholder: "¿Dónde te gustaria trabajar?",
            },
            nacionality: {
              label: "Nacionalidad",
              placeholder: "Española, francesa, inglesa...",
            },
            date: {
              label: "Año de nacimiento",
              placeholder: "22/05/1996",
              ok_label: "Aceptar",
              cancel_label: "Cancelar",
              wrong_date: "Fecha incorrecta",
            },
            photos: { label: "Añade al menos 2 fotos", button: "Añadir fotos" },
          },
          texts: {
            contact_us: {
              mobile: null,
              web: "Si tienes cualquier duda, escríbenos a:",
            },
          },
          selects: {
            gender: {
              label: "Género",
              placeholder: "Elige",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
            dress_size: {
              label: "Talla vestido (mujer) o camisa (hombre)",
              placeholder: "Elige",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
            shoe_size: {
              label: "Talla zapato",
              placeholder: "Elige",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
            english_level: {
              label: "Nivel inglés",
              placeholder: "Elige",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
            extra_languages: {
              label: "Extra idiomas",
              placeholder: "Escribe",
              items: [
                { value: "hombre", label: "Hombre" },
                { value: "mujer", label: "Mujer" },
              ],
            },
          },
          textareas: {
            extra_languages: "¿Hablas otros idiomas?",
          },
          checkboxs: {
            privacy: {
              label: "Yo acepto ",
              link: "las políticas de privacidad",
            },
          },
          buttons: {
            send: "Enviar",
          },
        },
      },
    },
    our_philosophy: {
      title: {
        mobile: "Nuestra filosofía",
        web: "Nuestra filosofía",
      },
      text: {
        mobile:
          "En Eloquence cuidamos de nuestro personal, valoramos su trabajo y hacemos que disfruten de la experiencia única de cada evento.<br/><br/>Comprueba la opinión de las personas que han trabajado con nosotros.<b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>Ver opciones</a></b><br/><br/>Si tienes cualquier duda, escríbenos a: work@eloquence.es",
        web:
          "En Eloquence cuidamos de nuestro personal, valoramos su trabajo y hacemos que disfruten de la experiencia única de cada evento.<br/><br/>Comprueba la opinión de las personas que han trabajado con nosotros.<b><a href='https://www.google.com/search?q=eloquence&rlz=1C5CHFA_enES719ES719&oq=eloquence&aqs=chrome..69i57j35i39l2j0j69i60l4.1839j0j1&sourceid=chrome&ie=UTF-8#lrd=0xd42264c16b421c9:0x12b78e0bf93862e9,1,,,'>Ver opciones</a></b><br/><br/>Si tienes cualquier duda, escríbenos a: work@eloquence.es",
      },
    },
  },
  navbar: {
    items: {
      services: "Servicios",
      hostesses: "Azafatas",
      congress_hostesses: "Azafatas de congreso",
      stands_hostesses: "Azafatas de feria",
      promotion_hostesses: "Azafatas promotoras",
      brand_hostesses: "Azafatas de imagen",
      about_us: "Sobre Eloquence",
      work_with_us: "Trabaja con nosotros",
      request_budget: "Pedir presupuesto",
      contact_us: "Contáctanos",
      assemblers: "Montadores",
      auxiliars: "Auxiliares",
      waiters: "Camareros",
    },
  },
  footer: {
    items: {
      sales: "Eventos: ",
      hostess: "Trabaja con nosotros: ",
      phone: "",
      work_with_us: "Trabaja con nosotros",
      contact_us: "Contáctanos",
      services: "Servicios",
      about_us: "Sobre Eloquence",
    },
  },
};

export { translation };
