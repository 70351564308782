import React, { useContext } from "react";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link as LinkLibrary } from "components/Atoms/Link";
import { Link, useHistory } from "react-router-dom";
import { Text } from "components/Atoms/Text";
import { UserContext } from "core/UserContext";
import { getLink } from "assets/languages/i18n/links";
import Spanish from "assets/img/languages/spanish.png";
import English from "assets/img/languages/english.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonLinkPortalClients: {
    textDecoration: "none",
    color: "#0A28A4",
    border: "2px solid #0A28A4",
  },
  buttonLinkPortalWorkers: {
    textDecoration: "none",
    color: "#fff",
    background: "#0A28A4",
    border: "2px solid #0A28A4",
  },
}));
const MobileNavBar = (props) => {
  const classes = useStyles();
  const context = useContext(UserContext);
  const [collapseOpen, setCollapseOpen] = React.useState({
    services: false,
    hostesses: false,
  });
  const history = useHistory();
  const collapseHostesses = (
    <Collapse in={collapseOpen.hostesses} timeout="auto" unmountOnExit>
      <List style={{ paddingLeft: "8px" }}>
        {[
          props.translation.items.congress_hostesses,
          props.translation.items.brand_hostesses,
          props.translation.items.stand_hostesses,
          props.translation.items.promotion_hostesses,
        ].map((text) => (
          <ListItem button key={text.label} component={Link} to={text.link}>
            <ListItemText primary={text.label} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  const collapseServices = (
    <Collapse in={collapseOpen.services} timeout="auto" unmountOnExit>
      <List style={{ paddingLeft: "8px" }}>
        {[
          props.translation.items.hostesses,
          props.translation.items.assemblers,
          props.translation.items.auxiliars,
          props.translation.items.waiters,
        ].map((text) => (
          <>
            <ListItem
              button
              key={text.label}
              onClick={
                text.id === "hostesses"
                  ? () =>
                      setCollapseOpen({
                        ...collapseOpen,
                        hostesses: !collapseOpen.hostesses,
                      })
                  : null
              }
            >
              <ListItemText
                primary={text.label}
                onClick={() => {
                  history.push(text.link);
                }}
              />
              {text.id === "hostesses" ? (
                collapseOpen.hostesses ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItem>
            {text.id === "hostesses" ? collapseHostesses : null}
          </>
        ))}
      </List>
    </Collapse>
  );

  const content = (
    <div width="auto" role="presentation" onClose={props.handleDrawerOpen}>
      <List>
        {[
          props.translation.items.services,
          props.translation.items.about_us,
          // props.translation.items.work_with_us,
          // props.translation.items.request_budget,
        ].map((text) => (
          <>
            <ListItem
              button
              key={text.label}
              onClick={
                text.id === "services"
                  ? () =>
                      setCollapseOpen({
                        ...collapseOpen,
                        services: !collapseOpen.services,
                      })
                  : null
              }
            >
              <ListItemText
                primary={text.label}
                onClick={() => {
                  history.push(text.link);
                }}
              />
              {text.id === "services" ? (
                collapseOpen.services ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItem>
            {text.id === "services" ? collapseServices : null}
          </>
        ))}
        <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            onClick={() => {
              context.language === "es"
                ? context.handleLanguageChange("en")
                : context.handleLanguageChange("es");
              history.push(getLink(props.link));
            }}
            src={context.language === "es" ? Spanish : English}
            style={{ width: "30px" }}
            alt="language-flag"
          />
          <a
            href="https://eloquence.onsinch.com/users/login"
            className={classes.buttonLinkPortalClients}
          >
            <Text variant="customize" fontSize="12px" margin="12px 4px" weight>
              {props.translation.items.portal_clients.label}
            </Text>
          </a>
          <a
            href="https://eloquence.onsinch.com/users/login"
            className={classes.buttonLinkPortalWorkers}
            style={{ marginLeft: "8px" }}
          >
            <Text variant="customize" fontSize="12px" margin="12px 4px" weight>
              {props.translation.items.portal_workers.label}
            </Text>
          </a>
        </ListItem>
      </List>
    </div>
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <LinkLibrary to={props.translation.items.request_budget.link}>
        <Text variant="customize" fontSize="12px" color="black">
          {props.translation.items.request_budget.label}
        </Text>
      </LinkLibrary>
      <MenuIcon
        onClick={props.handleDrawerOpen}
        style={{ color: "black", marginLeft: "18px" }}
      />
      <Drawer
        anchor="top"
        onClose={props.handleDrawerOpen}
        top
        open={props.drawerOpen}
      >
        {content}
      </Drawer>
    </div>
  );
};

export { MobileNavBar };
