import React, { Component } from "react";
import { UserContext } from "core/UserContext";
import {
  Grid,
  Checkbox,
  Hidden,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { windowMobileDetection } from "core/windowMobileDetection";
import { Text } from "components/Atoms/Text";
import { Button } from "components/Atoms/Button";
import { ListCard } from "components/Organisms/ListCard";
import { Carousel } from "components/Atoms/Carousel";
import { Image } from "components/Atoms/Image";
import { Card } from "components/Organisms/Card";
import Input from "components/Atoms/Input";
import { Navbar } from "components/Organisms/NavBar";
import { Footer } from "components/Organisms/Footer";
import FormServices from "core/services/index.services";
import { getLink } from "assets/languages/i18n/links";
import { Redirect } from "react-router";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: {
        email: "",
        phone: "",
        number_hostess: "",
        details: "",
        policies: null,
      },
      redirect_to_thanks: false,
    };
    this.service = new FormServices();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChangeInput = (event) => {
    const quote = { ...this.state.quote };
    quote[event.target.name] = event.target.value;
    this.setState({ quote });
  };

  handleChangeCheckbox = (name) => (event) => {
    const quote = { ...this.state.quote };
    quote[name] = event.target.checked;
    this.setState({ quote });
  };

  callApi = () => {
    this.service
      .contactUs(this.state.quote)
      .then((response) => {
        this.setState({ redirect_to_thanks: true });
        // this.setState({
        //   quote: {
        //     email: "",
        //     phone: "",
        //     number_hostess: "",
        //     details: "",
        //     policies: null,
        //   },
        // });
      })
      .catch((err) => {
        alert("Error en la petición, intentelo más tarde");
        this.setState({
          quote: {
            email: "",
            phone: "",
            number_hostess: "",
            details: "",
            policies: null,
          },
        });
      });
  };

  checkObject = () => {
    if (this.state.quote.email === "") {
      return false;
    }
    if (this.state.quote.phone === "") {
      return false;
    }
    if (this.state.quote.number_hostess === "") {
      return false;
    }
    if (this.state.quote.details === "") {
      return false;
    }
    if (!this.state.quote.policies) {
      return false;
    }
    return true;
  };

  static contextType = UserContext;
  render() {
    return this.state.redirect_to_thanks ? (
      <Redirect to={getLink("thanks")} />
    ) : (
      <Grid container>
        {/* NavBar */}
        <Navbar
          translation={this.context.translation.templates.navbar}
          link="home"
        />
        {/* <Hidden smUp>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Text
              variant="customize"
              color="#000"
              fontSize="24px"
              padding="0 0 0 8px"
              weight
            >
              {this.context.translation.templates.home.header.title}
            </Text>
          </Grid>
        </Hidden> */}
        <Grid
          item
          xs={12}
          style={{
            width: "100%",
            minHeight: windowMobileDetection() ? "40vh" : "700px",
            marginTop: windowMobileDetection() ? null : "80px",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "-1",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <Carousel
              autoplay
              numberOfCards={1}
              items={[
                ...this.context.translation.templates.home.header.slides.map(
                  (slide) => (
                    <Image
                      backgroundPosition={
                        windowMobileDetection()
                          ? slide.position.mobile
                          : slide.position.web
                      }
                      sizeImage={
                        windowMobileDetection()
                          ? slide.backgroundSize.mobile
                          : slide.backgroundSize.web
                      }
                      isBackground={true}
                      url={slide.src}
                      width={"100%"}
                      height={windowMobileDetection() ? "400px" : "850px"}
                    />
                  )
                ),
              ]}
            />
          </div>
          <Hidden smUp>
            <Grid
              container
              style={{
                marginTop: "90px",
                height: windowMobileDetection() ? "200px" : "100%",
              }}
            >
              <Grid item xs={9} />
              <Grid
                item
                xs={3}
                style={{
                  paddingRight: "12px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Card>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Image url="home/google_5stars.png" height="20px" />
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <div
              style={{
                position: "absolute",
                top: "40px",
                right: "0",
                background: "#3a3a3a",
                color: "#fff",
                height: "40px",
                borderRadius: "40px 0 0 40px",
                display: "flex",
                alignItems: "center",
                padding: "12px",
                zIndex: "9999",
              }}
            >
              <Text margin="12px" style={{ zIndex: "9999" }}>
                {this.context.translation.templates.home.header.ad}
              </Text>
            </div>
            <Grid
              container
              justify="center"
              style={{
                height: windowMobileDetection() ? "100%" : "700px",
                paddingTop: windowMobileDetection() ? null : "32px",
                paddingLeft: windowMobileDetection() ? "5%" : "5%",
                paddingBottom: "32px",
                alignItems: "flex-end",
                zIndex: "9999",
              }}
              alignItems={windowMobileDetection() ? "center" : null}
            >
              <Grid item xs={4}>
                <Text
                  variant={windowMobileDetection() ? "customize" : "h2"}
                  color="#fff"
                  fontSize="24px"
                  padding="0 0 0 8px"
                  margin="8px 0 10px 0"
                  weight={windowMobileDetection() ? false : true}
                >
                  {this.context.translation.templates.home.header.title}
                </Text>
                <Card styles={{ padding: "16px 32px", zIndex: "9999" }}>
                  <Text
                    variant="customize"
                    weight
                    fontSize="28px"
                    margin="8px 0 0 0"
                  >
                    {
                      this.context.translation.templates.home.header.form.web
                        .title
                    }
                  </Text>
                  <Text variant="customize" fontSize="18px" margin="8px 0">
                    {
                      this.context.translation.templates.home.header.form.web
                        .subtitle
                    }
                  </Text>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel
                          id="profile_type_label"
                          ref={this.refProfileType}
                        >
                          {
                            this.context.translation.templates.home.header.form
                              .web.selects.profile_type.label
                          }
                        </InputLabel>
                        <Select
                          required
                          name="profile_type"
                          labelId="profile_type_label"
                          id="demo-simple-select-outlined"
                          value={this.state.profile_type}
                          onChange={this.handleChangeInput}
                          labelWidth={
                            this.context.translation.templates.home.header.form
                              .web.selects.profile_type.width
                          }
                        >
                          {this.context.translation.templates.home.header.form.web.selects.profile_type.items.map(
                            (item) => (
                              <MenuItem value={item.value}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        fullWidth
                        name="number_hostess"
                        id="number_hostess"
                        label={
                          this.context.translation.templates.home.header.form
                            .web.inputs.number_hostess.label
                        }
                        placeholder={
                          this.context.translation.templates.home.header.form
                            .web.inputs.number_hostess.placeholder
                        }
                        InputProps={{ inputProps: { min: 0 } }}
                        variant="outlined"
                        type="number"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.quote.number_hostess}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Input
                        fullWidth
                        name="email"
                        id="email"
                        label={
                          this.context.translation.templates.home.header.form
                            .web.inputs.email.label
                        }
                        placeholder={
                          this.context.translation.templates.home.header.form
                            .web.inputs.email.placeholder
                        }
                        variant="outlined"
                        type="email"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.quote.email}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        fullWidth
                        name="phone"
                        id="phone"
                        label={
                          this.context.translation.templates.home.header.form
                            .web.inputs.phone.label
                        }
                        placeholder={
                          this.context.translation.templates.home.header.form
                            .web.inputs.phone.placeholder
                        }
                        variant="outlined"
                        type="telephone"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.quote.phone}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Input
                        fullWidth
                        name="details"
                        id="details"
                        label={
                          this.context.translation.templates.home.header.form
                            .web.inputs.details.label
                        }
                        placeholder={
                          this.context.translation.templates.home.header.form
                            .web.inputs.details.placeholder
                        }
                        variant="outlined"
                        type="text"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.quote.details}
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant={this.checkObject() ? "b1" : "b1_disabled"}
                        label={
                          this.context.translation.templates.home.header.form
                            .web.buttons.send
                        }
                        color="primary"
                        fontSize="22px"
                        disabled={this.checkObject() ? false : true}
                        onClick={this.callApi}
                      />
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={this.state.quote.policies}
                        onChange={this.handleChangeCheckbox("policies")}
                        color="default"
                        value="policies"
                      />
                      <Text variant="customize" margin="0 4px 0 0">
                        {
                          this.context.translation.templates.home.header.form
                            .web.checkboxs.privacy.label
                        }
                      </Text>
                      {
                        this.context.translation.templates.home.header.form.web
                          .checkboxs.privacy.link
                      }
                    </div>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={6} />
              <Grid
                item
                xs={1}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                {/* <Card>
                  <Grid container wrap="nowrap">
                    <Grid item xs={1} />
                    <Grid item xs={3} style={{ minWidth: "60px" }}>
                      <Text variant="customize" fontSize="14px" weight>
                        Oficial Suppliers
                      </Text>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        url="home/Icono_UEFA_Champions.png"
                        height="50px"
                      />
                    </Grid>
                  </Grid>
                </Card> */}
                <div style={{ marginTop: "16px" }}>
                  <Card>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Image url="home/google_5stars.png" height="40px" />
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        {/* SECTION BUDGET */}
        <Hidden smUp>
          <Grid container style={{ background: "#F6F8F9" }}>
            <Grid item xs={12}>
              <Text
                variant="customize"
                weight
                fontSize="28px"
                textAlign="center"
                margin="8px 0 0 0"
              >
                {this.context.translation.templates.home.budget.mobile.title}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text
                variant="customize"
                fontSize="16px"
                textAlign="center"
                margin="0 0 16px 0"
              >
                {this.context.translation.templates.home.budget.mobile.subtitle}
              </Text>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10} style={{ marginBottom: "16px" }}>
              <Button
                variant="b3"
                weight
                label={
                  this.context.translation.templates.home.budget.mobile.budget
                    .buttons.request_budget
                }
                link={getLink("budget")}
              />
            </Grid>
          </Grid>
        </Hidden>
        {/* SECTION TYPE PROFILE */}
        <Grid container>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              fontSize="22px"
              textAlign="center"
              margin={windowMobileDetection() ? "8px 0 8px 0" : "24px 0 8px 0"}
            >
              {this.context.translation.templates.home.type_profile.title}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text
              variant="customize"
              fontSize={windowMobileDetection() ? "16px" : "24px"}
              textAlign="center"
              margin={windowMobileDetection() ? "8px 0 8px 0" : "8px 0 24px 0"}
            >
              {this.context.translation.templates.home.type_profile.subtitle}
            </Text>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={8} style={{ paddingBottom: "58px" }}>
            <Grid container spacing={4}>
              <ListCard
                items={Object.keys(
                  this.context.translation.templates.home.type_profile.cards
                ).map((card, index) => {
                  return {
                    xs: 6,
                    md: 3,
                    variant: "ImageCard",
                    sizeTitle: windowMobileDetection() ? "16px" : "24px",
                    marginTitle: "8px 0",
                    title:
                      this.context.translation.templates.home.type_profile
                        .cards[card].title,
                    url: this.context.translation.templates.home.type_profile
                      .cards[card].image,
                    to: this.context.translation.templates.home.type_profile
                      .cards[card].to,
                    height: windowMobileDetection() ? "100px" : "260px",
                    sizeImage: windowMobileDetection()
                      ? index === 2
                        ? "150%"
                        : "100%"
                      : index === 2
                      ? "150%"
                      : "100%",
                    backgroundPosition:
                      index === 2 ? "center right" : "top center",
                  };
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* SECTION CLIENTS */}
        <Grid
          container
          style={{ margin: windowMobileDetection() ? "16px 0" : "32px 0" }}
        >
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Carousel
              numberOfCards={4}
              items={[
                ...this.context.translation.templates.home.clients.map(
                  (client) => (
                    <Image
                      url={client}
                      height={windowMobileDetection() ? null : "50px"}
                      width={windowMobileDetection() ? "100%" : null}
                    />
                  )
                ),
              ]}
            />
          </Grid>
        </Grid>
        {/* SECTION GUARANTEE */}
        <Grid
          container
          style={{
            background: "#F6F8F9",
            paddingTop: "8px",
            marginTop: "8px",
          }}
        >
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign="center"
              margin={windowMobileDetection() ? "8px 0 8px 0" : "26px 0"}
            >
              {this.context.translation.templates.home.guarantee.title}
            </Text>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Grid
              container
              spacing={2}
              style={{
                paddingBottom: windowMobileDetection() ? "32px" : "90px",
              }}
            >
              <ListCard
                items={Object.keys(
                  this.context.translation.templates.home.guarantee.cards
                ).map((card) => {
                  return {
                    xs: 12,
                    md: 4,
                    url: this.context.translation.templates.home.guarantee
                      .cards[card].icon,
                    variant: "IconCard",
                    filterCss: "hue-rotate(210deg) brightness(500%)",
                    light: true,
                    sizeTitle: windowMobileDetection() ? "20px" : "28px",
                    sizeSubtitle: windowMobileDetection() ? "16px" : "20px",
                    title:
                      this.context.translation.templates.home.guarantee.cards[
                        card
                      ].title,
                    textLabel:
                      this.context.translation.templates.home.guarantee.cards[
                        card
                      ].text,
                  };
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* SECTION RESULT */}
        <Grid container>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              fontSize="24px"
              textAlign="center"
              weight
              margin={windowMobileDetection() ? "8px 0 8px 0" : "26px 0 8px 0"}
            >
              {this.context.translation.templates.home.result.title}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "customize"}
              fontSize={windowMobileDetection() ? "18px" : "24px"}
              textAlign="center"
              margin={windowMobileDetection() ? "0 0 8px 0" : "0 0 24px 0"}
            >
              {this.context.translation.templates.home.result.subtitle}
            </Text>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Carousel
              numberOfCards={windowMobileDetection() ? 1 : 3}
              items={[
                ...Object.keys(
                  this.context.translation.templates.home.result.cards
                ).map((review) => (
                  <Card
                    variant="CommentCard"
                    text={
                      this.context.translation.templates.home.result.cards[
                        review
                      ].text
                    }
                    image={
                      this.context.translation.templates.home.result.cards[
                        review
                      ].image
                    }
                    name={
                      this.context.translation.templates.home.result.cards[
                        review
                      ].name
                    }
                  />
                )),
              ]}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1} md={4} />
          <Grid item xs={10} md={4} style={{ margin: "8px 0" }}>
            <Button
              variant="b7"
              weight
              externalLink="https://google.com/search?q=Eloquence+Hostess+Madrid&stick=H4sIAAAAAAAA_-NgU1IxqEgxMTKyTDRNM0oxNUsxTrMyqDBOTDZMMk1KsUxLMrU0NzJZxCrhmpNfWJqal5yq4JFfXJJaXKzgm5hSlJkCAAE1ayBDAAAA&hl=es&mat=CS-yoVdVHj_eElYBNqvzOvy_SuZOdFClVKeNTP-oHmjewOEEE_QrS20rxq4vzK8zHtaW6RoMGsR4m2tctnG9OnSXgCCrSWQTcNvIkcdl1E8f-VZPsGO4jpUMjjNpM8Y4mA&authuser=0"
            >
              <Grid
                container
                style={{ padding: windowMobileDetection() ? "4px" : "0" }}
              >
                <Grid item xs={8}>
                  <Text
                    weight
                    fontSize={windowMobileDetection() ? "16px" : "22px"}
                  >
                    {
                      this.context.translation.templates.home.result.buttons
                        .reviews
                    }
                  </Text>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    url="home/google_5stars.png"
                    height={windowMobileDetection() ? "30px" : "40px"}
                  />
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
        {/* SECTION CONTACT US */}
        <Grid
          container
          style={{
            background: "#F6F8F9",
            paddingTop: windowMobileDetection() ? "8px" : "48px",
            marginTop: windowMobileDetection() ? "8px" : "16px",
            paddingBottom: windowMobileDetection() ? "8px" : "48px",
            marginBottom: windowMobileDetection() ? "8px" : "-24px",
          }}
          spacing={windowMobileDetection() ? 0 : 0}
        >
          <Hidden smUp>
            <Grid item xs={12}>
              <Text
                variant="customize"
                fontSize="24px"
                textAlign="center"
                margin="8px 0 8px 0"
                weight
              >
                {this.context.translation.templates.home.contact_us.title}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text
                variant="customize"
                fontSize="16px"
                textAlign="center"
                margin="0 0 16px 0"
              >
                {this.context.translation.templates.home.contact_us.subtitle}
              </Text>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                height: "400px",
              }}
            >
              <Image
                isBackground
                height="100%"
                url="home/contact.jpg"
                width="100%"
                sizeImage="150%"
                backgroundPosition="top center"
              />
            </Grid>
            <Grid item xs={12}>
              <Text
                variant="customize"
                fontSize="18px"
                textAlign="center"
                margin="16px 0 8px 0"
              >
                {this.context.translation.templates.home.contact_us.phone}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text
                variant="customize"
                fontSize="18px"
                textAlign="center"
                margin="0 0 12px 0"
              >
                {this.context.translation.templates.home.contact_us.email}
              </Text>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10} style={{ marginBottom: "16px" }}>
              <Button
                variant="b3"
                weight
                link={getLink("budget")}
                label={
                  this.context.translation.templates.home.contact_us.buttons
                    .request_budget
                }
              />
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid md={1} />
            <Grid item md={4}>
              <Grid
                container
                style={{
                  height: "100%",
                }}
              >
                <Grid item xs={12}>
                  <Text
                    variant={"customize"}
                    fontSize="28px"
                    textAlign="left"
                    margin="8px 0 8px 0"
                    weight
                  >
                    {this.context.translation.templates.home.contact_us.title}
                  </Text>
                </Grid>

                <Grid item xs={12}>
                  <Text
                    variant={"customize"}
                    fontSize="48px"
                    weight
                    textAlign="left"
                    margin="-16px 0 16px"
                  >
                    {
                      this.context.translation.templates.home.contact_us
                        .subtitle
                    }
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <Text
                    variant="customize"
                    fontSize="18px"
                    textAlign="left"
                    margin="-8px 0 8px 0"
                  >
                    {this.context.translation.templates.home.contact_us.phone}
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <Text
                    variant="customize"
                    fontSize="18px"
                    textAlign="left"
                    margin="-16px 0 16px  0"
                  >
                    {this.context.translation.templates.home.contact_us.email}
                  </Text>
                </Grid>
                <div
                  style={{
                    flexGrow: "2",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="b3"
                    weight
                    link={getLink("budget")}
                    fontSize="22px"
                    margin="12px 0"
                    label={
                      this.context.translation.templates.home.contact_us.buttons
                        .request_budget
                    }
                  />
                </div>
              </Grid>
            </Grid>
            <Grid md={1} />
            <Grid
              item
              md={5}
              style={{
                height: "400px",
              }}
            >
              <Image
                isBackground
                height="100%"
                url="home/contact.jpg"
                width="100%"
                sizeImage="100%"
                borderRadius="12px"
                backgroundPosition="center center"
              />
            </Grid>
          </Hidden>
        </Grid>
        {/* SECTION WORK WITH US */}
        {/* <Grid container>
          <Hidden smUp>
            <Grid item xs={12}>
              <Text
                variant="customize"
                fontSize="24px"
                textAlign="center"
                margin="24px 0 8px 0"
                weight
              >
                {this.context.translation.templates.home.work_with_us.title}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text
                variant="customize"
                fontSize="18px"
                textAlign="center"
                margin="0 0 8px 0"
              >
                {this.context.translation.templates.home.work_with_us.subtitle}
              </Text>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10} style={{ marginBottom: "16px" }}>
              <Button
                variant="b3"
                weight
                label={
                  this.context.translation.templates.home.work_with_us.buttons
                    .join
                }
                link={getLink("work_with_us")}
              />
            </Grid>
            <Grid item xs={12}>
              <Text
                variant="customize"
                fontSize="16px"
                textAlign="center"
                margin="0 0 8px 0"
              >
                {this.context.translation.templates.home.work_with_us.text}
              </Text>
            </Grid>
          </Hidden>
        </Grid> */}
        <Footer translation={this.context.translation.templates.footer} />
      </Grid>
    );
  }
}

export { Home };
