const links = {
  thanks: { es: "/gracias", en: "/thanks" },
  home: { es: "/personal-para-eventos", en: "/event-staffing-agency" },
  services: {
    es: "/agencia-de-personal-de-eventos",
    en: "/staff-for-events-in-spain",
  },
  hostesses: {
    es: "/agenciadeazafatas",
    en: "/hostess-agency-in-spain",
  },
  congress_hostesses: {
    es: "/azafatas-de-congreso",
    en: "/congress-hostesses ",
  },
  brand_hostesses: {
    es: "/azafatas-de-imagen",
    en: "/brand-ambassador",
  },
  promoter_hostesses: {
    es: "/azafatas-promotoras",
    en: "/promoter-hostesses",
  },
  stand_hostesses: {
    es: "/azafatas-de-feria",
    en: "/tradeshow-hostesses",
  },
  assemblers: {
    es: "/azafatas-montadores",
    en: "/azafatas-montadores",
  },
  waiters: {
    es: "/azafatas-camareros",
    en: "/azafatas-camareros",
  },
  auxiliars: {
    es: "/azafatas-auxiliares-de-eventos",
    en: "/azafatas-auxiliares-de-eventos",
  },
  about_us: {
    es: "/eloquence",
    en: "/eloquence",
  },
  work_with_us: {
    es: "mailto:work@eloquence.es",
    en: "mailto:work@eloquence.es",
  },
  budget: {
    es: "/pedir-presupuesto",
    en: "/pedir-presupuesto",
  },
  contact_us: {
    es: "/contacto",
    en: "/contact",
  },
  hostesses_madrid: {
    es: "/agencia-azafatas-madrid",
    en: "/hostess-agency-in-madrid",
  },
  hostesses_barcelona: {
    es: "/agencia-azafatas-barcelona",
    en: "/hostess-agency-in-barcelona",
  },
};

const getLink = (link) => {
  const property = Object.keys(links).find((key) => key === link);
  // console.log("property", property, link);
  // console.log(links[property][localStorage.getItem("eloquence-lang")], [
  //   localStorage.getItem("eloquence-lang"),
  // ]);
  return links[property][localStorage.getItem("eloquence-lang")] || null;
};

export { getLink, links };
