import { HomeTemplate } from "./Home";
import { AboutUsTemplate } from "assets/languages/i18n/templates/AboutUs";
import { BudgetTemplate } from "./Budget";
import { ContactUsTemplate } from "./ContactUs";
import { ServicesHostessesTemplate } from "./Services/Hostesses";
import { ServicesCongressHostessesTemplate } from "./Services/CongressHostesses";
import { ServicesStandHostessesTemplate } from "./Services/StandHostesses";
import { ServicesPromoterHostessesTemplate } from "./Services/PromoterHostesses";
import { ServicesBrandHostessesTemplate } from "./Services/BrandHostesses";
import { WorkWithUsTemplate } from "./WorkWithUs";
import { NavBarTemplate } from "./NavBar";
import { FooterTemplate } from "./Footer";
import { PaymentTemplate } from "./Payment";
import { ServicesTemplate } from "./Services/Services";
import { AssemblersTemplate } from "./Services/Assemblers";
import { AuxiliarsTemplate } from "./Services/Auxiliars";
import { WaitersTemplate } from "./Services/Waiters";
import { HostessesMadridTemplate } from "./HostessesMadrid";
import { HostessesBarcelonaTemplate } from "./HostessesBarcelona";
import { ThanksTemplate } from "./Thanks";

const templates = (translation) => {
  return {
    home: HomeTemplate(translation),
    about_us: AboutUsTemplate(translation),
    budget: BudgetTemplate(translation),
    contact_us: ContactUsTemplate(translation),
    services: ServicesTemplate(translation),
    assemblers: AssemblersTemplate(translation),
    auxiliars: AuxiliarsTemplate(translation),
    waiters: WaitersTemplate(translation),
    hostesses_madrid: HostessesMadridTemplate(translation),
    hostesses_barcelona: HostessesBarcelonaTemplate(translation),
    services_hostesses: ServicesHostessesTemplate(translation),
    services_congress_hostesses: ServicesCongressHostessesTemplate(translation),
    services_stand_hostesses: ServicesStandHostessesTemplate(translation),
    services_promoter_hostesses: ServicesPromoterHostessesTemplate(translation),
    services_brand_hostesses: ServicesBrandHostessesTemplate(translation),
    work_with_us: WorkWithUsTemplate(translation.work_with_us),
    payment: PaymentTemplate(translation.payment),
    thanks: ThanksTemplate(translation.thanks),
    navbar: NavBarTemplate(translation),
    footer: FooterTemplate(translation),
  };
};

export { templates };
