import React from "react";
const BudgetTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";

  return {
    header: {
      title: translation.budget.header.title[typeFormat],
      card: {
        title: translation.budget.header.card.title[typeFormat],
        subtitle: translation.budget.header.card.subtitle[typeFormat],
        form: {
          mobile: null,
          web: {
            inputs: {
              name: {
                label:
                  translation.budget.header.card.form.web.inputs.name.label,
                placeholder:
                  translation.budget.header.card.form.web.inputs.name
                    .placeholder,
              },
              email: {
                label:
                  translation.budget.header.card.form.web.inputs.email.label,
                placeholder:
                  translation.budget.header.card.form.web.inputs.email
                    .placeholder,
              },
              phone: {
                label:
                  translation.budget.header.card.form.web.inputs.phone.label,
                placeholder:
                  translation.budget.header.card.form.web.inputs.phone
                    .placeholder,
              },
              city: {
                label:
                  translation.budget.header.card.form.web.inputs.city.label,
                placeholder:
                  translation.budget.header.card.form.web.inputs.city
                    .placeholder,
              },
              number_hostess: {
                label:
                  translation.budget.header.card.form.web.inputs.number_hostess
                    .label,
                placeholder:
                  translation.budget.header.card.form.web.inputs.number_hostess
                    .placeholder,
              },
              message: {
                label:
                  translation.budget.header.card.form.web.inputs.message.label,
                placeholder:
                  translation.budget.header.card.form.web.inputs.message
                    .placeholder,
              },
            },
            selects: {
              profile_type: {
                label:
                  translation.budget.header.card.form.web.selects.profile_type
                    .label,
                width:
                  translation.budget.header.card.form.web.selects.profile_type
                    .width,
                placeholder:
                  translation.budget.header.card.form.web.selects.profile_type
                    .placeholder,
                items:
                  translation.budget.header.card.form.web.selects.profile_type
                    .items,
              },
            },
            checkboxs: {
              privacy: {
                label:
                  translation.budget.header.card.form.web.checkboxs.privacy
                    .label,
                link: (
                  <a
                    href="https://eloquence.es/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {
                      translation.budget.header.card.form.web.checkboxs.privacy
                        .link
                    }
                  </a>
                ),
              },
            },
            buttons: {
              send: translation.budget.header.card.form.web.buttons.send,
            },
          },
        },
      },
    },
    subtitle: translation.budget.subtitle[typeFormat],
    form: {
      mobile: {
        inputs: {
          name: {
            label: translation.budget.form.mobile.inputs.name.label,
            placeholder: translation.budget.form.mobile.inputs.name.placeholder,
          },
          email: {
            label: translation.budget.form.mobile.inputs.email.label,
            placeholder:
              translation.budget.form.mobile.inputs.email.placeholder,
          },
          phone: {
            label: translation.budget.form.mobile.inputs.phone.label,
            placeholder:
              translation.budget.form.mobile.inputs.phone.placeholder,
          },
          city: {
            label: translation.budget.form.mobile.inputs.city.label,
            placeholder: translation.budget.form.mobile.inputs.city.placeholder,
          },
          number_hostess: {
            label: translation.budget.form.mobile.inputs.number_hostess.label,
            placeholder:
              translation.budget.form.mobile.inputs.number_hostess.placeholder,
          },
          message: {
            label: translation.budget.form.mobile.inputs.message.label,
            placeholder:
              translation.budget.form.mobile.inputs.message.placeholder,
          },
        },
        checkboxs: {
          privacy: {
            label: translation.budget.form.mobile.checkboxs.privacy.label,
            link: (
              <a
                href="https://eloquence.es/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translation.budget.form.mobile.checkboxs.privacy.link}
              </a>
            ),
          },
        },
        buttons: {
          send: translation.budget.form.mobile.buttons.send,
        },
      },
      web: null,
    },
    contact_us: {
      title: translation.budget.contact_us.title[typeFormat],
      card: {
        call_us: {
          title: translation.budget.contact_us.card.call_us.title[typeFormat],
          text: translation.budget.contact_us.card.call_us.text[typeFormat],
        },
        write_us: {
          title: translation.budget.contact_us.card.write_us.title[typeFormat],
          text: translation.budget.contact_us.card.write_us.text[typeFormat],
        },
      },
      buttons: {
        back_home: {
          label:
            translation.budget.contact_us.buttons.back_home.label[typeFormat],
        },
      },
    },
  };
};

export { BudgetTemplate };
