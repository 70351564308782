const PaymentTemplate = translation => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: null,
    payment_gateway: {
      title: translation.payment_gateway.title[typeFormat],
      card: {
        title: translation.payment_gateway.card.title[typeFormat],
        subtitle: translation.payment_gateway.card.subtitle[typeFormat],
        form: {
          inputs: {
            company: {
              label: translation.payment_gateway.card.form.inputs.company.label,
              placeholder:
                translation.payment_gateway.card.form.inputs.company.placeholder
            },
            number_invoice: {
              label:
                translation.payment_gateway.card.form.inputs.number_invoice
                  .label,
              placeholder:
                translation.payment_gateway.card.form.inputs.number_invoice
                  .placeholder
            },
            cuantity: {
              label:
                translation.payment_gateway.card.form.inputs.cuantity.label,
              placeholder:
                translation.payment_gateway.card.form.inputs.cuantity
                  .placeholder
            }
          },
          checkboxs: {
            privacy: {
              label:
                translation.payment_gateway.card.form.checkboxs.privacy.label,
              link: translation.payment_gateway.card.form.checkboxs.privacy.link
            }
          },
          buttons: {
            send: translation.payment_gateway.card.form.buttons.send
          }
        }
      }
    },
    some_dude: {
      title: translation.some_dude.title[typeFormat],
      subtitle: translation.some_dude.subtitle[typeFormat],
      phone: translation.some_dude.phone[typeFormat],
      email: translation.some_dude.email[typeFormat]
    }
  };
};

export { PaymentTemplate };
