const ServicesCongressHostessesTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.congress_hostesses.header.title[typeFormat],
      texts: {
        text_1: translation.congress_hostesses.header.texts.text_1[typeFormat],
        text_2: translation.congress_hostesses.header.texts.text_2[typeFormat],
      },
    },
    hire: {
      title: translation.congress_hostesses.hire.title[typeFormat],
      buttons: {
        request_budget:
          translation.congress_hostesses.hire.buttons.request_budget[
            typeFormat
          ],
      },
    },
    section: {
      text: translation.congress_hostesses.section.text[typeFormat],
    },
    other_services: {
      title: translation.congress_hostesses.other_services.title[typeFormat],
      subtitle:
        translation.congress_hostesses.other_services.subtitle[typeFormat],
      cards: {
        stand: {
          image:
            "services/congress_hostesses/Tarjetas_Servicios_Azafatas_Congresos_galeria1.jpg",
          title:
            translation.congress_hostesses.other_services.cards.stand.title[
              typeFormat
            ],
        },
        promoters: {
          image:
            "services/congress_hostesses/Tarjetas_Servicios_Azafatas_Congresos_galeria2.jpg",
          title:
            translation.congress_hostesses.other_services.cards.promoters.title[
              typeFormat
            ],
        },
        brand: {
          image:
            "services/congress_hostesses/Tarjetas_Servicios_Azafatas_Congresos_galeria3.jpg",
          title:
            translation.congress_hostesses.other_services.cards.brand.title[
              typeFormat
            ],
        },
        // assemblers: {
        //   image: "services/assemblers_hostesses.jpg",
        //   title:
        //     translation.congress_hostesses.other_services.cards.assemblers
        //       .title[typeFormat],
        // },
      },
    },
    contact_us: {
      button: {
        contact_us:
          translation.congress_hostesses.contact_us.button.contact_us[
            typeFormat
          ],
      },
    },
    footer: translation.congress_hostesses.footer[typeFormat],
  };
};

export { ServicesCongressHostessesTemplate };
