import React, { Component } from "react";
import { UserContext } from "core/UserContext";
import { Grid } from "@material-ui/core";
import { windowMobileDetection } from "core/windowMobileDetection";
import { Header } from "components/Organisms/Header";
import { Text } from "components/Atoms/Text";
import { Button } from "components/Atoms/Button";
import { ListCard } from "components/Organisms/ListCard";
import { Navbar } from "components/Organisms/NavBar";
import { Footer } from "components/Organisms/Footer";
import { getLink } from "assets/languages/i18n/links";

class ServicesHostesses extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  static contextType = UserContext;
  render() {
    return (
      <Grid container>
        {/* NavBar */}
        <Navbar
          translation={this.context.translation.templates.navbar}
          link="hostesses"
        />
        <Header
          image="services/hostesses/header.jpg"
          height={windowMobileDetection() ? "30vh" : "60vh"}
          backgroundPosition={
            windowMobileDetection() ? "top center" : "top center"
          }
          sizeImage={windowMobileDetection() ? "190%" : "100%"}
          isFixed
        >
          {/* <Grid
            container
            style={{ height: "90%" }}
            justify="center"
            alignItems="flex-end"
          >
            <Grid item xs={windowMobileDetection() ? "6" : "2"}>
              <Button
                variant="b6"
                label={this.context.translation.templates.services_hostesses.header.button.request_budget}
                fontSize="18px"
                margin="10px 0"
                weight
              />
            </Grid>
          </Grid> */}
        </Header>
        {/* SECTION HOSTESSES */}
        <Grid container>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign="center"
              margin="8px 0 4px 0"
            >
              {
                this.context.translation.templates.services_hostesses.hostesses
                  .title
              }
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "customize"}
              fontSize={windowMobileDetection() ? "18px" : "24px"}
              textAlign="center"
              margin={windowMobileDetection() ? "0 0 16px 0" : "0 0 32px 0"}
            >
              {
                this.context.translation.templates.services_hostesses.hostesses
                  .subtitle
              }
            </Text>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <ListCard
                items={Object.keys(
                  this.context.translation.templates.services_hostesses
                    .hostesses.cards
                ).map((card) => {
                  return {
                    xs: 12,
                    md: 6,
                    variant: "ImageCard",
                    sizeTitle: windowMobileDetection() ? "18px" : "28px",
                    sizeSubtitle: windowMobileDetection() ? "16px" : "22px",
                    to: this.context.translation.templates.services_hostesses
                      .hostesses.cards[card].to,
                    title:
                      this.context.translation.templates.services_hostesses
                        .hostesses.cards[card].title,
                    textLabel:
                      this.context.translation.templates.services_hostesses
                        .hostesses.cards[card].text,
                    url: this.context.translation.templates.services_hostesses
                      .hostesses.cards[card].image,
                    light: true,
                    sizeImage: windowMobileDetection() ? "120%" : "120%",
                    height: windowMobileDetection() ? "100px" : "190px",
                  };
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* SECTION DOUBT */}
        <Grid
          container
          style={{
            margin: windowMobileDetection() ? "8px 0 8px 0" : "68px 0 0 0",
            background: "#F6F8F9",
            padding: windowMobileDetection() ? "16px 0" : "32px 0",
          }}
        >
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign="center"
              margin="8px 0 8px 0"
            >
              {
                this.context.translation.templates.services_hostesses.doubt
                  .title
              }
            </Text>
          </Grid>
          <Grid item xs={1} md={5} />
          <Grid
            item
            xs={10}
            md={2}
            style={{ marginTop: "16px", marginBottom: "16px" }}
          >
            <Button
              variant="b3"
              weight
              fontSize={windowMobileDetection() ? "16px" : "22px"}
              label={
                this.context.translation.templates.services_hostesses.doubt
                  .button.contact_us
              }
              margin={windowMobileDetection() ? null : "4px 8px"}
              link={getLink("contact_us")}
            />
          </Grid>
        </Grid>
        {/* SECTION HOW WE WORK */}
        <Grid container>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign="center"
              margin={windowMobileDetection() ? "8px 0 8px 0" : "52px 0 44px 0"}
            >
              {
                this.context.translation.templates.services_hostesses
                  .how_we_work.title
              }
            </Text>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <ListCard
                items={Object.keys(
                  this.context.translation.templates.services_hostesses
                    .how_we_work.cards
                ).map((card) => {
                  return {
                    xs: 12,
                    md: 4,
                    variant: "IconCard",
                    url: this.context.translation.templates.services_hostesses
                      .how_we_work.cards[card].icon,
                    sizeTitle: windowMobileDetection() ? "20px" : "28px",
                    sizeSubtitle: windowMobileDetection() ? "16px" : "22px",
                    widthImage: "120px",
                    light: true,
                    title:
                      this.context.translation.templates.services_hostesses
                        .how_we_work.cards[card].title,
                    textLabel:
                      this.context.translation.templates.services_hostesses
                        .how_we_work.cards[card].text,
                  };
                })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={6}>
                <Text
                  variant={windowMobileDetection() ? "customize" : "p2"}
                  fontSize="18px"
                  textAlign="center"
                  margin={windowMobileDetection() ? "16px 0 16px 0" : "52px 0"}
                  padding="0 16px"
                >
                  {
                    this.context.translation.templates.services_hostesses
                      .how_we_work.footer
                  }
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* SECTION FILL YOUR EVEWNT WITH TALENT */}
        <Grid container style={{ background: "#F6F8F9" }}>
          <Grid item xs={12}>
            <Text
              variant={windowMobileDetection() ? "customize" : "h2"}
              weight
              fontSize="28px"
              textAlign="center"
              margin={windowMobileDetection() ? "8px 0 8px 0" : "28px 0 52px 0"}
            >
              {
                this.context.translation.templates.services_hostesses.talent
                  .title
              }
            </Text>
          </Grid>
          <Grid item xs={2} />
          <Grid
            item
            xs={8}
            style={{
              margin: windowMobileDetection() ? "0" : "0 0 52px 0",
              paddingBottom: "40px",
            }}
          >
            <Grid container spacing={4} justify="center">
              <ListCard
                items={Object.keys(
                  this.context.translation.templates.services_hostesses.talent
                    .cards
                ).map((card) => {
                  return {
                    xs: 6,
                    md: 3,
                    variant: "ImageCard",
                    sizeTitle: windowMobileDetection() ? "16px" : "28px",
                    title:
                      this.context.translation.templates.services_hostesses
                        .talent.cards[card].title,
                    url: this.context.translation.templates.services_hostesses
                      .talent.cards[card].image,
                    to: this.context.translation.templates.services_hostesses
                      .talent.cards[card].to,
                    height: windowMobileDetection() ? "130px" : "250px",
                    sizeImage: windowMobileDetection() ? "200%" : "110%",
                  };
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            margin: windowMobileDetection() ? "0" : "52px 0",
          }}
        >
          <Grid item xs={1} md={5} />
          <Grid item xs={10} md={2}>
            <Button
              weight
              variant="b3"
              label={
                this.context.translation.templates.services_hostesses
                  .request_budget.button.request_budget
              }
              link={getLink("budget")}
              fontSize={windowMobileDetection() ? "16px" : "22px"}
              margin={windowMobileDetection() ? null : "4px 8px"}
            />
          </Grid>
        </Grid>
        <Footer translation={this.context.translation.templates.footer} />
      </Grid>
    );
  }
}

export { ServicesHostesses };
