import React from "react";
import { Card } from "components/Organisms/Card";
import { Grid } from "@material-ui/core";

const ListCard = (props) => {
  let items = props.items.map((item) => {
    // console.log(item);
    return (
      <Grid item xs={item.xs} md={item.md}>
        <Card {...item} />
      </Grid>
    );
  });
  return items;
};

export { ListCard };
