import React from "react";
import { Text } from "components/Atoms/Text";
import PropTypes from "prop-types";
import "./style.css";
import { Image } from "components/Atoms/Image";

const CommentCard = (props) => {
  return (
    <div className="commentCard">
      <Text variant="customize" color="#7E8085" fontSize="22px" light>
        {props.text}
      </Text>
      <div className="commentCardAvatar">
        <Image url={props.image} width="32px"></Image>
        <Text
          variant="customize"
          color="#2C2E30"
          fontSize="16px"
          margin="0px 0px 0px 8px"
        >
          {props.name}
        </Text>
      </div>
    </div>
  );
};

CommentCard.prototype = {
  text: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
};

export { CommentCard };
