import { getLink } from "../../links";

const FooterTemplate = (translation) => {
  return {
    items: {
      sales: {
        label: translation.footer.items.sales,
        links: [
          {
            label: "sales@eloquence.es",
            link: "mailto:sales@eloquence.es",
          },
          {
            label: "+34 650 377 313",
            link: "tel:+34650377313",
          },
        ],
        id: "sales",
      },
      hostess: {
        label: translation.footer.items.hostess,
        links: [
          {
            label: "work@eloquence.es",
            link: "mailto:work@eloquence.es",
          },
          {
            label: "+34 910 557 420",
            link: "tel:+34910557420",
          },
        ],
        id: "hostesses",
      },
      phone: {
        label: translation.footer.items.phone,
        links: [{ label: "+34 910 55 74 20", link: "tel:+34910557420" }],
        id: "phone",
      },
      work_with_us: {
        label: translation.footer.items.work_with_us,
        link: "/work-with-us",
        id: "footer_work",
      },
      contact_us: {
        label: translation.footer.items.contact_us,
        link: getLink("contact_us"),
        id: "footer_contacto",
      },
      services: {
        label: translation.footer.items.services,
        link: getLink("services"),
        id: "footer_services",
      },
      about_us: {
        label: translation.footer.items.about_us,
        link: getLink("about_us"),
        id: "footer_nosotros",
      },
    },
  };
};
export { FooterTemplate };
