import * as React from "react";
import withInstagramFeed from "origen-react-instagram-feed";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import compose from "recompose/compose";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Text } from "../Text";
import { Image } from "../Image";

const styles = () => ({
  wrapper: {},
  image: {
    width: "100%",
    height: "auto",
  },
});

const InstaGrid = ({ classes, media, account, status, title }) => {
  return (
    <Grid container className={classes.wrapper}>
      <Grid item xs={12}>
        <a
          target="_blank"
          href="https://www.instagram.com/eloquence_es/"
          rel="noopener noreferrer"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            borderRadius: "10px",
            background: "#000",
            textDecoration: "none",
          }}
        >
          <Text
            variant="customize"
            fontSize="24px"
            color="#fff"
            textAlign="center"
          >
            {title}
          </Text>
          <InstagramIcon
            fontSize="large"
            style={{ textAlign: "center", color: "#fff", marginLeft: "8px" }}
          />
        </a>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "16px",
        }}
      ></Grid>
      {media &&
        status === "completed" &&
        media.map(({ displayImage, id, postLink, accessibilityCaption }) => (
          <Grid item xs={4} key={id || displayImage} style={{ padding: "0" }}>
            <ButtonBase
              target="_blank"
              href={postLink || `https://www.instagram.com/${account}/`}
              style={{ width: "100%" }}
              rel="noopener noreferrer"
            >
              <Image
                isUrl
                isBackground
                height="120px"
                url={displayImage}
                width="120px"
                sizeImage="150%"
                backgroundPosition="50% 50%"
              />
              {/* <img
                src={displayImage}
                alt={accessibilityCaption || "Instagram picture"}
                className={classes.image}
              /> */}
            </ButtonBase>
          </Grid>
        ))}
      {status === "loading" && <p>loading...</p>}
      {status === "failed" && <p>Check instagram here</p>}
    </Grid>
  );
};

InstaGrid.defaultProps = {
  media: undefined,
};

export default compose(withInstagramFeed, withStyles(styles))(InstaGrid);
