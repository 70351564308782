import React from "react";
import { Text } from "components/Atoms/Text";
import { Image } from "components/Atoms/Image";
import PropTypes from "prop-types";
import "./style.css";

const IconCard = (props) => {
  return (
    <div className="iconCard" style={{ textAlign: "center", marginTop: "8px" }}>
      <Image width={props.widthImage || "80px"} url={props.url} />
      <div
        style={{
          flexGrow: "2",
          display: "flex",
          justifyContent: props.textLabel ? "flex-end" : "center",
          flexDirection: "column",
        }}
      >
        <Text weight fontSize={props.sizeTitle} margin="4px 0">
          {props.title}
        </Text>
        {props.textLabel ? (
          <Text fontSize={props.sizeSubtitle} margin="8px" light={props.light}>
            {props.textLabel}
          </Text>
        ) : null}
      </div>
    </div>
  );
};

IconCard.prototype = {
  url: PropTypes.string.isRequired,
  widthImage: PropTypes.string,
  sizeTitle: PropTypes.number,
  title: PropTypes.string.isRequired,
  sizeSubtitle: PropTypes.number,
  textLabel: PropTypes.string,
};

export { IconCard };
