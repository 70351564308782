import React, { Component } from "react";
import { UserContext } from "core/UserContext";
import {
  Grid,
  Hidden,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { windowMobileDetection } from "core/windowMobileDetection";
import { Header } from "components/Organisms/Header";
import { Text } from "components/Atoms/Text";
import { Card } from "components/Organisms/Card";
import Input from "components/Atoms/Input";
import { Button } from "components/Atoms/Button";
import { Navbar } from "components/Organisms/NavBar";
import { Footer } from "components/Organisms/Footer";
import FormServices from "core/services/index.services";
import { getLink } from "assets/languages/i18n/links";
import { Redirect } from "react-router";

class Budget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business: {
        name: "",
        email: "",
        phone: "",
        city: "",
        number_hostess: "",
        profile_type: "",
        message: "",
        policies: false,
      },
      redirect_to_thanks: false,
    };
    this.service = new FormServices();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChangeInput = (event) => {
    const business = { ...this.state.business };
    business[event.target.name] = event.target.value;
    this.setState({ business });
  };

  handleChangeCheckbox = (name) => (event) => {
    const business = { ...this.state.business };
    business[name] = event.target.checked;
    this.setState({ business });
  };

  callApi = () => {
    this.service
      .contactUs(this.state.business)
      .then((response) => {
        this.setState({ redirect_to_thanks: true });
        // this.setState({
        //   business: {
        //     name: "",
        //     email: "",
        //     phone: "",
        //     city: "",
        //     number_hostess: "",
        //     message: "",
        //     policies: false,
        //   },
        // });
      })
      .catch((err) => {
        alert("Error en la petición, intentelo más tarde");
        this.setState({
          business: {
            name: "",
            email: "",
            phone: "",
            city: "",
            number_hostess: "",
            message: "",
            policies: false,
          },
        });
      });
  };

  checkObject = () => {
    if (this.state.business.name === "") {
      return false;
    }
    if (this.state.business.email === "") {
      return false;
    }
    if (this.state.business.phone === "") {
      return false;
    }
    if (this.state.business.city === "") {
      return false;
    }
    if (this.state.business.number_hostess === "") {
      return false;
    }
    if (this.state.business.message === "") {
      return false;
    }
    if (!this.state.business.policies) {
      return false;
    }
    return true;
  };

  static contextType = UserContext;
  render() {
    //TODO IMPROVE WHEN CHANGE LANGUAGE

    return this.state.redirect_to_thanks ? (
      <Redirect to={getLink("thanks")} />
    ) : (
      <Grid container>
        {/* NavBar */}
        <Navbar
          translation={this.context.translation.templates.navbar}
          link="budget"
        />

        {/* Header */}
        <Header
          image="budget/header.jpg"
          height={windowMobileDetection() ? "40vh" : "90vh"}
          backgroundPosition={
            windowMobileDetection() ? "50% center" : "top center"
          }
          sizeImage={windowMobileDetection() ? "150%" : "120%"}
        >
          <Grid
            container
            style={{
              height: windowMobileDetection() ? "100%" : "60%",
              paddingTop: windowMobileDetection() ? "14%" : "2%",
              paddingLeft: windowMobileDetection() ? "8%" : "10%",
              paddingBottom: "32px",
            }}
            alignItems={windowMobileDetection() ? "center" : null}
          >
            <Hidden mdUp>
              <Grid item xs={6}>
                <Text variant="customize" fontSize="28px" weight>
                  {this.context.translation.templates.budget.header.title}
                </Text>
              </Grid>
            </Hidden>
            <Hidden mdDown>
              <Grid item xs={6}>
                <Text variant="h2" weight fontSize="24px">
                  {this.context.translation.templates.budget.header.title}
                </Text>
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={5}>
                <Card styles={{ padding: "24px 40px 40px 40px" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Text
                        variant="customize"
                        weight
                        fontSize="24px"
                        margin="0"
                      >
                        {
                          this.context.translation.templates.budget.header.card
                            .title
                        }
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Text
                        variant="customize"
                        fontSize="20px"
                        margin="6px 0 10px 0"
                      >
                        {
                          this.context.translation.templates.budget.header.card
                            .subtitle
                        }
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        fullWidth
                        name="name"
                        id="name"
                        label={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.name.label
                        }
                        placeholder={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.name.placeholder
                        }
                        variant="outlined"
                        type="text"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.business.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        fullWidth
                        name="email"
                        id="email"
                        label={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.email.label
                        }
                        placeholder={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.email.placeholder
                        }
                        variant="outlined"
                        type="email"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.business.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        fullWidth
                        name="phone"
                        id="phone"
                        label={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.phone.label
                        }
                        placeholder={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.phone.placeholder
                        }
                        variant="outlined"
                        type="telephone"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.business.phone}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        fullWidth
                        name="city"
                        id="city"
                        label={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.city.label
                        }
                        placeholder={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.city.placeholder
                        }
                        variant="outlined"
                        type="text"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.business.city}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel
                          id="profile_type_label"
                          ref={this.refProfileType}
                        >
                          {
                            this.context.translation.templates.budget.header
                              .card.form.web.selects.profile_type.label
                          }
                        </InputLabel>
                        <Select
                          required
                          name="profile_type"
                          labelId="profile_type_label"
                          id="demo-simple-select-outlined"
                          value={this.state.profile_type}
                          onChange={this.handleChangeInput}
                          labelWidth={
                            this.context.translation.templates.budget.header
                              .card.form.web.selects.profile_type.width
                          }
                        >
                          {this.context.translation.templates.budget.header.card.form.web.selects.profile_type.items.map(
                            (item) => (
                              <MenuItem value={item.value}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        fullWidth
                        name="number_hostess"
                        id="number_hostess"
                        label={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.number_hostess.label
                        }
                        placeholder={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.number_hostess.placeholder
                        }
                        variant="outlined"
                        type="number"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.business.number_hostess}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        fullWidth
                        name="message"
                        id="message"
                        label={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.message.label
                        }
                        placeholder={
                          this.context.translation.templates.budget.header.card
                            .form.web.inputs.message.placeholder
                        }
                        variant="outlined"
                        type="number"
                        margin="dense"
                        required
                        onChange={this.handleChangeInput}
                        value={this.state.business.message}
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant={this.checkObject() ? "b1" : "b1_disabled"}
                        label={
                          this.context.translation.templates.budget.header.card
                            .form.web.buttons.send
                        }
                        color="primary"
                        fontSize="16px"
                        disabled={this.checkObject() ? false : true}
                        onClick={this.callApi}
                        margin="0"
                      />
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={this.state.business.policies}
                        onChange={this.handleChangeCheckbox("policies")}
                        color="default"
                        value="policies"
                      />
                      <Text variant="customize" margin="0 4px 0 0">
                        {
                          this.context.translation.templates.budget.header.card
                            .form.web.checkboxs.privacy.label
                        }
                      </Text>
                      {
                        this.context.translation.templates.budget.header.card
                          .form.web.checkboxs.privacy.link
                      }
                    </div>
                  </Grid>
                </Card>
              </Grid>
            </Hidden>
          </Grid>
        </Header>
        <Hidden mdUp>
          <Grid
            container
            style={{ background: "#F6F8F9", paddingBottom: "32px" }}
          >
            <Grid item xs={12}>
              <Text variant="p3" textAlign="center">
                {this.context.translation.templates.budget.subtitle}
              </Text>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Card styles={{ padding: "8px" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      name="name"
                      id="name"
                      label={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.name.label
                      }
                      placeholder={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.name.placeholder
                      }
                      variant="outlined"
                      type="text"
                      margin="dense"
                      required
                      onChange={this.handleChangeInput}
                      value={this.state.business.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      name="email"
                      id="email"
                      label={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.email.label
                      }
                      placeholder={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.email.placeholder
                      }
                      variant="outlined"
                      type="email"
                      margin="dense"
                      required
                      onChange={this.handleChangeInput}
                      value={this.state.business.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      name="phone"
                      id="phone"
                      label={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.phone.label
                      }
                      placeholder={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.phone.placeholder
                      }
                      variant="outlined"
                      type="telephone"
                      margin="dense"
                      required
                      onChange={this.handleChangeInput}
                      value={this.state.business.phone}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      name="city"
                      id="city"
                      label={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.city.label
                      }
                      placeholder={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.city.placeholder
                      }
                      variant="outlined"
                      type="text"
                      margin="dense"
                      required
                      onChange={this.handleChangeInput}
                      value={this.state.business.city}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <InputLabel
                        id="profile_type_label"
                        ref={this.refProfileType}
                      >
                        {
                          this.context.translation.templates.budget.header.card
                            .form.web.selects.profile_type.label
                        }
                      </InputLabel>
                      <Select
                        required
                        name="profile_type"
                        labelId="profile_type_label"
                        id="demo-simple-select-outlined"
                        value={this.state.profile_type}
                        onChange={this.handleChangeInput}
                        labelWidth={
                          this.context.translation.templates.budget.header.card
                            .form.web.selects.profile_type.width
                        }
                      >
                        {this.context.translation.templates.budget.header.card.form.web.selects.profile_type.items.map(
                          (item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      name="number_hostess"
                      id="number_hostess"
                      label={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.number_hostess.label
                      }
                      placeholder={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.number_hostess.placeholder
                      }
                      variant="outlined"
                      type="number"
                      margin="dense"
                      required
                      onChange={this.handleChangeInput}
                      value={this.state.business.number_hostess}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      name="message"
                      id="message"
                      label={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.message.label
                      }
                      placeholder={
                        this.context.translation.templates.budget.form.mobile
                          .inputs.message.placeholder
                      }
                      variant="outlined"
                      type="number"
                      margin="dense"
                      required
                      onChange={this.handleChangeInput}
                      value={this.state.business.message}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={this.state.business.policies}
                        onChange={this.handleChangeCheckbox("policies")}
                        color="default"
                        value="policies"
                      />
                      <Text variant="customize" margin="0 4px 0 0">
                        {
                          this.context.translation.templates.budget.form.mobile
                            .checkboxs.privacy.label
                        }
                      </Text>
                      {
                        this.context.translation.templates.budget.form.mobile
                          .checkboxs.privacy.link
                      }
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant={this.checkObject() ? "b1" : "b1_disabled"}
                      label={
                        this.context.translation.templates.budget.form.mobile
                          .buttons.send
                      }
                      color="primary"
                      disabled={this.checkObject() ? false : true}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Hidden>
        <Grid container justify="center">
          <Grid item xs={10} md={12}>
            <Text
              variant={windowMobileDetection() ? "p3" : "h2"}
              textAlign="center"
              margin="32px 0 16px 0"
            >
              {this.context.translation.templates.budget.contact_us.title}
            </Text>
          </Grid>

          <Grid item xs={10} md={3} style={{ paddingBottom: "24px" }}>
            <Card
              variant="ContactCard"
              sizeTitle={windowMobileDetection() ? "24px" : "28px"}
              sizeSubtitle={windowMobileDetection() ? "16px" : "18px"}
              callUsTitle={
                this.context.translation.templates.budget.contact_us.card
                  .call_us.title
              }
              callUsSubtitle={
                this.context.translation.templates.budget.contact_us.card
                  .call_us.text
              }
              writeUsTitle={
                this.context.translation.templates.budget.contact_us.card
                  .write_us.title
              }
              writeUsSubtitle={
                this.context.translation.templates.budget.contact_us.card
                  .write_us.text
              }
            />
            <Hidden mdUp>
              <br />
              <Button
                variant="b3"
                label={
                  this.context.translation.templates.budget.contact_us.buttons
                    .back_home.label
                }
                link={getLink("home")}
                fontSize="16px"
              />
            </Hidden>
          </Grid>
        </Grid>
        <Footer translation={this.context.translation.templates.footer} />
      </Grid>
    );
  }
}

export { Budget };
