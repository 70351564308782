const AboutUsTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";

  return {
    header: {
      title: translation.about_us.header.title[typeFormat],
      texts: {
        info: translation.about_us.header.texts.info[typeFormat],
      },
      buttons: {
        contact_us: translation.about_us.header.buttons.contact_us[typeFormat],
      },
    },
    our_values: {
      title: translation.about_us.our_values.title[typeFormat],
      cards: {
        support: {
          title:
            translation.about_us.our_values.cards.support.title[typeFormat],
          text: translation.about_us.our_values.cards.support.text[typeFormat],
        },
        excellency: {
          title:
            translation.about_us.our_values.cards.excellency.title[typeFormat],
          text:
            translation.about_us.our_values.cards.excellency.text[typeFormat],
        },
        decisives: {
          title:
            translation.about_us.our_values.cards.decisives.title[typeFormat],
          text:
            translation.about_us.our_values.cards.decisives.text[typeFormat],
        },
      },
    },
    our_history: {
      title: translation.about_us.our_history.title[typeFormat],
      text: translation.about_us.our_history.text[typeFormat],
      footer: translation.about_us.our_history.footer[typeFormat],
    },
    success_stories: {
      title: translation.about_us.success_stories.title[typeFormat],
      subtitle: translation.about_us.success_stories.subtitle[typeFormat],
      text: translation.about_us.success_stories.text[typeFormat],
      footer: translation.about_us.success_stories.footer[typeFormat],
    },
    budget: {
      buttons: {
        request_budget:
          translation.about_us.budget.buttons.request_budget[typeFormat],
      },
    },
  };
};

export { AboutUsTemplate };
