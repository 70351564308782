import React from "react";
import { Text } from "components/Atoms/Text";
import PropTypes from "prop-types";
import "./style.css";

const TextCard = props => {
  return (
    <div className="textCard">
      <Text weight fontSize={props.sizeTitle} textAlign="center" margin="16px">
        {props.title}
      </Text>
      <Text
        fontSize={props.sizeSubtitle}
        textAlign="center"
        light={props.light}
      >
        {props.textLabel}
      </Text>
    </div>
  );
};

TextCard.prototype = {
  sizeTitle: PropTypes.number,
  title: PropTypes.string.isRequired,
  sizeSubtitle: PropTypes.number,
  textLabel: PropTypes.string
};

export { TextCard };
