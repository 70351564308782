import React from "react";
import styled from "styled-components";
import ItemsCarousel from "react-items-carousel";

const autoPlayDelay = 3000;

const SlideItem = styled.div`
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
`;

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeItemIndex: 0 };
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, autoPlayDelay);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () =>
    this.setState((prevState) => ({
      activeItemIndex: prevState.activeItemIndex + 1,
    }));

  onChange = (value) => this.setState({ activeItemIndex: value });

  render() {
    return (
      <ItemsCarousel
        gutter={this.props.gutter || 12}
        infiniteLoop={this.props.autoplay ? true : false}
        numberOfCards={this.props.numberOfCards}
        activeItemIndex={this.state.activeItemIndex}
        requestToChangeActive={this.onChange}
        children={[
          this.props.items.map((item, index) => (
            <SlideItem key={"comment" + index}>{item}</SlideItem>
          )),
        ]}
      />
    );
  }
}

export { Carousel };
