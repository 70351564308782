import React, { Component } from "react";
import { UserContext } from "core/UserContext";
import { Grid, Hidden } from "@material-ui/core";
import { windowMobileDetection } from "core/windowMobileDetection";
import { Text } from "components/Atoms/Text";
import { Button } from "components/Atoms/Button";
import { ListCard } from "components/Organisms/ListCard";
import { Image } from "components/Atoms/Image";
import { Navbar } from "components/Organisms/NavBar";
import { Footer } from "components/Organisms/Footer";
import { getLink } from "assets/languages/i18n/links";

class ServicesBrandHostesses extends Component {
  constructor(props) {
    super(props);
    this.translation = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  static contextType = UserContext;
  render() {
    return (
      <Grid container>
        {/* NavBar */}
        <Navbar
          translation={this.context.translation.templates.navbar}
          link="brand_hostesses"
        />
        {/* SECTION HEADER */}
        <Grid item xs={12} style={{ marginTop: "120px" }} />
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={12}>
              <Text
                variant={windowMobileDetection() ? "customize" : "h2"}
                weight
                fontSize="28px"
                textAlign={windowMobileDetection() ? "center" : "left"}
                margin="8px 4px"
              >
                {
                  this.context.translation.templates.services_brand_hostesses
                    .header.title
                }
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text
                variant={windowMobileDetection() ? "customize" : "p2"}
                fontSize="18px"
                textAlign={windowMobileDetection() ? "center" : "left"}
                margin="0 4px 16px 4px"
                padding={windowMobileDetection() ? "0 16px" : "0"}
              >
                {
                  this.context.translation.templates.services_brand_hostesses
                    .header.texts.text_1
                }
              </Text>
            </Grid>
            <Grid item xs={1} md={false} />
            <Grid
              item
              xs={10}
              md={12}
              style={{
                height: windowMobileDetection() ? "200px" : "400px",
              }}
            >
              <Image
                isBackground
                height="100%"
                url="services/brand_hostesses/brand_1.jpg"
                width="100%"
                borderRadius="10px"
                sizeImage="120%"
                backgroundPosition="top center"
              />
            </Grid>
            <Grid item xs={12}>
              <Text
                variant={windowMobileDetection() ? "customize" : "p2"}
                fontSize="18px"
                margin="16px 8px"
                padding={windowMobileDetection() ? "0 16px" : "0"}
              >
                {
                  this.context.translation.templates.services_brand_hostesses
                    .header.texts.text_2
                }
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={false} md={2} />
        {/* SECTION HIRE */}
        <Grid item xs={12}>
          <Grid
            container
            style={{
              background: "#F6F8F9",
              padding: windowMobileDetection() ? "8px 0" : "32px 0",
            }}
          >
            <Grid item xs={12}>
              <Text
                variant={windowMobileDetection() ? "customize" : "h2"}
                weight
                fontSize="28px"
                textAlign="center"
                margin={windowMobileDetection() ? "8px 0" : "0 0 24px 0"}
              >
                {
                  this.context.translation.templates.services_brand_hostesses
                    .hire.title
                }
              </Text>
            </Grid>
            <Grid item xs={1} md={5} />
            <Grid item xs={10} md={2} style={{ paddingBottom: "40px" }}>
              <Button
                weight
                variant="b3"
                label={
                  this.context.translation.templates.services_brand_hostesses
                    .hire.buttons.request_budget
                }
                fontSize={windowMobileDetection() ? "16px" : "20px"}
                margin={windowMobileDetection() ? "4px 0" : "8px 0"}
                link={getLink("budget")}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* SECTION SECTION */}
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={8}>
          <Hidden smUp>
            <Grid container style={{ marginTop: "16px" }}>
              <Grid item xs={1} />
              <Grid
                item
                xs={10}
                style={{
                  height: "400px",
                }}
              >
                <Image
                  isBackground
                  height="100%"
                  url="services/brand_hostesses/brand_2.jpg"
                  width="100%"
                  borderRadius="10px"
                  sizeImage="140%"
                  backgroundPosition="0% 0%"
                />
              </Grid>
              <Grid item xs={12}>
                <Text
                  variant={windowMobileDetection() ? "customize" : "p2"}
                  fontSize="18px"
                  margin="16px 8px"
                  padding="0 16px"
                >
                  {
                    this.context.translation.templates.services_brand_hostesses
                      .section.text
                  }
                </Text>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid container style={{ marginTop: "16px", padding: "32px 0" }}>
              <Grid item md={6}>
                <Text
                  variant={windowMobileDetection() ? "customize" : "p2"}
                  fontSize="18px"
                  margin="16px 0 16px 0"
                  padding="0 16px"
                >
                  {
                    this.context.translation.templates.services_brand_hostesses
                      .section.text
                  }
                </Text>
              </Grid>

              <Grid
                item
                md={6}
                style={{
                  height: "500px",
                }}
              >
                <Image
                  isBackground
                  height="100%"
                  url="services/brand_hostesses/brand_2.jpg"
                  width="100%"
                  borderRadius="10px"
                  sizeImage="100%"
                  backgroundPosition="top center"
                />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={false} md={2} />
        {/* SECTION OTHER SERVICES */}
        <Hidden smDown>
          <Grid item xs={12}>
            <Grid
              container
              style={{
                background: "#F6F8F9",
                padding: windowMobileDetection() ? "8px 0" : "64px 0",
              }}
            >
              <Grid item xs={2} />
              <Grid
                item
                xs={8}
                style={{ paddingBottom: "40px", paddingTop: "16px" }}
              >
                <Grid container spacing={4} justify="center">
                  <ListCard
                    items={Object.keys(
                      this.context.translation.templates
                        .services_brand_hostesses.other_services.cards
                    ).map((card) => {
                      return {
                        xs: 6,
                        md: 3,
                        variant: "ImageCard",
                        // sizeTitle: windowMobileDetection() ? "16px" : "28px",
                        // title: this.context.translation.templates.services_brand_hostesses.other_services.cards[card]
                        //   .title,
                        url: this.context.translation.templates
                          .services_brand_hostesses.other_services.cards[card]
                          .image,
                        height: windowMobileDetection() ? "100px" : "260px",
                        sizeImage: windowMobileDetection() ? "100%" : "110%",
                        backgroundPosition: "top center",
                      };
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{ paddingTop: "16px" }}>
              <Grid item xs={1} md={2} />
              <Grid item xs={10} md={8}>
                <Text
                  variant={windowMobileDetection() ? "customize" : "p2"}
                  fontSize="18px"
                  textAlign="center"
                  margin="16px 0 16px 0"
                  padding="0 16px"
                >
                  {
                    this.context.translation.templates.services_brand_hostesses
                      .footer
                  }
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Footer translation={this.context.translation.templates.footer} />
      </Grid>
    );
  }
}

export { ServicesBrandHostesses };
