const ServicesBrandHostessesTemplate = (translation) => {
  const typeFormat = window.innerWidth > 576 ? "web" : "mobile";
  return {
    header: {
      title: translation.brand_hostesses.header.title[typeFormat],
      texts: {
        text_1: translation.brand_hostesses.header.texts.text_1[typeFormat],
        text_2: translation.brand_hostesses.header.texts.text_2[typeFormat],
      },
    },
    hire: {
      title: translation.brand_hostesses.hire.title[typeFormat],
      buttons: {
        request_budget:
          translation.brand_hostesses.hire.buttons.request_budget[typeFormat],
      },
    },
    section: {
      text: translation.brand_hostesses.section.text[typeFormat],
    },
    other_services: {
      title: translation.brand_hostesses.other_services.title[typeFormat],
      subtitle: translation.brand_hostesses.other_services.subtitle[typeFormat],
      cards: {
        stand: {
          image:
            "services/brand_hostesses/Tarjetas_Servicios_Azafatas_Imagen_galeria1.jpeg",
          title:
            translation.brand_hostesses.other_services.cards.stand.title[
              typeFormat
            ],
        },
        promoters: {
          image:
            "services/brand_hostesses/Tarjetas_Servicios_Azafatas_Imagen_galeria2.jpeg",
          title:
            translation.brand_hostesses.other_services.cards.promoters.title[
              typeFormat
            ],
        },
        brand: {
          image:
            "services/brand_hostesses/Tarjetas_Servicios_Azafatas_Imagen_galeria3.jpeg",
          title:
            translation.brand_hostesses.other_services.cards.brand.title[
              typeFormat
            ],
        },
        // assemblers: {
        //   image: "services/brand_hostesses/Tarjetas_Servicios_Azafatas_Imagen_galeria1.jpeg",
        //   title:
        //     translation.brand_hostesses.other_services.cards.assemblers.title[
        //       typeFormat
        //     ],
        // },
      },
    },
    contact_us: {
      button: {
        contact_us:
          translation.brand_hostesses.contact_us.button.contact_us[typeFormat],
      },
    },
    footer: translation.brand_hostesses.footer[typeFormat],
  };
};

export { ServicesBrandHostessesTemplate };
